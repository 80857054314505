/* Utils class for showing ADED search results in map using GeoJSONLayers */
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import MapView from '@arcgis/core/views/MapView';
import { getLayer, transformExtent } from ".";
import { ALERT_TYPE, LAYER_TYPE, WKID_LATLON, WKID_UTM33 } from "../constants";
import { setLeftMenuVisibility, showAlert, showLoading } from "../store/appSlice";
import { GeoJSONFeature, GeoJSONResponse, LayerInfo } from "../types";
import { replaceInType } from "./adedHelper";

/**
 * Get group layer for grouping search result in Table of Contents
 * @param id id of group layer
 * @param caption caption (display name) for group layer
 * @returns Group layer
 */
export const getGroupLayer = (id: string, caption: string): GroupLayer | null => {
    const groupLayer = getLayer({
        id: id,
        type: LAYER_TYPE.GROUP,
        caption: caption,
        hidden: false
    });
    
    if(groupLayer) {
        return groupLayer as GroupLayer;
    }

    return null;
};

/**
 * Get GeoJSONLayer from ADED search result
 * @param dispatch react useDispatch hook
 * @param map application map instance
 * @param geojson geojson search result to add to layer
 * @param cluster whether or not to group elements in layer
 * @param featuretype type of feature in layer (point, line, polygon)
 * @returns GeoJSONLayer containing given ADED search result elements
 */
export const getSearchLayer = (dispatch: any, map: MapView, geojson: GeoJSONResponse, cluster: boolean, featuretype?: "point" | "line" | "polygon"): GeoJSONLayer => {
    const geometryType = geojson.features[0].geometry.type;

    const str = JSON.stringify(geojson);
    const blob = new Blob([str], {type: "application/json" });

    let caption = "";
    if(cluster) caption = "Oversikt";
    if(featuretype === "polygon") caption = "Flater";
    if(featuretype === "line") caption = "Linjer";
    if(featuretype === "point") caption = "Punkter";

    const slConfig:LayerInfo = {
        id: "searchresult-" + geometryType,
        type: LAYER_TYPE.GEOJSON,
        caption: caption,
        url: URL.createObjectURL(blob),
        featuretype: featuretype
    }

    if(cluster) {
        slConfig.maxScale = 4001;
        slConfig.enableClustering = true;
    }
    else {
        slConfig.minScale = 4000;
    }

    let layer = getLayer(slConfig) as GeoJSONLayer;

    if(cluster) {
        layer.on("layerview-create", (event) => {
            dispatch(showLoading(false));

            const l = (event.layerView.layer as GeoJSONLayer);
            const ext = transformExtent(l.fullExtent, WKID_LATLON, WKID_UTM33);
            map.goTo(ext.expand(3));

            l.queryFeatures().then((result) => {
                console.log(result.features.length)
                // if(result.features.length > 1000) {
                //     dispatch(showAlert({title: "For mange treff", message: "Viser 1000", type: ALERT_TYPE.SEARCH, manualClose: true, 
                //             button: {caption: "Begrens søket", tooltip: "Åpne filtreringspanelet", action: setLeftMenuVisibility(true)}}));
                // }
            }).catch((error: any) => {
                console.error("Error on querying point search layer: " + error.message);
            });
        });

        layer.on("layerview-create-error", (event) => {
            console.log(event);
            dispatch(showLoading(false));
        });
    }

    return layer;
};

/**
 * Prepare feature attributes and properties for use in ArcGIS
 * Esri disapproves of Attributter-property
 * Some properties contain parts we want to remove, like "Lag_", "Hus_"
 * @param f feature to prepare
 */
export const makeFeatureDisplayable = (f: GeoJSONFeature) => {
    delete f.properties.Attributter; //  :(

    f.properties.ObjektHovedType = replaceInType(f.properties.ObjektHovedType);
    f.properties.ObjektType = replaceInType(f.properties.ObjektType);
};