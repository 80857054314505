/* Configuration values for artefact data */
import { Museum } from "../types";

export const museums:Array<Museum> = [
    {
        code: "AM",
        name: "Arkeologisk Museum i Stavanger"
    },
    {
        code: "KHM",
        name: "Kulturhistorisk museum i Oslo"
    },
    {
        code: "UM",
        name: "Universitetsmuseet i Bergen"
    },
    {
        code: "UMAK",
        name: "Norges arktiske universitetsmuseum og akademi for kunstfag i Tromsø"
    },
    {
        code: "VM",
        name: "NTNU Vitenskapsmuseet i Trondheim"
    }
];