/* app state - search */
import { Filter, FilterValue, SearchState } from "../types";
import { createSlice, current } from "@reduxjs/toolkit"; // use current() to peek at state object when using Immer API
import { ADED, Artefact, MapHit, ProjectTypeKey, RANGE_FILTER, VALUE_FILTER } from "../constants";
import { parseCurrentFilters } from "../utils/adedHelper";

const SetValueForValueFilter = (savedFilter: Filter, stateFilter: Filter) => {
    if (savedFilter && savedFilter.values?.length) {
        const caption = savedFilter.values[0].caption;
        const alterValue = stateFilter?.values?.find((v: FilterValue) => v.caption === caption);
        if (alterValue) {
            alterValue.active = true;
        }
    }
};

const initialState: SearchState = {
    searchQueryADED: "",
    searchQueryArtefact: "",
    searchQueryMaphit: "",
    selectedSearchBase: ADED,
    adedResults: null,
    artefactResults: null,
    maphitResults: null,
    resultFilters: [],
    artefactFilters: {
        museum: null,
        requirePhoto: false,
        museumNO: null,
        county: null,
        municipality: null
    },
    maxHits: 50,
    currentPage: 1,
    sortField: {
        field:"",
        order:"asc"
    },
    selectedItem: null,
    adedPanelsOpen: false,
    artefactPanelsOpen: false,
    shareDialogVisible: false,
    urlToShare: null,
    geoBBox: null
};

const searchSlice = createSlice({
    name: "search",
    initialState: initialState,
    reducers: {
        setSearchResult(state, action) {
            if(action.payload.type === ADED) {
                state.adedResults = action.payload;
            }
            if(action.payload.type === Artefact) {
                state.artefactResults = action.payload;
            }
            if(action.payload.type === MapHit) {
                state.maphitResults = action.payload;
            }
        },
        getSearchResult(state, action) {
            if(action.payload.type === ADED) {
                state.searchQueryADED = action.payload.phrase;
            }
            if(action.payload.type === Artefact) {
                state.searchQueryArtefact = action.payload.phrase;
            }
            if(action.payload.type === MapHit) {
                state.searchQueryMaphit = action.payload.phrase;
            }
        },
        clearADEDSearchResult(state) {
            state.searchQueryADED = "";
            state.adedResults = null;
            state.currentPage = 1;
            state.selectedItem = null;
        },
        clearArtefactSearchResult(state) {
            state.searchQueryArtefact = "";
            state.artefactResults = null;
            state.currentPage = 1;
            state.selectedItem = null;
        },
        clearMapHitSearchResult(state) {
            state.searchQueryMaphit = "";
            state.maphitResults = null;
        },
        setSearchResultBase(state, action) {
            state.selectedSearchBase = action.payload;
            state.currentPage = 1;
            state.selectedItem = null;
        },
        setSearchResultFilters(state, action) {
            // force alphabetical order
            action.payload.forEach((f: Filter) => {
                if(f.filtertype === VALUE_FILTER) {
                    const sortedValues = f.values?.sort((a,b) => {
                        return a.caption.localeCompare(b.caption);
                    });

                    f.values = sortedValues;
                }
            });

            state.resultFilters = action.payload;
        },
        updateSearchResultFilters(state, action) {
            const fs = parseCurrentFilters(action.payload);

            if (fs.length) {
                // Update noHits for existing value-filters
                state.resultFilters.forEach((f: Filter) => {
                    if(f.filtertype === VALUE_FILTER) {
                        f.values?.forEach((v: FilterValue) => {
                            let updated = false;
                            const upd = fs.filter((x: Filter) => { return x.typeKey === f.typeKey });
                            if(upd.length) {
                                let updToUpdate = upd[0];
                                if(f.typeKey !== ProjectTypeKey && typeof(v.value) === "string" && v.value.indexOf("|") > -1) {
                                    const t = v.value.split("|")[0];
                                    updToUpdate = upd.filter(f => f.type === t)[0];
                                }
                                if(updToUpdate) {
                                    const updValue = updToUpdate.values?.filter((xv: FilterValue) => { return xv.value === v.value });
                                    if(updValue && updValue.length) {
                                        v.noHits = updValue[0].noHits;
                                        updated = true;
                                    }
                                }
                            }
                            if(!updated) {
                                v.noHits = 0;
                            }
                        });

                        const sortedValues = f.values?.sort((a,b) => {
                            // Aktive først
                            if(a.active && !b.active) {
                                return -1;
                            }
                            if(b.active && !a.active) {
                                return 1;
                            }

                            // Så de med treff
                            if((a.noHits && a.noHits > 0) && (!b.noHits || b.noHits === 0)) {
                                return -1;
                            }
                            if((b.noHits && b.noHits > 0) && (!a.noHits || a.noHits === 0)) {
                                return 1;
                            }

                            // Alt alfabetisk
                            return a.caption.localeCompare(b.caption);
                        });

                        f.values = sortedValues;
                    }
                });
            }
        },
        toggleFilter(state, action) {
            if(action.payload.values && action.payload.values.length > 0) {
                state.resultFilters.forEach((f: Filter) => {
                    if(f.typeKey === action.payload.typeKey) {
                        const vals = f.values?.filter(v => {return v.value === action.payload.values[0].value});
                        if(vals && vals.length > 0) {
                            vals[0].active = !vals[0].active;
                        }
                    }
                });

                state.currentPage = 1;
            }
        },
        resetADED(state) {
            state.resultFilters.forEach((f: Filter) => {
                f.values?.forEach((v: FilterValue) => {
                    v.active = false;
                    if (f.filtertype === RANGE_FILTER) {
                        v.value = null;
                    }
                });
            });

            state.searchQueryADED = ""; // reset search query also
            state.currentPage = 1;
        },
        applySavedSearch(state, action) {
            const savedFilters: Filter[] = action.payload.filters;
            const searchPhrase = action.payload.searchPhrase;
            state.resultFilters.forEach((stateFilter: Filter) => {
                stateFilter.values?.map((fv: FilterValue) => fv.active = false);
                savedFilters.forEach((savedFilter: Filter) => {
                    if (stateFilter.typeKey === "ObjektType.keyword" && stateFilter.type === savedFilter.type && stateFilter.values?.length) {
                        if (savedFilter.values?.length) {
                            SetValueForValueFilter(savedFilter, stateFilter);
                        }
                    }
                    else if (stateFilter.typeKey !== "ObjektType.keyword" && stateFilter.typeKey === savedFilter.typeKey && stateFilter.values?.length) {
                        if (savedFilter.filtertype === "value") {
                            SetValueForValueFilter(savedFilter, stateFilter);
                        }
                        if (savedFilter.filtertype === "range") {
                            stateFilter.values = savedFilter.values;
                        }
                    }
                    else if (stateFilter.typeKey === ProjectTypeKey && savedFilter.typeKey === "ProsjektId.keyword" && savedFilter.values?.length) {
                        const projectId = savedFilter.values[0].value;
                        const value = stateFilter.values?.find((v: FilterValue) => v.value.includes(projectId));
                        if (value) {
                            value.active = true;
                        }
                    }
                });
            });
            state.currentPage = 1;
            state.searchQueryADED = searchPhrase;
            state.geoBBox = action.payload.geobbox;
        },
        deactivateFilters(state, action) {
            const types = action.payload.map((f: Filter) => { return f.type });

            state.resultFilters.forEach((f: Filter) => {
                if (!types.length || types.indexOf(f.type) !== -1) {
                    f.values?.forEach((v: FilterValue) => {
                        v.active = false;
                        // if (f.filtertype === RANGE_FILTER) {
                        //     v.value = null;
                        // }
                    });
                }
            });

            state.currentPage = 1;
        },
        activateFilters(state, action) {
            const types = action.payload.map((f: Filter) => { return f.type });

            state.resultFilters.forEach((f: Filter) => {
                if(types.indexOf(f.type) !== -1) {
                    f.values?.forEach((v: FilterValue) => {
                        if(f.filtertype === RANGE_FILTER) {
                            f.values?.forEach((v: FilterValue) => {
                                if(!isNaN(v.value) && v.value !== null) {
                                    v.active = true;
                                }
                            });
                        }
                        else {
                            v.active = true;
                        }
                    });
                }
            });

            state.currentPage = 1;
        },
        initAndActivateFilterValue(state, action) {
            const filterValue: FilterValue = action.payload.filterValue;
            const projectId: string = action.payload.projectId;

            const equals = (val1: any, val2: any): boolean => {
                if(typeof(val1) === "string" && typeof(val2) === "string") {
                    return val1.toLowerCase() === val2.toLowerCase();
                }

                return val1 === val2;
            }

            state.resultFilters.forEach((f: Filter) => { 
                if (filterValue && f.values?.some((fv: FilterValue) => equals(fv.value, filterValue.value))) {
                    f.values?.forEach((v: FilterValue) => {
                        v.active = false;
                        if (f.filtertype === RANGE_FILTER) {
                            v.value = null;
                        }
                    });
                    const entryToModify = f?.values?.find((x: FilterValue) => equals(x.value, filterValue.value));
                    if (entryToModify) {
                        entryToModify.active = true;
                    }
                }
                else { // deactivate everything else
                    f.values?.forEach((v: FilterValue) => {
                        v.active = false;
                        if (f.filtertype === RANGE_FILTER) {
                            v.value = null;
                        }
                    });
                }
            });

            if(projectId) {
                // activate project
                const project = state.resultFilters.find((x: Filter) => x.typeKey === ProjectTypeKey);
                if (project) {
                    project.values?.forEach((v: FilterValue) => {
                        if (v.value.includes(projectId)) {
                            v.active = true;
                        }
                    });
                }
            }

            state.searchQueryADED = ""; // reset search query also
            state.geoBBox = null; // reset geo boundingbox also
            state.currentPage = 1;
        },
        updateFilter(state, action) {
            if(action.payload.values && action.payload.values.length > 0) {
                if(action.payload.typeKey === "Prosjektnavn") {
                    // Skal skru på eller av den som kommer inn, og skru AV alle andre
                    const payloadCaption = action.payload.values[0].caption;
                    const payloadActive = action.payload.values[0].active;

                    state.resultFilters.forEach((f: Filter) => {
                        if(f.typeKey === action.payload.typeKey) {
                            f.values?.forEach((v: FilterValue) => {
                                if(v.caption === payloadCaption) {
                                    v.active = payloadActive;
                                }
                                else {
                                    v.active = false;
                                }
                            });
                        }
                    });
                }
                else if(action.payload.filtertype === RANGE_FILTER) {
                    // Skal sette active true på alle values som faktisk har verdi 
                    const payloadCaption = action.payload.values[0].caption;
                    const payloadValue = action.payload.values[0].value;

                    state.resultFilters.forEach((f: Filter) => {
                        if(f.typeKey === action.payload.typeKey) {
                            f.values?.forEach((v: FilterValue) => {
                                if(v.caption === payloadCaption) {
                                    v.value = payloadValue;
                                }
        
                                v.active = ((isNaN(v.value) || v.value === null ) ? false : true);
                            });
                        }
                    });
                }
                
                state.currentPage = 1;
            }
        },
        setArtefactRequirePhoto(state, action) {
            state.artefactFilters.requirePhoto = action.payload;
            state.currentPage = 1;
        },
        setArtefactMuseum(state, action) {
            state.artefactFilters.museum = action.payload;
            state.currentPage = 1;
        },
        setArtefactMuseumNO(state, action) {
            state.artefactFilters.museumNO = action.payload;
            state.currentPage = 1;
        },
        setArtefactCounty(state, action) {
            state.artefactFilters.county = action.payload;
            state.currentPage = 1;
        },
        setArtefactMunicipality(state, action) {
            state.artefactFilters.municipality = action.payload;
            state.currentPage = 1;
        },
        searchForArtefactMuseumNO(state, action) {
            state.searchQueryArtefact = "";
            state.artefactFilters = {
                museum: null,
                requirePhoto: false,
                museumNO: action.payload,
                county: null,
                municipality: null
            };
            state.selectedSearchBase = Artefact;
            state.currentPage = 1;
        },
        resetArtefact(state) {
            state.artefactFilters.requirePhoto = false;
            state.artefactFilters.museum = null;
            state.artefactFilters.museumNO = null;
            state.artefactFilters.county = null;
            state.artefactFilters.municipality = null;
            state.searchQueryArtefact = "";
            state.currentPage = 1;
            state.sortField = {field:"", order:"asc"};
        },
        setMaxHits(state, action) {
            state.maxHits = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setSortField(state, action) {
            state.sortField = action.payload;
        },
        setSelectedItem(state, action) {
            state.selectedItem = action.payload;
        },
        setAdedPanelsOpen(state, action) {
            state.adedPanelsOpen = action.payload;
        },
        setArtefactPanelsOpen(state, action) {
            state.artefactPanelsOpen = action.payload;
        },
        setShareDialogVisible(state, action) {
            state.shareDialogVisible = action.payload;
        },
        setUrlToShare(state, action) {
            state.urlToShare = action.payload;
        },
        setGeoBBox(state, action) {
            state.currentPage = 1;
            state.geoBBox = action.payload;
        }
    }
});

export const {setSearchResult, getSearchResult, setSearchResultBase, 
    clearADEDSearchResult, clearArtefactSearchResult, clearMapHitSearchResult,
    setSearchResultFilters, updateSearchResultFilters, toggleFilter, applySavedSearch, deactivateFilters, activateFilters, 
    initAndActivateFilterValue, updateFilter, resetADED, setArtefactRequirePhoto, setArtefactMuseum, setArtefactMuseumNO, 
    setArtefactCounty, setArtefactMunicipality, resetArtefact, searchForArtefactMuseumNO, setMaxHits, setCurrentPage, setSortField,
    setSelectedItem, setAdedPanelsOpen, setArtefactPanelsOpen, setShareDialogVisible, setUrlToShare, setGeoBBox} = searchSlice.actions;

export default searchSlice.reducer;
