/* String constants for map layers */
export const WMS = "wms";
export const FEATURE = "feature";
export const GEOJSON = "geojson";
export const VECTORTILE = "vectortile";
export const GRAPHIC = "graphic";
export const GROUP = "group";
export const IMAGE = "image";
export const TILE = "tile";

export enum LAYER_TYPE { WMS, FEATURE, GEOJSON, VECTORTILE, GRAPHIC, GROUP, IMAGE, TILE };