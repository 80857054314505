/* app state - user */
import { createSlice } from '@reduxjs/toolkit'
import { RoleEnum, UserState } from '../types';

const initialState: UserState = {
    user: null,
    isLoadingUser: false,
    signin: false,
    error: null,
    role: RoleEnum.Anonym
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        signIn(state) {
            state.signin = true;
        },
        userExpired(state) {
            state.user = null;
            state.signin = false;
            state.isLoadingUser = false;
        },
        silentRenewError(state, action) {
            state.user = null;
            state.signin = false;
            state.isLoadingUser = false;
            state.error = action.payload;
        },
        sessionTerminated(state) {
            state.user = null;
            state.signin = false;
            state.isLoadingUser = false
        },
        userSignedOut(state) {
            state.user = null;
            state.signin = false;
            state.isLoadingUser = false;
        },
        userFound(state, action) {
            state.user = action.payload;
            state.signin = false;
            state.isLoadingUser = false;
        },
        loadingUser(state) {
            state.isLoadingUser = true;
            state.signin = false;
        },
        setRole(state, action) {
            state.role = action.payload as RoleEnum;
        }
    }
});

export default userSlice.reducer;

export const { signIn, userExpired, silentRenewError, sessionTerminated, userSignedOut, userFound, loadingUser, setRole } = userSlice.actions;
