/* Utils class calling map services using identify */
import axios from "axios";

export default class MapService {
    /**
     * 
     * @param serviceUrl url to map service
     * @param x x-coordinate
     * @param y y-coordinate
     * @param ex1 x1 in boundingbox for query
     * @param ex2 x2 in boundingbox for query
     * @param ex3 y1 in boundingbox for query
     * @param ex4 y2 in boundingbox for query
     * @param h height of map view
     * @param w width of map view
     * @returns identify result, contents depends on map service
     */
    static identify(serviceUrl: string, x:number, y: number, ex1: number, ex2: number, ex3: number, ex4: number, h:number, w:number): Promise<any> {

        // ex: 
        // https://hoydedata.no/arcgis/rest/services/DTM/ImageServer/identify?f=json&
        // returnFieldName=false&returnGeometry=false&returnUnformattedValues=false&returnZ=false&tolerance=3&imageDisplay=1920%2C920%2C96&
        // geometry=%7B%22x%22%3A113917.11116755567%2C%22y%22%3A6877588.688510709%7D&geometryType=esriGeometryPoint&sr=25833&
        // mapExtent=-1075482.600965202%2C6576852.087037507%2C1525482.600965202%2C7823147.912962493&layers=top
        
        const urlParams = "f=json&returnFieldName=false&returnGeometry=false&returnUnformattedValues=false&returnZ=false&tolerance=3&layers=top&imageDisplay=" + w + "%2C" + h + "%2C96";
        const geometryParams = "&geometryType=esriGeometryPoint&sr=25833&geometry=%7B%22x%22%3A" + x.toString().replace(",", ".") + "%2C%22y%22%3A" + y.toString().replace(",", ".") + "%7D";
        const extentParam = "&mapExtent=" + ex1.toString().replace(",", ".") + "%2C" + ex2.toString().replace(",", ".") + "%2C" + ex3.toString().replace(",", ".") + "%2C" + ex4.toString().replace(",", ".");

        return new Promise((resolve, reject) => {
            axios.get(serviceUrl + "/identify?" + urlParams + geometryParams + extentParam)
            .then(function (response) {
                resolve({result: response.data, error: null});
            })
            .catch(function (error) {
                resolve({result: [], error: error});
            })
            .then(function () {
                // always executed
            });
        });
    }
};