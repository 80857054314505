/* Home page for App, containing some project info and links to UiO */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "antd";

import { ADED, Artefact, MapHit } from "../../constants";
import { searchbase } from "../../types";
import { setSearchResultBase } from "../../store/searchSlice";

import backdropImg from "../../images/home_backdrop.jpg";
import rectanglesImg from "../../images/home_rectangles.png";
import mapImg from "../../images/home_map.png";
import adedImg from "../../images/home_aded.png";
import artefactImg from "../../images/home_artefact.png";
import "../App.css";
import "./home.css";

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const setSearchBase = (sb: searchbase) => {
        dispatch(setSearchResultBase(sb));
        navigate("/");
    }

    return (
        <div className="home">
            <img src={backdropImg} alt="ADED - Archaeological Digital Excavation Documentation" />
            <div className="home-overlay">
                <div className="home-main">
                    <div className="home-main-part">
                        <div className="home-header">
                            <Button type="link" onClick={() => setSearchBase(ADED)} title="Utforsk utgravninger" >
                                Archaeological<br/> 
                                Digital<br/> 
                                Excavation<br/>
                                Documentation<br/>
                            </Button>
                            <img src={rectanglesImg} alt="Header separator" />
                        </div>
                        <div className="home-exclamation">
                            Utgravninger i Norge <br />
                            samlet på ett sted!
                        </div>
                        <div className="home-text" style={{zIndex: 50}}>
                            <div>
                                ADED (Archaeological Digital Excavation Documentation) har som mål å få samlet alle utgravninger i Norge på ett sted.
                            </div>
                            <div>
                                Nye utgravninger vil bli registrert her og det vil etterhvert også bli registrert eldre utgravinger.
                            </div>
                            <div>
                                Det er også mulige å se hvor alle registrerte gjenstander har blitt funnet.
                            </div>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" title="Gå til UiOs sider for å lese mer om ADED-prosjektet"
                                    href="https://www.khm.uio.no/forskning/prosjekter/archaeological-digital-excavation-documentation/">
                                    Les mer på UiOs sider
                                </a>
                            </div>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.duo.uio.no/">
                                    Se rapporter fra utgravninger
                                </a>
                            </div>
                            <div className="home-citations">
                                Enkeltprosjekter hentet fra ADED siteres ved henvisning til prosjektets egen publikasjon. <br />
                                Materiale hentet fra ADED som består av flere prosjekter siteres ved henvisning til ADED 
                                med tid og dato for nedlasting av datasettet.
                            </div>
                        </div>
                    </div>
                    <div className="home-main-part">
                        <div className="home-buttons">
                            <div>
                                <Button type="link" onClick={() => setSearchBase(MapHit)} title="Utforsk kartet" >
                                    <img src={mapImg} alt="Kart" />
                                    <div className="home-button-header">
                                        Kart
                                    </div>
                                    <div className="home-button-info">
                                        Søk etter et sted og se hva som finnes i kartet.
                                    </div>
                                </Button>
                            </div>
                            <div>
                                <Button type="link" onClick={() => setSearchBase(ADED)} title="Utforsk utgravninger" >
                                    <img src={adedImg} alt="Utgravninger" />
                                    <div className="home-button-header">
                                        Utgravninger
                                    </div>
                                    <div className="home-button-info">
                                        Se registrerte utgravninger.
                                    </div>
                                </Button>
                            </div>
                            <div>
                                <Button type="link" onClick={() => setSearchBase(Artefact)} title="Utforsk gjenstander" >
                                    <img src={artefactImg} alt="Gjenstander" />
                                    <div className="home-button-header">
                                        Gjenstander
                                    </div>
                                    <div className="home-button-info">
                                        Se alle gjenstander som har blitt registrert i Norge gjennom tidene.
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
