/* app state - administration */
import { createSlice } from '@reduxjs/toolkit'
import { AdminState } from '../types';

const initialState: AdminState = {
    dialogVisible: false
};

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        setUserAdminDialogVisible(state, action) {
            state.dialogVisible = action.payload;
        }
    }
});

export const { setUserAdminDialogVisible } = adminSlice.actions;

export default adminSlice.reducer;