/* Container for all search functionality */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { Button, Tabs } from "antd";

import { searchbase, SearchResult, StoreState } from "../../types";
import { setSearchResultBase } from "../../store/searchSlice";
import { setLeftMenuVisibility } from "../../store/appSlice";
import { ADED, Artefact, MapHit } from "../../constants";
import MapHitSearch from "./maphit/maphitsearch";
import ADEDSearch from "./aded/adedsearch";
import ArtefactSearch from "./artefact/artefactsearch";
import { getPublicSearchQueries } from "../../services/userservice";
import { setPublicSearchQueries } from "../../store/exportSlice";

import panelRightImg from "../../images/panel_right.png";
import panelLeftImg from "../../images/panel_left.png";
import "./leftmenu.css";

const LeftMenu = () => {
    const dispatch = useDispatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    
    const visible: boolean = useSelector((state: StoreState) => state.app.leftMenuOpen);
    const leftMenuTopVisible: boolean = useSelector((state: StoreState) => state.app.leftMenuTopVisible);
    const selectedSearchBase: searchbase | null = useSelector((state: StoreState) => state.search.selectedSearchBase);
    const adedResults: SearchResult | null = useSelector((state: StoreState) => state.search.adedResults);
    const artefactResults: SearchResult | null = useSelector((state: StoreState) => state.search.artefactResults);
    const maphitResults: SearchResult | null = useSelector((state: StoreState) => state.search.maphitResults);  

    useEffect(
        () => {
            
        }, [selectedSearchBase]
    );

    if(!initialized) {
        setInitialized(true);
        getPublicSearchQueries().then((response: any) => {
            if (response.status === 200) {
                dispatch(setPublicSearchQueries(response.data));
            }
        });
    }

    useHotkeys("ctrl+alt+s", () => {
        dispatch(setLeftMenuVisibility(true));
        
        // Set focus on first tab, small timeout to allow rendering

        setTimeout(() => {
            const focusElements = document.getElementsByClassName("ant-tabs-tab-btn");
            if(focusElements.length > 0) {
                (focusElements.item(0) as HTMLElement).focus();
            }
        }, 300);
    });

    const getResultTypeCaption = (sb: searchbase):string => {
        if(sb === ADED) {
            return "Utgravning";
        }
        if(sb === Artefact) {
            return "Gjenstand";
        }
        if(sb === MapHit) {
            return "Sted";
        }

        return "";
    } 

    const renderBtn = (sb: searchbase, noHits: number) => {
        return (
            <span className="resultbase-caption">
                {getResultTypeCaption(sb)}
            </span>
        );
    };
    
    const renderResultBaseSelector = () => {
        const tabItems = [
            { label: renderBtn(ADED, adedResults?.result.hits?.total.value), key: ADED, children: <ADEDSearch /> }, // remember to pass the key prop
            { label: renderBtn(Artefact, artefactResults?.result.totalHits), key: Artefact, children: <ArtefactSearch /> },
            { label: renderBtn(MapHit, maphitResults?.result.hits?.length), key: MapHit, children: <MapHitSearch /> },
        ];

        return(
            <Tabs items={tabItems} style={{marginTop: (leftMenuTopVisible ? "10px" : "-50px"), height: "calc(100% - " + (leftMenuTopVisible ? "10px" : "-50px") + ")"}} 
                  defaultActiveKey={selectedSearchBase || ADED} activeKey={selectedSearchBase || ADED}
                  onChange={(sb) => dispatch(setSearchResultBase(sb))} >
            </Tabs>
        )
    };

    return (
        <div className={`left-menu ${!visible ? "move-left" : ""}`}>
            <div className={`left-menu-content ${!visible ? "remove-element" : ""}`} >
                {renderResultBaseSelector()}
            </div>
            <Button type="default" className="drawer-btn" onClick={() => dispatch(setLeftMenuVisibility(!visible))} hidden={visible} >
                <img src={panelRightImg} alt="Open" className="btn-icon" />
            </Button>

            <div className="shortcut-hint" hidden >CTRL + ALT + F</div>

            <Button type="link" className="drawer-btn drawer-open" onClick={() => dispatch(setLeftMenuVisibility(!visible))} hidden={!visible} >
                <img src={panelLeftImg} alt="Close" className="btn-icon" />
            </Button>
        </div>
    );
}

export default LeftMenu;
