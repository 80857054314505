/* UI for filtering ADED objects on given properties */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Collapse, Checkbox, Button, Input, Tooltip } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";

import { StoreState, Filter, FilterValue, HighlightAttributeFilter, SortField } from "../../../types";
import * as conf from "../../../config/SearchConfig";
import { highlightAttributeFilters } from "../../../config/ADEDConfig";
import { RANGE_FILTER, VALUE_FILTER, DISPLAY_TYPE, ProjectTypeKey } from "../../../constants";
import SearchData from "../../../services/searchservice";
import { getActiveFilterValues, isActiveValuesChanged, parseCurrentFilters } from "../../../utils/adedHelper";
import { navigateApp, onlyUnique } from "../../../utils";
import { setSearchResult, updateSearchResultFilters, updateFilter, deactivateFilters, activateFilters,
         toggleFilter, setSearchResultFilters, setSelectedItem, clearADEDSearchResult, setAdedPanelsOpen } from "../../../store/searchSlice";
import { showDisplayType } from "../../../store/appSlice";
import { clearMapSearchResult } from "../../../store/mapSlice";
import { ADEDResultIsEmpty, hasActiveFilters } from "../../../utils/searchHelper";

import homeImg from "../../../images/home.png";
import "../filterdrawer.css";

interface filterGroup {
    group: string;
    filters: Array<Filter>;
}

interface rangeInput {
    filter: string;
    gte: number | string;
    lte: number | string;
}

const FilterDrawer: React.FC = () => {
    const { Panel } = Collapse;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [rangeInputsForReset, setRangeInputsForReset] = useState<Array<rangeInput>>([]);
    const [currentActiveFilterValues, setCurrentActiveFilterValues] = useState<Array<string>>([]);
    const [activeKeys, setActiveKeys] = useState<Array<any>>([]);
    const [activeHighlightKeys, setActiveHighlightKeys] = useState<Array<any>>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [filterTexts, setFilterTexts] = useState<{ [key: string]: string }>({}); //  Local state for filter inputs

    const resultFilters: Array<Filter> = useSelector((state: StoreState) => state.search.resultFilters);
    const maxHits: number = useSelector((state: StoreState) => state.search.maxHits);
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryADED);
    const sortField: SortField = useSelector((state: StoreState) => state.search.sortField);
    const panelsOpen: boolean = useSelector((state: StoreState) => state.search.adedPanelsOpen);
    const geoBBox: Array<number> | null = useSelector((state: StoreState) => state.search.geoBBox);

    const initializeFilters = (count: number) => {
        SearchData.postBucketsOnly(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart, 
            window.environment.aded_un || "", window.environment.aded_pwd || "").then(response => {
            if(response.error !== null) {
                if(count < 3) {
                    setTimeout(() => initializeFilters(count + 1), 1000);
                }
            }
            else {
                const filters = parseCurrentFilters(response);

                let rangeFilters:Array<rangeInput> = [];
                filters.forEach((f: Filter) => {
                    if (f.filtertype === RANGE_FILTER) {
                        rangeFilters.push({filter: f.typeKey, gte: "", lte: ""});
                    }
                });

                setRangeInputsForReset(rangeFilters);

                dispatch(setSearchResultFilters(filters));
            }
        });
    }

    const resetSelected = () => {
        if(initialized && location.pathname !== "/") {
            navigateApp(dispatch, navigate, "/");  // On new result - always navigate to root
        }
        dispatch(setSelectedItem(null));
    };

    const performSearch = (activeValues: Array<string>) => {
        resetSelected();

        if(activeValues.length > 0 || searchQuery || geoBBox) {
            SearchData.post(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart, 
                resultFilters, searchQuery, maxHits, 1, sortField, window.environment.aded_un || "", window.environment.aded_pwd || "", geoBBox).then(response => {
                dispatch(updateSearchResultFilters(response));
                dispatch(setSearchResult(response));

                if(!ADEDResultIsEmpty(response)) {
                    dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
                }
            });
        }
        else {
            if (!hasActiveFilters(resultFilters)) {
                setInitialized(true);
                initializeFilters(3);
                dispatch(clearADEDSearchResult());
                dispatch(clearMapSearchResult());
            };
        }
    };

    useEffect(() => {
        const activeValues = getActiveFilterValues(resultFilters);
        const activeFiltersChanged = isActiveValuesChanged(activeValues, currentActiveFilterValues);

        if(activeFiltersChanged) {
            setCurrentActiveFilterValues(activeValues);
            performSearch(activeValues);
        } 
    }, [resultFilters]);

    useEffect(
        () => {
        if (!searchQuery) { // Will also run on first render cycle
            if(!initialized || hasActiveFilters(resultFilters)) {
                performSearch(currentActiveFilterValues);
            }
            else { 
                // må tilbakestille antall pr filter når søkeord nullstilles
                initializeFilters(1);
            }
        }
    }, [searchQuery]);

    useEffect(
        () => {
        if (initialized) {
            if(geoBBox || hasActiveFilters(resultFilters)) {
                performSearch(currentActiveFilterValues);
            }
            else { 
                // må tilbakestille antall pr filter når søkeord nullstilles
                initializeFilters(1);

                if (searchQuery === "") {
                    dispatch(clearADEDSearchResult());
                    dispatch(clearMapSearchResult());
                }
            }
        }
    }, [geoBBox]);

    useEffect(
        () => {
        if (!panelsOpen) {
            setActiveKeys([]);
            setActiveHighlightKeys([]);
            setShowAll(false);
            dispatch(setAdedPanelsOpen(true));
        }
    }, [panelsOpen]);

    const toggleClickFilter = (f: Filter) => {
        dispatch(toggleFilter(f));
    };

    const updateFilterValue = (e: any, f:Filter, p:string) => {
        const upd = {
            filtertype: f.filtertype, 
            typeKey: f.typeKey, 
            values:[{caption: p, value: parseInt(e.target.value)}]
        };

        dispatch(updateFilter(upd));
    }

    const deactivateFilterlist = (event: React.MouseEvent | null, fs: Array<Filter>) => {
        event?.stopPropagation();
        dispatch(deactivateFilters(fs));
    };

    const activateFilterlist = (event: React.MouseEvent | null, fs: Array<Filter>) => {
        event?.stopPropagation();
        dispatch(activateFilters(fs));
    };

    const handleKeyDown = (e: any, f: Filter, t: string) => {
        e.stopPropagation();
        if (e.key === "Enter") {
            updateFilterValue(e, f, t);
        }
    }

    const handleRangeInputChange = (e: any) => {
        const updatedInputs = [...rangeInputsForReset];

        const idx = e.target.dataset.idx;
        const field = e.target.className;

        if(field === "gte") {
            updatedInputs[idx].gte = e.target.value;
        }
        else {
            updatedInputs[idx].lte = e.target.value;
        }
        
        setRangeInputsForReset(updatedInputs);
      };

    const renderRangeFilter = (f: Filter, keypart: string) => {
        let invalidValues = false;
        if(f.values && f.values.length === 2 && f.values[0].value && f.values[1].value) {
            if(f.values[1].value <= f.values[0].value) {
                invalidValues = true;
            }
        }

        let idx = rangeInputsForReset.findIndex((ri: rangeInput) => {return ri.filter === f.typeKey});
        if(idx === -1) {
            idx = (rangeInputsForReset.push({filter: f.typeKey, gte: "", lte: ""}) - 1);
        }

        return (
            <div key={"resultFilterValue" + keypart} className={`range-filter-container${invalidValues ? " range-input-error" : ""}`}
                title={invalidValues ? "Max må være større enn min" : ""}>
                <input type="number" placeholder="min" onBlur={(e) => {updateFilterValue(e, f, "gte")}} className="gte"
                       onKeyDown={(e) => {handleKeyDown(e, f, "gte")}} min={0} 
                       data-idx={idx} value={rangeInputsForReset[idx].gte} onChange={handleRangeInputChange} />
                <span className="margin-left-small margin-right-small">-</span>
                <input type="number" placeholder="max" onBlur={(e) => {updateFilterValue(e, f, "lte")}} className="lte"
                       onKeyDown={(e) => {handleKeyDown(e, f, "lte")}} min={0} 
                       data-idx={idx} value={rangeInputsForReset[idx].lte} onChange={handleRangeInputChange} />
                <Button type="link">
                    <SearchOutlined />
                </Button>
            </div>
        );
    };

    const renderValueFilter = (f: Filter, keypart: string) => {
        const someActive = f.values?.some((v: FilterValue) => v.active);
        const count = f.filtertype === VALUE_FILTER ? (f.values?.reduce((acc, curr) => acc + (curr.active ? 1 : 0), 0)) : 0;

        const filterText = filterTexts[keypart] || "";
    
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setFilterTexts(prev => ({ ...prev, [keypart]: event.target.value }));
        };

        // Update the rendering logic to include the filter text check
        const visibleValues = f.values?.filter((v: FilterValue) => 
            !filterText || v.caption.toLowerCase().includes(filterText.toLowerCase())
        );

        return (
            <div className="values-container">
                <div className="values-header">
                    <Checkbox onChange={() => { someActive ? deactivateFilterlist(null, [f]) : activateFilterlist(null, [f]) }} checked={someActive}>
                        {count + " valgt"}
                    </Checkbox>
                    <Input
                        type="text"
                        placeholder="" // Placeholder for the input
                        suffix={
                            <Tooltip title="Filtrér valgene">
                                <FilterOutlined className="filter-input-icon" />
                            </Tooltip>
                        }
                        value={filterText}
                        onChange={handleChange} // Synchronize input field value with state
                        allowClear
                        className="filter-filter-input"
                    />

                    {f.typeKey === ProjectTypeKey &&
                        <span>Gå til</span>
                    }
                </div>
                <div className="scrollable-container" key={"resultFilterValue" + keypart}>
                {/* Replacing this filter with the new based on user input. HRI, 250424
                    {f.values?.map((v: FilterValue, j: number) => {
                */}
                    {visibleValues?.map((v: FilterValue, j: number) => {
                        const vCaption = (f.typeKey === ProjectTypeKey ? (v.caption.split('|')[0]) : v.caption);
                        return (
                            <div key={"resultFilter" + keypart + "_" + j} className="filter-value-row" >
                                <Checkbox onChange={() => toggleClickFilter({filtertype: f.filtertype, typeKey: f.typeKey, values:[v]})} checked={v.active}>
                                    <span>{vCaption + (((v.noHits && v.noHits > 0) || v.active) ? " (" + v.noHits + ")" : "")}</span>
                                </Checkbox>

                                {f.typeKey === ProjectTypeKey &&
                                    <Link to={"/ADED/" + v.value?.split("|")[1]} title={`Gå til prosjekt ${vCaption}`}>
                                        <img src={homeImg} alt="Home" />
                                    </Link>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderFilterValue = (f: Filter, keypart: string) => {
        if(f.filtertype === VALUE_FILTER) {
            return renderValueFilter(f, keypart);
        }

        if(f.filtertype === RANGE_FILTER) {
            return renderRangeFilter(f, keypart);
        }
    };

    const renderFilter = (f: Filter, keypart: string, grouped: boolean) => {
        const someActive = f.values?.some((v: FilterValue) => v.active);
        const activeClass = "filtertype-active" + (grouped ? "-grouped" : "");
        const count = f.filtertype === VALUE_FILTER ? (f.values?.reduce((acc, curr) => acc + ((curr.noHits || 0) > 0 ? 1 : 0), 0)) : 0;
        return (
            <Panel id={`filter${keypart}`} className={`
                    ${grouped ? "filtertype-type-grouped " : "filtertype-type "} ${someActive ? activeClass : ""}  
                    ${(f.filtertype === VALUE_FILTER && count === 0 && f.type !== "Utgravningsprosjekter") ? " filtertype-empty" : ""}
                `} 
                header={f.type + (f.filtertype === VALUE_FILTER && count && count > 0 ? (" (" + count + ")") : "")} key={`f${keypart}`} >
                <div className={`filtertype-container ${grouped ? "-grouped" : ""}`}>
                    {renderFilterValue(f, "p" + keypart)}
                </div>
            </Panel>
        );
    };

    const renderFilterWithoutPanel = (f: Filter, i: number) => {
        const someActive = f.values?.some((v: FilterValue) => v.active);

        return (
            <div key={"filterWOPanel_" + i} className="filtertype-container">
            <div className="filtertype-type-grouped">
                <div className="removable-filter">
                    <Checkbox onChange={() => { someActive ? deactivateFilterlist(null, [f]) : activateFilterlist(null, [f]) }} checked={someActive}>
                        <span>{f.type}</span>
                    </Checkbox>
                </div>
            
                <div className="filter-nopanel-values">
                    {renderFilterValue(f, "notP" + i)}
                </div>
            </div>
            </div>
        );
    };

    const renderGroup = (g: filterGroup, i: number) => {
        const collapsible = g.filters[0].filtertype !== RANGE_FILTER;
        let someActive = false;
        g.filters.forEach((f: Filter) => {
            f.values?.forEach((v: FilterValue) => {
                if(v.active) someActive = true;
            });
        });

        if(collapsible) {
            return(
            <Panel className={"filtertype-type " + (someActive ? "filtertype-active" : "")} header={g.group} key={"g" + i}>
                <Collapse key={"groupCollapse" + i} expandIconPosition="end" >
                    {g.filters.map((f, i) => renderFilter(f, "g" + i, true))}
                </Collapse>
            </Panel>
            );
        }

        return (
            <Panel className={"filtertype-type " + (someActive ? "filtertype-active" : "")} header={g.group} key={"g" + i} >
                <div className="margin-top">
                    {g.filters.map((f, i) => renderFilterWithoutPanel(f, i))}
                </div>
            </Panel>
        );
    };

    const renderFilters = () => {
        if(resultFilters.length === 0) {
            if(initialized) {
                return (
                    <div className="empty-result">
                        Kunne ikke hente inn filter akkurat nå, oppdater siden og prøv på nytt.
                    </div>
                );
            }
            
            return (
                <div className="empty-result">
                    Henter filtreringsinformasjon...
                </div>
            );
        }

        let advancedFilters = resultFilters.filter(rf => {
            return highlightAttributeFilters.filter(haf => {return haf.filtertype === rf.type}).length === 0;
        });

        let groupNames = advancedFilters.map((rf: Filter) => {return (rf.group || ""); }).filter(onlyUnique);
        const groups:Array<filterGroup> = groupNames.map((g: string) => {
            return {
                group: g,
                filters: advancedFilters.filter((f: Filter) => {
                    return f.group === g;
                })
            };
        });

        const unGrouped = advancedFilters.filter((rf: Filter) => {return !rf.group;});
       
        return (
            <div>
                <Collapse expandIconPosition="end" activeKey={activeKeys} onChange={(val: any) => {setActiveKeys(val); }}> 
                    {unGrouped.map((f, i) => renderFilter(f, "f" + i, false))}
                    {groups.map((g, i) => renderGroup(g, i))}
                </Collapse>
            </div>
        );
    };

    const getHighlightFilterHeader = (haf: HighlightAttributeFilter, fType: string, count: number) => {
        return (
            <div>
                <img className="margin-right" src={haf.icon} alt={haf.filtertype} />
                {haf.caption + (fType === VALUE_FILTER && count && count > 0 ? (" (" + count + ")") : "")}
            </div>
        )
    }

    const renderHighlightFilter = (haf: HighlightAttributeFilter, keypart: string) => {
        const fs = resultFilters.filter(rf => {
            return rf.type === haf.filtertype;
        });

        if(fs.length === 0) {
            return null;
        }

        const f = fs[0];

        const someActive = f.values?.some((v: FilterValue) => v.active);
        const count = f.filtertype === VALUE_FILTER ? (f.values?.reduce((acc, curr) => acc + ((curr.noHits || 0) > 0 ? 1 : 0), 0)) : 0;
        return (
            <Panel id={`haf${keypart}`} className={`filtertype-type ${someActive ? "filtertype-active" : ""}  
                    ${(f.filtertype === VALUE_FILTER && count === 0 && f.type !== "Utgravningsprosjekter") ? " filtertype-empty" : ""}
                `} 
                header={getHighlightFilterHeader(haf, f.filtertype, count || 0)} key={`f${keypart}`} >
                <div className="filtertype-container">
                    {renderFilterValue(f, "p" + keypart)}
                </div>
            </Panel>
        );
    };

    const renderHighlightFilters = () => { 
        return (
            <div>
                <Collapse expandIconPosition="end" activeKey={activeHighlightKeys} onChange={(val: any) => {setActiveHighlightKeys(val); }}> 
                    {highlightAttributeFilters.map((haf, i) => renderHighlightFilter(haf, "haf" + i))}
                </Collapse>
            </div>
        );
    }

    return (
        <div className="filter-container">
            {renderHighlightFilters()}

            <div className="advanced-filters">
                <div className="advanced-filters-btn">
                    <Button onClick={() => setShowAll(!showAll)} title={`${showAll ? "Skjul" : "Vis"} avanserte valg`} >
                        {`${showAll ? "Skjul" : "Vis"} avansert`}
                    </Button>
                </div>
                {showAll && renderFilters()}
            </div>
        </div>
    );
}

export default FilterDrawer;
