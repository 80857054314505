/* Utils class for performing cancellable service queries */
import { useRef, useEffect } from "react";
import { CancellablePromise } from "../types";

/**
 * Type guard with "type predicate", for use in catch-clause
 * @param candidate candidate to cancel
 * @returns true if canceled
 */
export const isCanceled = (candidate:any): boolean =>{
    return candidate.isCanceled === true;
}

/**
 * Make Promise object cancellable by wrapping in another promise
 * @param promise promise to make cancellable
 * @returns Wrapped promise
 */
export function makeCancelable(promise:Promise<any>) : CancellablePromise {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((val) => (isCanceled ? reject({ isCanceled }) : resolve(val)))
            .catch((error) => (isCanceled ? reject({ isCanceled }) : reject(error)));
    });

    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        }
    };
}

/**
 * Use cancellable promise
 * @param cancelable refers to makeCancellable
 * @returns Cancellable promise
 */
export function useCancellablePromise(cancelable = makeCancelable) {
    const emptyPromise = Promise.resolve(true);

    // test if the input argument is a cancelable promise generator
    if (cancelable(emptyPromise).cancel === undefined) {
        throw new Error(
            "promise wrapper argument must provide a cancel() function"
        );
    }

    const promises = useRef<Array<CancellablePromise>>([]);

    useEffect(() => {
        promises.current = promises.current || [];
        return function cancel() {
            promises.current.forEach((p) => p.cancel());
            promises.current = [];
        };
    }, []);

    function cancellablePromise(p: Promise<any>) {
        const cPromise = cancelable(p);
        promises.current.push(cPromise);
        return cPromise.promise;
    }

    return { cancellablePromise };
}
