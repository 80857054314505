/* Utils class for handling user roles */
import { RoleEnum } from "../types";

/**
 * Check if role has "Bruker" privileges
 * @param role role to check
 * @returns true if role is Bruker, Saksbehandler or Administrator
 */
export const HasBrukerPrivileges = (role:RoleEnum):boolean => {
    if(role === RoleEnum.Bruker || 
        role === RoleEnum.Saksbehandler || 
        role === RoleEnum.Administrator) {
        return true;
    }

    return false;
}

/**
 * Check if role has "Saksbehandler" privileges
 * @param role role to check
 * @returns true if role is Saksbehandler or Administrator
 */
export const HasSaksbehandlerPrivileges = (role:RoleEnum):boolean => {
    if(role === RoleEnum.Saksbehandler || 
        role === RoleEnum.Administrator) {
        return true;
    }

    return false;
}

/**
 * Check if role has "Administrator" privileges
 * @param role role to check
 * @returns true if role is Administrator
 */
export const HasAdministratorPrivileges = (role:RoleEnum):boolean => {
    if(role === RoleEnum.Administrator) {
        return true;
    }

    return false;
}