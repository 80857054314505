/* UI for performing string search in location data like places and addresses */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";

import * as mapConf from "../../../config/MapConfig";
import SearchData from "../../../services/searchservice";
import { MapHit } from "../../../constants";
import { StoreState } from "../../../types";
import { clearMapHitSearchResult, getSearchResult, setSearchResult } from "../../../store/searchSlice";
import { showLoading } from "../../../store/appSlice";

const SearchBarMap: React.FC = () => {
    const dispatch = useDispatch();

    const [searchVal, setSearchVal] = useState<string>("");
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryMaphit);
    
    const { Search } = Input;

    const perfomSearch = () => {
        const searchUrl = window.environment.aded_api + mapConf.MapSearchUrl;

        SearchData.get(searchUrl + "?query=" + searchQuery.trim(), MapHit).then(response => {
            dispatch(showLoading(false));
            dispatch(setSearchResult(response));
        });
    };

    useEffect(
        () => {
        // we don't want to make a "blind" search when searchQuery is empty:
        if (searchQuery) {
            perfomSearch();
        }
        else {
            dispatch(clearMapHitSearchResult());
        }
    }, [searchQuery]);

    const showSearchResult = (q: string) => {
        if(q !== searchQuery) {
            dispatch(showLoading(true));
            dispatch(getSearchResult({type: MapHit, phrase: q}));
        }
    };

    return (
        <div className="searchbar">
            <Search suffix={""} placeholder="Søk på sted eller adresse" value={searchVal} onChange={(e) => setSearchVal(e.target.value)}
                    onSearch={value => showSearchResult(value)} className="searchbar-input" allowClear enterButton maxLength={100} />
        </div>
    );
}

export default SearchBarMap;
