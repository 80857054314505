/* Show relations between one selected ADED object and other ADED objects */
import { Collapse } from 'antd';
import { ADEDRelatedObject } from "../../../types";
import { Link } from "react-router-dom";
import { replaceInType } from "../../../utils/adedHelper";
import { onlyUnique } from "../../../utils";

interface myProps {
    relations: Array<ADEDRelatedObject>;
}

interface relationBucket {
    type: string;
    relations: Array<typeBucket>;
}

interface typeBucket {
    type: string;
    objects: Array<ADEDRelatedObject>;
}

export const Relations = (props: myProps) => {

    const { Panel } = Collapse;

    const relationTypes = props.relations.map((r: ADEDRelatedObject) => {return r.Relasjontype}).filter(onlyUnique);
    const objectRelations:Array<relationBucket> = relationTypes.map((t: string) => {
        const rels = props.relations.filter((r: ADEDRelatedObject) => {
            return r.Relasjontype === t;
        });
        const objTypes = rels.map((r: ADEDRelatedObject) => {return r.ObjektType || r.ObjektHovedType}).filter(onlyUnique);
        return {
            type: t,
            relations: objTypes.map((o: string) => {
                return {
                    type: o,
                    objects: rels.filter((r2: ADEDRelatedObject) => {
                        return ((r2.ObjektType && r2.ObjektType === o) || r2.ObjektHovedType === o);
                    })
                }
            })
        };
    });

    const getCaption = (o: ADEDRelatedObject): string => {
        const oType = replaceInType(o.ObjektType ? o.ObjektType : o.ObjektHovedType);
        return oType + " - " + o.InputId;
    };

    const renderObjectLink = (o: ADEDRelatedObject) => {
        return (
            <div key={"relationbucket_type_object" + o.ObjektId} className="object-link">
                <Link className="hit-link-btn" to={"/ADED/" + o.ObjektId} 
                      title={"Gå til " + getCaption(o)}>
                    <span>
                        {getCaption(o)}
                    </span>
                </Link>
            </div>
        );
    }

    const renderTypeBucket = (t: typeBucket) => {
        if(t.objects.length === 1) {
            return (
                <div key={"relation_single_object" + t.type} className="relation-single-object">
                    { renderObjectLink(t.objects[0]) }
                </div>
            );
        }

        return (
            <Panel header={replaceInType(t.type) + " (" + t.objects.length + ")"} key={"relationbucket_type_" + t.type}>
                <div className="margin-left">
                    {t.objects.map((r3: ADEDRelatedObject) => {
                        return renderObjectLink(r3);
                    })}
                </div>
            </Panel>
        );
    }

    const renderRelationBucket = (r: relationBucket) => {
        return (
            <Panel header={r.type} key={"relationbucket_" + r.type}>
                <div style={{margin: "-2px 0 -2px 1rem"}}>
                    <Collapse className="margin-left" expandIconPosition="end" >
                        {r.relations.map((r2: typeBucket) => {
                            return renderTypeBucket(r2);
                        })}
                    </Collapse>
                </div>
            </Panel>
        );
    }
    
    return (
        <div>
            <Collapse expandIconPosition="end">
                {objectRelations.map((r: relationBucket) => {
                    return renderRelationBucket(r);
                })}
            </Collapse>
        </div>
    );
};