/* List of artefact results from a search */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

import { ArtefactFilters, ArtefactResult, StoreState } from "../../../types";
import { Paging } from "./paging";
import { setFirstTime, showDisplayType } from "../../../store/appSlice";
import { capitalize, getMuseumName, onlyUnique } from "../../../utils";
import { getSearchResult, resetArtefact, setArtefactCounty, setArtefactMunicipality, setArtefactMuseum, setArtefactMuseumNO, setArtefactPanelsOpen, setArtefactRequirePhoto } from "../../../store/searchSlice";
import { Artefact, DISPLAY_TYPE } from "../../../constants";

import cameraDashedImg from "../../../images/camera_dashed.png";
import helpGreenLargerImg from "../../../images/help_green_larger.png";

interface myProps {
    results: Array<ArtefactResult>;
    searchPhrase: string;
    noHits: number;
}

export const ArtefactResultItems = (props: myProps) => {
    const dispatch = useDispatch();

    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryArtefact);
    const filters: ArtefactFilters = useSelector((state: StoreState) => state.search.artefactFilters);

    const renderPhoto = (r: ArtefactResult) => {
        if(!r.thumbnailUrl && !r.smallImageUrl) {
            return <img src={cameraDashedImg} alt="Har ikke bilde" />
        }

        return (
            <img src={r.thumbnailUrl || r.smallImageUrl || ""} alt={r.termNo || ""} />
        )
    };

    const renderKommuneFylke = (r: ArtefactResult) => {
        if(r.places && r.places.length > 0) {
            const k = r.places.map(p => {return p.municipality || ""}).filter(onlyUnique);
            const f = r.places.map(p => {return p.county || ""}).filter(onlyUnique);
            return k.join(", ") + ", " + f.join(", ");
        }

        return "";
    };

    const getDescription = (r: ArtefactResult) => {
        if(!r.descriptionNo) {
            return "";
        }

        let desc = (r.descriptionNo.length < 50 ? r.descriptionNo : r.descriptionNo?.substring(0, 50) + "...");

        return capitalize(desc);
    }

    const renderResult = (r: ArtefactResult, i: number) => {
        return (
            <div key={"resultItem" + i} className="hit-container"> 
                <div className="hit-content">
                    <div className="hit-thumbnail">
                        {renderPhoto(r)}
                    </div>
                    <div className="hit-info">
                        <div>
                            <Link onClick={() => dispatch(setFirstTime(false))} className="hit-link-btn" 
                                to={"/artefact/" + r.thingUuid}>
                                <div className="hit-header">
                                    {capitalize(r.termNo || "")} 
                                    <span>{" - " + capitalize(r.museumNo || "")}</span>
                                </div>
                            </Link>
                            <div className="hit-details" title={r.descriptionNo || ""}>
                                {getDescription(r)}
                            </div>
                        </div>

                        <div className="hit-details">
                            <div className="bold">
                                {getMuseumName(r.museum || "")}
                            </div>
                            <div>
                                {renderKommuneFylke(r)}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    };

    const resetAll = (event: React.MouseEvent | null) => {
        event?.stopPropagation();
        dispatch(resetArtefact());
        dispatch(showDisplayType(DISPLAY_TYPE.MAP_VIEW));
        dispatch(setArtefactPanelsOpen(false));
    };

    const renderSearchInfo = () => {
        return (
            <div className="resultlist-search-info">
                <div className="resultlist-search-info-content">
                    {searchQuery && 
                        <Button type="link" onClick={() => {dispatch(getSearchResult({type: Artefact, phrase: ""}))}} title="Nullstill søkeord">
                            Søkeord: <b>{searchQuery}</b>
                            <CloseCircleFilled />
                        </Button>
                    }
                    {filters.county && 
                        <Button type="link" onClick={() => {dispatch(setArtefactCounty(null))}} title="Nullstill fylke">
                            Fylke: <b>{filters.county}</b>
                            <CloseCircleFilled />
                        </Button>
                    }
                    {filters.municipality && 
                        <Button type="link" onClick={() => {dispatch(setArtefactMunicipality(null))}} title="Nullstill kommune">
                            Kommune: <b>{filters.municipality}</b>
                            <CloseCircleFilled />
                        </Button>
                    }
                    {filters.museumNO && 
                        <Button type="link" onClick={() => {dispatch(setArtefactMuseumNO(null))}} title="Nullstill museumsnummer">
                            Museumsno: <b>{filters.museumNO}</b>
                            <CloseCircleFilled />
                        </Button>
                    }
                    {filters.museum && 
                        <Button type="link" onClick={() => {dispatch(setArtefactMuseum(null))}} title="Fjern filter">
                            {getMuseumName(filters.museum)}
                            <CloseCircleFilled />
                        </Button>
                    }
                    {filters.requirePhoto && 
                        <Button type="link" onClick={() => {dispatch(setArtefactRequirePhoto(false))}} title="Fjern filter">
                            Kun med bilder
                            <CloseCircleFilled />
                        </Button>
                    }

                    <br/>
                    <Button type="link" style={{color: "black"}} onClick={(event) => resetAll(event)} title="Nullstill utvalg" >
                        Fjern alle
                        <CloseCircleFilled />
                    </Button>
                </div>

                <div>
                    <Button type="link" onClick={() => setInfoVisible(!infoVisible)} >
                        <img src={helpGreenLargerImg} alt="Hjelp" title="Hjelp meg!" />
                    </Button>
                </div>
            </div>
        )
    };

    const renderList = () => {
        if(props.results.length < 1) {
            return (
                <div className="empty-result">Ingen resultater å vise, gjør et nytt søk</div>
            );
        }

        return (
            props.results.map((r: ArtefactResult, i:number) => renderResult(r, i))
        );
    };

    const renderPaging = () => {
        if(props.noHits === props.results.length) {
            return <div></div>;
        }

        return <Paging noHits={props.results.length} totalNoHits={props.noHits} />;
    }

    return (
        <div className="result-container">
            <div className="result-container-scroll">
                <div className="resultlist-header">
                    <div className={"context-help hitlist-help " + (infoVisible ? "" : "remove-element")}>
                        <p>
                            Lista under inneholder objekter i Gjenstander som inneholder angitt søkeord i et eller flere felter, og/eller matcher 
                            valgte kriterier i filterlista til venstre.
                        </p>
                        <p>
                            <i>Eksempel: </i> Hvis du har skrevet "Sverd" i søkefeltet, vil lista inneholde alle gjenstander som inneholder ordet "Sverd" i
                            en eller flere egenskaper.
                        </p>
                        <p>
                            For å sikre at alle treffene tilhører et gitt museum må du ekspandere <i>Museum</i>-panelet i filtreringslista til venstre,
                            og velge ønsket museum der.
                        </p>
                        <p>
                            Hvis du har et museumsnummer du ønsker å hente gjenstander for, er det best om du legger det i feltet for museumsnummer.
                        </p>
                        <p>
                            Gå inn på en enkelt gjenstand for å se detaljer og plassering i kartet.
                        </p>
                        <div>
                            <Button type="link" onClick={() => setInfoVisible(false)} style={{float: "right"}}>
                                Lukk
                            </Button>
                        </div>
                    </div>

                    {renderSearchInfo()}
                </div>
                <div className="resultlist-content">
                    {renderList()}
                </div>
            </div>
            {renderPaging()}
        </div>
    );
};