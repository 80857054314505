/* Utils class for structuring and formatting general data for presentation */
import { setFirstTime } from "../store/appSlice";

/**
 * Get only unique instances of string in array
 * @param value value to check
 * @param index index of value in array
 * @param self array to search in
 * @returns unique values from array
 */
export const onlyUnique = (value:string, index:number, self:Array<string>) => {
    return value && self.indexOf(value) === index;
};

/**
 * Generate unique user identifier for use in local storage
 */
export const uuidv4 = () => { 
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c => {
        const r = crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (parseInt(c) / 4);
        return (parseInt(c) ^ r).toString(16);
    });
}

/**
 * Capitalize string
 * @param s string to capitalize
 * @returns capitalized string
 */
export const capitalize = (s: string): string => {
    if (typeof s !== "string") {
        return "";
    }

    return s.charAt(0).toUpperCase() + s.slice(1)
};

/**
 * Ensure consistant use of route navigation
 * @param dispatch react useDispatch hook
 * @param navigate react-router-dom useNavigate hook
 * @param toRoute route to navigate to
 */
export const navigateApp = (dispatch: any, navigate: any, toRoute: string) => {
    navigate(toRoute);
    dispatch(setFirstTime(false));
};

/**
 * Order items in an array according to given property
 * @param items array to sort
 * @param orderBy property to sort by
 * @param attrType type of attribute to sort by
 * @param reverse sort ascending or descending
 * @returns ordered list
 */
export const orderItems = (items: Array<any>, orderBy: string, attrType: string, reverse: boolean) => {
    var orderedItems = items.sort((a: any, b: any) => {
        if(attrType === "number") {
            if(reverse) {
                return b[orderBy] - a[orderBy];
            }
            return a[orderBy] - b[orderBy];
        }
        if(attrType === "string") {
            if(reverse) {
                return ("" + b[orderBy]).localeCompare(a[orderBy]);
            }
            return ("" + a[orderBy]).localeCompare(b[orderBy]);
        }
        if(attrType === "date") {
            try {
                const aDate = new Date(a[orderBy]);
                const bDate = new Date(b[orderBy]);

                if(reverse) {
                    return bDate.getTime() - aDate.getTime();
                }
                return aDate.getTime() - bDate.getTime();
            }
            catch {
                return 0;
            }
        }

        return 0;
    });

    return orderedItems;
};

/**
 * Get application root url
 * @returns app url without route part
 */
export const getUrlWithoutRoute = (): string => {
    const urlParts = window.location.pathname.split("/");
    let url = window.location.origin;

    let i = 0;
    let p = urlParts[i];
    while(i < urlParts.length && p !== "artefact" && p !== "ADED") {
        if(p) {
            url += "/" + p;
        }

        i++;
        p = urlParts[i];
    }

    return url;
};

/**
 * Make string unicode friendly by replacing Norwegian characters
 * @param name string to format
 * @returns unicode friendly string
 */
export const makeFriendly = (name: string): string => {
    if(!name) {
        return name;
    }

    let friendly = name.replace(/\s|,|:|\./g, "_");
    friendly = friendly.replace(/æ/g, "e").replace(/Æ/g, "E");
    friendly = friendly.replace(/å/g, "a").replace(/Å/g, "A");
    friendly = friendly.replace(/ø/g, "o").replace(/Ø/g, "O");

    return friendly;
}