/* Utils class for structuring and formatting search results */
import { capitalize } from ".";
import { MapSearchResult, AdresseResult, StedsnavnResult, SearchResult, DisplayableResult, Filter, FilterValue } from "../types";

/**
 * Format location search result (place, address)
 * @param r location result to format
 * @returns formatted location
 */
export const getDisplayableMapHitResult = (r: MapSearchResult):DisplayableResult => {
    let dr = getDisplayableResult(r);

    if(r.kilde === "Adresse") {
        const a = r as AdresseResult;
        dr.navn = a.gatenavn + " " + a.husnr + a.husbokstav + ", " + a.postnr + " " + a.poststed;
        if(a.matrikkelnummer) {
            dr.info = "Eiendom " + a.matrikkelnummer;
        }
        return dr;
    }

    if(r.kilde === "Stedsnavn") {
        const s = r as StedsnavnResult;
        dr.navn = s.stedsnavn || "Ikke angitt stedsnavn";
        if(s.stedsnavnstype) {
            dr.info = splitOnUppercase(s.stedsnavnstype);
        }
        
        return dr;
    }

    return dr;
}

/**
 * Check if ADED search yielded no results
 * @param r ADED search result
 * @returns true if no hits
 */
export const ADEDResultIsEmpty = (r: SearchResult):boolean => {
    return (r.result?.hits?.total.value === 0);
}

/**
 * Check if artefact search yielded no results
 * @param r artefact search result
 * @returns true if no hits
 */
export const artefactResultIsEmpty = (r: SearchResult):boolean => {
    return (r.result.numberOfRecords === 0);
}

/**
 * Check if location search yielded no results
 * @param r location search result
 * @returns true if no hits
 */
export const mapHitResultIsEmpty = (r: SearchResult):boolean => {
    return (!r.result || !r.result.hits || r.result.hits.length === 0);
}

/**
 * Extract search phrase from search query object
 * @param queryObject query object to extract search phrase from
 * @returns search phrase or empty string if none present
 */
export const extractSearchPhraseFromSearchQueryObject = (queryObject: any): string => {
    let searchPhrase = queryObject.bool.must.length ? queryObject.bool.must[0].multi_match?.query : "";

    if (searchPhrase) {
        return searchPhrase;
    }

    searchPhrase = queryObject.bool.should?.length ? queryObject.bool.should[0].multi_match?.query : "";

    return searchPhrase;
}

/**
 * Construct value filter from type/subtype info
 * @param parent parent type
 * @param child child type
 * @returns FilterValue
 */
export const constructValueFilter = (parent: any, child: any): FilterValue => {
    const filterValue: FilterValue = {
        caption: child.Label,
        value: `${parent.Label}|${child.Label}`
    }

    return filterValue;
}

/**
 * extract json object from database containing filters and search phrase in valid elastic search format
 * typical example of JSON to parse, containing two value filters:
 * {
 * 	"bool": {
 * 		"must": [],
 * 		"filter": [
 * 			{
 * 				"bool": {
 * 					"should": [
 * 						{
 * 							"match": {
 * 								"SideIProfilVenstre.keyword": "buet"
 * 							}
 * 						},
 * 						{
 * 							"match": {
 * 								"SideIProfilVenstre.keyword": "skrå"
 * 							}
 * 						}
 * 					]
 * 				}
 * 			}
 * 		],
 * 	}
 * }
 * @param queryObject object to parse
 * @returns list of filters
 */
export const extractSearchFiltersFromSearchQueryObject = (queryObject: any): Array<Filter> => {
    if (!queryObject.bool.filter.length && (!queryObject.bool.should || !queryObject.bool.should.length)) {
        return [];
    }

    const filters: Array<Filter> = [];

    // parse value filters
    queryObject.bool.filter.forEach((element: any) => {
        if(element.bool) {
            element.bool.should.forEach((x: any) => {
                const match = x.match;
                // extract key / categories from match object
                const keys = Object.keys(match);
                
                const values: Array<any> = [];

                // this is the filter category, such as "SideIProfilVenstre.keyword"
                const typeKey = keys[0]; // always an array of length 1
                if (typeKey === "ObjektType.keyword" || typeKey === "ObjektHovedType.keyword") {
                    // handle objekttype specifically
                    if (typeKey === "ObjektType.keyword") {
                        const filter = typeFilterHandler(match, typeKey);
                        filters.push(filter);
                    }
                    else if (typeKey === "ObjektHovedType.keyword") {
                        const type = match[typeKey];
                        filters.filter((x: Filter) => x.typeKey === "ObjektType.keyword").map((x: Filter) => {
                            x.type = type;
                            x.values?.map((v: FilterValue) => {
                                v.value = `${type}|${v.value}`;
                                return v;
                            });
                            return x;
                        });
                    }
                }
                else {
                    // generic value filter handler
                    const filter = genericValueFilterHandler(match, typeKey, values);
                    filters.push(filter);
                }
            });
        }
    });

    // parse range filters
    // typical range filter JSON with gte and lte:
    // {
    //     "bool": {
    //         "must": [],
    //         "filter": [],
    //         "should": [
    //             {
    //                 "range": {
    //                     "Lengde": {
    //                         "gte": 1,
    //                         "lte": 4
    //                     }
    //                 }
    //             },
    //             {
    //                 "range": {
    //                     "Bredde": {
    //                         "gte": 2,
    //                         "lte": 5
    //                     }
    //                 }
    //             }
    //         ]
    //     }
    // }
    queryObject.bool.should?.forEach((element: any) => {

        const keys = Object.keys(element.range);
        const rangeProperty = keys[0]; // "Lengde" / "Bredde"
        const values: Array<FilterValue> = [];
        const rangeValues = Object.keys(element.range[rangeProperty]);

        // extract properties for range filter, typically "lte", "gte" as properties of range object
        // should be added to Filter object as an array of two, in the case of the example provided above
        rangeValues.forEach((rangeValue: any) => {
            values.push({
                caption: rangeValue,
                active: true,
                value: element.range[rangeProperty][rangeValue]
            });
        });

        const filter: Filter = {
            filtertype: "range",
            typeKey: rangeProperty,
            type: rangeProperty,
            values: values
        };

        filters.push(filter);
    });

    return filters;
}

/**
 * extract json object from database containing filters and search phrase in valid elastic search format
 * typical example of JSON to parse, containing a geo boundingbox:
 * {
 * 	"bool": {
 * 		"must": [],
 * 		"filter": [
 *          {
 *              "geo_bounding_box": {
 *                  "CenterPoint": {
 *                      "top_left": {
 *                          "lat": "59.3988429958092",
 *                          "lon": "10.698090891457"
 *                      },
 *                      "bottom_right": {
 *                          "lat": "59.396923424633215",
 *                          "lon": "10.702186188136618"
 *                      }
 *                  }
 *              }
 *          }
 * 		],
 * 	}
 * }
 * @param queryObject query object to parse
 * @returns geographic bounds as array of coordinates
 */
export const extractGeoBBoxFromSearchQueryObject = (queryObject: any): Array<number> | null => {
    if (!queryObject.bool.filter.length) {
        return [];
    }

    let geobbox: Array<number> | null = null;
    
    // parse value filters
    queryObject.bool.filter.forEach((element: any) => {
        if(element.geo_bounding_box) {
            const topleft = element.geo_bounding_box.CenterPoint.top_left;
            const bottomright = element.geo_bounding_box.CenterPoint.bottom_right;

            geobbox = [topleft.lon, bottomright.lat, bottomright.lon, topleft.lat];
            return;
        }
    });

    return geobbox;
}

// private
const getDisplayableResult = (r: MapSearchResult): DisplayableResult => {
    return {
        kommunenummer: r.kommunenummer,
        kommunenavn: r.kommunenavn,
        x: r.x,
        y: r.y,
        kilde: r.kilde,
        navn: "",
        info: ""
    }
};

export const hasActiveFilters = (resultFilters: Array<Filter>): boolean => {
    return resultFilters.some((f: Filter) => f.values?.some((v: FilterValue) => v.active));
}

const genericValueFilterHandler = (match: any, typeKey: string, values: Array<any>): Filter => {
    const value = match[typeKey];

    values.push({
        caption: capitalize(value),
        value: value,
        active: true
    });

    const filter: Filter = {
        filtertype: "value",
        typeKey: typeKey,
        values: values
    };

    return filter;
}

const typeFilterHandler = (match: any, typeKey: string): Filter => {

    const filter: Filter = {
        filtertype: "value",
        typeKey: typeKey,
        values: [{
            caption: match[typeKey],
            active: true,
            value: match[typeKey]
        }]
    };

    return filter;

};

const splitOnUppercase = (s: string): string => {
    let r = capitalize(s);

    const p = r.split(new RegExp(/(?=[A-Z]+[^A-Z]?)/)); // Split string on capital letters, ex "annenKulturdetalj"
    return p.join(" ");
}
