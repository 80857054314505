/* Using unimus media search to search for and display photos related to the ADED objects */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";

import { ArtefactResult, StoreState, ADEDResult, Photo } from "../../../../types";
import LookupData from "../../../../services/lookupservice";
import * as conf from '../../../../config/SearchConfig';
import { ADED, ALERT_TYPE } from "../../../../constants";
import { capitalize, getOtherThings, onlyUnique, uniquePhotos } from "../../../../utils";
import { showAlert } from "../../../../store/appSlice";
import { Link } from "react-router-dom";
import { FotoNOKey } from "../../../../config/ADEDConfig";
import { getArtefactNOs } from "../../../../utils/adedHelper";
import { isCanceled, useCancellablePromise } from "../../../../utils/useCancelablePromise";

import "./photogallery.css";

const ADEDPhoto = () => {
    const dispatch = useDispatch();
    const { cancellablePromise } = useCancellablePromise();

    const [prosjektID, setProsjektID] = useState<string>("");
    const [showGallery, setShowGallery] = useState<boolean>(true);
    const [photoNOs, setPhotoNOs] = useState<Array<string>>([]);
    const [photoNO, setPhotoNO] = useState<string>("");
    const [queryOffset, setQueryOffset] = useState<number>(0);
    const [totalNO, setTotalNO] = useState<number>(0);
    const [photos, setPhotos] = useState<Array<Photo>>([]);
    const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
    const [otherThings, setOtherThings] = useState<Array<ArtefactResult>>([]);

    const selectedItem: ADEDResult | ArtefactResult | null = useSelector((state: StoreState) => state.search.selectedItem);

    useEffect(
        () => {
            if(selectedPhoto) {
                const load = async () => {
                    try {
                        const response = await cancellablePromise(LookupData.getArtefactPhotos(conf.PhotoUrl + selectedPhoto.mediaObjectUuid));
        
                        const res = response.result as Photo;
                        if(res && res.things) {
                            let tUid = (selectedItem as ArtefactResult)?.thingUuid || "";
                            setOtherThings(getOtherThings(res, tUid));
                        }
                    } catch (error) {
                        if (!isCanceled(error)) {
                            dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente info om bildet.", type: ALERT_TYPE.ERROR, manualClose: false }));
                        }
                    }
                };
                load();
            }
            else {
                setOtherThings([]);
            }
    }, [selectedPhoto]);

    const performMediaQueries = (photonumber: string, offset: number, append: boolean) => {
        setPhotoNO(photonumber);
        setQueryOffset(offset + 1);

        const q = conf.MediaSearchUrl + "?filename=" + photonumber + "&truncated=true&limit=100&offset=" + (offset * 100);

        const load = async () => {
            try {
                const response = await cancellablePromise(LookupData.getMedia(q));

                const res = response.result.objects as Array<Photo>;
                if(res) {
                    setTotalNO(response.result.totalHits);

                    let unique = uniquePhotos(res);
                    if(append) {
                        setPhotos(photos.concat(unique));
                    }
                    else {
                        setPhotos(unique);
                    }

                    if(unique.length === 1) {
                        setSelectedPhoto(unique[0]);
                    }
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente bilder.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            } catch (error) {
                if (!isCanceled(error)) {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente bilder.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            }
        };
        load();
    };

    useEffect(
        () => {
            if(selectedItem !== null && selectedItem.type === ADED && selectedItem.ProsjektId && selectedItem.ProsjektId !== prosjektID) {
                setQueryOffset(0);
                setPhotoNOs([]);
                setPhotos([]);
                setSelectedPhoto(null);
                setProsjektID(selectedItem.ProsjektId);
                setTotalNO(0);
        
                const photonoAttr = selectedItem.Attributter.filter(a => {return a.Label === FotoNOKey});
                if(photonoAttr.length > 0) {
                    const photonumbers = getArtefactNOs(photonoAttr[0].Value || "");
                    setPhotoNOs(photonumbers);
                    performMediaQueries(photonumbers[0], 0, false);
                }
            }
    }, []);

    const renderKommuneFylke = () => {
        if(!selectedPhoto) {
            return null;
        }

        if(selectedPhoto.places && selectedPhoto.places.length > 0) {
            const k = selectedPhoto.places.map(p => {return p.municipality || ""}).filter(onlyUnique);
            const f = selectedPhoto.places.map(p => {return p.county || ""}).filter(onlyUnique);
            return k.join(", ") + ", " + f.join(", ");
        }

        return "";
    };

    const renderCadaster = () => {
        if(!selectedPhoto || !selectedPhoto.places || selectedPhoto.places.length === 0) {
            return null;
        }

        let cadasters:Array<string> = [];
        selectedPhoto.places.forEach(p => {
            if(p.cadastralName && p.cadastralNo) {
                cadasters.push(p.cadastralName + " - " + p.cadastralNo);
            }
            else if(p.cadastralName) {
                cadasters.push(p.cadastralName);
            }
            else if(p.cadastralNo) {
                cadasters.push(p.cadastralNo.toString());
            }
        });

        return (
            <tr>
                <td className="details-key">Gård</td>
                <td className="details-value">{cadasters.join(", ")}</td>
            </tr>
        );
    };

    const renderAttributes = () => {
        if(!selectedPhoto) {
            return null;
        }

        return (
            <div className="details-table-container-content margin-top">
                <table className="details-table">
                    <tbody>
                        {selectedPhoto.motif &&
                            <tr>
                                <td className="details-key">Motiv</td>
                                <td className="details-value">{selectedPhoto.motif}</td>
                            </tr>
                        }
                        {selectedPhoto.places && selectedPhoto.places.length > 0 &&
                            <tr>
                                <td className="details-key">Kommune</td>
                                <td className="details-value">{renderKommuneFylke()}</td>
                            </tr>
                        }
                        {renderCadaster()}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderSelectedPhoto = ()  => {
        if(!selectedPhoto || (!selectedPhoto.smallImageUrl && !selectedPhoto.imageUrl)) {
            return <div></div>;
        }

        let infotext = "- info mangler";
        if(selectedPhoto.motif) {
            infotext = selectedPhoto.motif;
        }
        else if(selectedPhoto.title) {
            infotext = selectedPhoto.title;
        }
        else if(selectedPhoto.filename) {
            infotext = selectedPhoto.filename;
        }

        return (
            <div className="gallery-selectedPhoto">
                <div className="details-subheader" style={{textAlign: "center"}}>{selectedPhoto.title || selectedPhoto.filename || ""}</div>
                <a target="_blank" rel="noopener noreferrer" href={selectedPhoto.imageUrl || selectedPhoto.smallImageUrl || ""} title="Trykk for å åpne bildet i ny fane" >
                    <img src={selectedPhoto.smallImageUrl || selectedPhoto.imageUrl || ""} alt={"Bilde " + infotext} />
                    {selectedPhoto.photographers && selectedPhoto.photographers.length > 0 &&
                        <div className="photo-metadata">
                            <div>
                                {selectedPhoto.filename}
                            </div>
                            <div>
                                <i>Foto:</i>
                                <span className="margin-left-small">{selectedPhoto.photographers.join()}</span>
                            </div>
                        </div>
                    }
                </a>
                {renderAttributes()}

                {(otherThings.length > 0) && 
                    <div className="details-key margin-left">
                        Andre gjenstander dette bildet er tilknyttet:
                        <br />
                        {otherThings.map((t: ArtefactResult) => {
                            return (
                                <Link key={`photo_thing_${t.thingUuid}`} to={"/Artefact/" + t.thingUuid} title="Gå til gjenstand" className="margin-right" >
                                    <span>{capitalize(t.termNo || "") + " (" + t.museumNo + ")"} </span>
                                </Link>
                            )
                        })}
                    </div>
                }
            </div>
        );
    };

    const selectPhotoNO = (no: string) => {
        setSelectedPhoto(null);
        performMediaQueries(no, 0, false);
    }

    const renderPhotoNOSelector = () => {
        if(photoNOs.length < 1) {
            return <div></div>;
        }

        if(photoNOs.length === 1) {
            return (
                <div className="photo-additional-info">
                    Viser bilder tilknyttet fotonummer <b>{photoNOs[0]}</b>
                </div>
            );
        }

        return (
            <div className="photo-additional-info">
                Hent bilder tilknyttet fotonummer:
                <div>
                    {photoNOs.map((m: string, i: number) => {
                        return (
                            <Button key={`fotoNO${i}`} type="link" className="margin-right" title="Hent bilder"
                                    onClick={() => selectPhotoNO(m)}>
                                <b className={m === photoNO ? "fotono-btn" : ""}>{m}</b>
                            </Button>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderPhotos = () => {
        return (
            <div className="gallery-thumbnails">
                {photos.map((p: Photo, i: number) => {
                    let infotext = "Bilde - info mangler";
                    if(p.motif) {
                        infotext = p.motif;
                    }
                    else if(p.title) {
                        infotext = p.title;
                    }
                    else if(p.filename) {
                        infotext = "Bilde " + p.filename;
                    }
                    return (
                        <Button key={`thumbnail_${i}`} type="link" onClick={() => setSelectedPhoto(p)} title="Trykk for å se bildet i større utgave" >
                            {p.thumbnailUrl ? (
                                <img src={p.thumbnailUrl} alt={infotext} />
                            ) : (
                                <span>{"Bilde " + infotext + ", thumbnail mangler"}</span>
                            )}
                        </Button>
                    )
                })}
            </div>
        );
    };

    if(photos.length === 0 && photoNOs.length === 0) {
        return <div></div>;
    }

    return (
        <div className="details-table-wrapper">
            <div className="details-table-container w60p">
                <div className={`details-table-container-header ${showGallery ? "" : "white"}`}>
                    Bilder
                    <Button type="link" onClick={() => setShowGallery(!showGallery)} >
                        {showGallery && <MinusCircleFilled /> }
                        {!showGallery && <PlusCircleFilled /> }
                    </Button>
                </div>
                { showGallery && renderPhotoNOSelector() }
                { showGallery && renderSelectedPhoto() }
                { showGallery && renderPhotos() }
                { showGallery && 
                    <div className="photo-additional-info">
                        Viser {photos.length} av {totalNO}
                        {photos.length < totalNO &&
                            <Button type="link" onClick={() => performMediaQueries(photoNO, queryOffset, true)} title="Trykk for å hente flere bilder" >
                                Hent flere
                            </Button>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default ADEDPhoto;