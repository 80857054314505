/* Show an overview of types and subtypes for objects related to one selected ADED project */
import React from "react";
import { Collapse, Button, Popconfirm } from 'antd';
import { ConsistingOf, Filter, StoreState } from "../../../types";
import { replaceInType } from "../../../utils/adedHelper";
import { constructValueFilter as constructFilterValues, hasActiveFilters } from "../../../utils/searchHelper";
import { initAndActivateFilterValue } from "../../../store/searchSlice";
import { useDispatch, useSelector } from "react-redux";

interface myProps {
    types: Array<ConsistingOf>;
    selectedItem: any;
}

export const TypeBucket = (props: myProps) => {
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const [visible, setVisible] = React.useState(false); // for popup
    const [selectedChild, setSelectedChild] = React.useState({});
    const [selectedParent, setSelectedParent] = React.useState({});
    const resultFilters: Array<Filter> = useSelector((state: StoreState) => state.search.resultFilters);
    
    const applyAsFilter = (c1: any, c2: any): void => {
        const filterValue = constructFilterValues(c1, c2);

        dispatch(initAndActivateFilterValue({
            filterValue: filterValue,
            projectId: props.selectedItem?.ProsjektId }));
    }

    const doApply = (c1: any, c2: any): void => {
        applyAsFilter(c1, c2);
        setVisible(false);
    }

    const setVisiblePopup = (c1: ConsistingOf, c2: ConsistingOf): void => {
        if (hasActiveFilters(resultFilters)) {
            setVisible(true);
            setSelectedParent(c1);
            setSelectedChild(c2);
        }
        else {
            doApply(c1, c2);
        }
    }

    const handleOk = () => {
        doApply(selectedParent, selectedChild);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <div className="margin-top" >
            <Popconfirm 
                title="For å se objekttype for et prosjekt må søkekriteriene i venstremenyen nullstilles"
                open={visible}
                onConfirm={handleOk}
                onCancel={handleCancel}
                cancelText="Avbryt"
                placement="left"
            >
                <Collapse expandIconPosition="end">
                    {props.types.map((c: ConsistingOf, i: number) => {
                        return (
                            <Panel header={c.Label + " (" + c.ConsistingOf.reduce((acc, curr) => acc + ((curr.Count || 0) > 0 ? 1 : 0), 0) + ")"} key={"typebucket_" + i}>
                                <div className="margin-left margin-bottom">
                                    {c.ConsistingOf.map((c2: ConsistingOf, j: number) => {
                                        return (
                                            <div key={"typebucket_" + c2.Label + j} className="details">
                                                <Button type="link" className="object-link" onClick={() => setVisiblePopup(c, c2)}>{replaceInType(c2.Label) + (c2.Count ? " (" + c2.Count + ")" : "")}</Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Panel>
                        );
                    })}
                </Collapse>
            </Popconfirm>
        </div>
    );
};
