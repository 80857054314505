/* Utils class calling API endpoints for admin functions */
import { User } from "oidc-client";
import axios from "axios";

/**
 * Get all users and roles
 * @param user current logged in user
 * @returns all registered users in the system and their roles
 */
export const getUsers = (user: User): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}admin/users`;

    return new Promise((resolve, reject) => {
        axios.get(queryUrl, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * Update role for user
 * @param user current logged in user
 * @param username username for user to update
 * @param newRole new role for username
 * @returns object containing updated user
 */
export const putUserRole = (user: User, username: string, newRole: string): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}admin/setrole`;
    const putObject = {
        userName: username,
        groupName: newRole
    }

    return new Promise((resolve, reject) => {
        axios.put(queryUrl, putObject, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * Delete imported project
 * @param user current logged in user
 * @param projectId ID of project to delete
 * @returns ok or error
 */
export const deleteProject = (user: User, projectId: string): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}admin/project/${projectId}`;
    
    return new Promise((resolve, reject) => {
        axios.delete(queryUrl, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};