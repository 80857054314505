/* Utils class for geometry operations */
import proj4 from "proj4";
import Point from "@arcgis/core/geometry/Point";
import Extent from "@arcgis/core/geometry/Extent";

/**
 * Transform a geographic point to another projection
 * @param c point to transform (x,y)
 * @param from current projection for c
 * @param to wanted projection
 * @returns c transformed to wanted projection
 */
export const transform = (c: Point, from: number, to: number) : Point => {
    let fromProj = proj4.Proj(proj4.defs("EPSG:" + from));
    if(!fromProj.datum) {
        fromProj = proj4.Proj(getProj(from));
    }
    const toProj = proj4.Proj(UTM33);
    const p = {x: c.x, y: c.y};
    const t = proj4.transform(fromProj, toProj, p);
    return new Point({x: t.x, y: t.y, spatialReference: {wkid: to}});
}

/**
 * Transform list of x,y-pairs from given projection to UTM 33
 * @param listOfXY list of coordinates (x,y) to transform
 * @param from WKID for input coordinates
 * @returns list of transformed coordinates as points in UTM 33
 */
export const transformListOfXY = (listOfXY: [], from: number): any => {
    if (listOfXY.length === 0)
        return [];

    const transformedList: any = [];
    listOfXY.forEach((c: any) => {
        var p = {x: c[0], y: c[1]};
        var t = proj4.transform(proj4.Proj(proj4.defs("EPSG:" + from)), proj4.Proj(UTM33), p); 
        transformedList.push([t.x, t.y]);   
    });
    
    return transformedList;
}

/**
 * Transform extent from one projection to another
 * @param c extent (boundingbox) to transform
 * @param from WKID for input extent
 * @param to wanted WKID 
 * @returns transformed extent in wanted projection
 */
export const transformExtent = (c: Extent, from: number, to: number) : Extent => {
    var utm33 = UTM33;
    var topLeft = proj4.transform(proj4.Proj(proj4.defs("EPSG:" + from)), proj4.Proj(utm33), {x: c.xmin, y: c.ymin});
    var bottomRight = proj4.transform(proj4.Proj(proj4.defs("EPSG:" + from)), proj4.Proj(utm33), {x: c.xmax, y: c.ymax});
    
    return new Extent({xmin: topLeft.x, ymin: topLeft.y, xmax: bottomRight.x, ymax: bottomRight.y, spatialReference: {wkid: to}});
};

/**
 * Transform extent from UTM 33 to gepgraphic coordinates (LatLon)
 * @param c extent to transform
 * @returns Extent in geographic coordinates (LatLon)
 */
export const transformExtentUTM33ToLL = (c: Extent) : Array<number> => {
    var topLeft = proj4.transform(proj4.Proj(UTM33), proj4.Proj("EPSG:4326"), {x: c.xmin, y: c.ymin});
    var bottomRight = proj4.transform(proj4.Proj(UTM33), proj4.Proj("EPSG:4326"), {x: c.xmax, y: c.ymax});
    
    return [topLeft.x, topLeft.y, bottomRight.x, bottomRight.y];
};

/**
 * Get projection for given WKID
 * @param wkid WKID to get projection for
 * @returns projection for given WKID
 */
const getProj = (wkid: number): string => {
    switch(wkid) {
        case 25831:
            return UTM31;
        case 25832: 
            return UTM32;
        case 25833:
            return UTM33;
        case 25834:
            return UTM34;
        case 25835:
            return UTM35;
        case 25836:
            return UTM36;
        default:
            return UTM33;
    }
};

const UTM31 = 'PROJCS["ETRS89 / UTM zone 31N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",3],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","25831"]]';
const UTM32 = 'PROJCS["ETRS89 / UTM zone 32N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",9],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","25832"]]';
const UTM33 = 'PROJCS["ETRS89 / UTM zone 33N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",15],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","25833"]]';
const UTM34 = 'PROJCS["ETRS89 / UTM zone 34N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",21],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","25834"]]';
const UTM35 = 'PROJCS["ETRS89 / UTM zone 35N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",27],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","25835"]]';
const UTM36 = 'PROJCS["ETRS89 / UTM zone 36N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",33],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","25836"]]';