/* UI for performing string search in the artefact data */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Input } from "antd";

import SearchData from "../../../services/searchservice";
import { Artefact, DISPLAY_TYPE } from "../../../constants";
import { ArtefactFilters, StoreState } from "../../../types";
import { artefactResultIsEmpty } from "../../../utils/searchHelper";
import { clearArtefactSearchResult, getSearchResult, setSearchResult, setSelectedItem } from "../../../store/searchSlice";
import { showDisplayType, showLoading } from "../../../store/appSlice";
import { navigateApp, getArtefactQueryUrl } from "../../../utils";

const SearchBarArtefact: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [searchVal, setSearchVal] = useState<string>("");
    
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryArtefact);
    const filters: ArtefactFilters = useSelector((state: StoreState) => state.search.artefactFilters);
    const maxHits: number = useSelector((state: StoreState) => state.search.maxHits);
    
    const { Search } = Input;

    const perfomSearch = () => {
        dispatch(showLoading(true));
        const queryUrl = getArtefactQueryUrl(searchQuery, filters.museum, filters.requirePhoto, filters.museumNO,
            filters.county, filters.municipality, maxHits, 1);
        
        SearchData.get(queryUrl, Artefact).then(response => {
            dispatch(showLoading(false));
            
            if(location.pathname !== "/") {
                navigateApp(dispatch, navigate, "/");  // On new result - always navigate to root
            }
            dispatch(setSelectedItem(null));
            dispatch(setSearchResult(response));

            if(!artefactResultIsEmpty(response)) {
                dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
            }
        });

        if(searchQuery !== searchVal) {
            setSearchVal(searchQuery);
        }
    };

    useEffect(
        () => {
        // we don't want to make a "blind" search when searchQuery is empty:
        if (searchQuery) {
            perfomSearch();
        }
        else {
            if (searchQuery === "") {
                setSearchVal("");
            }
            
            if(!filters.museumNO) {
                dispatch(clearArtefactSearchResult());
            }
        }
    }, [searchQuery]);

    const showSearchResult = (q: string) => {
        if(q !== searchQuery) {
            dispatch(getSearchResult({type: Artefact, phrase: q}));
        }
    };

    return (
        <div className="searchbar">
            <Search suffix={""} placeholder="Søk på ord og uttrykk" value={searchVal} onChange={(e) => setSearchVal(e.target.value)}
                    onSearch={value => showSearchResult(value)} className="searchbar-input" allowClear enterButton maxLength={100} />
        </div>
    );
}

export default SearchBarArtefact;
