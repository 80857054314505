/* List of place and address results from a location search */
import { Button } from "antd";
import { DisplayableResult, MapSearchResult, StoreState } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayableMapHitResult } from "../../../utils/searchHelper";
import { addMapMarkers } from "../../../store/mapSlice";

import pushpinImg from "../../../images/pushpin.png";
import homeImg from "../../../images/home.png";
import placemarkerImg from "../../../images/place_marker_gray_dark_big.png";

interface myProps {
    results: Array<MapSearchResult>;
    searchPhrase: string;
    noHits: number;
}

export const MapHitResultItems = (props: myProps) => {
    const dispatch = useDispatch();

    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryMaphit);

    const showInMap = (e: any, m: DisplayableResult) => {
        dispatch(addMapMarkers([{
            shape: "Point",
            geometry: {x: m.x || 0, y: m.y || 0 },
            caption: m.navn,
            type: m.kilde
        }]));
    };

    const renderIcon = (r: DisplayableResult) => {
        if(r.kilde === "Stedsnavn") {
            return <img src={pushpinImg} alt="Tegnestift" title="Stedsnavn" />;
        }

        return <img src={homeImg} alt="Hus" title="Adresse" />;
    };

    const renderResult = (r: DisplayableResult, i: number) => {
        return (
            <div key={"maphitResultItem" + i} className="hit-container" style={{marginRight: 0}}> 
                <div className="hit-content">
                    <div className="map-hit-thumbnail">
                        <div className="hit-icon">
                            {renderIcon(r)}
                        </div>
                    </div>
                    <div className="hit-info map-hit-info">
                        <div>
                            <Button type="link" onClick={(e) => showInMap(e, r)} style={{padding: "4px 0"}} title={`Gå til ${r.navn}`}>
                                <div className="hit-header">
                                    <span className="bold">{r.navn}</span>
                                </div>
                            </Button>
                            <div className="hit-details">
                                {r.info}
                            </div>
                            <div className="hit-details">
                                <span>{r.kommunenavn}</span>
                            </div>
                        </div>
                    </div>
                    <div className="map-hit-buttons">
                        <Button className="btn-link-small" onClick={(e) => showInMap(e, r)} type="link" >
                            <img src={placemarkerImg} alt="Kartmarkør" className="hit-btn-map-img" />
                        </Button>
                    </div>
                </div>
                <hr />
            </div>
        );
    };

    const renderList = () => {
        if(props.results.length < 1) {
            return (
                <div></div>
            );
        }

        const displayableResults = props.results.map((h: MapSearchResult) => { return getDisplayableMapHitResult(h); })

        return (
            <div>
                {displayableResults.map((r: DisplayableResult, i:number) => {
                    return renderResult(r, i)
                })}
            </div>
        );
    };


    return (
        <div className="result-container">
            <div className="result-container-scroll">
                <div className="resultlist-header">
                    <div className="resultlist-search-info">
                        <span>
                            Resultater
                        </span>
                        <span className={"margin-left-small" + (searchQuery ? "" : " remove-element")}>
                            for <b>{searchQuery}</b>
                        </span>
                    </div>
                    <div className="margin-left-small">
                        {props.noHits} treff
                    </div>
                </div>
                <div className="resultlist-content" style={{paddingLeft: 0}}>
                    {renderList()}
                </div>
            </div>
        </div>
    );
};