/* Configuration values for basemaps and map layers */
import { LayerInfo } from "../types";
import { LAYER_TYPE } from "../constants";
import { KulturminneUrl } from "./SearchConfig";

import gratoneImg from "../images/thumbnails/gratone.png";
import grunnkartImg from "../images/thumbnails/grunnkart.png";
import landskapImg from "../images/thumbnails/landskap.png";
import bilderImg from "../images/thumbnails/bilder.png";
import hybridImg from "../images/thumbnails/hybrid.png";

// Basemap layers
const VectorGratone: LayerInfo = {
    id: "vector_gratone",
    type: LAYER_TYPE.VECTORTILE,
    caption: "Gråtone",
    url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatone/VectorTileServer",
    thumbnail: gratoneImg
};

const VectorGrunnkart: LayerInfo = {
    id: "vector_grunnkart",
    type: LAYER_TYPE.VECTORTILE,
    caption: "Grunnkart",
    url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis/VectorTileServer",
    thumbnail: grunnkartImg
};

const LandskapGrunnkart: LayerInfo = {
    id: "base_landskap",
    type: LAYER_TYPE.TILE,
    caption: "Landskap",
    url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheLandskap/MapServer",
    thumbnail: landskapImg
};

const BilderGrunnkart: LayerInfo = {
    id: "base_bilder",
    type: LAYER_TYPE.TILE,
    caption: "Bilder",
    url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheBilder/MapServer",
    thumbnail: bilderImg
};

const HybridGrunnkart: LayerInfo = {
    id: "base_hybrid",
    type: LAYER_TYPE.TILE,
    caption: "Hybrid",
    url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheHybrid/MapServer",
    thumbnail: hybridImg,
    attachTo: "base_bilder"
};

// Data layers
// Kulturminner
// Bygg
const KMBygninger_freda: LayerInfo = {
    id: "km_bygninger_freda",
    type: LAYER_TYPE.FEATURE,
    caption: "Freda bygninger",
    url: KulturminneUrl + "1",
    hidden: true,
    minScale: 20000
};
const KMBygninger_sefrak: LayerInfo = {
    id: "km_bygninger_sefrak",
    type: LAYER_TYPE.FEATURE,
    caption: "SEFRAK",
    url: KulturminneUrl + "2",
    hidden: true,
    minScale: 20000
};
const KMBygninger: LayerInfo = {
    id: "km_bygninger",
    type: LAYER_TYPE.GROUP,
    caption: "Bygninger",
    hidden: true,
    layerInfos: [KMBygninger_freda, KMBygninger_sefrak]
};
// Kulturminner
const KMKulturminner_enk: LayerInfo = {
    id: "km_kulturminner_enk",
    type: LAYER_TYPE.FEATURE,
    caption: "Enkeltminner",
    url: KulturminneUrl + "6",
    hidden: true,
    minScale: 20000
};
const KMKulturminner_lok: LayerInfo = {
    id: "km_kulturminner_lok",
    type: LAYER_TYPE.FEATURE,
    caption: "Lokaliteter",
    url: KulturminneUrl + "7",
    hidden: false,
    minScale: 20000
};
const KMKulturminner_sikringssoner: LayerInfo = {
    id: "km_kulturminner_sikringssoner",
    type: LAYER_TYPE.FEATURE,
    caption: "Sikringssoner",
    url: KulturminneUrl + "8",
    hidden: false,
    minScale: 20000
};
const KMKulturminner_lok_punkt: LayerInfo = {
    id: "km_kulturminner_lok_punkt",
    type: LAYER_TYPE.FEATURE,
    caption: "Lokaliteter oversikt",
    url: KulturminneUrl + "5",
    hidden: false,
    maxScale: 20000
};
const KMKulturminner: LayerInfo = {
    id: "km_kulturminner",
    type: LAYER_TYPE.GROUP,
    caption: "Kulturminner",
    hidden: false,
    layerInfos: [KMKulturminner_sikringssoner, KMKulturminner_lok, KMKulturminner_enk, KMKulturminner_lok_punkt]
};

// Brannvern
const KMBrannvern_smitteomrader: LayerInfo = {
    id: "km_brannvern_smitteomrader",
    type: LAYER_TYPE.FEATURE,
    caption: "Brannsmitteområder",
    url: KulturminneUrl + "12",
    hidden: true,
    minScale: 20000
};
const KMBrannvern_verneverdig_trehus: LayerInfo = {
    id: "km_brannvern_verneverdig_trehus",
    type: LAYER_TYPE.FEATURE,
    caption: "Verneverdige trehusmiljøer",
    url: KulturminneUrl + "13",
    hidden: true,
    minScale: 20000
};
const KMBrannvern: LayerInfo = {
    id: "km_brannvern",
    type: LAYER_TYPE.GROUP,
    caption: "Brannvern",
    hidden: true,
    layerInfos: [KMBrannvern_smitteomrader, KMBrannvern_verneverdig_trehus]
};

// Kulturmiljø
const KMKulturmiljo_punkt: LayerInfo = {
    id: "km_kulturmiljo_punkt",
    type: LAYER_TYPE.FEATURE,
    caption: "Kulturmiljø oversikt",
    url: KulturminneUrl + "16",
    hidden: false,
    maxScale: 20000
};
const KMKulturmiljo_flate: LayerInfo = {
    id: "km_kulturmiljo_flate",
    type: LAYER_TYPE.FEATURE,
    caption: "Kulturmiljø",
    url: KulturminneUrl + "15",
    hidden: false,
    minScale: 20000
};
const KMKulturmiljo: LayerInfo = {
    id: "km_kulturmiljo",
    type: LAYER_TYPE.GROUP,
    caption: "Kulturmiljøer",
    hidden: false,
    layerInfos: [KMKulturmiljo_flate, KMKulturmiljo_punkt]
};

const Kulturminner: LayerInfo = {
    id: "kulturminner", 
    type: LAYER_TYPE.GROUP,
    caption: "Kulturminner", 
    hidden: true,
    layerInfos: [KMKulturmiljo, KMBrannvern, KMKulturminner, KMBygninger]
};

// const Skyggerelieff: LayerInfo = {
//     id: "skyggerelieff", 
//     type: LAYER_TYPE.IMAGE,
//     caption: "Høydedata skyggerelieff", 
//     // url: "https://hoydedata.no/arcgis/rest/services/las_dtm_skyggerelieff/ImageServer", //Utgått, ser ut til å mangle god erstatning
//     hidden: true,
//     opacity: 0.9,
//     minScale: 80000
// };

const N5Raster: LayerInfo = {
    id: "n5raster",
    type: LAYER_TYPE.WMS,
    caption: "N5 Raster ØK",
    url: "https://openwms.statkart.no/skwms1/wms.n5raster2",
    hidden: true
};

export const HistoriskKart: LayerInfo = {
    id: "historiskekart",
    type: LAYER_TYPE.WMS,
    caption: "Historisk kart",
    url: "https://wms.geonorge.no/skwms1/wms.historiskekart",
    hidden: true
};

const Berggrunn: LayerInfo = {
    id: "berggrunn",
    type: LAYER_TYPE.WMS,
    caption: "Berggrunn N50 (NGU)",
    url: "http://geo.ngu.no/mapserver/BerggrunnN50WMS2",
    hidden: true,
    featureInfoFormat: "text/html",
    minScale: 50000
};

const Grunnforhold: LayerInfo = {
    id: "grunnforhold",
    type: LAYER_TYPE.WMS,
    caption: "Grunnforhold, arealressurs FKB (NIBIO)",
    url: "https://wms.nibio.no/cgi-bin/ar5",
    hidden: true,
    featureInfoFormat: "text/html",
    opacity: 0.6,
    minScale: 50000
};

// ADED layers
const ADEDFunn: LayerInfo = {
    id: "aded_funnt_punkter",
    type: LAYER_TYPE.FEATURE,
    caption: "Funnenhet",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/1",
    hidden: true,
    useADEDTemplate: true,
    minScale: 20000
};

const ADEDProve: LayerInfo = {
    id: "aded_prove_punkter",
    type: LAYER_TYPE.FEATURE,
    caption: "Prøve",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/2",
    hidden: true,
    useADEDTemplate: true,
    minScale: 20000
};

const ADEDProfil: LayerInfo = {
    id: "aded_profil_linjer",
    type: LAYER_TYPE.FEATURE,
    caption: "Profil",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/4",
    hidden: true,
    useADEDTemplate: true,
    minScale: 20000
};

// Arkeologisk objekt
const ADEDArkObjFlater: LayerInfo = {
    id: "aded_arkobj_flater",
    type: LAYER_TYPE.FEATURE,
    caption: "Flater",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/9",
    hidden: false,
    useADEDTemplate: true,
    opacity: 0.7,
    minScale: 20000
};

const ADEDArkObjLinjer: LayerInfo = {
    id: "aded_arkobj_linjer",
    type: LAYER_TYPE.FEATURE,
    caption: "Linjer",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/3",
    hidden: false,
    useADEDTemplate: true,
    minScale: 20000
};

const ADEDArkObjPunkter: LayerInfo = {
    id: "aded_arkobj_punkter",
    type: LAYER_TYPE.FEATURE,
    caption: "Punkter",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/0",
    hidden: false,
    useADEDTemplate: true,
    minScale: 20000
};

const ADEDArkObj: LayerInfo = {
    id: "aded_arkobj",
    type: LAYER_TYPE.GROUP,
    caption: "Arkeologisk objekt",
    hidden: false,
    layerInfos: [ADEDArkObjFlater, ADEDArkObjLinjer, ADEDArkObjPunkter]
};

const ADEDGrave: LayerInfo = {
    id: "aded_grave_flater",
    type: LAYER_TYPE.FEATURE,
    caption: "Graveenhet",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/7",
    hidden: true,
    useADEDTemplate: true,
    minScale: 20000
};

// Topografisk objekt
const ADEDTopoFlater: LayerInfo = {
    id: "aded_topo_flater",
    type: LAYER_TYPE.FEATURE,
    caption: "Flater",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/8",
    hidden: true,
    useADEDTemplate: true,
    opacity: 0.7,
    minScale: 20000
};

const ADEDTopoLinjer: LayerInfo = {
    id: "aded_topo_linjer",
    type: LAYER_TYPE.FEATURE,
    caption: "Linjer",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/5",
    hidden: true,
    useADEDTemplate: true,
    minScale: 20000
};

const ADEDTopo: LayerInfo = {
    id: "aded_topo",
    type: LAYER_TYPE.GROUP,
    caption: "Topografisk objekt",
    hidden: true,
    layerInfos: [ADEDTopoFlater, ADEDTopoLinjer]
};

const ADEDRute: LayerInfo = {
    id: "aded_rute_flater",
    type: LAYER_TYPE.FEATURE,
    caption: "Rute",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/6",
    hidden: true,
    useADEDTemplate: true,
    minScale: 20000
};

const ADEDOmrade: LayerInfo = {
    id: "aded_omrade_flater",
    type: LAYER_TYPE.FEATURE,
    caption: "Område",
    url: window.environment.aded_mapserver + "/Hovedtyper/MapServer/10",
    hidden: false,
    useADEDTemplate: true,
    opacity: 0.7,
    minScale: 20000
};

// Prosjekt
const ADEDProsjektFlater: LayerInfo = {
    id: "aded_prosjekt_flater",
    type: LAYER_TYPE.FEATURE,
    caption: "Flater - Estimert geometri",
    url: window.environment.aded_mapserver + "/Estimerte/MapServer/3",
    hidden: false,
    useADEDTemplate: true,
    opacity: 0.6,
    maxScale: 5000,
    minScale: 160000
};

const ADEDProsjektPunkter: LayerInfo = {
    id: "aded_prosjekt_punkter",
    type: LAYER_TYPE.FEATURE,
    caption: "Punkter",
    url: window.environment.aded_mapserver + "/Estimerte/MapServer/0",
    hidden: false,
    useADEDTemplate: true,
    maxScale: 10000,
    enableClustering: true
};

const ADEDProsjekt: LayerInfo = {
    id: "aded_prosjekt",
    type: LAYER_TYPE.GROUP,
    caption: "Prosjekt",
    hidden: false,
    layerInfos: [ADEDProsjektFlater, ADEDProsjektPunkter]
};

const ADED: LayerInfo = {
    id: "aded_group",
    type: LAYER_TYPE.GROUP,
    caption: "Utgravninger",
    hidden: false,
    layerInfos: [ADEDProsjekt, ADEDOmrade, ADEDArkObj, ADEDTopo, ADEDGrave, ADEDRute, ADEDProfil, ADEDProve, ADEDFunn ]
};

const Gjenstandsbasen: LayerInfo = {
    id: "genstandsbasen",
    type: LAYER_TYPE.FEATURE,
    caption: "Gjenstandsbasen",
    url: "https://services7.arcgis.com/wCvLzGFkz06gCfBg/arcgis/rest/services/GjenstandsbasenTotalUTM33/FeatureServer/0",
    hidden: true,
    minScale: 320000
};

export const basemapLayers: Array<LayerInfo> = [VectorGratone, VectorGrunnkart, LandskapGrunnkart, BilderGrunnkart, HybridGrunnkart];
export const mapLayers: Array<LayerInfo> = [N5Raster, Berggrunn, Grunnforhold, Kulturminner, ADED, Gjenstandsbasen ];
