/* Utils class calling methods on OIDC usermanager object */
import { Log, User, UserManager } from 'oidc-client';
import { getUserManagerSettings } from "../config/AuthConfig";

export class AuthService {
  public userManager: UserManager;

  /**
   * Create UserManager instance using properties from AuthConfig.ts
   */
  constructor() {
    let settings = getUserManagerSettings();
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  /**
   * Get current logged in user
   * @returns user or null
   */
  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  /**
   * Log in
   * @returns redirects to root if success
   */
  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  /**
   * Renew token for logged in user, performed silently
   * @returns nothing
   */
  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  /**
   * Log out
   * @returns redirects to sign out
   */
  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
    
}
