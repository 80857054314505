/* Get and show details of one selected Artefact, using unimus artefact API to get the details */
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { PlusCircleFilled, MinusCircleFilled, LeftOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { ArtefactResult, StoreState, ADEDResult, SearchResult } from "../../../types";
import LookupData from "../../../services/lookupservice";
import * as conf from '../../../config/SearchConfig';
import { ADED, ALERT_TYPE, Artefact, DISPLAY_TYPE } from "../../../constants";
import { capitalize, getMuseumName, getWKID, onlyUnique, orderMaterials } from "../../../utils";
import { setSelectedItem } from "../../../store/searchSlice";
import { showAlert, showDisplayType } from "../../../store/appSlice";
import { addMapMarkers, clearMapSearchResult } from "../../../store/mapSlice";
import ArtefactPhoto from "./photogallery/artefactphoto";
import Timeline from "./timeline/timeline";
import Kulturminner from "./kulturminner/kulturminner";
import { isCanceled, useCancellablePromise } from "../../../utils/useCancelablePromise";

import potImg from "../../../images/pot.png";
import potWhiteImg from "../../../images/pot_white.png";
import listImg from "../../../images/list.png";
import "./details.css";

interface iParams {
    id?: string
}

const ArtefactDetails = () => {
    const params:iParams = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cancellablePromise } = useCancellablePromise();

    const [artefactResult, setArtefactResult] = useState<ArtefactResult | null>(null);
    const [showAttributes, setShowAttributes] = useState<boolean>(true);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showTimeline, setShowTimeline] = useState<boolean>(false);

    const selectedItem: ADEDResult | ArtefactResult | null = useSelector((state: StoreState) => state.search.selectedItem);
    const displayType: DISPLAY_TYPE = useSelector((state: StoreState) => state.app.displayType);
    const artefactResults: SearchResult | null = useSelector((state: StoreState) => state.search.artefactResults);
    const firstTime: boolean = useSelector((state: StoreState) => state.app.firstTime);

    const artefactID = useRef<string>("");

    useEffect(
        () => {
            if(selectedItem !== null && selectedItem.type === Artefact) {
                setArtefactResult(selectedItem as ArtefactResult);
            }
            else {
                setArtefactResult(null);
            }
    }, [selectedItem]);

    if(selectedItem === null || selectedItem.type === ADED || artefactID.current !== params.id) {
        artefactID.current = params.id || '';

        let artefactQ = conf.ArtefactUrl + conf.ArtefactDocPart + params.id;

        const load = async () => {
            try {
                const response = await cancellablePromise(
                    LookupData.getArtefact(artefactQ)
                );

                const res = response.result as ArtefactResult;
                if(res) {
                    setShowMore(false);
                    
                    if(res.coordinateSets && res.coordinateSets.length > 0 && 
                        res.coordinateSets[0].coordinates && res.coordinateSets[0].coordinates.length > 0) {
                        markGeometry(res);
                    }
                    else {
                        dispatch(clearMapSearchResult());
                    }

                    dispatch(setSelectedItem(response.result));
                    if(displayType === DISPLAY_TYPE.MAP_VIEW) {
                        dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
                    }
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Fant ikke objektet.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            } catch (error) {
                if (!isCanceled(error)) {
                    dispatch(showAlert({title: "Feilet!", message: "Fant ikke objektet.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            }
        };
        load();
    }

    const markGeometry = (r: ArtefactResult) => {
        const mapMarker = {
            shape: "Point",
            geometry: {
                x: r.coordinateSets[0].coordinates[0].utmEast || 0, 
                y: r.coordinateSets[0].coordinates[0].utmNorth || 0,
                wkid: getWKID(r.coordinateSets[0].projection)
            },
            caption: r.termNo,
            type: "Arkeologi",
            title: capitalize(r.termNo + " " + r.museumNo),
            tooltipContent: "Nøyaktighet: " + r.coordinateSets[0].coordinatePrecision
        };
        dispatch(addMapMarkers([mapMarker]));
    }

    const close = () => {
        dispatch(setSelectedItem(null));
        navigate(-1);
    };

    const renderCadaster = (r: ArtefactResult) => {
        if(!r.admPlaces || r.admPlaces.length === 0) {
            return null;
        }

        let cadasters:Array<string> = [];
        r.admPlaces.forEach(p => {
            if(p.cadastralName && p.cadastralNo) {
                cadasters.push(p.cadastralName + " - " + p.cadastralNo);
            }
            else if(p.cadastralName) {
                cadasters.push(p.cadastralName);
            }
            else if(p.cadastralNo) {
                cadasters.push(p.cadastralNo.toString());
            }
        });

        return (
            <tr>
                <td className="details-key">Gård</td>
                <td className="details-value">{cadasters.join(", ")}</td>
            </tr>
        );
    };

    const renderFindCategories = (r: ArtefactResult) => {
        if(!r.findCategories || r.findCategories.length === 0) {
            return null;
        }

        return (
            <tr>
                <td className="details-key">Funnkategori</td>
                <td className="details-value">{r.findCategories.map(k => {return capitalize(k);}).join(", ")}</td>
            </tr>
        );
    }

    const renderAttributes = (r: ArtefactResult) => {
        return (
            <div className="details-table-container-content">
                <table className="details-table">
                    <tbody>
                        <tr>
                            <td className="details-key">Periode</td>
                            <td className="details-value">{r.periods?.map(p => {return capitalize(p);}).join(", ") || "Ikke angitt"}</td>
                        </tr>
                        <tr>
                            <td className="details-key">Materiale</td>
                            <td className="details-value">{r.materials ? orderMaterials(r.materials) : "Ikke angitt"}</td>
                        </tr>
                        <tr>
                            <td className="details-key">Form</td>
                            <td className="details-value">{capitalize(r.form || "Ikke angitt")}</td>
                        </tr>
                        {renderFindCategories(r)}
                        {renderCadaster(r)}
                    </tbody>
                </table>
            </div>
        );
    }

    const getDesc = (d: string) => {
        const desc = ((showMore || d.length <= 500) ? d : (d.substring(0, 500) + " ..."));
        const parts = desc.split("\n");
        return (
            <div>
                {parts.map((p: string, i: number) => {
                    return (
                        <div key={`desc_part${i}`} className="margin-top">{capitalize(p)}</div>
                    )
                })}
            </div>
        );
    }

    const renderDescription = (r: ArtefactResult) => {
        if(!r.descriptionNo) {
            return <div></div>;
        }

        if(r.descriptionNo.length < 500) {
            return (
                <div className="details-table-wrapper">
                    <div className="details-description">
                        <div className="details-subheader">Beskrivelse</div>
                        <span>{capitalize(r.descriptionNo)}</span>
                    </div>
                </div>
            )
        }

        return (
            <div className="details-table-wrapper">
                <div className="details-description">
                    <div className="details-subheader">Beskrivelse</div>
                    {getDesc(r.descriptionNo)}
                    <Button type="link" onClick={() => setShowMore(!showMore)}>
                        {"Vis " + (showMore ? "mindre" : "mer")}
                    </Button>
                </div>
            </div>
        );
    }

    const renderKulturminneLink = (r: ArtefactResult) => {
        if(!r.locationId) {
            return null;
        }

        return (
            <Kulturminner lokaliteter={[r.locationId.trim()]} />
        );
    };

    const renderKommuneFylke = (r: ArtefactResult) => {
        if(r.admPlaces && r.admPlaces.length > 0) {
            const k = r.admPlaces.map(p => {return p.municipality || ""}).filter(onlyUnique);
            const f = r.admPlaces.map(p => {return p.county || ""}).filter(onlyUnique);
            return " - " + k.join(", ") + ", " + f.join(", ");
        }

        return "";
    };

    const renderHeader = () => {
        if(artefactResult === null) {
            return <div></div>;
        }
        return (
            <div className="details-header">
                <b>
                    { capitalize(artefactResult.termNo || "") + " - " + artefactResult.museumNo }
                </b>
            </div>
        );
    };

    const renderResult = () => {
        if(artefactResult === null) {
            return <div></div>;
        }

        return (
            <div className="details-form">
                <div className="details-objekt-header">
                    <div className="details" style={{position: "absolute"}}>
                        <Button className={`timeline-btn ${showTimeline ? "shown" : ""}`} onClick={() => {setShowTimeline(!showTimeline)}} 
                            title={`${showTimeline ? "Skjul" : "Vis"} tidsepoker med lenker til norgeshistorie.no`}>
                            <img src={showTimeline ? potWhiteImg : potImg} alt="Vase" />
                            <span>{`${showTimeline ? "Skjul" : "Vis"} tidsepoker`}</span>
                            {showTimeline && <CaretUpOutlined /> }
                            {!showTimeline && <CaretDownOutlined /> }
                        </Button>
                    </div>

                    {capitalize((artefactResult.artefact ? artefactResult.artefact : artefactResult.termNo) || "") + (artefactResult.variant ? ", " + capitalize(artefactResult.variant) : "")}
                    {renderKommuneFylke(artefactResult)}
                    <div className="details-objekt-subheader">
                        {getMuseumName(artefactResult.museum || "") + " (" + artefactResult.museum + ")"}
                    </div>
                </div>

                {showTimeline && <Timeline />}

                <div className="details-table-wrapper">
                    <div className={`details-table-container ${showAttributes ? "" : "w60p"}`}>
                        <div className={`details-table-container-header ${showAttributes ? "" : "white"}`}>
                            Egenskaper
                            <Button type="link" onClick={() => setShowAttributes(!showAttributes)} >
                                {showAttributes && <MinusCircleFilled /> }
                                {!showAttributes && <PlusCircleFilled /> }
                            </Button>
                        </div>
                        { showAttributes && renderAttributes(artefactResult) }
                        { showAttributes && 
                            <Button type="link" title="Ekstern lenke - se treffet i gjenstandsdatabasen" target="_blank"
                                    href={conf.ArtefactPortalUrl + artefactResult.thingUuid} rel="noopener noreferrer" >
                                Se i gjenstandsbasen
                            </Button>
                        }
                        { showAttributes && renderDescription(artefactResult) }

                        {(!artefactResult.coordinateSets || artefactResult.coordinateSets.length === 0) &&
                            <div className="no-geometry">
                                Ingen geometri registrert
                            </div>
                        }
                    </div>
                </div>

                {renderKulturminneLink(artefactResult)}

                <ArtefactPhoto />
            </div>
        );
    };

    const renderBackBtn = () => {
        if(!artefactResults) {
            return <div></div>;
        }

        return (
            <Button type="link" className="btn-link-small no-padding resultlist-btn" onClick={() => close()} >
                Til resultatlisten
                <img src={listImg} alt="List" className="info-header-btn-img" />
            </Button>
        );
    };

    const goToPrevious = () => {
        navigate(-1);
    };

    const renderNavigation = () => {
        if(navigate.length < 1) {
            return <div></div>
        }

        return (
            <div className="info-header">
                <div className="info-header-btns">
                    { !firstTime &&
                    <Button type="link" className="btn-link-small no-padding" onClick={goToPrevious} >
                        <LeftOutlined />
                        Forrige
                    </Button>
                    }

                    { renderBackBtn() }
                </div>
                { renderHeader() }
            </div>
        );
    };

    return (
        <div className="details-container"> 
            {renderNavigation()}
            <div className="details-content">
                {renderResult()}
            </div>
        </div>
    );
};

export default ArtefactDetails;