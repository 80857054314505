/* UI for map functionality like measurements and location queries */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { CloseOutlined, PrinterFilled, SearchOutlined } from "@ant-design/icons";

import MapView from '@arcgis/core/views/MapView';
import Sketch from "@arcgis/core/widgets/Sketch";
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D";
import AreaMeasurement2D from "@arcgis/core/widgets/AreaMeasurement2D";
import CoordinateConversion from "@arcgis/core/widgets/CoordinateConversion";
import Format from "@arcgis/core/widgets/CoordinateConversion/support/Format";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Conversion from "@arcgis/core/widgets/CoordinateConversion/support/Conversion";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Graphic from "@arcgis/core/Graphic";

import { ADED, LAYER_TYPE, WKID_LATLON, WKID_UTM33 } from "../../../constants";
import { HohService } from "../../../config/MapConfig";
import MapData from "../../../services/mapservice";
import { getLayer, transformExtentUTM33ToLL } from "../../../utils";
import * as conf from "../../../config/MapConfig";
import { setPrintDialogVisible, setWidgetsExpanded } from "../../../store/mapSlice";
import { searchbase, StoreState } from "../../../types";
import { setGeoBBox } from "../../../store/searchSlice";

import doubleArrowDownImg from "../../../images/double_arrow_down.png";
import measureDistanceImg from "../../../images/measure_distance.png";
import measureAreaImg from "../../../images/measure_area.png";
import coordImg from "../../../images/coord.png";
import shareImg from "../../../images/share.svg";
import copyImg from "../../../images/copy.svg";
import "./widgets.css";

interface widgetProps {
    map: MapView | null
};

export const Widgets = (props: widgetProps) => {
    const dispatch = useDispatch();
    
    const sketchWidget = useRef<any>(null);
    const distanceWidget = useRef<any>(null);
    const areaWidget = useRef<any>(null);
    const coordWidget = useRef<any>(null);
    const hohContent = useRef<any>(null);
    const mapClickListener = useRef<any>(null);
    const searchStateLayer = useRef<any>(null);
    const searchLayer = useRef<any>(null);
    const graphicsLayer = useRef<any>(null);

    const [hideCloseWidgetBtn, setHideCloseWidgetBtn] = useState<boolean>(true);
    const [mapUrl, setMapUrl] = useState<string>(window.location.href);
    const [sharingActive, setSharingActive] = useState<boolean>(false);
    const [copyActive, setCopyActive] = useState<boolean>(false);
    
    const widgetsExpanded: boolean = useSelector((state: StoreState) => state.map.widgetsExpanded);
    const geoBBox: Array<number> | null = useSelector((state: StoreState) => state.search.geoBBox);
    const selectedResultBase: (searchbase | null) = useSelector((state: StoreState) => state.search.selectedSearchBase);

    useEffect(() => {
        if(!widgetsExpanded) {
            setActiveWidget("");
        }
    }, [widgetsExpanded]);

    useEffect(() => {
        if(props.map) {
            if(!searchStateLayer.current) {
                const g = getLayer({id: "geobbox", type: LAYER_TYPE.GRAPHIC}) as GraphicsLayer;
                if(g) {
                    g.listMode = "hide";
                    props.map.map.add(g);
                    searchStateLayer.current = g;
                }
            }
            else {
                searchStateLayer.current.removeAll();
            }

            if(geoBBox && geoBBox) {
                const rings = [[
                    [geoBBox[0], geoBBox[3]],
                    [geoBBox[2], geoBBox[3]],
                    [geoBBox[2], geoBBox[1]],
                    [geoBBox[0], geoBBox[1]],
                    [geoBBox[0], geoBBox[3]],
                ]];

                const polygon = new Polygon({
                    hasZ: true,
                    hasM: true,
                    rings: rings,
                    spatialReference: { wkid: WKID_LATLON }
                });

                let symbol = {
                    type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                    color: [ 0,0,0,0.15],
                    style: "solid",
                    outline: {  // autocasts as new SimpleLineSymbol()
                      color: "#0C7978",
                      width: 2
                    }
                };
                
                searchStateLayer.current.add(new Graphic({
                    geometry: polygon,
                    symbol: symbol,
                    popupTemplate: {
                      title: "Søkeområde"
                    }
                }));

                if(searchLayer.current) {
                    searchLayer.current.removeAll();
                }
            }
            else {
                tryCloseWidget(sketchWidget);
                if(!(distanceWidget.current || areaWidget.current || coordWidget.current)) {
                    setHideCloseWidgetBtn(true);
                }
            }
        }
    }, [geoBBox]);

    useEffect(() => {
        if(props.map) {
            if(searchStateLayer.current) {
                searchStateLayer.current.visible = (selectedResultBase === ADED);

                if(selectedResultBase !== ADED) {
                    tryCloseWidget(sketchWidget);
                    if(!(distanceWidget.current || areaWidget.current || coordWidget.current)) {
                        setHideCloseWidgetBtn(true);
                    }
                }
            }
        }
    }, [selectedResultBase]);


    useEffect(() => {
        if(props.map) {
            props.map.watch("stationary", (isStationary) => {
                if (isStationary && props.map) {
                    setMapUrl(window.location.origin + window.location.pathname + "?x=" + Math.round(props.map.extent.center.x).toString() + "&y=" + Math.round(props.map.extent.center.y).toString() + "&zoom=" + props.map.zoom.toString());
                    setCopyActive(false); //Resett hvis bruker har trykket på copy tidligere
                }
            });
        }
    }, [props.map]);

    const tryCloseWidget = (widget: any) => {
        if (props.map && widget.current) {
            props.map.ui.remove(widget.current);
            if(widget.current.destroy) {
                widget.current.destroy();
            }
            widget.current = null;
        }
    };

    const createHohElement = (content: string) => {
        let div = document.createElement("div");
        var att = document.createAttribute("class");
        att.value = "hoh-container";
        div.setAttributeNode(att);
        div.appendChild(document.createTextNode(content));

        hohContent.current = div;
    };

    const replaceHohElement = (newContent: string) => {
        if(coordWidget.current !== null) {
            if(hohContent.current !== null) {
                coordWidget.current.container.removeChild(hohContent.current);
            }
            createHohElement(newContent);
            coordWidget.current.container.appendChild(hohContent.current);
        }
    };

    const addMapClickListener = () => {
        if(props.map === null) {
            return;
        }

        if(coordWidget.current !== null) {
            // Allow time to render, otherwise hoh element ends up on top
            setTimeout(() => {
                createHohElement("Klikk i kartet for terrenghøyde");
                coordWidget.current.container.appendChild(hohContent.current);
            }, 300);
        }

        mapClickListener.current = props.map.on("click", (event) => {
            if(props.map && event.mapPoint.x && event.mapPoint.y) {
                let g:GraphicsLayer;
                if(graphicsLayer.current) {
                    g = graphicsLayer.current;
                    g.removeAll();
                }
                else {
                    g = getLayer({id: "hoh", type: LAYER_TYPE.GRAPHIC}) as GraphicsLayer;
                    if(g) {
                        g.listMode = "hide";
                        props.map.map.add(g);
                        graphicsLayer.current = g;
                    }
                }

                replaceHohElement("Henter terrenghøyde...");
                MapData.identify(HohService, event.mapPoint.x, event.mapPoint.y, 
                props.map.extent.xmin, props.map.extent.ymin, props.map.extent.xmax, props.map.extent.ymax, 
                props.map.height, props.map.width).then((response) => {
                    let content = "";
                    let tooltip = "";
                    if(response.error || response.result.value === "NoData") {
                        content = "Fant ikke terrenghøyde";
                        tooltip = "Ukjent";
                    }
                    else {
                        let t = response.result;
                        content = "Terrenghøyde: " + t.value + " moh.  (hoydedata.no)";
                        tooltip = t.value + " moh.";
                    }
                    
                    replaceHohElement(content);

                    // Kartmarkering
                    let p = new Point({x: event.mapPoint.x, y: event.mapPoint.y, spatialReference: event.mapPoint.spatialReference});
                    const gp = new Graphic({
                        geometry: p,
                        symbol: conf.hohPointSymbol
                    });
            
                    g.add(gp);

                    var textSymbol = {
                        type: "text",
                        color: "black",
                        haloColor: "white",
                        haloSize: "1px",
                        text: tooltip,
                        yoffset: "22px",
                        font: {
                          size: 12,
                          family: "Arial",
                          weight: "bold"
                        }
                    };
                    const gpt = new Graphic({
                        geometry: p,
                        symbol: textSymbol
                    });
            
                    g.add(gpt);
                });
            }
        });
    };

    const setInState = (geometry: any) => {
        let bb = transformExtentUTM33ToLL(geometry.extent);
        dispatch(setGeoBBox(bb));
    };

    const getUTM33Format = () => {
        // Regular expression to find a number
        const numberSearchPattern = /-?\d+[.]?\d*/;

        return new Format({
            name: "UTM 33",
            spatialReference: new SpatialReference({ wkid: WKID_UTM33 }),
            coordinateSegments: [
                {
                    alias: "X",
                    description: "easting",
                    searchPattern: numberSearchPattern
                },
                {
                    alias: "Y",
                    description: "northing",
                    searchPattern: numberSearchPattern
                }
            ],
            defaultPattern: "X, Y"
        });
    }

    const setActiveWidget = (type: string) => {
        if(props.map === null) {
            return;
        }
        
        switch (type) {
            case "sketch":
                // close other active widgets
                tryCloseWidget(areaWidget);
                tryCloseWidget(distanceWidget);
                tryCloseWidget(coordWidget);
                setSharingActive(false);

                if(sketchWidget.current === null) {
                    let g:GraphicsLayer;
                    if(!searchLayer.current) {
                        g = getLayer({id: "searcharea", type: LAYER_TYPE.GRAPHIC}) as GraphicsLayer;
                        if(g) {
                            g.listMode = "hide";
                            props.map.map.add(g);
                            searchLayer.current = g;
                        }
                    }
                    sketchWidget.current = new Sketch({
                        view: props.map,
                        layer: searchLayer.current,
                        availableCreateTools: ["rectangle"],
                        creationMode: "single",
                        visibleElements: {
                            selectionTools:{
                                "lasso-selection": false,
                                "rectangle-selection": false
                            }
                        }
                    });

                    sketchWidget.current.create("rectangle");
                    sketchWidget.current.on("create", function(event: any) {
                        if (event.state === "start") {
                            searchLayer.current.removeAll(); // Kan bare ha ett samtidig område
                        }
                        if (event.state === "complete") {
                            setInState(event.graphic.geometry);
                        }
                    });
                    sketchWidget.current.on("update", function(event: any) {
                        if (event.state === "complete") {
                            setInState(event.graphic.geometry);
                        }
                    });

                    props.map.ui.add(sketchWidget.current, "bottom-left");
                    setHideCloseWidgetBtn(false);
                }
                break;
            case "distance":
                // close other active widgets
                tryCloseWidget(sketchWidget);
                tryCloseWidget(areaWidget);
                tryCloseWidget(coordWidget);
                setSharingActive(false);

                if(distanceWidget.current === null) {
                    distanceWidget.current = new DistanceMeasurement2D({
                        view: props.map,
                        unit: "meters",
                        unitOptions: ["centimeters", "decimeters", "meters", "kilometers"]
                    });

                    // skip the initial 'new measurement' button
                    distanceWidget.current.viewModel.start();

                    props.map.ui.add(distanceWidget.current, "bottom-left");
                    setHideCloseWidgetBtn(false);
                }
                break;
            case "area":
                // close other active widgets
                tryCloseWidget(sketchWidget);
                tryCloseWidget(distanceWidget);
                tryCloseWidget(coordWidget);
                setSharingActive(false);

                if(areaWidget.current === null) {
                    areaWidget.current = new AreaMeasurement2D({
                        view: props.map,
                        unit: "square-meters",
                        unitOptions: ["square-centimeters", "square-decimeters", "square-meters", "square-kilometers", "ares", "hectares"]
                    });

                    // skip the initial 'new measurement' button
                    areaWidget.current.viewModel.start();

                    props.map.ui.add(areaWidget.current, "bottom-left");
                    setHideCloseWidgetBtn(false);
                }
                break;
            case "coord":
                tryCloseWidget(areaWidget);
                tryCloseWidget(distanceWidget);
                tryCloseWidget(sketchWidget);
                setSharingActive(false);

                if(coordWidget.current === null) {
                    coordWidget.current = new CoordinateConversion({
                        view: props.map
                    });

                    // Add our new format to the widget's dropdown
                    const utm33 = getUTM33Format();
                    coordWidget.current.formats.add(utm33);
                    // Add the custom format to the top of the widget's display
                    coordWidget.current.conversions.splice(0, 0, new Conversion({format: utm33}));
    
                    props.map.ui.add(coordWidget.current, "bottom-left");
                    setHideCloseWidgetBtn(false);
                    addMapClickListener();
                }
                break;
            case "sharing":
                tryCloseWidget(sketchWidget);
                tryCloseWidget(distanceWidget);
                tryCloseWidget(areaWidget);
                tryCloseWidget(coordWidget);
                setSharingActive(true);
                setHideCloseWidgetBtn(false);
                break;
            default:
                tryCloseWidget(sketchWidget);
                tryCloseWidget(distanceWidget);
                tryCloseWidget(areaWidget);
                tryCloseWidget(coordWidget);
                setSharingActive(false);
                hohContent.current = null;
                
                if(searchLayer.current) {
                    searchLayer.current.removeAll();
                }
                if(graphicsLayer.current) {
                    graphicsLayer.current.removeAll();
                }

                setHideCloseWidgetBtn(true);

                if(mapClickListener.current !== null) {
                    mapClickListener.current.remove();
                    mapClickListener.current = null;
                }

                break;
        }
    };

    const toggleExpanded = () => {
        setActiveWidget("");
        dispatch(setWidgetsExpanded(false));
    };

    const copytext = () => {
        // Copy the text inside the text field
        navigator.clipboard.writeText(mapUrl);
        setCopyActive(true);
    };

    if(props.map === null) {
        return <div></div>;
    }

    return (
        <div className="widget-btn">
            {widgetsExpanded &&
                <Button type="link" className="first-button" onClick={() => toggleExpanded()} 
                        title="Avslutt og lukk alle målinger" >
                    <img src={doubleArrowDownImg} alt="Pil" />
                </Button>
            }
            {widgetsExpanded &&
                <div className="widget-expanded">
                    <Button type="link" onClick={() => setActiveWidget("sketch")} title="Søk etter ADED-objekter fra område i kart" >
                        <div className="rectangle" >
                            <SearchOutlined />
                        </div>
                    </Button>
                    <br />
                    <Button type="link" onClick={() => setActiveWidget("distance")} title="Mål avstanden mellom to eller flere punkter" >
                        <img src={measureDistanceImg} alt="Avstand" />
                    </Button>
                    <br />
                    <Button type="link" onClick={() => setActiveWidget("area")} title="Mål areal" >
                        <img src={measureAreaImg} alt="Areal" />
                    </Button>
                    <br />
                    <Button type="link" onClick={() => setActiveWidget("coord")} title="Vis koordinater og terrenghøyde" >
                        <img src={coordImg} alt="Punkt" />
                    </Button>
                    <br />
                    <Button type="link" onClick={() => setActiveWidget("sharing")} title="Del kartutsnittet" >
                        <img src={shareImg} alt="Punkt" />
                    </Button>
                    <br />
                    <Button type="link" className="last-button" onClick={() => dispatch(setPrintDialogVisible(true))} title="Skriv ut kart" >
                        <PrinterFilled />
                    </Button>
                </div>
            }
            {sharingActive && 
                <div className="share-container">
                    <div className="share-container-text">Del dette kartutsnittet: {copyActive && <p className="share-container-copytext">Kopiert!</p>}</div>
                    <div>
                        <input className="share-container-input" type="text" value={mapUrl} readOnly ></input>
                        <Button size="small" className="copy-share-btn" onClick={() => copytext()} title="Kopier lenke til kartutsnittet" >
                            <img src={copyImg} alt="Kopier" />
                        </Button>
                    </div>

                </div>
            }
            <Button size="small" className="close-widgets-btn" onClick={() => setActiveWidget("")} title="Avslutt og lukk alle målinger"
                    hidden={hideCloseWidgetBtn} >
                <CloseOutlined />
            </Button>
        </div>
    );
};