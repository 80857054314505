/* Map component, using ArcGIS Maps SDK for JavaScript */
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Basemap from '@arcgis/core/Basemap';
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import Extent from '@arcgis/core/geometry/Extent';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import Polyline from '@arcgis/core/geometry/Polyline';

import Graphic from "@arcgis/core/Graphic";
import Layer from "@arcgis/core/layers/Layer";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";

// import Legend from "@arcgis/core/widgets/Legend.js"; //NY
// import * as query from "@arcgis/core/rest/query.js"; //NY
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js"; //NY

import northArrow from "../../images/northarrow.png";
// import legendIcon from "../../images/legendicon.svg";
import expandIcon from "../../images/expandIcon.svg";
import collapseIcon from "../../images/collapseIcon.svg";


import { TOC } from "./toc/toc";
import { Widgets } from "./widgets/widgets";
import Print from "../app/dialogs/print";
import * as conf from "../../config/MapConfig";
import * as layersConf from "../../config/MapLayersConfig";
import { StoreState, Location, ADEDResult, ArtefactResult, MapMarker, searchbase, Filter, GeoJSONResponse, GeoJSONFeature, Bounds, SearchResult, FeatureProperties } from "../../types";
import { LAYER_TYPE, DISPLAY_TYPE, WKID_LATLON, WKID_UTM33, ADED } from "../../constants";
import { getLayers, getBasemaps, setWMSGFIProps, getLayer, transform, transformListOfXY, navigateApp, getPopupTemplate } from "../../utils";
import { wkid } from "../../config/MapConfig";
import { replaceInType } from "../../utils/adedHelper";
import { showLoading } from "../../store/appSlice";
import { setMapReady, setTOCVisibility, setWidgetsExpanded } from "../../store/mapSlice";
import { getGroupLayer, getSearchLayer } from "../../utils/mapUtils";

import fullExtentImg from "../../images/full_extent.png";
import doubleArrowUpImg from "../../images/double_arrow_up.png";
import "./adedmap.css";

const ADEDMap = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mapRef = useRef(null);
    
    const [hoverTooltipText, setHoverTooltipText] = useState<string>("");
    const [hoverTooltipX, setHoverTooltipX] = useState<number>(0);
    const [hoverTooltipY, setHoverTooltipY] = useState<number>(0);

    const [overviewVisible, setOverviewVisible] = useState<boolean>(true);
    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const [infoZoomVisible, setInfoZoomVisible] = useState<boolean>(true);
    const [infoToolsVisible, setInfoToolsVisible] = useState<boolean>(false);
    const [mapInstance, setMapInstance] = useState<Map | null>(null);
    const [map, setMap] = useState<MapView | null>(null);
    const [basemaps, setBasemaps] = useState<Array<Basemap> | null>(null);
    const [historicalLayer, setHistorcalLayer] = useState<Layer | null>(null);
    const [searchLayers, setSearchLayers] = useState<GroupLayer | null>(null);
    const [graphicsLayer, setGraphicsLayer] = useState<GraphicsLayer | null>(null);
    const [printoutgraphicsLayer, setPrintoutGraphicsLayer] = useState<GraphicsLayer | null>(null);
    const [currentClass, setCurrentClass] = useState<string>("");
    
    const location: Location | null = useSelector((state: StoreState) => state.map.location);
    const bounds: Bounds | null = useSelector((state: StoreState) => state.map.bounds);
    const mapMarkers: Array<MapMarker> | null = useSelector((state: StoreState) => state.map.mapMarkersToAdd);
    const displayType: DISPLAY_TYPE = useSelector((state: StoreState) => state.app.displayType);
    const selectedResultBase: (searchbase | null) = useSelector((state: StoreState) => state.search.selectedSearchBase);
    const selectedItem: ADEDResult | ArtefactResult | null = useSelector((state: StoreState) => state.search.selectedItem);
    const adedResults: SearchResult | null = useSelector((state: StoreState) => state.search.adedResults);
    const printDialogVisible: boolean = useSelector((state: StoreState) => state.map.printDialogVisible);
    
    const checkHideSearchLayers = () => {
        if(selectedResultBase !== ADED && searchLayers && searchLayers.layers.length > 0) {
            searchLayers.layers.forEach(sl => {
                sl.visible = false;
            });
        }
    };

    const getProps = (r: ADEDResult): FeatureProperties => {
        return {
            ID: r.InputId?.toString() || "",
            ObjektId: r.ObjektId,
            ObjektType: replaceInType(r.ObjektType),
            ProsjektId: r.ProsjektId || "",
            ObjektHovedType: replaceInType(r.ObjektHovedType),
            InputId: r.InputId || 0,
            Prosjektnavn: r.Prosjektnavn || "",
            GeometryType: r.GeometryType || "",
            Kommuner: r.Kommuner || "",
            Fylker: r.Fylker || "",
            Areal: null,
            Lengde: null
        };
    };

    const addSearchResult = () => {
        if(map && adedResults !== null && adedResults.result?.hits?.hits.length > 0) {
            dispatch(showLoading(true));
            
            let clusters:GeoJSONLayer | null = null, points:GeoJSONLayer | null = null, 
                lines:GeoJSONLayer | null = null, polygons:GeoJSONLayer | null = null;

            // Point cluster - overview
            let clusterFeatures:Array<GeoJSONFeature> = [];
            // Actual object geometries
            let pointFeatures:Array<GeoJSONFeature> = [], lineFeatures:Array<GeoJSONFeature> = [], polygonFeatures:Array<GeoJSONFeature> = [];

            adedResults.result.hits.hits.forEach((r: any) => {
                const rr = r._source;
                if(rr.CenterPoint) { // Point cluster - overview
                    const cf: GeoJSONFeature = {
                        type: "Feature",
                        properties: getProps(rr),
                        geometry: {
                            type: "Point",
                            coordinates: rr.CenterPoint
                        }
                    };

                    clusterFeatures.push(cf);
                }

                if(rr.Shape) {
                    const f: GeoJSONFeature = {
                        type: "Feature",
                        properties: getProps(rr),
                        geometry: {
                            type: rr.Shape.type,
                            coordinates: rr.Shape.coordinates
                        }
                    };

                    if(f.geometry.type.toLowerCase().indexOf("polygon") !== -1) {
                        polygonFeatures.push(f);
                    }
                    else if(f.geometry.type.toLowerCase().indexOf("line") !== -1) {
                        lineFeatures.push(f);
                    }
                    else {
                        pointFeatures.push(f);
                    }
                }
            });

            if(clusterFeatures.length > 0) {
                let cfs:GeoJSONResponse = {
                    type: "FeatureCollection",
                    features: clusterFeatures
                };
                clusters = getSearchLayer(dispatch, map, cfs, true, "point");
            }
            if(polygonFeatures.length > 0) {
                let polyfs:GeoJSONResponse = {
                    type: "FeatureCollection",
                    features: polygonFeatures
                };
                polygons = getSearchLayer(dispatch, map, polyfs, false, "polygon");
            }
            if(lineFeatures.length > 0) {
                let linefs:GeoJSONResponse = {
                    type: "FeatureCollection",
                    features: lineFeatures
                };
                lines = getSearchLayer(dispatch, map, linefs, false, "line");
            }
            if(pointFeatures.length > 0) {
                let pointfs:GeoJSONResponse = {
                    type: "FeatureCollection",
                    features: pointFeatures
                };
                points = getSearchLayer(dispatch, map, pointfs, false, "point");
            }

            let resultLayers:Array<GeoJSONLayer> = [];
            if(polygons) resultLayers.push(polygons);
            if(lines) resultLayers.push(lines);
            if(points) resultLayers.push(points);
            if(clusters) resultLayers.push(clusters);

            if(resultLayers.length > 0) {
                if(searchLayers) {
                    searchLayers?.removeAll();
                    setTimeout(() => { // Ut av rendring scope i tilfelle oppstarts-query
                        searchLayers?.addMany(resultLayers);
                    }, 100)
                }
            }
            else {
                dispatch(showLoading(false));
            }
        }
        else {
            searchLayers?.removeAll();
        }
    };

    const clearSearchResult = () => {
        searchLayers?.removeAll();
        dispatch(showLoading(false));
    };

    const fullExtentBtn = (mapIns: MapView):HTMLElement => {
        let element = document.createElement("button");
        element.className = "full-extent-btn";
        element.title = "Vis hele landet"
        let img = document.createElement("img");
        img.src = fullExtentImg;
        img.alt = "Hele landet";
        element.appendChild(img);
        element.addEventListener("click", function(evt) {
            const extent = new Extent({xmin:conf.initialXMin, ymin: conf.initialYMin, xmax: conf.initialXMax, ymax: conf.initialYMax, spatialReference: {wkid: conf.wkid}});
            mapIns.goTo(extent);
        });

        return element;
    };

    const toggleWidgetsBtn = ():HTMLElement => {
        let element = document.createElement("button");
        element.className = "expand-widgets-btn";
        element.title = "Åpne verktøypanel"
        let img = document.createElement("img");
        img.src = doubleArrowUpImg;
        img.alt = "Åpne verktøypanel";
        element.appendChild(img);
        element.addEventListener("click", function(evt) {
            dispatch(setWidgetsExpanded(true));
        });

        return element;
    };

    const addPoint = (x: number, y: number, inputWkid: number, tooltipTitle?: string, tooltipContent?: string) => {
        let p = new Point({x: x, y: y, spatialReference: {wkid: inputWkid}});

        if(inputWkid !== wkid) {
            p = transform(p, inputWkid, wkid);
        }

        const g = new Graphic({
            geometry: p,
            symbol: conf.selectedPointSymbol
        });

        if(tooltipTitle) {
            g.popupTemplate = getPopupTemplate(tooltipTitle, tooltipContent || "");
        }

        graphicsLayer?.add(g);

        return p;
    }
    
    const clearGraphicslayer = () => {
        if(mapInstance && graphicsLayer) {
            graphicsLayer.removeAll();

            // make sure graphicslayer is always on top
            mapInstance.reorder(graphicsLayer, mapInstance.layers.length - 1);
        }
    };

    const addGraphicsLayer = (group: GroupLayer) => {
        if(graphicsLayer === null) {
            let g = getLayer({id: "highlight", type: LAYER_TYPE.GRAPHIC, caption: "Valgt objekt"}) as GraphicsLayer;
            if(g) {
                group.add(g);
                setGraphicsLayer(g);
            }
        }
    }

    const renderHoverTooltip = () => {
        return (
            <div className="hover-tooltip" style={{top: hoverTooltipY, left: hoverTooltipX, display: (hoverTooltipText ? "block" : "none")}}>
                {hoverTooltipText}
            </div>
        )
    };
    
    useEffect(() => {
        if(map === null || mapInstance === null) {
            let bms = getBasemaps(layersConf.basemapLayers)
            const mapIns = new Map({
                basemap: bms[0]
            });

            // Create another Map, to be used in the overview "view"
            const overviewMap = new Map({
                basemap: bms[2]
            });

            const histL = getLayer(layersConf.HistoriskKart);
            if(histL) {
                histL.listMode = "hide";
                setHistorcalLayer(histL);
                mapIns.add(histL);
            }

            const layers = getLayers(layersConf.mapLayers);
            mapIns.addMany(layers);

            //Legg til kartlag for printout
            const printoutgraphicsLayer = new GraphicsLayer();
            // mapIns.add(printoutgraphicsLayer);
            setPrintoutGraphicsLayer(printoutgraphicsLayer)

            const markingsGroupLayer = getGroupLayer("marking_group", "Mine markeringer");
            if(markingsGroupLayer) {
                mapIns.add(markingsGroupLayer);

                const searchGroupLayer = getGroupLayer("search_group", "Søkeresultat");
                if(searchGroupLayer) {
                    markingsGroupLayer.add(searchGroupLayer);
                    setSearchLayers(searchGroupLayer as GroupLayer);
                }

                addGraphicsLayer(markingsGroupLayer as GroupLayer);
            }

            setMapInstance(mapIns); // add map instance to local state

            const extent = new Extent({xmin:conf.initialXMin, ymin: conf.initialYMin, xmax: conf.initialXMax, ymax: conf.initialYMax, spatialReference: {wkid: conf.wkid}});

            let container: any = mapRef.current;

            var mapView = new MapView({
                container: container,
                map: mapIns,
                extent: extent,
                constraints: { rotationEnabled: false }
            });

            // Create the MapView for overview map
            const mapViewOverview = new MapView({
                container: "overviewDiv",
                map: overviewMap,
                constraints: { rotationEnabled: false }
            });
            // Remove the default widgets
            mapViewOverview.ui.components = [];

            mapView.watch("stationary", (isStationary) => {
                //console.log("stationary property changed: ", isStationary);
                if (isStationary) {
                    mapViewOverview.goTo({
                        center: mapView.center,
                        scale:
                        mapView.scale *
                        4 *
                        Math.max(
                            mapView.width / mapViewOverview.width,
                            mapView.height / mapViewOverview.height
                        )
                    });
                }
            });

            mapViewOverview.when(() => {
                mapView.when(() => {
                    const extent3Dgraphic = new Graphic({
                        // @ts-ignore
                        geometry: null,
                        symbol: {
                            // @ts-ignore
                          type: "simple-fill",
                          color: [0,0,0,0.5],
                          outline: null
                        }
                      });
                      mapViewOverview.graphics.add(extent3Dgraphic);
            
                      reactiveUtils.watch(
                        () => mapView.extent,
                        (extent) => {
                            extent3Dgraphic.geometry = extent as any;
                        },
                        {
                          initial: true
                        }
                      );
                });
              });

            mapView.when(() => {
                dispatch(showLoading(false));
                dispatch(setMapReady());

                const fullExtBtn = fullExtentBtn(mapView);
                mapView.ui.add(fullExtBtn, "bottom-right");

                const widgetsBtn = toggleWidgetsBtn();
                mapView.ui.add(widgetsBtn, "bottom-left");

                mapView.on("pointer-move", (event) => {
                    mapView.hitTest(event).then((response: any)  => {
                        const graphicHits = response.results?.filter(
                            (hitResult: any) => {
                                return hitResult.type === "graphic" && hitResult.graphic && hitResult.graphic.layer &&
                                    hitResult.graphic.layer.id.indexOf("aded_prosjekt_punkter") !== -1;
                            }
                        );
                        if (graphicHits?.length > 0) {
                            if(hoverTooltipText !== graphicHits[0].graphic.attributes.prosjektnavn) {
                                setHoverTooltipText(graphicHits[0].graphic.attributes.prosjektnavn);

                                if (event.x || event.y) {
                                    setHoverTooltipX(event.x + 200);
                                    setHoverTooltipY(event.y + 20);
                                }
                            }
                            else {
                                setHoverTooltipText("");
                            }
                        }
                        else {
                            setHoverTooltipText("");
                        }
                    });
                });

                var url = new URL(window.location.href);
                var x = parseInt(url.searchParams.get("x") || "0");
                var y = parseInt(url.searchParams.get("y") || "0");
                var zoom = parseInt(url.searchParams.get("zoom") || "0");
                if(mapView && url.searchParams.get("x") && url.searchParams.get("y") && url.searchParams.get("zoom")) {
                    const p = new Point({x: x, y: y, spatialReference: {wkid: conf.wkid}});
                    mapView.goTo({
                        center: p,
                        zoom: zoom
                    });
                }



            });

            mapView.popup.defaultPopupTemplateEnabled = true;
            mapView.popup.maxInlineActions = 4;

            mapView.ui.move("zoom", "bottom-right");

            var scaleBar = new ScaleBar({
                view: mapView,
                unit: "metric"
            });
            mapView.ui.add(scaleBar, {
                position: "bottom-right"
            });

            mapView.on("layerview-create", (event) => {
                if(event.layer instanceof WMSLayer && event.layer.featureInfoFormat) {
                    setWMSGFIProps(event.layer as WMSLayer);
                }
            });

            mapView.popup.viewModel.on("trigger-action", function(event) {
                if (event.action.id === "show-aded") {
                    try {
                        if(mapView.popup.viewModel.selectedFeature.attributes.objekt_id) { // ADED-layer
                            navigateApp(dispatch, navigate, "/ADED/" + mapView.popup.viewModel.selectedFeature.attributes.objekt_id);
                        }
                        else if(mapView.popup.viewModel.selectedFeature.attributes.ObjektId) { // GeoJSON
                            navigateApp(dispatch, navigate, "/ADED/" + mapView.popup.viewModel.selectedFeature.attributes.ObjektId);
                        }
                        else if(mapView.popup.viewModel.selectedFeature.attributes.prosjekt_id) { // ADED-layer
                            navigateApp(dispatch, navigate, "/ADED/" + mapView.popup.viewModel.selectedFeature.attributes.prosjekt_id);
                        }
                        else if(mapView.popup.viewModel.selectedFeature.attributes.ProsjektId) { // GeoJSON
                            navigateApp(dispatch, navigate, "/ADED/" + mapView.popup.viewModel.selectedFeature.attributes.ProsjektId);
                        }
                    }
                    catch{
                        console.error("klarte ikke å finne ADED objektID for selectedFeature");
                    }
                }
                if(event.action.id === "show-toc") {
                    dispatch(setTOCVisibility(true));
                }
            });

            setMap(mapView);
            setBasemaps(bms);
        }
    }, []); // Empty dependency array = execute only once

    useEffect(
        () => {
            if (selectedResultBase && selectedResultBase === ADED && adedResults !== null) {
                addSearchResult();
            }
            else {
                clearSearchResult();
            }
        }, [selectedResultBase, adedResults]);

    useEffect(() => {
        if(map !== null) {
            checkHideSearchLayers();
        }
    }, [selectedResultBase]);

    // Update viewport
    useEffect(() => {
        if(displayType === DISPLAY_TYPE.MAP_VIEW) {
            setCurrentClass("");
        }
        if(displayType === DISPLAY_TYPE.INFO_VIEW) {
            setCurrentClass("map-hidden");
        }
        if(displayType === DISPLAY_TYPE.SPLIT_VIEW) {
            setCurrentClass("map-split-view");
        }
    }, [displayType]);
    
    // Update map markers
    useEffect(() => {
        if (map !== null) {
            //Denne oppdaterer kartet dersom et prosjekt er valgt i url
            console.log("mapMarkers", mapMarkers);
            clearGraphicslayer();

            if(mapMarkers && mapMarkers.length > 0) {
                let p: Point | null = null;
                mapMarkers.forEach(m => {
                    if (m.geometry) {
                        if (m.shape === "Point") {

                            //Tidvis dårlig datakvalitet i unimus, så vi må håndtere diverse x/y verdier. Også try for å unngå krasj av løsning
                            try {
                                if (typeof m.geometry.x === "string") {
                                    m.geometry.x = parseFloat(m.geometry.x.replace(',','.'));
                                }
                                if (typeof m.geometry.y === "string") {
                                    m.geometry.y = parseFloat(m.geometry.y.replace(',','.'));
                                }
                                p = addPoint(m.geometry.x, m.geometry.y, m.geometry.wkid || 25833, m.title, m.tooltipContent);
                            }
                            catch {
                                console.log("Feil skjedde, dropper å legge til punkt i kartet")
                            }
                        }
                        if (m.shape === "MultiPoint") {
                            p = addPoint(m.geometry[0][0], m.geometry[0][1], 4326);
                        }
                        if (m.shape === "Polyline" || m.shape === "MultiLineString" ) {
                            let geometry = m.geometry;
                            if(m.shape === "MultiLineString") {
                                geometry = geometry[0];
                            }
                            const utmPaths = transformListOfXY(geometry, WKID_LATLON);
                            const line = new Polyline({
                                hasZ: false,
                                hasM: false,
                                paths: utmPaths,
                                spatialReference: { wkid: WKID_UTM33}
                            });

                            const graphic = new Graphic({
                                geometry: line,
                                symbol: conf.selectedLineSymbol
                            });
                            graphicsLayer?.add(graphic);
                            var url = new URL(window.location.href);
                            var x = parseInt(url.searchParams.get("x") || "0");
                            var y = parseInt(url.searchParams.get("y") || "0");
                            var zoom = parseInt(url.searchParams.get("zoom") || "0");
                            if(x !== 0 && y !== 0 && zoom !== 0) {
                                const p2 = new Point({
                                    x: x,
                                    y: y,
                                    spatialReference: { wkid: WKID_UTM33}
                                });
                                map.goTo({
                                    center: p2,
                                    zoom: zoom
                                });
                            }
                            else{
                                map.goTo(line.extent.expand(1.5)).then(() => {
                                    if(map.zoom > 17) {
                                        map.zoom = 17;
                                    }
                                });
                            }

                        }
                        if (m.shape === "Polygon" || m.shape === "MultiPolygon" ) {
                            let geometry = m.geometry[0];
                            if(m.shape === "MultiPolygon") {
                                geometry = geometry[0];
                            }

                            const utmRings = transformListOfXY(geometry, WKID_LATLON);
                            const polygon = new Polygon({
                                hasZ: false,
                                hasM: false,
                                rings: utmRings,
                                spatialReference: { wkid: WKID_UTM33}
                            });

                            const graphic = new Graphic({
                                geometry: polygon,
                                symbol: conf.selectedPolygonSymbol
                            });
                            graphicsLayer?.add(graphic);
                            var url = new URL(window.location.href);
                            var x = parseInt(url.searchParams.get("x") || "0");
                            var y = parseInt(url.searchParams.get("y") || "0");
                            var zoom = parseInt(url.searchParams.get("zoom") || "0");
                            if(x !== 0 && y !== 0 && zoom !== 0) {
                                const p2 = new Point({
                                    x: x,
                                    y: y,
                                    spatialReference: { wkid: WKID_UTM33}
                                });
                                map.goTo({
                                    center: p2,
                                    zoom: zoom
                                });
                            }
                            else {
                                map.goTo(polygon.extent.expand(1.5)).then(() => {
                                    if(map.zoom > 17) {
                                        map.zoom = 17;
                                    }
                                });
                            }
                        }
                    }
                });
                if(p !== null) {
                    var url = new URL(window.location.href);
                    var x = parseInt(url.searchParams.get("x") || "0");
                    var y = parseInt(url.searchParams.get("y") || "0");
                    var zoom = parseInt(url.searchParams.get("zoom") || "0");
                    if(map && url.searchParams.get("x") && url.searchParams.get("y") && url.searchParams.get("zoom")) {
                        const p2 = new Point({x: x, y: y});
                        map.goTo({
                            center: p2,
                            zoom: zoom
                        });
                    }
                    else {
                        map?.goTo({
                            center: p,
                            zoom: 17
                        });
                    }

                }
            }
        } 
    }, [mapMarkers]);

    useEffect(() => {
        if(map && location) {
            const p = new Point({x: location.x, y: location.y});
            map.goTo({
                center: transform(p, WKID_LATLON, WKID_UTM33),
                zoom: location.zoom || 15
            });
        }
    }, [location]);

    useEffect(() => {
        if(map && bounds) {
            const extent = new Extent({xmin:bounds.xMin, ymin: bounds.yMin, xmax: bounds.xMax, ymax: bounds.yMax, spatialReference: {wkid: conf.wkid}});
            map.goTo(extent);
        }
    }, [bounds]);

    useEffect(() => {
        if(map !== null && selectedItem === null) {
            clearGraphicslayer();
        }
    }, [selectedItem]);

    return (
        <div className={"map-container " + currentClass} >
            <div ref={mapRef} style={{position: "relative"}} >
                {renderHoverTooltip()}

                <Widgets map={map} />
                <button className="map-help-btn" onClick={() => setInfoVisible(!infoVisible)} >
                    ?
                </button>

                <div className={"context-help map-help " + (infoVisible ? "" : "remove-element")}>
                    <Button type="link" onClick={() => setInfoZoomVisible(!infoZoomVisible)} style={{paddingLeft: 0}}>
                        Zoom og panorering
                        {infoZoomVisible && <DownOutlined />}
                        {!infoZoomVisible && <UpOutlined />}
                    </Button>
                    {infoZoomVisible && 
                        <div>
                            <p>
                                Trykk på + eller - for å zoome et hakk inn eller ut. Du kan også rulle med musehjulet for å zoome.
                            </p>
                            <p>
                                Når du har kartet i fokus (synlig svart ramme rundt) kan du også bruke +/- og piltaster på tastaturet for zoom og panorering.
                            </p>
                            <p>
                                Trykk på Norge for å zoome ut til hele landet.
                            </p>
                            <p>
                                Hold inne shift + venstre musetast og dra et rektangel i kartet for å zoome til angitt område.
                            </p>
                        </div>
                    }
                    <br />
                    <Button type="link" onClick={() => setInfoToolsVisible(!infoToolsVisible)} style={{paddingLeft: 0}}>
                        Verktøy
                        {infoToolsVisible && <DownOutlined />}
                        {!infoToolsVisible && <UpOutlined />}
                    </Button>
                    {infoToolsVisible && 
                        <div>
                            <p>
                                Ekspander verktøymenyen i venstre hjørne av kartet for målinger, koordinater og utskrift.
                            </p>
                            <p>
                                <b>Søk innenfor område:</b> Aktiver rektangel, dra en firkant i kartet for å søke etter ADED-objekter innenfor området.
                                <b>MERK!</b> Kartet er i UTM-33 mens søket opererer i Lat/Lon, så firkanten tegnes forskjøvet for å matche det søket som utføres.
                            </p>
                            <p>
                                <b>Målinger:</b> Aktiver lengde eller areal, klikk i kart for å sette målepunkter, dobbeltklikk for å avslutte. 
                                Velg enhet i nedtrekksliste i dialogen.
                            </p>
                            <p>
                                <b>Terrenghøyde:</b> Aktiver koordinatvisning, og klikk i kart for å hente terrenghøyde fra hoydedata.no.
                            </p>
                            <p>
                                <b>Koordinater:</b> Aktiver koordinatvisning og beveg musepekeren over kartet for å se koordinater i UTM-33. <br/>
                                Trykk pil opp og "Legg til konvertering" i dialogen for å se koordinater i andre formater. Velger du UTM i nedtrekkslista
                                vil den automatisk vise egnet sone mens du beveger musepekeren.
                            </p>
                        </div>
                    }
                    <div>
                        <Button type="link" onClick={() => setInfoVisible(false)} style={{float: "right"}}>
                            Lukk
                        </Button>
                    </div>
                </div>
                <div id="overviewDiv" className="overviewDiv" style={overviewVisible ? {width: "600px"} : {width: "0px", border:"hidden"}}>
                    <div id="extentDiv"></div>
                    <Button id="overviewDivMinimize" onClick={() => setOverviewVisible(!overviewVisible)} style={overviewVisible ? {right: "0px"} : {right: "-50px", border:"1px solid #649291"}} title={overviewVisible ? "Minimer oversiktskart" : "Ekspander oversiktskart"}>
                        <img src={overviewVisible ? collapseIcon : expandIcon} alt={overviewVisible ? "Minimer oversiktskart" : "Ekspander oversiktskart"} />
                    </Button>
                </div>
                <img src={northArrow} alt="Kompass" className="northarrow" />

            </div>
                        
            <TOC mapView={map} basemaps={basemaps} map={mapInstance} historicalLayer={historicalLayer} />
            <Print map={map} mapInstance={mapInstance} printoutgraphicsLayer={printoutgraphicsLayer}/>
        </div>
    );
};

export default ADEDMap;