import { combineReducers } from "redux";
import app from "./appSlice";
import search from "./searchSlice";
import map from "./mapSlice";
import user from "./userSlice";
import export_ from "./exportSlice";
import import_ from "./importSlice";
import admin from "./adminSlice";

const rootReducer = combineReducers({
    app: app,
    search: search,
    map: map,
    user: user,
    export: export_,
    import: import_,
    admin: admin
});

export default rootReducer;
