/* UI for logged in user, content depending on user role */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { User } from "oidc-client";
import { UserOutlined, FileZipOutlined, TeamOutlined, LogoutOutlined, DownloadOutlined } from "@ant-design/icons";

import { RoleEnum, StoreState } from "../../../types";
import { AuthService } from "../../../services/authorisationservice";
import { HasAdministratorPrivileges, HasSaksbehandlerPrivileges } from "../../../utils/userHelper";
import { setImportDialogVisible } from "../../../store/importSlice";
import { setUserAdminDialogVisible } from "../../../store/adminSlice";

import "./userview.css";
import { setExportDialogVisible } from "../../../store/exportSlice";

interface userProps {
  authService: AuthService
};

const UserView = (props: userProps) => {
    const dispatch = useDispatch();

    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const user: User | null = useSelector((state: StoreState) => state.user.user);
    const role: RoleEnum = useSelector((state: StoreState) => state.user.role);

    const logout = () => {
      props.authService.logout();
    };

    const openImportDialog = () => {
        dispatch(setImportDialogVisible(true));
        setMenuVisible(false);
    };

    const openUserAdminDialog = () => {
        dispatch(setUserAdminDialogVisible(true));
        setMenuVisible(false);
    };

    const openExportDialog = () => {
        dispatch(setExportDialogVisible(true));
        setMenuVisible(false);
    };

    if (!user) {
        return null;
    }
    
    return (
        <div className="user-container">
            <Button type="link" className="user-btn" onClick={() => setMenuVisible(!menuVisible)} >
                <span>{user !== null ? user.profile.name : ""}</span>
            </Button>
            <div className={"user-menu " + (menuVisible ? "" : "remove-element")}>
                <div>
                    <UserOutlined style={{marginRight: "10px"}} />
                    { role }                    
                </div>
                {HasSaksbehandlerPrivileges(role) &&
                    <div style={{marginLeft: 0}}>
                        <hr style={{marginLeft: "1rem"}} />
                        <Button type="link" onClick={openImportDialog} >
                            <FileZipOutlined />
                            Importèr prosjekt
                        </Button>
                    </div>
                }
                {HasAdministratorPrivileges(role) &&
                    <Button type="link" onClick={openUserAdminDialog} >
                        <TeamOutlined />
                        Brukeradministrasjon
                    </Button>
                }
                <div>
                    <Button type="link" onClick={openExportDialog} style={{paddingLeft: "0"}} >
                        <DownloadOutlined />
                        Se eksportjobber
                    </Button>
                </div>
                <div>
                    <hr />
                    <Button type="link" onClick={logout} style={{paddingLeft: "0"}} >
                        <LogoutOutlined />
                        Logg ut
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default UserView;