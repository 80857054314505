/* Callback component to handle return from Feide log in */
import React, { useEffect, ReactNode } from "react";
import { UserManager, User } from "oidc-client";
import { useNavigate } from "react-router-dom";

interface callbackProps {
    children?: ReactNode,
    userManager: UserManager | null;
    errorCallback?: (error: Error) => void;
};

const CallbackComponent: React.FC<callbackProps> = (props: callbackProps) => {
    const navigate = useNavigate();
  
    const onRedirectSuccess = (user: User) => {
        console.log("CallbackComponent user: " + user);
        navigate("/");
    };

    const onRedirectError = (error: Error) => {
        if (props.errorCallback) {
            props.errorCallback(error);
        } else {
            throw new Error(`Error handling redirect callback: ${error.message}`);
        }
    };

    useEffect(
        () => {
            if(props.userManager !== null) {
                props.userManager.signinRedirectCallback()
                    .then((user) => onRedirectSuccess(user))
                    .catch((error) => onRedirectError(error));
            }
        }, [props.userManager]);

    return ( null );
}

export default CallbackComponent;