/* Configuration values for the map component */
import { WKID_UTM33 } from "../constants";
import { PrintFormat, PrintLayout } from "../types";

import placeMarkerBlueImg from "../images/place_marker_blue.png";
import placeMarkerPinkImg from "../images/place_marker_pink.png";
import placeMarkerOrangeImg from "../images/place_marker_orange.png";

export const initialXMin: number = 150000;
export const initialYMin: number = 6700000;
export const initialXMax: number = 300000;
export const initialYMax: number = 7700000;
export const wkid: number = WKID_UTM33;

export const MapSearchUrl = "services.geodataonline.no/arcgis/rest/services/ProxyServices/search/adresse";
export const HohService = "https://hoydedata.no/arcgis/rest/services/DTM/ImageServer"; //DTM er i UTM33N, noe som kartet vårt også er

export const PrintLayouts: Array<PrintLayout> = [ //default eller custom
    {caption: "Velg oppsett", value: undefined, endpoint: "custom" },
    {caption: "A4 Liggende", value: "A4 liggende", endpoint: "custom" },
    {caption: "A4 Stående", value: "A4 stående", endpoint: "custom" },
    {caption: "A3 Liggende", value: "A3 liggende", endpoint: "custom" },
    {caption: "A3 Stående", value: "A3 stående", endpoint: "custom" },
    {caption: "A2 Liggende", value: "A2 liggende", endpoint: "custom" },
    {caption: "A2 Stående", value: "A2 stående", endpoint: "custom" },
    {caption: "A5 Liggende", value: "A5 liggende", endpoint: "custom" },
    {caption: "A5 Stående", value: "A5 stående", endpoint: "custom" },
    {caption: "A1 Liggende", value: "A1 liggende", endpoint: "custom" },
    {caption: "A1 Stående", value: "A1 stående", endpoint: "custom" },
    // {caption: "A0 Liggende", value: "A0 liggende", endpoint: "custom" },
    // {caption: "A0 Stående", value: "A0 stående", endpoint: "custom" },
    {caption: "Kun kart", value: "map-only", endpoint: "custom"}
];

export const PrintFormats: Array<PrintFormat> = [
    {caption: "Velg format", value: undefined },
    {caption: "PDF", value: "pdf" },
    {caption: "32-bits PNG", value: "png32" },
    {caption: "8-bits PNG", value: "png8" },
    {caption: "JPG", value: "jpg" },
    {caption: "Encapsulated PostScript", value: "eps" },
    {caption: "SVG", value: "svg" }
];

// Renderers for map objects
const resultColor = "0,160,255";
const selectedColor = "224,32,127";
const projectPointColor = "0,77,168";

const pointRenderer:any = {
    type: "simple",
    symbol: {
        type: "picture-marker",
        url: placeMarkerBlueImg,
        width: "25px",
        height: "30px",
        yoffset: "15px"
    }
};

const lineRenderer:any = {
    type: "simple",
    symbol: {
        type: "simple-line",
        color: "rgb(" + resultColor + ")",
        width: 2
    }
};

const polygonRenderer:any = {
    type: "simple",
    symbol: {
        type: "simple-fill",
        color: "rgba(0, 0, 0, 0.2)",
        outline: {
            color: "rgb(" + resultColor + ")",
            width: 2
        }
    }
};

export const clusterRenderer:any = {
    type: "simple",
    symbol: {
        type: "simple-marker",
        size: 12,
        color: "rgb(" + resultColor + ")",
        outline: {
            color: "rgba(" + resultColor + ", 0.37)",
            width: 6
        }
    }
};

export const projectPointClusterRenderer:any = {
    type: "simple",
    symbol: {
        type: "simple-marker",
        size: 12,
        color: "rgb(" + projectPointColor + ")",
        outline: {
            color: "rgba(" + projectPointColor + ", 0.37)",
            width: 6
        }
    }
};

export const resultPointRenderer:any = {
    ...pointRenderer,
    symbol: {
        ...pointRenderer.symbol,
        url: placeMarkerBlueImg
    }
};

export const resultLineRenderer:any = {
    ...lineRenderer,
    symbol: {
        ...lineRenderer.symbol,
        color: "rgb(" + resultColor + ")"
    }
};

export const resultPolygonRenderer:any = {
    ...polygonRenderer,
    symbol: {
        ...polygonRenderer.symbol,
        outline: {
            ...polygonRenderer.symbol.outline,
            color: "rgb(" + resultColor + ")"
        }
    }
};

export const selectedPointSymbol:any = {
    ...pointRenderer.symbol,
    url: placeMarkerPinkImg
};

export const selectedLineSymbol:any = {
    ...lineRenderer.symbol,
    color: "rgb(" + selectedColor + ")"
};

export const selectedPolygonSymbol:any = {
    ...polygonRenderer.symbol,
    outline: {
        ...polygonRenderer.symbol.outline,
        color: "rgb(" + selectedColor + ")"
    }
};

export const hohPointSymbol:any = {
    ...pointRenderer.symbol,
    url: placeMarkerOrangeImg,
    width: "14px",
    height: "20px",
    yoffset: "10px"
};