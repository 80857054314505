/* Container for performing string and filter queries in the artefact data */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

import SearchBarArtefact from "./searchbarartefact";
import FilterArtefact from "./filterartefact";
import { resetArtefact, setArtefactPanelsOpen } from "../../../store/searchSlice";
import { setLeftMenuTopVisibility, showDisplayType } from "../../../store/appSlice";
import { DISPLAY_TYPE } from "../../../constants";
import { StoreState } from "../../../types";

import panelUpImg from "../../../images/panel_up.png";
import panelDownImg from "../../../images/panel_down.png";
import helpGreenLargerImg from "../../../images/help_green_larger.png";

const ArtefactSearch: React.FC = () => {
    const dispatch = useDispatch();
    const [infoVisible, setInfoVisible] = useState<boolean>(true);

    const leftMenuTopVisible: boolean = useSelector((state: StoreState) => state.app.leftMenuTopVisible);
    const requirePhoto: boolean = useSelector((state: StoreState) => state.search.artefactFilters.requirePhoto);
    const museum: string | null = useSelector((state: StoreState) => state.search.artefactFilters.museum);
    const museumNO: string | null = useSelector((state: StoreState) => state.search.artefactFilters.museumNO);
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryArtefact);

    useEffect(() => {
        setTimeout(() => {
            setInfoVisible(false);
        }, 10000); // Close help after 10 sek
    }, []); // empty dependency array = execute only once

    const resetSelection = (event: React.MouseEvent | null) => {
        event?.stopPropagation();
        dispatch(resetArtefact());
        dispatch(showDisplayType(DISPLAY_TYPE.MAP_VIEW));
    };

    const renderDeactivateAll = () => {
        if(requirePhoto || museum || museumNO || searchQuery) {
            return (
                <Button type="link" onClick={(e) => resetSelection(e)} className="remove-filters-btn" title="Fjern søkeord og alle aktive filtreringer" >
                    <span>Nullstill utvalg</span>
                    <DeleteOutlined />
                </Button>
            );
        }

        return <div></div>;
    };


    return (
        <div className="search-content">

            <div className="center-content border-bottom">
                <Button type="link" onClick={() => dispatch(setLeftMenuTopVisibility(!leftMenuTopVisible))} title={`${leftMenuTopVisible ? "Skjul" : "Vis"} `} >
                    <img src={leftMenuTopVisible ? panelUpImg : panelDownImg} 
                        alt="Toggle panel" />
                </Button>
            </div>

            <div className="search-content-top">
                <SearchBarArtefact />
                <Button type="link" style={{ zIndex: 1000, padding: "4px .5rem" }} onClick={() => setInfoVisible(!infoVisible)} >
                    <img src={helpGreenLargerImg} alt="Hjelp" title="Hjelp meg!" />
                </Button>
            </div>
            
            <div className={"search-help " + (infoVisible ? "" : "remove-element")}>
                <div className="search-help-header">
                    Hvordan fungerer det?
                    <Button type="link" onClick={() => setInfoVisible(false)} title="Lukk">
                        <CloseOutlined />
                    </Button>
                </div>
                <div>
                    Du kan søke på ord og utrykk i gjenstander. <b>F.eks. Oseberg, ring, bronse</b>
                </div>
                <div>
                    Avgrens treffene ved å velge egenskaper i listen under.
                </div>
                <div>
                    Du må legge inn enten søkeord eller museumsnummer for å få treff.
                </div>
            </div>

            <div className="reset-collapse-container">
                {renderDeactivateAll()}
                <Button type="link" className="collapse-all-btn" onClick={() => {dispatch(setArtefactPanelsOpen(false))}} title="Lukk alle åpne paneler" >
                    Lukk alle
                </Button>
            </div>

            <FilterArtefact />
        </div>
    );
}

export default ArtefactSearch;
