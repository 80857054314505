/* UI for map layers */
import { useEffect, useState, useRef } from "react";

import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import LayerList from "@arcgis/core/widgets/LayerList";

interface layerlistProps {
    map: Map | null,
    mapView: MapView | null
};

export const Layerlist = (props: layerlistProps) => {
    const lagRef = useRef(null);
    const [layerList, setLayerList] = useState<LayerList | null>(null);
    
    const defineActions = (event:any) => {
      const item = event.item;

      if (item.layer.type && item.layer.type !== "group") {
        // don't show legend twice
        item.panel = {
          content: "legend",
          open: false
        };
      }

      item.actionsSections = [
        [
          {
            title: "Mer gjennomsiktig",
            className: "esri-icon-plus",
            id: "decrease-opacity"
          },
          {
            title: "Mindre gjennomsiktig",
            className: "esri-icon-minus",
            id: "increase-opacity"
          }
        ]
      ];
    };

    useEffect(
      () => {
        if(props.mapView !== null) {
          if(layerList === null) {
            let lagContainer: any = lagRef.current;
            if(lagContainer !== null) {
              var lList = new LayerList({
                view: props.mapView,
                container: lagContainer,
                listItemCreatedFunction: defineActions
              });

              setLayerList(lList);

              lList.on("trigger-action", (event:any) => {
                let item = event.item;
                if (item && item.layer) {
                  let id = event.action.id;
                
                  if (id === "increase-opacity") {              
                    if (item.layer.opacity < 1) {
                      item.layer.opacity += 0.25;
                    }
                  } else if (id === "decrease-opacity") {
                    if (item.layer.opacity > 0) {
                      item.layer.opacity -= 0.25;
                    }
                  }
                }
              });
            }
        }
      }
    });

    return (
      <div ref={lagRef} />
    );
};