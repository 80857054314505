/* Utils class calling services for data retrieval */
import axios from "axios";
import { ADED } from "../constants";
import { LookupResult } from "../types";

export default class LookupService {
    /**
     * Get details for ADED object
     * @param queryUrl url to query
     * @param un username for search auth
     * @param pwd password for search auth
     * @returns ADED object details or error
     */
    static getADED(queryUrl: string, un: string, pwd: string): Promise<LookupResult> {
        let headers:any = {};
        if(un && pwd) {
            headers = { auth: {
                username: un,
                password: pwd
            }};
        }
        return new Promise((resolve, reject) => {
            axios.get(queryUrl, headers)
            .then(function (response) {
                let result = response.data._source;
                result.type = ADED;
                resolve({result: result, error: null});
            })
            .catch(function (error) {
                resolve({result: null, error: error});
            })
            .then(function () {
                // always executed
            });
        });
    }

    /**
     * Get details for artefact
     * @param queryUrl url to query
     * @returns Artefact details or error
     */
    static getArtefact(queryUrl: string): Promise<LookupResult> {
        return new Promise((resolve, reject) => {
            axios.get(queryUrl)
            .then(function (response) {
                let result = response.data;
                result.type = "Artefact";
                resolve({result: result, error: null});
            })
            .catch(function (error) {
                resolve({result: null, error: error});
            })
            .then(function () {
                // always executed
            });
        });
    }

    /**
     * Get photos for artefact
     * @param queryUrl url to query
     * @returns photos for artefact or error
     */
    static getArtefactPhotos(queryUrl: string): Promise<LookupResult> {
        return new Promise((resolve, reject) => {
            axios.get(queryUrl)
            .then(function (response) {
                let result = response.data;
                resolve({result: result, error: null});
            })
            .catch(function (error) {
                resolve({result: null, error: error});
            })
            .then(function () {
                // always executed
            });
        });
    }

    /**
     * Get photos for ADED object
     * @param queryUrl url to query
     * @returns photos or error
     */
    static getMedia(queryUrl: string): Promise<LookupResult> {
        return new Promise((resolve, reject) => {
            axios.get(queryUrl)
            .then(function (response) {
                let result = response.data;
                resolve({result: result, error: null});
            })
            .catch(function (error) {
                resolve({result: null, error: error});
            })
            .then(function () {
                // always executed
            });
        });
    }

    /**
     * Get kulturminner for ADED object or artefact
     * @param queryUrl url to query
     * @returns kulturminner or error
     */
    static getKulturminne(queryUrl: string): Promise<LookupResult> {
        const q = queryUrl + "&outFields=*&returnGeometry=true&f=json";
        return new Promise((resolve, reject) => {
            axios.get(q)
            .then(function (response) {
                let result = response.data;
                resolve({result: result, error: null});
            })
            .catch(function (error) {
                resolve({result: null, error: error});
            })
            .then(function () {
                // always executed
            });
        });
    }
};
