/* Utils class for structuring and formatting ADED data for presentation */
import { ADEDBuckets, projectAttributes, projectHighlightedAttributes, replaceInTypes } from "../config/ADEDConfig";
import { ProjectTypeKey, RANGE_FILTER, SEARCH_FIELDS, VALUE_FILTER } from "../constants";
import { ADEDResult, filtertype, Filter, FilterValue, SearchResult, Bucket, ADEDAttributt } from "../types";

const cmLabels = ["lengde", "bredde", "dybde", "diameter"];
const mLabels = ["høyde"];
const gLabels = ["vekt"];

/**
 * Add unit to given value
 * @param val value to format
 * @returns formatted value
 */
export const getLabel = (val: string | null) => {
    if (val === null) {
        return "";
    }
    if (cmLabels.indexOf(val.trim().toLocaleLowerCase()) > -1) {
        return val + " (cm)";
    }
    if (mLabels.indexOf(val.trim().toLocaleLowerCase()) > -1) {
        return val + " (m)";
    }
    if (gLabels.indexOf(val.trim().toLocaleLowerCase()) > -1) {
        return val + " (g)";
    }

    return val;
};

/**
 * Format data language like boolean to "Ja/Nei"
 * @param val value to format
 * @returns formatted value
 */
export const getFormattedValue = (val: string | null) => {
    if (val === null) {
        return "";
    }
    if (val.trim().toLowerCase() === "true") {
        return "Ja";
    }
    if (val.trim().toLowerCase() === "false") {
        return "Nei";
    }
    if(val.indexOf('|') !== -1) {
        return val.replace(/^\|/, '').replace(/\s/g, '').replace(/\|/g, ', ').replace(/,(\s{1}[a-zA-ZæøåÆØÅ]+)$/, ' og $1').toLowerCase();
    }

    return val;
};

/**
 * Replace given content (Hus_, Lag_) in types
 * @param t type to format
 * @returns formatted value
 */
export const replaceInType = (t: string | null) => {
    let type = t || "";

    replaceInTypes.forEach(rTxt => {
        type = type?.replace(rTxt, "");
    });

    return type.length > 1 ? type[0].toUpperCase() + type.substring(1) : "";
};

/**
 * Check if given object is of type ADEDResult
 * @param object object to chek
 * @returns true or false
 */
export const testADEDType = (object: any): object is ADEDResult => {
    return "Shape" in object;
};

/**
 * Format given value as date
 * @param val value to format
 * @returns formatted date
 */
export const getFormattedDate = (val: string | null) => {
    if (val === null) {
        return "(dato ikke angitt)"
    }

    try {
        const newDate = new Date(val);

        const yyyy = newDate.getFullYear().toString();
        const mm = (newDate.getMonth() + 1).toString(); // getMonth() is zero-based
        const dd = newDate.getDate().toString();

        const dateString = (dd[1] ? dd : "0" + dd[0]) + "." + (mm[1] ? mm : "0" + mm[0]) + "." + yyyy; // padding

        const hh = newDate.getHours().toString();
        const MM = newDate.getMinutes().toString();
        const ss = newDate.getSeconds().toString();

        const timeString = (hh[1] ? hh : "0" + hh[0]) + ":" + (MM[1] ? MM : "0" + MM[0]) + ":" + (ss[1] ? ss : "0" + ss[0]); // padding

        return dateString + " " + timeString;

    }
    catch {
        return val;
    }
};

/**
 * Filter list of ADED Project properties
 * @param p list of attributes
 * @param all whether to return all or just highlighted properties
 * @returns filtered properties list
 */
export const getProjectAttributes = (p: Array<ADEDAttributt>, all: boolean): Array<ADEDAttributt> => {
    if (p.length < 1) {
        return [];
    }

    let attributes:Array<ADEDAttributt> = [];

    const attrList = all ? projectAttributes : projectHighlightedAttributes;
    
    attrList.forEach((l: string) => {
        let attr = p.filter(a => {return a.Label.toLowerCase() === l.toLowerCase()});
        if(attr.length === 1) {
            attributes.push(attr[0]);
        }
        else if(attr.length > 1) {
            attributes.push({
                Label: attr[0].Label, 
                Value: attr.map(a => {return a.Value}).join(", ")
            });
        }
    });

    if(all) {
        const unknownAttrs = p.filter(a => {return projectAttributes.indexOf(a.Label) === -1;});
        if(unknownAttrs.length > 0) {
            attributes.push(...unknownAttrs);
        }
    }

    return attributes;
};

/**
 * Try to get list of artefact NOs related to ADED object
 * ex
 * 1
 * 1,2,3
 * 1-3
 * 1 til 3
 * @param p string value to check for artefact NOs
 * @returns list of artefact NOs
 */
export const getArtefactNOs = (p: string): Array<string> => {
    if(!p || p.length < 2) {
        return [];
    }

    let museumLetter = p[0];
    if(p[0] === "T" && !isNaN(parseInt(p[1]))) {
        museumLetter = p.substring(0, 2);
    }

    let museumsNOs:Array<string> = [];

    if(p.indexOf(",") > -1) { // List separated by comma
        museumsNOs = p.split(",");
    }
    else if(p.indexOf("til") > -1) { // Two numbers, indicating all between
        const pList = p.match(/\d+/g) || [];
        if(pList.length !== 2) {
            return [];
        }
        let v1 = parseInt(pList[0]);
        let v2 = parseInt(pList[1]);
        if(v1 > v2) {
            return [];
        }
        let v = v1;
        while(v <= v2) {
            museumsNOs.push(museumLetter + v.toString());
            v++;
        }
    }
    else { // One number
        museumsNOs.push(p);
    }

    return museumsNOs;
}

/**
   * Get JSON query for current search term and active filter parameters
   *
   * @param resultFilters All current filters
   * @param queryValue Current search term
   * @returns json describing query to send to Elastic search index
   * 
   * query: match string in SEARCH_FIELDS
   * 
   * find active value filters 
   * parse into "filter[]"-part of query object, each element as {"bool": {"should": []}}
   * Projects and objecttypes are treated specially
   * Projects value: [name]|[ID] - send ID
   * Objecttype value: [Hovedtype]|[Objekttype] - send both!
   * Add active range filters to filter-part as {"range": {"gte": g, "lte": l}}
   */
export const getMainQueryPart = (resultFilters: Array<Filter>, queryValue: string): any => {
    
    let queryPart:Array<any> = [];

    if(queryValue)
    {
        queryPart.push({"multi_match" : {"query" : queryValue, "fields" : SEARCH_FIELDS, "fuzziness": "AUTO"}}); // fuzziness = AUTO er anbefalt av elastic
    }

    const activeFilters = resultFilters.filter((x: Filter) => x.values?.some((y: FilterValue) => y.active));
    const exactMatchFilters = activeFilters.filter(x => x.filtertype === VALUE_FILTER);
    let filterPart:Array<any> = [];

    if(exactMatchFilters.length > 0) {
        exactMatchFilters.map((filter: Filter) => {
            let extraAnd:Array<any> = [];
            let or:Array<any> = [];

            if(filter.values && filter.values.length > 0) {
                filter.values.map((val: FilterValue) => {
                    if (val.active) {
                        let filterJSON:any = {};

                        if(val.value && typeof(val.value) === "string" && val.value.indexOf("|") > -1) {
                            const fv = val.value.split("|");
                            if(filter.typeKey === ProjectTypeKey) {
                                filterJSON["ProsjektId.keyword"] = fv[1];
                            }
                            else { // Hovedtype/Objekttype
                                filterJSON[filter.typeKey] = fv[1];

                                // select each hovedobjekttype-value only once
                                const alreadyAdded = extraAnd.filter(ea => {return (ea.match && ea.match["ObjektHovedType.keyword"] === fv[0]); });
                                if(alreadyAdded.length < 1) {
                                    extraAnd.push({"match": {"ObjektHovedType.keyword": fv[0]}});
                                }
                            }
                        }
                        else {
                            let fv = (val.value !== null ? val.value : val.caption);
                            filterJSON[filter.typeKey] = fv;
                        }
                        or.push({"match": filterJSON});
                    }
                });
            }

            filterPart.push({"bool": {"should": or}});
            if(extraAnd.length > 0) {
                filterPart.push({"bool": {"should": extraAnd}});
            }
        });
    }

    const rangeFilters = activeFilters.filter(x => x.filtertype === RANGE_FILTER);

    if(rangeFilters.length > 0) {
        rangeFilters.map((filter: Filter) => {
            if(filter.values && filter.values.length > 0) {
                let rangePart:any;
                filter.values.map((val: FilterValue) => {
                    if(!isNaN(val.value) && val.value !== null) {
                        rangePart = rangePart || {}
                        rangePart[val.caption] = val.value;
                    }
                });
                
                if(rangePart) {
                    queryPart.push({"range": {[filter.typeKey]: rangePart}});
                }
            }
        });
    }
    return {"bool": { "must": queryPart, "filter": filterPart }};
};

/**
 * Parse bucket-part of search result to filters
 * @param adedResult result to parse
 * @returns list of filters with updated numbers of objects
 */
export const parseCurrentFilters = (adedResult: SearchResult): Array<Filter> => {
    let result = adedResult.result.aggregations ?? {};

    if (Object.keys(result).length === 0) {
        return result;
    }

    let filters: Array<Filter> = [];

    ADEDBuckets.forEach((bucket: Bucket) => {
        if(bucket.range) {
            tryPushFilter(filters, RANGE_FILTER, bucket.field, bucket.caption, "", null, undefined, bucket.group);
        }
        else {
            if (result[bucket.field + "_bucket"]) {
                parseBuckets(filters, VALUE_FILTER, bucket.field + (bucket.keyword ? ".keyword" : ""), bucket.caption, 
                    result[bucket.field + "_bucket"].buckets, bucket.group, bucket.buckets);
            }
        }
    });

    return filters;
};

export const isAnyFilterActive = (resultFilters:Array<Filter>):boolean => {
    let isAny = false;
    let i = 0;
    while(!isAny && i < resultFilters.length) {
        resultFilters[i].values?.forEach((xv: FilterValue) => {
            if(xv.active) {
                isAny = true;
            }
        });
        i++;
    }

    return isAny;
};

/**
 * Get list of active filter values from complete list of filters
 * @param resultFilters all filters
 * @returns list of active filter values
 */
export const getActiveFilterValues = (resultFilters:Array<Filter>):Array<string> => {
    let activeValues: Array<string> = [];

    resultFilters.forEach((x: Filter) => {
        x.values?.forEach((xv: FilterValue) => {
            if(xv.active) {
            activeValues.push(x.typeKey + "_" + (xv.value || xv.caption));
            }
        });
    });

    return activeValues;
};

/**
 * Get list of active filters from complete list
 * @param resultFilters all filters
 * @returns list of active filters
 */
export const getActiveFilters = (resultFilters:Array<Filter>):Array<Filter> => {
    let activeValues: Array<Filter> = [];

    resultFilters.forEach((x: Filter) => {
        x.values?.forEach((xv: FilterValue) => {
            if(xv.active) {
                activeValues.push({
                    filtertype: x.filtertype, group: x.group, type: x.type, typeKey: x.typeKey, values:[xv]
                });
            }
        });
    });

    return activeValues;
};

/**
 * Check if there are changes in active filter values
 * @param activeValues new active filter values
 * @param currentActiveFilterValues current active filter values
 * @returns true if there are changes
 */
export const isActiveValuesChanged = (activeValues:Array<string>, currentActiveFilterValues:Array<string>):boolean => {
    let activeFiltersChanged = false;

    activeValues.forEach((c: string) => { // Are there new active values?
      if(currentActiveFilterValues.filter((s:string) => {return s === c;}).length === 0) {
        activeFiltersChanged = true;
      }
    });
    currentActiveFilterValues.forEach((c: string) => { // Are there de-activated values?
      if(activeValues.filter((s:string) => {return s === c;}).length === 0) {
        activeFiltersChanged = true;
      }
    });

    return activeFiltersChanged;
};

// private
const parseBuckets = (filters: any, filterType: any, typeKey: string, type: string, bucket: any, group?: string, subBuckets?: Array<Bucket>) => {
    bucket.forEach((element: any) => {
        if(subBuckets) {
            subBuckets.forEach((subBucket: Bucket) => {
                const subKey = subBucket.field + "_bucket";
                if(element[subKey] && element[subKey].buckets) {
                    element[subKey].buckets.forEach((e2: any) => {
                        let caption = e2.key;
                        let value = e2.key;
                        if(e2.key_as_string) {
                            if(e2.key_as_string === "true") {
                                caption = "Ja";
                                value = true;
                            }
                            if(e2.key_as_string === "false") {
                                caption = "Nei";
                                value = false;
                            }
                        }
                        else {
                            caption = replaceInType(e2.key);
                        }
                        tryPushFilter(filters, filterType, subBucket.field + ".keyword", element.key, caption, element.key + "|" + value, e2.doc_count, type);
                    });
                }
            });
        }
        else if(!bucket.range) {
            let caption = element.key;
            let value = element.key;
            if(element.key_as_string) {
                if(element.key_as_string === "true") {
                    caption = "Ja";
                    value = true;
                }
                if(element.key_as_string === "false") {
                    caption = "Nei";
                    value = false;
                }
            }
            else {
                caption = replaceInType(element.key);
            }
            tryPushFilter(filters, filterType, typeKey, type, caption, value, element.doc_count, group);
        }
    });
};

const tryPushFilter = (filters: Array<Filter>, filtertype: filtertype, typeKey: string, type: string, 
                        caption: string, value: string | null, noHits?: number, group?: string) => {
    const p = filters.find((f: Filter) => {
        return f.type === type;
    });

    if (p) {
        if (p.values && p.values.filter((f: FilterValue) => { return f.caption === caption }).length === 0) {
            let val: FilterValue = { caption: caption, noHits: noHits };
            if (value !== null) {
                val.value = value;
            }
            p.values.push(val);
        }
    }
    else {
        let vals: Array<FilterValue> = [];
        if (filtertype === VALUE_FILTER) {
            vals = [{ caption: caption, active: false, noHits: noHits }];
            if (value !== null) {
                vals[0].value = value;
            }
        }
        else if (filtertype === RANGE_FILTER) {
            vals = [{ caption: "gte", value: null }, { caption: "lte", value: null }];
        }
        filters.push({
            filtertype: filtertype,
            typeKey: typeKey,
            type: type,
            values: vals,
            group: group
        });
    }
};