/* Edit user, used in useradmin dialog for admin users */
import { useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { EditOutlined, DownOutlined, SaveOutlined } from "@ant-design/icons";
import { RoleEnum, UserRole } from "../../../types";

interface userprops {
    user: UserRole;
    callback: any;
}

const EditableUser = (props: userprops) => {
    
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newRole, setNewRole] = useState<string>(props.user.groupName);

    const handleChangeRole = () => {
        setIsEditing(false);
        // Only callback if actual change
        if(newRole !== props.user.groupName) {
            props.callback({...props.user, groupName: newRole});
        }
    };

    const dropdownmenu = (
        <Menu>
            <Menu.Item onClick={() => {setNewRole(RoleEnum.Bruker)}}>
                {RoleEnum.Bruker}
            </Menu.Item>
            <Menu.Item onClick={() => {setNewRole(RoleEnum.Saksbehandler)}}>
                {RoleEnum.Saksbehandler}
            </Menu.Item>
            <Menu.Item onClick={() => {setNewRole(RoleEnum.Administrator)}}>
                {RoleEnum.Administrator}
            </Menu.Item>
        </Menu>
    );

    const editUser = () => {
        setNewRole(props.user.groupName);
        setIsEditing(true);
    }

    const renderRole = () => {
        if(!isEditing) {
            return props.user.groupName;
        }

        return (
            <Dropdown overlay={dropdownmenu} trigger={["click"]}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <span className="margin-right-small" >{newRole}</span>
                    <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const renderAction = () => {
        if(!isEditing) {
            return (
                <Button type="link" title="Endre rolle" onClick={() => editUser()} style={{padding: 0}} >
                    <EditOutlined />
                </Button>
            );
        }

        return (
            <Button type="link" title="Oppdater rolle" onClick={() => handleChangeRole()} style={{padding: 0}} >
                <SaveOutlined />
            </Button>
        )
    }

    return (
        <tr>
            <td>{props.user.userName}</td>
            <td>{props.user.fullName}</td>
            <td>
                {renderRole()}
            </td>
            <td>
                {renderAction()}
            </td>
        </tr>
    );
}

export default EditableUser;