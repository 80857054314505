/* Container for displaying search results of given type, using ADEDResultItems or ArtefactResultItems for the actual presentation */
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Dropdown, Input, Menu } from "antd";
import { DownOutlined, DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import { User } from "oidc-client";

import * as conf from "../../../config/SearchConfig";
import { StoreState, SearchResult, searchbase, Filter, ExportFormat, Exportwkid, AlertButton, ArtefactFilters, PresentableSearchResult, ADEDResult, ArtefactResult, SortField } from "../../../types";
import { ArtefactResultItems } from "./artefactresultitems";
import { ADED, ALERT_TYPE, Artefact, MapHit } from "../../../constants";
import SearchData from "../../../services/searchservice";
import { setMaxHits, setSearchResult, setSortField } from "../../../store/searchSlice";
import { ADEDResultItems } from "./adedresultitems";
import { postUserExport } from "../../../services/userservice";
import { ExportFormats, ExportWKID } from "../../../config/ADEDConfig";
import { showAlert } from "../../../store/appSlice";
import { setExportDialogVisible } from "../../../store/exportSlice";
import { getArtefactQueryUrl, makeFriendly } from "../../../utils";

import "./resultlist.css";
import { isAnyFilterActive } from "../../../utils/adedHelper";

declare var grecaptcha: any;

const addrecaptchaScript = (url: string) => {
    var alreadyLoaded = false;
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src == url){
            //console.log("recaptcha already loaded");
            alreadyLoaded = true;
            break;
        }
    }
    if (alreadyLoaded === false) {
        const script = document.createElement('script');
        script.src = url;
        document.head.appendChild(script);
    }
}

const ResultList = () => {
    addrecaptchaScript("https://www.google.com/recaptcha/enterprise.js?render=" + window.environment.aded_recaptcha_key);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const noHitsSelection = [50, 100, 200, 500, 1000, 5000, 10000];

    const [currentResult, setCurrentResult] = useState<PresentableSearchResult | null>(null);
    const [visibleExportQueryModal, setVisibilityExportQueryModal] = useState<boolean>(false);
    const [customName, setCustomName] = useState<string>(""); // for use when exporting results
    const [selectedFormat, setSelectedFormat] = useState<ExportFormat>(ExportFormats[0]); // for use when exporting results
    const [selectedWKID, setSelectedWKID] = useState<Exportwkid>(ExportWKID[1]); // for use when exporting results (Default UTM33N)

    const user: User | null = useSelector((state: StoreState) => state.user.user);
    const searchQueryADED: string = useSelector((state: StoreState) => state.search.searchQueryADED);
    const searchQueryArtefact: string = useSelector((state: StoreState) => state.search.searchQueryArtefact);
    const adedResults: SearchResult | null = useSelector((state: StoreState) => state.search.adedResults);
    const artefactResults: SearchResult | null = useSelector((state: StoreState) => state.search.artefactResults);
    const selectedItem: ADEDResult | ArtefactResult | null = useSelector((state: StoreState) => state.search.selectedItem);
    const firstTime: boolean = useSelector((state: StoreState) => state.app.firstTime);
    
    const selectedResultType: (searchbase | null) = useSelector((state: StoreState) => state.search.selectedSearchBase);
    const maxHits: number = useSelector((state: StoreState) => state.search.maxHits);
    const currentPage: number = useSelector((state: StoreState) => state.search.currentPage);
    const sortField: SortField = useSelector((state: StoreState) => state.search.sortField);
    const geoBBox: Array<number> | null = useSelector((state: StoreState) => state.search.geoBBox);

    const prevPage = useRef<number>(currentPage);
    const prevBase = useRef<searchbase | null>(null);

    const resultFilters: Array<Filter> = useSelector((state: StoreState) => state.search.resultFilters);
    const artefactFilters: ArtefactFilters = useSelector((state: StoreState) => state.search.artefactFilters);
    
    const handleExportOk = (): void => {
        grecaptcha.enterprise.ready(async () => {
            var keyid = window.environment.aded_recaptcha_key;
            const token = await grecaptcha.enterprise.execute(keyid, {action: 'submit'});
            const friendlyName = makeFriendly(customName);
            const userIdentifyer = user?.profile.sub || localStorage.getItem("userIdentifyer");
            if (! (userIdentifyer === null || userIdentifyer === "")){
                postUserExport(userIdentifyer, resultFilters, searchQueryADED, friendlyName, selectedWKID.value, selectedFormat.value, geoBBox, token).then((response: any) => {
                    console.log(response)
                    if (response.status === 201) {
                        dispatch(setExportDialogVisible(true)) //Åpner eksportloggen med en gang
                    }
                    else {
                        dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å bestille eksport", type: ALERT_TYPE.ERROR, manualClose: false}));
                    }
                });
            }
            else {
                dispatch(showAlert({title: "Feilet!", message: "Noe gikk galt med å bestille eksport, forsøk å refreshe siden", type: ALERT_TYPE.ERROR, manualClose: false}));
            }

    
            setVisibilityExportQueryModal(false);
          });
    };

    const handleExportCancel = () => {
        setVisibilityExportQueryModal(false);
    };

    const exportHandler = () => {
        setVisibilityExportQueryModal(true);

        setTimeout(() => {
            const focusElement = document.getElementById("exportName");
            if(focusElement) {
                (focusElement as HTMLElement).focus();
            }
        }, 300);
    };

    const getMaxHitsDropdown = () => {
        return(
            <Dropdown overlay={
                <Menu>
                    {noHitsSelection.map(n => {
                        return (
                            <Menu.Item key={`menuItem` + n.toString()} onClick={() => {dispatch(setMaxHits(n))}}>
                                {n}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            } trigger={["click"]} className="margin-right">
                <Button type="link" className="ant-dropdown-link maxhitsdd" onClick={e => e.preventDefault()}>
                    <span className="margin-right-small" >{maxHits}</span>
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    const getHeader = () => {
        if (currentResult?.type === Artefact) {
            return (
                <div style={{textAlign: "center"}}>
                    <div>
                        Resultatliste gjenstander
                    </div>
                    {currentResult.noRecords !== 0 &&
                        <div style={{fontSize: ".75em", fontWeight: "normal"}}>
                            {currentResult.noRecords > maxHits && 
                                <span className="margin-right-small">NB! Kartet og listen viser {getMaxHitsDropdown()} av</span>
                            }
                            {currentResult.noRecords} treff
                        </div>
                    }
                </div>
            );
        }

        if (currentResult?.type === ADED) {
            return (
                <div style={{textAlign: "center"}}>
                    {selectedResultType === "ADED" && //Skal være tilgjengelig for alle brukere
                    <div className="export-btns">
                        <Button className="export-btn" onClick={() => exportHandler()} title="Eksportér treffliste" 
                                disabled={!currentResult || !currentResult.result || currentResult.result.length === 0} >
                            <DownloadOutlined />
                            <span className="margin-left-small">Eksportér søkeresultater</span>
                        </Button>
                        <Button className="export-btn" onClick={() => dispatch(setExportDialogVisible(true))} title="Se eksportjobber" >
                            <DownloadOutlined />
                            Se eksportjobber
                        </Button>
                    </div>
                    }
                    <div>
                        Resultatliste utgravninger
                    </div>
                    {currentResult.noRecords !== 0 &&
                        <div style={{fontSize: ".75em", fontWeight: "normal"}}>
                            {currentResult.noRecords > maxHits && 
                                <span className="margin-right-small">NB! Kartet og listen viser {getMaxHitsDropdown()} av</span>
                            }
                            {currentResult.noRecords} treff
                        </div>
                    }
                </div>
            );
        }

        return <div></div>;
    }

    const renderList = () => {
        if (currentResult?.type === Artefact) {
            return (
                <ArtefactResultItems results={currentResult.result} searchPhrase={searchQueryArtefact} noHits={currentResult.noRecords} />
            );
        }

        if (currentResult?.type === ADED) {
            const maxPages = Math.ceil(currentResult.noRecords/maxHits);
                
                return (
                    <ADEDResultItems results={currentResult.result?.map((r: any) => { return r._source; })}
                        searchPhrase={searchQueryADED} noHits={currentResult.noRecords} lastPageNotMax={maxPages > (10000/maxHits)} />
                );
        }

        return <div className="empty-result">Ingen resultater å vise, gjør et nytt søk</div>;
    };

    useEffect(
        () => {
            let curRes: PresentableSearchResult | null = null;
            let setRes: string = "";

            if(selectedResultType === MapHit) { // Keep current list if map tab is selected
                if(currentResult !== null) {
                    return;
                }
                if(selectedItem?.type === ADED) { // deduce from selected if re-rendering on navigating from details page
                    setRes = ADED;
                }
                else if(selectedItem?.type === Artefact) {
                    setRes = Artefact
                }
            }

            if (selectedResultType) {
                if (selectedResultType === ADED && adedResults !== null) {
                    setRes = ADED;
                }
                else if (selectedResultType === Artefact && artefactResults !== null) {
                    setRes = Artefact;
                }
            }

            if(setRes === ADED) {
                curRes = {
                    type: ADED,
                    noRecords: adedResults?.result?.hits?.total?.value || 0,
                    result: adedResults?.result?.hits?.hits
                };
            }
            else if(setRes === Artefact) {
                curRes = {
                    type: Artefact,
                    noRecords: parseInt(artefactResults?.result.totalHits || 0),
                    result: artefactResults?.result.objects || []
                };
            }

            setCurrentResult(curRes);

        }, [selectedResultType, adedResults, artefactResults]);

    useEffect(
        () => {
            if (currentPage !== prevPage.current) {
                if((prevBase.current === null || selectedResultType === prevBase.current)) {
                    prevPage.current = currentPage;

                    if(selectedResultType === ADED) {
                        prevBase.current = ADED;

                        if(searchQueryADED || isAnyFilterActive(resultFilters)) {
                            SearchData.post(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart, 
                                resultFilters, searchQueryADED, maxHits, currentPage, sortField,
                                window.environment.aded_un || "", window.environment.aded_pwd || "", geoBBox).then(response => {
                                dispatch(setSearchResult(response));
                            });
                        }
                    }
                    else if(selectedResultType === Artefact) {
                        prevBase.current = Artefact;

                        if(searchQueryArtefact || artefactFilters.museumNO) {
                            const queryUrl = getArtefactQueryUrl(searchQueryArtefact, artefactFilters.museum, 
                                artefactFilters.requirePhoto, artefactFilters.museumNO, artefactFilters.county, 
                                artefactFilters.municipality, maxHits, currentPage);
                            SearchData.get(queryUrl, Artefact).then(response => {
                                dispatch(setSearchResult(response));
                            });
                        }
                    }
                }
                else {
                    prevBase.current = null;
                }
            }
        }, [currentPage]);

        useEffect(
            () => {
                if(selectedResultType === ADED) {
                    if(searchQueryADED || isAnyFilterActive(resultFilters) || geoBBox) { // don't perform search without criteria
                        SearchData.post(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart, 
                            resultFilters, searchQueryADED, maxHits, currentPage, sortField,
                            window.environment.aded_un || "", window.environment.aded_pwd || "", geoBBox).then(response => {
                            dispatch(setSearchResult(response));
                        });
                    }
                }
                else if(selectedResultType === Artefact) {
                    if(searchQueryArtefact || artefactFilters.museumNO) {
                        const queryUrl = getArtefactQueryUrl(searchQueryArtefact, artefactFilters.museum, 
                            artefactFilters.requirePhoto, artefactFilters.museumNO, artefactFilters.county,
                            artefactFilters.municipality, maxHits, currentPage);
                        SearchData.get(queryUrl, Artefact).then(response => {
                            dispatch(setSearchResult(response));
                        });
                    }
                }
            }, [maxHits]);


            useEffect(
                () => {
                    if(selectedResultType === ADED) {
                        if(searchQueryADED || isAnyFilterActive(resultFilters) || geoBBox) { // don't perform search without criteria
                            SearchData.post(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart, 
                                resultFilters, searchQueryADED, maxHits, currentPage, sortField,
                                window.environment.aded_un || "", window.environment.aded_pwd || "", geoBBox).then(response => {
                                dispatch(setSearchResult(response));
                            });
                        }
                    }
                    else if(selectedResultType === Artefact) {
                        if(searchQueryArtefact || artefactFilters.museumNO) {
                            const queryUrl = getArtefactQueryUrl(searchQueryArtefact, artefactFilters.museum, 
                                artefactFilters.requirePhoto, artefactFilters.museumNO, artefactFilters.county,
                                artefactFilters.municipality, maxHits, currentPage);
                            SearchData.get(queryUrl, Artefact).then(response => {
                                dispatch(setSearchResult(response));
                            });
                        }
                    }
                }, [sortField]);

    const goToPrevious = () => {
        navigate(-1);
    };

    const renderNavigation = () => {
        if(navigate.length < 1) {
            return <div></div>
        }

        return (
            <div className="info-header">
                <div className="info-header-btns">
                    { !firstTime &&
                        <Button type="link" className="btn-link-small no-padding" onClick={goToPrevious} >
                            <LeftOutlined />
                            Forrige
                        </Button>
                    }
                </div>
                { getHeader() }
            </div>
        );
    };

    return (
        <div className="resultlist">
            <div className="dialog" style={{ visibility: visibleExportQueryModal ? 'visible' : 'hidden' }}>
                <div className="dialog-header">
                    Eksportèr treffliste
                </div>
                <div className="dialog-body">
                    <div className="dialog-info-text">
                        <div>
                            Det tar noen minutter å produsere resultatet.
                        </div>
                        <div>
                            Du kan følge status på jobben i eksportloggen.
                        </div>
                    </div>
                    <Input id="exportName" onChange={(e) => setCustomName(e.target.value)} value={customName} placeholder="Navn på eksport" required maxLength={100} />
                    
                    <div className="dialog-btn-container">
                        <Dropdown overlay={
                            <Menu>
                                {ExportWKID.map(f => {
                                    return (
                                        <Menu.Item key={f.value} onClick={() => {setSelectedWKID(f)}}>
                                            {f.caption}
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        } trigger={["click"]} className="margin-right">
                            <Button type="link" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <span className="margin-right-small" >{selectedWKID.caption}</span>
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={
                            <Menu>
                                {ExportFormats.map(f => {
                                    return (
                                        <Menu.Item key={f.value} onClick={() => {setSelectedFormat(f)}}>
                                            {f.caption}
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        } trigger={["click"]} className="margin-right">
                            <Button type="link" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <span className="margin-right-small" >{selectedFormat.caption}</span>
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        
                        <Button className="margin-right" onClick={() => handleExportCancel()}>Avbryt</Button>
                        <Button onClick={() => handleExportOk()} disabled={customName === "" || selectedFormat.value === ""}>Ok</Button>
                    </div>
                    <span className="dialog-footer">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy"> Privacy Policy </a> <br/>and
                        <a href="https://policies.google.com/terms"> Terms of Service </a> apply.
                    </span>
                </div>
            </div>

            { renderNavigation() }
            { renderList()}
        </div>
    );
};

export default ResultList;
