/* Container for performing location queries */
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

import SearchBarMap from "./searchbarmap";
import { PresentableSearchResult, SearchResult, StoreState } from "../../../types";
import { useSelector } from "react-redux";
import { MapHitResultItems } from "./maphitresultitems";
import { MapHit } from "../../../constants";
import { mapHitResultIsEmpty } from "../../../utils/searchHelper";

import helpGreenLargerImg from "../../../images/help_green_larger.png";

const MapHitSearch: React.FC = () => {
    const [infoVisible, setInfoVisible] = useState<boolean>(true);
    const [currentResult, setCurrentResult] = useState<PresentableSearchResult | null>(null);

    const maphitResults: SearchResult | null = useSelector((state: StoreState) => state.search.maphitResults);
    const searchQueryMaphit: string = useSelector((state: StoreState) => state.search.searchQueryMaphit);

    useEffect(() => {
        setTimeout(() => {
            setInfoVisible(false);
        }, 10000); // Close help after 10 sek
    }, []); // empty dependency array = execute only once

    const renderList = () => {
        if (currentResult?.type === MapHit && currentResult && currentResult.noRecords > 0) {
            return (
                <MapHitResultItems results={currentResult.result} searchPhrase={searchQueryMaphit} noHits={currentResult.noRecords} />
            );
        }
        else if(searchQueryMaphit) {
            return (
                <div className="empty-result">
                    <span>
                        Ingen treff, gjør et nytt søk
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        let currentResult: PresentableSearchResult | null = null;

        if (maphitResults !== null && !mapHitResultIsEmpty(maphitResults)) {
            currentResult = {
                type: MapHit,
                noRecords: maphitResults.result.hits?.length,
                result: maphitResults.result.hits
            };
        }

        setCurrentResult(currentResult);

    }, [maphitResults]);

    return (
        <div className="search-content">
            <div className="search-content-top">
                <SearchBarMap />
                <Button type="link" style={{ zIndex: 1000, padding: "4px .5rem" }} onClick={() => setInfoVisible(!infoVisible)} >
                    <img src={helpGreenLargerImg} alt="Hjelp" title="Hjelp meg!" />
                </Button>
            </div>

            <div className={"search-help " + (infoVisible ? "" : "remove-element")}>
                <div className="search-help-header">
                    Hvordan fungerer det?
                    <Button type="link" onClick={() => setInfoVisible(false)} title="Lukk">
                        <CloseOutlined />
                    </Button>
                </div>
                <div>
                    Bruk søkefeltet til å søke etter stedsnavn, adresser og/eller eiendom.
                </div>
                <div>
                    Trefflista vises nedenfor, og kan brukes til å navigere kartet til ønsket sted.
                </div>
            </div>

            { renderList()}
        </div>
    );
}

export default MapHitSearch;
