/* Header info for App */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { InfoCircleFilled, CloseOutlined } from "@ant-design/icons";

import UserView from "../user/userview";
import LoginView from "../login/loginview";
import { navigateApp } from "../../../utils";
import { clearMapSearchResult, setBounds, setWidgetsExpanded } from "../../../store/mapSlice";
import { DISPLAY_TYPE } from "../../../constants";
import { showDisplayType } from "../../../store/appSlice";
import * as conf from "../../../config/MapConfig";
import { clearArtefactSearchResult, initAndActivateFilterValue } from "../../../store/searchSlice";
import { StoreState } from "../../../types";
import { User } from "oidc-client";
import { setRole, userFound } from "../../../store/userSlice";
import { AuthService } from "../../../services/authorisationservice";
import { getSearchQueries, getUserInfo, registerUser } from "../../../services/userservice";
import { setUserSearchQueries } from "../../../store/exportSlice";

import logoBigImg from "../../../images/ADED_logo_big.png"; 
import logoImg from "../../../images/ADED_logo.png";
import "./headerview.css";

interface AuthProps {
    authService: AuthService
};

const HeaderView = (props: AuthProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [infoVisible, setInfoVisible] = useState<boolean>(false);

    const leftMenuOpen: boolean = useSelector((state: StoreState) => state.app.leftMenuOpen);
    const user: User | null = useSelector((state: StoreState) => state.user.user);

    useEffect(
        () => {
            if(!user) {
                props.authService.getUser().then((user: User | null) => {
                    if(user) {
                        dispatch(userFound({
                            id_token: user.id_token,
                            session_state: user.session_state,
                            access_token: user.access_token,
                            refresh_token: user.refresh_token,
                            token_type: user.token_type,
                            scope: user.scope,
                            profile: user.profile,
                            expires_at: user.expires_at,
                            state: user.state
                        }));

                        // get user search queries and user role
                        const getSearchQueriesPromise = getSearchQueries(user);
                        const getUserInfoPromise = getUserInfo(user);
                        const registerUserPromise = registerUser(user);

                        Promise.all([getSearchQueriesPromise, getUserInfoPromise, registerUserPromise]).then((response: any) => {
                            if (response[0].status === 200) {
                                dispatch(setUserSearchQueries(response[0].data));
                            }
                            if (response[1].status === 200) {
                                dispatch(setRole(response[1].data.groupName));
                            }                        
                        });
                    }
                });
            }
        }, [user]
    );

    const reset = () => {
        dispatch(initAndActivateFilterValue({}));
        dispatch(clearArtefactSearchResult());
        dispatch(clearMapSearchResult());
        dispatch(setWidgetsExpanded(false));
        navigateApp(dispatch, navigate, "/");
        dispatch(showDisplayType(DISPLAY_TYPE.MAP_VIEW));
        dispatch(setBounds({xMin:conf.initialXMin, yMin: conf.initialYMin, xMax: conf.initialXMax, yMax: conf.initialYMax}));
    };

    return (
        <header className={`app-header ${leftMenuOpen ? "" : "minimal"}`}>
            <Button type="link" onClick={() => reset()} style={{padding: 0}}>
                <img className="aded-logo-big" src={logoBigImg} alt="ADED logo" />
                <img className="aded-logo-small" src={logoImg} alt="ADED logo" />
            </Button>
            <div className={leftMenuOpen ? "" : "hide-title"}>
                <UserView authService={props.authService} />
                {/* //Disable ved fake user */}
                <LoginView authService={props.authService} />
            </div>
            <div className={`aded-title ${leftMenuOpen ? "" : "hide-title"}`}>
                Archaeological Digital Excavation Documentation
            </div>
            <Button type="link" className={`header-info-btn ${leftMenuOpen ? "" : "hide-title"}`} onClick={() => setInfoVisible(true)} >
                <InfoCircleFilled />
            </Button>
            <div className={"context-help general-help " + (infoVisible ? "" : "remove-element")}>
                <div className="general-info-header">
                    Om løsningen
                    <Button type="link" onClick={() => setInfoVisible(false)} style={{float: "right", marginTop: "-7px"}}>
                        <CloseOutlined />
                    </Button>
                </div>
                {/* Commented out by HRI 240424: ADED now considered in production with real (if not perfect) datasets.
                <p>
                <i>
                    Alle data i denne basen er testdata så lenge det står "Testdata" på nettsiden.
                    Disse vil bli slettet i andre kvartal 2023 og gradvis vil basen bli oppdatert med data fra utgravningsprosjekter. 
                    Det har oppstått tekniske utfordringer som prosjektet jobber med å få løst snarest slik at løsningen kan settes opp med gyldige datasett.
                    Vi ønsker likevel at publikum kan prøve løsningen.
                </i>
                </p>
                */}
                <p>
                    ADED er en NFR-finansiert infrastruktur for arkeologiske utgravningsdata i Norge. ADED er et samarbeidsprosjekt 
                    mellom de regionale museene, MUSIT (Universitetsmuseenes IT-organisasjon) og Riksantikvaren. ADED har data 
                    fra Kulturhistorisk museum (Universitetet i Oslo), Arkeologisk musuem (Universitetet i Stavanger), Universitetsmuseet 
                    (Universitetet i Bergen), Vitenskapsmuseet (Norges Teknisk-Vitenskapelige Universitet), Norges arktiske 
                    universitetsmuseum (Universitetet i Tromsø). Hvert museum eier sine data i ADED.
                </p>
                <p>
                    ADED integrerer den digitale utgravningsdokumentasjonen i en tilgjengelig, sømløs infrastruktur som er fritt 
                    tilgjengelig for alle. Dataene blir lastet opp når prosjektene er avsluttet og blir bare unntaksvis oppdatert senere. 
                    Alle har fri tilgang til å søke, filtrere, lese og hente prosjekter og informasjon om arkeologiske undersøkelser i Norge. 
                    Data kan eksporteres til flere formater som Excel, CSV, Esri Filegeodatabase og GeoPackage.
                </p>
                <p>
                    ADEDs nettsider henter også informasjon fra flere andre kilder. Det er gjenstander og fotografier fra universitetsmuseenes 
                    MUSIT-APIer og kulturminner fra Riksantikvarens Askeladden.
                </p>
                <p>
                    Løsningen er levert av Geodata og er basert på ArcGIS fra Esri. Sentralt er en geodatabase som bruker PostgreSQL. 
                    PostgreSQL er et relasjonelt objektorientert databasesystem som bruker PostGIS og PostGIS Topology til å håndtere 
                    romlige data.
                </p>
                <p>
                    Les mer om løsningen og prosjektet på <a target="_blank" href="https://www.khm.uio.no/forskning/prosjekter/aded/aded-informasjonsside.html">ADED informasjon- og hjelpeside</a>
                </p>                
                <div>
                    <Button type="link" onClick={() => setInfoVisible(false)} style={{float: "right"}}>
                        Lukk
                    </Button>
                </div>
            </div>
        </header>
    );
};

export default HeaderView;