/* Configuration values for ADED data */
import { Bucket, ExportFormat, Exportwkid, HighlightAttributeFilter, IncidentObjectType, TypeAttributes } from "../types";

import spadeImg from "../images/spade.png";
import kontekstImg from "../images/kontekst.png";
import alderImg from "../images/alder.png";

export const replaceInTypes = ["Hus_", "Grav_", "Lag_"];
export const excludedAttributter = ["Undersøkt", "Beskrivelse"];

export const incidentTypesToShow:Array<IncidentObjectType> = [
    {
        hendelseType: "Lage noe fysisk",
        caption: "Profiler",
        objektHovedType: "Profil"
    }, 
    {
        hendelseType: "Prøvetaking",
        caption: "Prøver",
        objektHovedType: "Prøve"
    }
];

export const highlightAttributeFilters:Array<HighlightAttributeFilter> = [
    {
        filtertype: "Utgravningsprosjekter",
        caption: "Utgravningsprosjekter",
        icon: spadeImg
    }, 
    {
        filtertype: "Arkeologisk objekt",
        caption: "Arkeologisk kontekst",
        icon: kontekstImg
    }/* ,
    {
        filtertype: "C14 alder (median)",
        caption: "C14 alder (median)",
        icon: alderImg
    }*/
];

export const hovedtypeAttr: Array<TypeAttributes> = [
    {
        typeKey: "Arkeologisk objekt",
        showAttr: ["Id", "Name", "Strukturtype"],
        excludedAttr: ["Anleggets tydelighet", "Undersøkt", "Beskrivelse", "Class",
            "Fyllmateriale jf nettbrett", "Observasjon jf nettbrett", "Undergrunn jf nettbrett", "Subklasse jf nettbrett",
            "Funn i strukturen", "Prøve", "Prøvenummer", "Fotografert", "Fotonr.", "Tegningsnummer i felt"]
    },
    {
        typeKey: "Topografisk objekt",
        showAttr: ["Name"],
        excludedAttr: []
    },
    {
        typeKey: "Område",
        showAttr: ["Name"],
        excludedAttr: []
    },
    {
        typeKey: "Profil",
        showAttr: ["Name"],
        excludedAttr: []
    },
    {
        typeKey: "Rute",
        showAttr: ["Name", "Rutens størrelse"],
        excludedAttr: ["Lokal X", "Lokal Y", "Kvadrant", "X", "Y", "Størrelse"]
    },
    {
        typeKey: "Graveenhet",
        showAttr: ["Name"],
        excludedAttr: ["Antall funn", "Steinfunn", "Skjørbrentstein i liter", "Dato", "Gravd av"]
    },
    {
        typeKey: "Prøve",
        showAttr: ["Name"],
        excludedAttr: ["Tatt i lag"]
    },
    {
        typeKey: "Prøveanalyse",
        showAttr: ["Name"],
        excludedAttr: ["Signatur avlevert"]
    }
];

export const ADEDBuckets: Array<Bucket> = [
    {
        field: "ProjectBucket",
        caption: "Utgravningsprosjekter",
        range: false,
        keyword: true
    },
    // Administrativ tilhørighet
    {
        field: "Fylker",
        caption: "Fylker",
        keyword: true,
        range: false,
        group: "Administrativ tilhørighet"
    },
    {
        field: "Kommuner",
        caption: "Kommuner",
        keyword: true,
        range: false,
        group: "Administrativ tilhørighet"
    },
    // Objekttyper
    {
        field: "ObjektHovedType",
        caption: "Hovedtyper",
        keyword: true,
        range: false,
        buckets: [
            {
                field: "ObjektType",
                caption: "Undertyper",
                range: false,
                keyword: true
            }
        ]
    },
    // Dimensjoner
    {
        field: "Lengde",
        caption: "Lengde (cm)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    {
        field: "Bredde",
        caption: "Bredde (cm)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    {
        field: "Høyde",
        caption: "Høyde (m)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    {
        field: "Dybde",
        caption: "Dybde (cm)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    {
        field: "Diameter",
        caption: "Diameter (cm)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    {
        field: "Vekt",
        caption: "Vekt (g)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    {
        field: "Hoh",
        caption: "Høyde over havet (moh.)",
        keyword: false,
        range: true,
        group: "Dimensjoner"
    },
    // Materiale
    {
        field: "Fyllmateriale",
        caption: "Fyllmateriale",
        keyword: true,
        range: false,
        group: "Materiale"
    },
    {
        field: "Observasjoner",
        caption: "Observasjoner",
        keyword: true,
        range: false,
        group: "Materiale"
    },
    {
        field: "Undergrunn",
        caption: "Undergrunn",
        keyword: true,
        range: false,
        group: "Materiale"
    },
    // Egenskaper
    {
        field: "SideIProfilVenstre",
        caption: "Side i profil venstre",
        keyword: true,
        range: false,
        group: "Egenskaper"
    },
    {
        field: "SideIProfilHoyre",
        caption: "Side i profil høyre",
        keyword: true,
        range: false,
        group: "Egenskaper"
    },
    {
        field: "FormIFlate",
        caption: "Form i flate",
        keyword: true,
        range: false,
        group: "Egenskaper"
    },
    {
        field: "BunnIProfil",
        caption: "Bunn i profil",
        keyword: true,
        range: false,
        group: "Egenskaper"
    },
    {
        field: "SkjorbrentsteinILiter",
        caption: "Skjørbrentstein (liter)",
        keyword: false,
        range: true,
        group: "Egenskaper"
    },
    {
        field: "MedianAge",
        caption: "C14 alder (median)",
        keyword: false,
        range: true,
        group: "Egenskaper"
    },
    // Dokumentasjon
    {
        field: "OverordnetKontekst",
        caption: "Overordnet kontekst",
        keyword: true,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Undersokelsesmetode",
        caption: "Undersøkelsesmetode",
        keyword: true,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "UndersoktGrad",
        caption: "Undersøkt grad",
        keyword: true,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Fotografert",
        caption: "Fotografert",
        keyword: false,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Saldet",
        caption: "Såldet",
        keyword: false,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Snittet",
        caption: "Snittet",
        keyword: false,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Metode",
        caption: "Metode",
        keyword: true,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Funninnsamlingsmetode",
        caption: "Funninnsamlingsmetode",
        keyword: true,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Bearbeidet",
        caption: "Bearbeidet",
        keyword: false,
        range: false,
        group: "Dokumentasjon"
    },
    {
        field: "Provestatus",
        caption: "Prøvestatus",
        keyword: true,
        range: false,
        group: "Dokumentasjon"
    },
];

// Order of project attributes
export const projectHighlightedAttributes = [
    "ProsjektID",
    "Gårdsnavn",
    "Overordnet kontekst",
    "Undersøkelsestype",
    "Undersøkelsesmetode(r)",
    "Tidsrom for utgravningen"
];

export const projectAttributes = [
    "Prosjektnavn",
    "ProsjektID",
    "Saksnavn",
    "Saksnr",
    "Aks.nr",
    "Museumsnr",
    "Fotonr.",
    "Lokalitetsnavn",
    "Askeladden ID",
    "Gårdsnavn",
    "Gårdsnr",
    "Bruk",
    "Bruksnr",
    "Kommune",
    "Kommunenummer",
    "Fylke",
    "Overordnet kontekst",
    "Undersøkelsestype",
    "Undersøkelsesmetode(r)",
    "Tidsrom for utgravningen",
    "Prosjektleder",
    "Tiltakshavers navn",
    "Tiltakshavers adresse",
    "Tiltakshaves stilling",
    "tiltakstype",
    "Grunneiers navn",
    "Grunneiers adresse",
    "Saksbehandler",
    "Innskrevet/endret av",
    "Rapportens forfatter",
    "Rapportens tittel",
    "Undersøkelsens Id",
    "Sluttdato"
];

export const ExportFormats: Array<ExportFormat> = [
    {caption: "Velg format", value: "" },
    {caption: "Excel", value: "XLSX" },
    {caption: "CSV", value: "CSV" },
    {caption: "Esri Shapefiler", value: "SHP" },
    {caption: "Esri Filgeodatabase", value: "FGDB" },
    {caption: "GeoPackage", value: "GPKG" },
    {caption: "GML", value: "GML" },
    {caption: "GeoJSON", value: "GEOJSON"},
    {caption: "PostGIS", value: "POSTGIS"}
];

export const ExportWKID: Array<Exportwkid> = [
    {caption: "UTM 32N", value: 32632 },
    {caption: "UTM 33N", value: 32633 },
    {caption: "UTM 34N", value: 32634 },
    {caption: "UTM 35N", value: 32635 },
    {caption: "UTM 36N", value: 32636 },
    {caption: "WGS 84", value: 4326 }
];

// Artefact integration
export const MuseumNOKey = "Museumsnr";
export const FotoNOKey = "Fotonr.";