/* Display info, using notification from Ant-Design library */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { notification, Button } from 'antd';
import { ArgsProps } from "antd/lib/notification";

import { ALERT_TYPE } from "../../../constants";
import { StoreState, AlertContent } from "../../../types";

import searchImg from "../../../images/search_icon.png";
import errorImg from "../../../images/error_icon_big.png";
import infoImg from "../../../images/priority_icon_big.png";
import "./alert.css";

const Alert = () => {
    const dispatch = useDispatch();
    const alertContent: AlertContent | undefined = useSelector((state: StoreState) => state.app.alertContent);

    const openNotification = () => {
      if(!alertContent) {
        return;
      }

      let duration = alertContent.duration || 6;
      if(alertContent.manualClose && alertContent.manualClose === true) {
        duration = 0;
      }

      let iconSrc: any = infoImg;
      switch(alertContent.type) {
        case ALERT_TYPE.SEARCH:
          iconSrc = searchImg;
          break;
        case ALERT_TYPE.ERROR:
          iconSrc = errorImg;
          break;
        case ALERT_TYPE.INFO:
          iconSrc = infoImg;
          break;
        default:
          iconSrc = infoImg;
          break;
      }

      const key = `open${Date.now()}`;
      let btn: React.ReactNode;
      if(alertContent.button) {
        const click = () => {
          if(alertContent.button?.action) {
              dispatch(alertContent.button?.action);
          }
          notification.close(key);
        };
        btn = (
          <Button type="primary" size="small" onClick={() => click()} title={alertContent.button?.tooltip}>
            {alertContent.button?.caption}
          </Button>
        );
      }
      else {
        btn = (
          <div className="alert-btn-placeholder" />
        );
      }

      let openOptions: ArgsProps = {
        message: alertContent.title,
        description: alertContent.message,
        duration: duration,
        className: "alert-box",
        icon: <img src={iconSrc} alt={alertContent.type.toString()} className="alert-icon"/>,
        btn,
        key,
        top: 55 // pixels from the top
      };

      notification.open(openOptions);
    }

    useEffect(
        () => {
          if(alertContent) {
            openNotification();
          }
      }, [alertContent]);

    return null;
};

export default Alert;