/* UI for filtering artefacts on given properties */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Collapse, Switch, Radio, Input } from "antd";
import { MacCommandOutlined } from "@ant-design/icons";

import { StoreState, Museum } from "../../../types";
import { DISPLAY_TYPE, Artefact } from "../../../constants";
import SearchData from "../../../services/searchservice";
import { navigateApp, getArtefactQueryUrl } from "../../../utils";
import { setSearchResult, setSelectedItem, setArtefactRequirePhoto, setArtefactMuseum, 
         clearArtefactSearchResult, setArtefactMuseumNO, setArtefactPanelsOpen, setArtefactCounty, setArtefactMunicipality } from "../../../store/searchSlice";
import { showDisplayType, showLoading } from "../../../store/appSlice";
import { clearMapSearchResult } from "../../../store/mapSlice";
import { artefactResultIsEmpty } from "../../../utils/searchHelper";
import { museums } from "../../../config/ArtefactConfig";

import skjemaImg from "../../../images/skjema.png";
import kameraImg from "../../../images/kamera.png";
import museumImg from "../../../images/museum.png";
import "../filterdrawer.css";

const FilterArtefact: React.FC = () => {
    const { Panel } = Collapse;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [museumNOVal, setMuseumNOVal] = useState<string>("");
    const [countyVal, setCountyVal] = useState<string>("");
    const [municipalityVal, setMunicipalityVal] = useState<string>("");
    const [activeKeys, setActiveKeys] = useState<Array<any>>([]);
    
    const requirePhoto: boolean = useSelector((state: StoreState) => state.search.artefactFilters.requirePhoto);
    const museum: string | null = useSelector((state: StoreState) => state.search.artefactFilters.museum);
    const county: string | null = useSelector((state: StoreState) => state.search.artefactFilters.county);
    const municipality: string | null = useSelector((state: StoreState) => state.search.artefactFilters.municipality);
    const museumNO: string | null = useSelector((state: StoreState) => state.search.artefactFilters.museumNO);
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryArtefact);
    const maxHits: number = useSelector((state: StoreState) => state.search.maxHits);
    const panelsOpen: boolean = useSelector((state: StoreState) => state.search.artefactPanelsOpen);

    const { Search } = Input;

    const performSearch = () => {
        if(location.pathname !== "/") {
            navigateApp(dispatch, navigate, "/");  // On new result - always navigate to root
        }
        dispatch(setSelectedItem(null));

        if(searchQuery || museumNO || county || municipality) { // search term or museumNO or county or municipality required
            dispatch(showLoading(true));
            const queryUrl = getArtefactQueryUrl(searchQuery, museum, requirePhoto, museumNO, county, municipality, maxHits, 1);

            SearchData.get(queryUrl, Artefact).then(response => {
                dispatch(showLoading(false));
                if(location.pathname !== "/") {
                    navigateApp(dispatch, navigate, "/");  // On new result - always navigate to root
                }
                dispatch(setSelectedItem(null));
                dispatch(setSearchResult(response));

                if(!artefactResultIsEmpty(response)) {
                    dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
                }
            });
        }
        else {
            dispatch(clearArtefactSearchResult());
            dispatch(clearMapSearchResult());
        }

        if(museumNO !== museumNOVal) {
            setMuseumNOVal(museumNO || "");
        }
        if(county !== countyVal) {
            setCountyVal(county || "");
        }
        if(municipality !== municipalityVal) {
            setMunicipalityVal(municipality || "");
        }
    };

    useEffect(() => {
        performSearch();
    }, [requirePhoto, museum, museumNO, county, municipality]);

    useEffect(
        () => {
        if (!searchQuery) {
            performSearch()
        }
    }, [searchQuery]);

    useEffect(
        () => {
        if (!panelsOpen) {
            setActiveKeys([]);
            dispatch(setArtefactPanelsOpen(true));
        }
    }, [panelsOpen]);

    const setMuseumNOFilterValue = (q: string) => {
        if(!q && museumNO) {
            dispatch(setArtefactMuseumNO(null));
        }
        else if(q !== museumNO) {
            dispatch(setArtefactMuseumNO(q));
        }
    };

    const setCountyFilterValue = (q: string) => {
        if(!q && county) {
            dispatch(setArtefactCounty(null));
        }
        else if(q !== county) {
            dispatch(setArtefactCounty(q));
        }
    };

    const setMunicipalityFilterValue = (q: string) => {
        if(!q && municipality) {
            dispatch(setArtefactMunicipality(null));
        }
        else if(q !== municipality) {
            dispatch(setArtefactMunicipality(q));
        }
    };

    const renderMuseumNO = () => {
        return (
            <div className={`ant-collapse-item filtertype-type ${museumNO ? "filtertype-active" : ""}`} >
                <div className="ant-collapse-header-unclickable">
                    <img className="margin-right" src={skjemaImg} alt="Skjema" />
                    Museumsnr
                    <Search suffix={""} placeholder="ex C59158" value={museumNOVal} onChange={(e) => setMuseumNOVal(e.target.value)} className="museumno-input"
                            onSearch={value => setMuseumNOFilterValue(value)} allowClear enterButton maxLength={20} />
                </div>
            </div>
        );
    };

    const renderPhoto = () => {
        return (
            <div className={`ant-collapse-item filtertype-type ${requirePhoto ? "filtertype-active" : ""}`} >
                <div className="ant-collapse-header-unclickable">
                    <img className="margin-right" src={kameraImg} alt="Kamera" />
                    Kun med bilder
                    <Switch onChange={() => dispatch(setArtefactRequirePhoto(!requirePhoto))} checked={requirePhoto} style={{float: "right"}} />
                </div>
            </div>
        );
    };

    const renderMuseumHeader = () => {
        return (
            <div>
                <img className="margin-right" src={museumImg} alt="Museum" />
                Museum
            </div>
        )
    };

    const renderMuseum = () => {
        return (
            <Panel className={`filtertype-type ${museum !== null ? "filtertype-active" : ""}`}
                header={renderMuseumHeader()} key="museumpanel" >
                <div className="filtertype-container">
                    <div className="values-container">
                        <div className="values-header">
                            <Radio onChange={() => dispatch(setArtefactMuseum(null))} checked={museum === null}>
                                <span>Vis alle</span>
                            </Radio>
                        </div>
                        <div className="scrollable-container">
                            {museums.map((m: Museum, j: number) => {
                                return (
                                    <div key={`museumFilter_${j}`} className="filter-value-row" >
                                        <Radio onChange={() => dispatch(setArtefactMuseum(m.code))} checked={m.code === museum}>
                                            <span>{m.name}</span>
                                        </Radio>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Panel>
        );
    };

    const renderAdmHeader = () => {
        return (
            <div>
                <MacCommandOutlined className="filter-type-icon" />
                Administrativ tilhørighet
            </div>
        )
    };

    const renderAdm = () => {
        return (
            <Panel className={`filtertype-type ${(county !== null || municipality !== null) ? "filtertype-active" : ""}`}
                header={renderAdmHeader()} key="admpanel" >
                <div className="ant-collapse filtertype-container">
                    <div className="ant-collapse-item filtertype-type-grouped">
                        <div className="ant-collapse-header-unclickable" >
                            Fylke
                            <Search suffix={""} placeholder="ex Viken" value={countyVal} onChange={(e) => setCountyVal(e.target.value)} className="adm-input"
                                onSearch={value => setCountyFilterValue(value)} allowClear enterButton maxLength={50} />
                        </div>
                    </div>
                    <div className="ant-collapse-item filtertype-type-grouped">
                        <div className="ant-collapse-header-unclickable" >
                            Kommune
                            <Search suffix={""} placeholder="ex Røros" value={municipalityVal} onChange={(e) => setMunicipalityVal(e.target.value)} className="adm-input"
                                onSearch={value => setMunicipalityFilterValue(value)} allowClear enterButton maxLength={50} />
                        </div>
                    </div>
                </div>
            </Panel>
        );
    };

    const renderFilters = () => {
        return (
            <div>
                <Collapse expandIconPosition="end" activeKey={activeKeys} onChange={(val: any) => {setActiveKeys(val); }}> 
                    {renderMuseum()}
                    {renderAdm()}
                    {renderPhoto()}
                    {renderMuseumNO()}
                </Collapse>
            </div>
        );
    };

    return (
        <div className="filter-container">
            {renderFilters()}
        </div>
    );
}

export default FilterArtefact;
