/* Container for displaying info of one selected item, using ADEDDetails or ArtefactDetails for the actual presentation */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { Routes, Route } from "react-router-dom";
import { Button } from "antd";

import { StoreState } from "../../types";
import { DISPLAY_TYPE } from "../../constants";
import ArtefactDetails from "./details/artefactdetails";
import ResultList from "./result/resultlist";
import ADEDDetails from "./details/ADEDdetails";
import { showDisplayType } from "../../store/appSlice";

import panelDownImg from "../../images/panel_down.png";
import panelUpImg from "../../images/panel_up.png";
import "./info.css";

const Info = () => {
    const dispatch = useDispatch();

    const [currentClass, setCurrentClass] = useState<string>("info content info-hidden");
    const [collapseExpandClass, setCollapseExpandClass] = useState<string>("");

    const displayType: DISPLAY_TYPE = useSelector((state: StoreState) => state.app.displayType);

    useHotkeys("ctrl+alt+i", () => {
        if(displayType === DISPLAY_TYPE.MAP_VIEW) {
            dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
        }

        // Set focus on first button in collapse expand container, small timeout to allow rendering
        setTimeout(() => {
            const focusContainer = document.getElementsByClassName("collapse-expand-container");
            if(focusContainer.length > 0) {
                const focusElements = focusContainer.item(0)?.getElementsByTagName("button");
                if(focusElements && focusElements.length > 0) {
                    (focusElements.item(0) as HTMLElement).focus();
                }
            }
        }, 200);
    });
   
    useEffect(
      () => {
        if(displayType === DISPLAY_TYPE.INFO_VIEW) {
            setCurrentClass("");
            setCollapseExpandClass("full-expanded");
        }
        if(displayType === DISPLAY_TYPE.MAP_VIEW) {
            setCurrentClass("info-hidden");
            setCollapseExpandClass("");
        }
        if(displayType === DISPLAY_TYPE.SPLIT_VIEW) {
            setCurrentClass("info-split-view");
            setCollapseExpandClass("");
        }
    }, [displayType]);

    const collapseView = () => {
        if(displayType === DISPLAY_TYPE.INFO_VIEW) {
            dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
        }
        else if(displayType === DISPLAY_TYPE.SPLIT_VIEW) {
            dispatch(showDisplayType(DISPLAY_TYPE.MAP_VIEW));
        }
    };

    const expandView = () => {
        if(displayType === DISPLAY_TYPE.MAP_VIEW) {
            dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
        }
        else if(displayType === DISPLAY_TYPE.SPLIT_VIEW) {
            dispatch(showDisplayType(DISPLAY_TYPE.INFO_VIEW));
        }
  };

    return (
        <div className={`info ${currentClass}`} >
            <div className="info-container" >
                <Routes>
                    <Route path="/" element={<ResultList />} />
                    <Route path="/artefact/:id" element={<ArtefactDetails />} />
                    <Route path="/ADED/:id" element={<ADEDDetails />} />
                </Routes>
            </div>
            
            <div className={"collapse-expand-container " + collapseExpandClass}>
                <Button type="link" onClick={collapseView} hidden={displayType === DISPLAY_TYPE.MAP_VIEW} title="Lukk visning" className="small-padding">
                    <img src={panelDownImg} alt="Collapse" className="toggle-info-view-btn" />
                </Button>

                <div className="shortcut-hint" hidden>CTRL + ALT + I</div>
                
                <Button type="link" onClick={expandView} hidden={displayType === DISPLAY_TYPE.INFO_VIEW} className="small-padding" title="Åpne visning">
                    <img src={panelUpImg} alt="Expand" className="toggle-info-view-btn" />
                </Button>
            </div>
        </div>
    );
};

export default Info;