/* Configuration values for authentication with Feide and OpenID Connect */
import { UserManagerSettings } from "oidc-client";

export const getUserManagerSettings = (): UserManagerSettings => {
    return {
        authority: window.environment.aded_app_authority || "",
        client_id: window.environment.aded_client_id || "",
        redirect_uri: window.environment.aded_redirect_uri || "http://localhost:3000/callback",
        post_logout_redirect_uri: window.environment.aded_post_logout_redirect_uri || "http://localhost:3000",
        response_type: "code",
        scope: window.environment.aded_client_id ? window.environment.aded_app_scopes : "openid profile",
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true
    }
};