/* Show incidents relates to one selected ADED object, ordered by type and time */
import { Collapse } from 'antd';
import { ADEDHendelse, ADEDHendelseObjekt, IncidentObjectType } from "../../../types";
import { Link } from "react-router-dom";
import { replaceInType } from "../../../utils/adedHelper";
import { onlyUnique } from "../../../utils";
import { incidentTypesToShow } from "../../../config/ADEDConfig";

interface myProps {
    objectID: string,
    incidents: Array<ADEDHendelse>;
}

interface incidentBucket {
    type: IncidentObjectType;
    incidents: Array<ADEDHendelse>;
}

export interface hendelse {
    object: ADEDHendelseObjekt,
    otherObjects: Array<ADEDHendelseObjekt>
}

export const Incidents = (props: myProps) => {
    const { Panel } = Collapse;

    const myTypes = props.incidents.map((h: ADEDHendelse) => {return h.Hendelsetype}).filter(onlyUnique);
    const incidentTypes = incidentTypesToShow.filter((t: IncidentObjectType) => {
        return myTypes.indexOf(t.hendelseType) !== -1;
    });

    const objectIncidents:Array<incidentBucket> = incidentTypes.map((t: IncidentObjectType) => {
        const incds = props.incidents.filter((h: ADEDHendelse) => {
            return h.Hendelsetype === t.hendelseType;
        });

        return {
            type: t,
            incidents: incds
        };
    });

    const getCaption = (o: ADEDHendelseObjekt): string => {
        const oType = replaceInType((o.ObjektType && o.ObjektType !== "Ikke angitt") ? o.ObjektType : o.ObjektHovedType);
        return oType + " - " + o.InputId;
    };

    const renderObjectLink = (o: ADEDHendelseObjekt, t: string) => {
        return (
            <div key={"incidentbucket_type_" + t + o.ObjektId} className="object-link">
                <Link className="hit-link-btn" to={"/ADED/" + o.ObjektId} 
                      title={"Gå til " + getCaption(o)}>
                    <span>
                        {getCaption(o)}
                    </span>
                </Link>
            </div>
        );
    };

    const renderHendelse = (h: ADEDHendelse, hovedType: string) => {
        const mainObjektList = h.RelatedObjects.filter(o => {
            return o.ObjektHovedType === hovedType;
        });

        const mainObjekt = mainObjektList[0] || null;

        const otherObjects = h.RelatedObjects.filter(o => {
            return o.ObjektHovedType !== hovedType && o.ObjektId !== props.objectID;
        });

        if(mainObjekt === null || mainObjekt.ObjektId === props.objectID) {
            return (
                <div key={"incidentbucket_hendelse" + h.HendelseId}>
                    {mainObjekt === null &&
                        <div className="empty-result">Finner ikke objekt med type {hovedType}</div>
                    }
                    { otherObjects.length > 0 && 
                        <div className="incident-other-objects">
                            <div className="incident-other-objects-header">
                                Berørte objekter:
                            </div>
                           { otherObjects.map((h3: ADEDHendelseObjekt) => {
                                return renderObjectLink(h3, h.Hendelsetype);
                            })}
                        </div>
                    }
                </div>
            );
        }

        return (
            <div key={"incidentbucket_hendelse" + h.HendelseId}>
                { renderObjectLink(mainObjekt, h.Hendelsetype) }
                { otherObjects.length > 0 && 
                    <div className="incident-other-objects">
                        <div className="incident-other-objects-header">
                            Andre objekter berørt av <i>{getCaption(mainObjekt) + ":"}</i>
                        </div>
                       { otherObjects.map((h3: ADEDHendelseObjekt) => {
                            return renderObjectLink(h3, h.Hendelsetype);
                        })}
                    </div>
                }
            </div>
        );
    };

    const renderIncidentBucket = (h: incidentBucket) => {
        return (
            <Panel header={h.type.caption} key={"incidentbucket_" + h.type.hendelseType}>
                <div>
                    {h.incidents.map((h2: ADEDHendelse) => {
                        return renderHendelse(h2, h.type.objektHovedType);
                    })}
                </div>
            </Panel>
        );
    }
    
    return (
        <div style={{marginTop: "-2px"}}>
            {objectIncidents.length > 0 &&
                <Collapse expandIconPosition="end">
                    {objectIncidents.map((h: incidentBucket) => {
                        return renderIncidentBucket(h);
                    })}
                </Collapse>
            }
        </div>
    );
};