/* An overview of historical periods */
import "./timeline.css";

const Timeline = () => {
    
    return (
        <div className="timeline-container">
            <table className="timeline-table">
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/eldre-steinalder/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Eldre steinalder på norgeshistorie.no (åpnes i ny fane)">
                                    Eldre steinalder
                                </a>
                            </div>
                            <div className="period-years">9500 - 4000 f.Kr.</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/bronsealder/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Bronsealder på norgeshistorie.no (åpnes i ny fane)">
                                    Bronsealder
                                </a>
                            </div>
                            <div className="period-years">1700 - 500 f.Kr.</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/romertid/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Romertid på norgeshistorie.no (åpnes i ny fane)">
                                    Romertid
                                </a>
                            </div>
                            <div className="period-years">Kr.f. - 400 e.Kr.</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/merovingertid/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Merovingertid på norgeshistorie.no (åpnes i ny fane)">
                                    Merovingertid
                                </a>
                            </div>
                            <div className="period-years">550 - 800</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/hoymiddelalder/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Høymiddelalder på norgeshistorie.no (åpnes i ny fane)">
                                    Høymiddelalder
                                </a>
                            </div>
                            <div className="period-years">1050 - 1350</div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="border-right"></td>
                        <td></td>
                        <td className="border-right" ></td>
                        <td></td>
                        <td className="border-right" ></td>
                        <td></td>
                        <td className="border-right" ></td>
                        <td></td>
                        <td className="border-right" ></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="center-line" >
                        <td></td>
                        <td></td>
                        <td className="border-left"></td>
                        <td></td>
                        <td className="border-left" ></td>
                        <td></td>
                        <td className="border-left" ></td>
                        <td></td>
                        <td className="border-left" ></td>
                        <td></td>
                        <td className="border-left" ></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/yngre-steinalder/" target="_blank" rel="noopener noreferrer"
                                    title="Se mer om Yngre steinalder på norgeshistorie.no (åpnes i ny fane)">
                                    Yngre steinalder
                                </a>
                            </div>
                            <div className="period-years">4000 - 1700 f.Kr.</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/forromersk-jernalder/" target="_blank" rel="noopener noreferrer"
                                    title="Se mer om Førromersk jernalder på norgeshistorie.no (åpnes i ny fane)">
                                    Førromersk jernalder
                                </a>
                            </div>
                            <div className="period-years">500 - Kr.f.</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/folkevandringstid/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Folkevandringstid på norgeshistorie.no (åpnes i ny fane)">
                                    Folkevandringstid
                                </a>
                            </div>
                            <div className="period-years">400 - 550</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/vikingtid/" target="_blank" rel="noopener noreferrer" 
                                    title="Se mer om Vikingtid på norgeshistorie.no (åpnes i ny fane)">
                                    Vikingtid
                                </a>
                            </div>
                            <div className="period-years">800 - 1050</div>
                        </td>
                        <td colSpan={2}>
                            <div className="period-name">
                                <a href="https://www.norgeshistorie.no/senmiddelalder/" target="_blank" rel="noopener noreferrer"
                                    title="Se mer om Senmiddelalder på norgeshistorie.no (åpnes i ny fane)">
                                    Senmiddelalder
                                </a>
                            </div>
                            <div className="period-years">1350 - 1500</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Timeline;