/* Configuration values for search services */
export const ADEDUrlPart: string = "/aded-objekt/";
export const ADEDProjectUrlPart: string = "/aded-prosjekt/";
export const ADEDSearchPart: string = "_search";
export const ADEDDocPart: string = "_doc";

export const ArtefactUrl: string = "https://www.unimus.no/api/things/";
export const ArtefactSearchPart = "search/";
export const ArtefactDocPart = "thing/";
export const ArtefactPortalUrl = "https://www.unimus.no/portal/#/things/";

export const PhotoUrl: string = "https://www.unimus.no/api/media/photos/";
export const MediaSearchUrl: string = "https://www.unimus.no/api/media/search";

export const KulturminneUrl: string = "https://kart.ra.no/arcgis/rest/services/Distribusjon/Kulturminner20180301/MapServer/";
export const Lokaliteter: string = "7"