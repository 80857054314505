/* Dialog for print: select layout and format, print map */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Dropdown, Menu, Radio } from "antd";
import { CloseOutlined, PrinterOutlined, PrinterFilled, DownOutlined, LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import Draggable from 'react-draggable';

import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from "@arcgis/core/Graphic";
import * as print from "@arcgis/core/rest/print";
import PrintTemplate from "@arcgis/core/rest/support/PrintTemplate";
import PrintParameters from "@arcgis/core/rest/support/PrintParameters";

import { showAlert } from "../../../store/appSlice";
import { setPrintDialogVisible } from "../../../store/mapSlice";
import { ALERT_TYPE } from "../../../constants";
import { PrintFormats, PrintLayouts } from "../../../config/MapConfig";
import { PrintFormat, PrintLayout, StoreState } from "../../../types";

import "./dialogs.css";

interface printProps {
    map: MapView | null,
    mapInstance: Map | null,
    printoutgraphicsLayer: GraphicsLayer | null
};

const Print = (props: printProps) => {
    const dispatch = useDispatch();
    
    const [printTitle, setPrintTitle] = useState<string>("");
    const [busy, setBusy] = useState<boolean>(false);
    const [printLayout, setPrintLayout] = useState<PrintLayout>(PrintLayouts[0]);
    const [printFormat, setPrintFormat] = useState<PrintFormat>(PrintFormats[0]);
    const [scalebarUnit, setScalebarUnit] = useState<"Kilometers" | "Meters">("Kilometers");
    const [dpi, setDpi] = useState<number>(96);
    const [height, setHeight] = useState<number>(800);
    const [width, setWidth] = useState<number>(1100);
    const [dragdisabled, setDragDisabled] = useState<boolean>(false);
    const [printResultUrl, setPrintResultUrl] = useState<string>("");
    const visible: boolean = useSelector((state: StoreState) => state.map.printDialogVisible);

    const printMap = () => {
        setPrintResultUrl("");

        if(props.map && props.mapInstance && props.printoutgraphicsLayer) {
            setBusy(true);

            const copyrightText = (document.getElementsByClassName("esri-attribution__sources")[0] as HTMLElement).innerText

            var template = new PrintTemplate({
                format: printFormat.value,
                layout: printLayout.value,
                attributionVisible: false, //Blir lagt til i templates
                scalePreserved: false, //vi ønsker ikke å bevare målestokk, men alt som er i extent
                layoutOptions: {
                    titleText: printTitle,
                    authorText: "ADED",
                    scalebarUnit: scalebarUnit,
                    copyrightText: copyrightText
                }
            });

            if(printLayout.value !== "map-only") {
                template.exportOptions = {
                    dpi: dpi
                };
            }
            else { //map-only trenger width og height
                if (width < 1 || width > 3000 || height < 1 || height > 3000) {
                    dispatch(showAlert({title: "Feilet!", message: "Bredde og høyde må være mellom 1 og 3000.", type: ALERT_TYPE.ERROR, manualClose: false}));
                    setBusy(false);
                    return;
                }
                else{
                    template.exportOptions = {
                        dpi: dpi,
                        width: width,
                        height: height
                    };
                }

            }
             
            const params = new PrintParameters({
                view: props.map,
                template: template
            });

            let url:string = window.environment.aded_printserver_default;
            if (printLayout.endpoint === "custom") {
                url = window.environment.aded_printserver_custom;
            }

            print.execute(url, params, {timeout: 120000}).then((printResult: any) => {
                setBusy(false);
                setPrintResultUrl(printResult.url);
            }, (printError: any) => {
                //console.log(printError);
                setBusy(false);
                dispatch(showAlert({title: "Feilet!", message: "Noe gikk galt, utskrift feilet.", type: ALERT_TYPE.ERROR, manualClose: false}));
            });
        }
    };

    if(!visible) {
        //console.log("not visible")
        //Clear Graphics layer
        return null;
    }

    
    return (
        <Draggable
            disabled={dragdisabled}
        >
            <div className="dialog">
                <div className="dialog-header">
                    <div>
                        <PrinterOutlined className="margin-right-small" />
                        Skriv ut kart
                    </div>
                    <Button type="link" onClick={() => dispatch(setPrintDialogVisible(!visible))} >
                        <CloseOutlined />
                    </Button>
                </div>
                <div className="dialog-body">
                    <div className="input-caption">
                        Tittel på utskrift
                    </div>
                    <div className="input-field-container">
                        <Input className="input-field" value={printTitle} onChange={(e) => setPrintTitle(e.target.value)} maxLength={50} />
                    </div>

                    <div className="print-settings">
                        <div>
                            <Dropdown overlay={
                                <Menu>
                                    {PrintFormats.map(f => {
                                        return (
                                            <Menu.Item key={f.value || "velgformat"} onClick={() => {setPrintFormat(f)}}>
                                                {f.caption}
                                            </Menu.Item>
                                        )
                                    })}
                                </Menu>
                            } trigger={["click"]} className="margin-right">
                                <Button type="link" className="ant-dropdown-link print-dd" onClick={e => e.preventDefault()}>
                                    <span className="margin-right-small" ><b>{printFormat.caption}</b></span>
                                    <DownOutlined />
                                </Button>
                            </Dropdown>

                            <div className="input-caption">
                                Målestokk enhet
                            </div>
                            <div className="print-setting">
                                <Radio.Group buttonStyle="solid" value={scalebarUnit} onChange={(e:any) => setScalebarUnit(e.target.value)}>
                                    <Radio.Button value={"Kilometers"}>km</Radio.Button>
                                    <Radio.Button value={"Meters"}>m</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>

                        <div>
                            <Dropdown overlay={
                                <Menu>
                                    {PrintLayouts.map(f => {
                                        return (
                                            <Menu.Item key={f.value || "velgoppsett"} onClick={() => {setPrintLayout(f)}}>
                                                {f.caption}
                                            </Menu.Item>
                                        )
                                    })}
                                </Menu>
                            } trigger={["click"]} className="margin-right">
                                <Button type="link" className="ant-dropdown-link print-dd" onClick={e => e.preventDefault()}>
                                    <span className="margin-right-small" ><b>{printLayout.caption}</b></span>
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                            <div className="print-setting">
                                <div className="input-caption">
                                    DPI
                                </div>
                                <Radio.Group buttonStyle="solid" value={dpi} onChange={(e:any) => setDpi(e.target.value)}>
                                    <Radio.Button value={96}>96</Radio.Button>
                                    <Radio.Button value={300}>300</Radio.Button>
                                </Radio.Group>
                            </div>
                             {printLayout.value === "map-only" &&
                                <div className="print-setting-hw">
                                    <div>
                                        Høyde: 
                                        <Input 
                                        type="number" step="1" min="1" max="3000" className="input-field inputhw" 
                                        value={height} defaultValue={800} 
                                        onChange={(e:any) => setHeight(e.target.value)} 
                                        onMouseEnter={(e) => setDragDisabled(true)} 
                                        onMouseLeave={(e) => setDragDisabled(false)}/>
                                    </div>
                                    <div>
                                        Bredde:
                                        <Input type="number" step="1" min="1" max="3000" className="input-field inputhw" 
                                        value={width} 
                                        defaultValue={1100} 
                                        onChange={(e:any) => setWidth(e.target.value)}
                                        onMouseEnter={(e) => setDragDisabled(true)} 
                                        onMouseLeave={(e) => setDragDisabled(false)}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="dialog-btn-container">
                        <Button className="margin-right" onClick={() => dispatch(setPrintDialogVisible(false))}>Avbryt</Button>
                        <Button onClick={() => printMap()} disabled={printLayout.caption === "Velg oppsett" || printFormat.caption === "Velg format"}>
                            <PrinterFilled />
                            Skriv ut
                        </Button>
                    </div>

                    <div className="margin-top">
                        {printResultUrl !== "" &&
                            <a href={printResultUrl} target="_blank" rel="noopener noreferrer" style={{margin: "1rem"}}>
                                <DownloadOutlined className="margin-right-small" />
                                Se resultat
                            </a>
                        }
                        {busy &&
                            <div className="empty-result" style={{textAlign: "left"}}>
                                <LoadingOutlined spin className="margin-right" />
                                Genererer utskrift (kan ta opptil 2 minutter)...
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default Print;