/* Utils class for performing tasks for logged in user */
import { User } from "oidc-client";
import { getMainQueryPart } from "../utils/adedHelper";
import axios from "axios";
import { queryObject, UserSearchQuery } from "../types";

/**
 * [httpPut] signed-in user is added with full name in database, if not previously added
 * @param user user to add
 * @returns added user or error
 */
export const registerUser = (user: User | null): Promise<void> => {
    const axiosInstance = axios.create({
        baseURL: window.environment.aded_protected_api,
        headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }
    });

    const putObject = {
        fullName: user?.profile.name
    }

    return new Promise((resolve, reject) => {
        axiosInstance.put(`${window.environment.aded_protected_api}user/register`, putObject)
        .then(function (response: any) {

            console.log(response);
            resolve(response);
        })
        .catch(function (error: any) {
            console.log(error);
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
}

/**
 * [httpGet] user by querying userInfo view, based on username
 * @param user user to get info for
 * @returns user info
 */
export const getUserInfo = (user: User | null): Promise<void> => {
    const axiosInstance = axios.create({
        baseURL: window.environment.aded_protected_api,
        headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }
    });

    return new Promise((resolve, reject) => {
        axiosInstance.get("user/getUserInfo")
        .then(function (response: any) {

            console.log(response);
            resolve(response);
        })
        .catch(function (error: any) {
            console.log(error);
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * post search query object to save for future use and reference
 * @param user current logged in user
 * @param resultFilters filters to save
 * @param queryValue queryterm (string) to save
 * @param newName name to save search as
 * @param isPublic whether the search has global access
 * @param geobbox boudingbox for gepgraphic limit
 * @returns the saved search object or error
 */
export const postUserQuery = (user: User, resultFilters: any, queryValue: string, newName: string, isPublic: boolean, geobbox: Array<number> | null): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}user/searchQuery`;
    
    const mainQuery = getMainQueryPart(resultFilters, queryValue);

    if(geobbox && geobbox.length === 4) {
        const bb = {
            "geo_bounding_box": {
                "CenterPoint": {
                    "top_left": {
                        "lat": geobbox[3].toString(),
                        "lon": geobbox[0].toString()
                    },
                    "bottom_right": {
                        "lat": geobbox[1].toString(),
                        "lon": geobbox[2].toString()
                    }
                }
            }
        };
    
        mainQuery.bool.filter.push(bb);
    }

    const postObject = {
        query: mainQuery,
        name: newName,
        public: isPublic
    };

    return new Promise((resolve, reject) => {
        axios.post(queryUrl, postObject, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * delete saved search query object
 * @param user current logged in user
 * @param queryName name of search to delete
 * @param isPublic whether the search has global access
 * @returns ok or error
 */
export const deleteUserQuery = (user: User, queryName: string, isPublic: boolean): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}user/searchQuery/delete`;
    const postObject = {
        name: queryName,
        public: isPublic
    };

    return new Promise((resolve, reject) => {
        axios.post(queryUrl, postObject, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * get search queries saved by signed-in user. User id is obtained from access token
 * @param user current logged in user
 * @returns saved searches for user
 */
export const getSearchQueries = (user: User): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}user/searchQueries`;

    return new Promise((resolve, reject) => {
        axios.get(queryUrl, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * update search query saved by signed-in user with guid used for sharing. User id is obtained from access token
 * @param user current logged in user
 * @param searchQuery saved search to share
 * @returns search updated with guid for sharing
 */
export const shareSearchQuery = (user: User, searchQuery: UserSearchQuery): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}user/shareSearchQuery`;

    const postObject = {
        name: searchQuery.queryName,
        query: "",
        public: false
    };
    
    return new Promise((resolve, reject) => {
        axios.post(queryUrl, postObject, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * get public search queries
 * @returns saved searches with global access
 */
export const getPublicSearchQueries = (): Promise<any> => {
    const queryUrl = `${window.environment.aded_api}user/searchQueries`;

    return new Promise((resolve, reject) => {
        axios.get(queryUrl)
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * get shared search query
 * @param uid guid to shared search to get
 * @returns shared search or error
 */
export const getSharedSearchQuery = (uid: string): Promise<any> => {
    const queryUrl = `${window.environment.aded_api}user/sharedSearchQuery/${uid}`;
    
    return new Promise((resolve, reject) => {
        axios.get(queryUrl)
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
}

/**
 * post export request object to save for FME triggered export
 * @param userIdentifyer unique identifyer for user
 * @param resultFilters filters to save
 * @param queryValue queryterm (string) to save
 * @param newName name to save search as
 * @param selectedWKID selected export WKID
 * @param selectedFormat selected export format
 * @param geobbox boudingbox for gepgraphic limit
 * @param captchaToken generated captcha token for user
 * @returns export element containing ID for status and download
 */
export const postUserExport = (userIdentifyer:string, resultFilters: any, queryValue: string, newName: string, selectedWKID: number, selectedFormat: string, geobbox: Array<number> | null, captchaToken: string): Promise<any> => {
    const queryUrl = `${window.environment.aded_api}user/exportQuery`;
    
    const mainQuery = getMainQueryPart(resultFilters, queryValue);

    if(geobbox && geobbox.length === 4) {
        const bb = {
            "geo_bounding_box": {
                "CenterPoint": {
                    "top_left": {
                        "lat": geobbox[3].toString(),
                        "lon": geobbox[0].toString()
                    },
                    "bottom_right": {
                        "lat": geobbox[1].toString(),
                        "lon": geobbox[2].toString()
                    }
                }
            }
        };
    
        mainQuery.bool.filter.push(bb);
    }

    let postQueryObject: queryObject = {
        "size": 10000,
        "from": 0,
        "track_total_hits": true,
        "sort": [],
        "query": mainQuery
    };

    const postObject = {
        captchaToken: captchaToken,
        query: postQueryObject,
        name: newName,
        epsg: selectedWKID,
        format: selectedFormat,
        exporttype: "hitlist",
        userIdentifyer: userIdentifyer
    };

    return new Promise((resolve, reject) => {
        axios.post(queryUrl, postObject)
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * get import jobs requested by signed-in user. User id is obtained from access token
 * @param user current logged in user
 * @returns import jobs requested by current user
 */
export const getImportJobs = (user: User): Promise<any> => {
    const queryUrl = `${window.environment.aded_protected_api}user/getimportjobs`;
    return new Promise((resolve, reject) => {
        axios.get(queryUrl, { headers: { "Authorization": `${user?.token_type} ${user?.access_token}` }})
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * get export jobs requested by user.
 * @param userIdentifyer unique identifyer for user
 * @returns export jobs requested by current user
 */
export const getExportJobs = (userIdentifyer:string): Promise<any> => {
    const queryUrl = `${window.environment.aded_api}user/getexportjobs/${userIdentifyer}`;
    return new Promise((resolve, reject) => {
        axios.get(queryUrl)
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * get export job result.
 * @param queueId id of export job
 * @returns result file for export
 */
export const getResultFile = (queueId: number): Promise<any> => {
    const queryUrl = `${window.environment.aded_api}user/export/${queueId}`;
    return new Promise((resolve, reject) => {
        axios.get(queryUrl, {
            responseType: "blob", // Important
        })
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};

/**
 * delete export job.
 * @param userIdentifyer unique identifyer for user
 * @param queueId id of export job to delete
 * @returns ok or error
 */
export const deleteExportJob = (userIdentifyer: string, queueId: number): Promise<any> => {
    const queryUrl = `${window.environment.aded_api}user/export/${userIdentifyer}/${queueId}`;
    return new Promise((resolve, reject) => {      
        axios.delete(queryUrl)
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            resolve(error);
        })
        .then(function () {
            // always executed
        });
    });
};
