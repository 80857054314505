/* UI for paging - navigate through pages in extensive search results */
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Artefact } from "../../../constants";
import { setCurrentPage } from "../../../store/searchSlice";
import { searchbase, StoreState } from "../../../types";

interface myProps {
    noHits: number;
    totalNoHits: number;
}

export const Paging = (props: myProps) => {
    const dispatch = useDispatch();

    const maxHits: number = useSelector((state: StoreState) => state.search.maxHits);
    const currentPage: number = useSelector((state: StoreState) => state.search.currentPage);
    const selectedResultType: (searchbase | null) = useSelector((state: StoreState) => state.search.selectedSearchBase);

    const gotoPage = (n:number) => {
        dispatch(setCurrentPage(n));
    }

    const renderPaging = () => {
        if(props.noHits === props.totalNoHits) {
            return <div></div>;
        }

        const noPagesToRender = 3;
        const middlePage = Math.ceil(noPagesToRender/2);
        const noPages = Math.ceil(props.totalNoHits/maxHits);
        let lastPage = noPages;
        if(selectedResultType !== Artefact && lastPage > (10000/maxHits)) { // max limit of 10.000 hits returned from Elastic
            lastPage = 10000/maxHits;
        }
        let pages = [];
        let i = 1;
        if(noPages > noPagesToRender && currentPage > middlePage) {
            i = (currentPage - middlePage);
            if(i > lastPage - noPagesToRender) {
                i = lastPage - noPagesToRender;
            }
        }
        let maxPages = i + noPagesToRender;
        if(maxPages > lastPage) {
            maxPages = lastPage;
        }
        for(i; i < maxPages; i++) {
            pages.push(i + 1);
        }

        return (
            <div className="resultlist-paging">
                <Button type="link" className="paging-btn" onClick={() => gotoPage(currentPage - 1)} disabled={currentPage === 1} >
                    <LeftOutlined />
                </Button>
                <div>
                    <Button key={"pagingBtn1"} type="link" className={"paging-btn paging" + (1 === currentPage ? " active-page" : "")} onClick={() => gotoPage(1)} >
                        1
                    </Button>
                    {pages[0] > 2 ? "..." : ""}
                    {pages.map((n: number) => {
                        return (
                            <Button key={"pagingBtn" + n} type="link" className={"paging-btn paging" + (n === currentPage ? " active-page" : "")} onClick={() => gotoPage(n)} >
                                {n}
                            </Button>
                        )
                    })}
                    {maxPages < (lastPage - 1) ? "..." : ""}
                    {maxPages < (lastPage - 1) &&
                        <Button key={"pagingBtn" + lastPage} type="link" className={"paging-btn paging" + (lastPage === currentPage ? " active-page" : "")} onClick={() => gotoPage(lastPage)} >
                            {lastPage}
                        </Button>
                    }
                </div>
                <Button type="link" className="paging-btn" onClick={() => gotoPage(currentPage + 1)} disabled={currentPage === lastPage} >
                    <RightOutlined />
                </Button>
            </div>
        )
    }

    return renderPaging();
};