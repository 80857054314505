/* app state - map */
import { MapState } from "../types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: MapState = {
    mapReady: false,
    location: null,
    bounds: null,
    layers: [],
    mapMarkersToAdd: null,
    tocVisible: false,
    printDialogVisible: false,
    widgetsExpanded: window.innerWidth > 450
}

const mapSlice = createSlice({
    name: 'map',
    initialState: initialState,
    reducers: {
        setMapReady(state) {
            state.mapReady = true;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setBounds(state, action) {
            state.bounds = action.payload;
        },
        addMapMarkers(state, action) {
            state.mapMarkersToAdd = action.payload;
        },
        clearMapSearchResult(state) {
            state.mapMarkersToAdd = null;
        },
        setTOCVisibility(state, action) {
            state.tocVisible = action.payload;
        },
        setPrintDialogVisible(state, action) {
            state.printDialogVisible = action.payload;
        },
        setWidgetsExpanded(state, action) {
            state.widgetsExpanded = action.payload;
        }
    }
});

export const {setMapReady, setLocation, setBounds, addMapMarkers, clearMapSearchResult, setTOCVisibility, 
    setPrintDialogVisible, setWidgetsExpanded} = mapSlice.actions;

export default mapSlice.reducer;