/* UI for selecting basemap */
import { useState } from "react";
import { RightOutlined, DownOutlined, InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

import Map from '@arcgis/core/Map';
import Basemap from '@arcgis/core/Basemap';
import Layer from "@arcgis/core/layers/Layer";

import historiskKartImg from "../../../images/thumbnails/historisk.png";
import kartImg from "../../../images/kart.png";

interface basemaplistProps {
    map: Map | null,
    basemaps: Array<Basemap> | null,
    historicalLayer: Layer | null
};

export const BasemapList = (props: basemaplistProps) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [currentBasemap, setCurrentBasemap] = useState<string>(props.map?.basemap.id || "");
    const [historicalVisible, setHistoricalVisible] = useState<boolean>(false);

    const setBasemap = (basemap: Basemap) => {
        if(props.map === null) {
            return;
        }

        props.map.basemap = basemap;
        setCurrentBasemap(basemap.id);
        if(historicalVisible) {
            toggleHistoricalVisible();
        }
    }

    const toggleHistoricalVisible = () => {
        if(props.historicalLayer) {
            props.historicalLayer.visible = !props.historicalLayer.visible;
            setHistoricalVisible(props.historicalLayer.visible);
        }
    };

    const renderBasemap = (basemap: Basemap, i: number) => {
        const isSelected = ((basemap.id === currentBasemap) || (!currentBasemap && i === 0));
        return (
            <div key={`rbtn${basemap.id}`} >
                <Button value={basemap.id} onClick={() => setBasemap(basemap)} 
                        className={`basemap-btn${isSelected ? " selected-basemap" : ""}`} >
                    <img src={basemap.thumbnailUrl} alt={"Thumbnail " + basemap.title} />
                    <div>
                        {basemap.title}
                        {isSelected ? 
                            <CheckCircleFilled className="expand blue" /> : 
                            <div className="circle inline gray" /> }
                    </div>
                </Button>
            </div>
        );
    };

    const renderHistoricalBtn = () => {
        if(!props.historicalLayer) {
            return null;
        }
        return (    
            <div>
                <Button onClick={() => toggleHistoricalVisible()} 
                        className={`basemap-btn${historicalVisible ? " selected-basemap" : ""}`} >
                    <img src={historiskKartImg} alt="Thumbnail Historisk kart" />
                    <div>
                        Historisk kart
                        <a className="margin-left-small" target="_blank" rel="noopener noreferrer" title="Gå til Geonorge for å lese mer om tjenesten" style={{color: "#979797"}}
                            href="https://kartkatalog.geonorge.no/metadata/historiske-kart-wms/f0ef87bf-91a1-4f00-b864-9655d3d7c1de" onClick={(evt) => evt.stopPropagation()}>
                            <InfoCircleFilled />
                        </a>
                        {historicalVisible ? 
                            <CheckCircleFilled className="expand blue" /> : 
                            <div className="circle inline gray" /> }
                    </div>
                </Button>
            </div>
        );
    };
 
    if(props.basemaps === null || props.basemaps.length === 0) {
        return <div></div>;
    }

    return (
        <div className="basemap-container">
            <div>
                <Button className="basemap-container-btn" onClick={() => setVisible(!visible)}>
                    <div>
                        <img src={kartImg} alt="Kart" />
                        Kart
                    </div>
                    {visible && <DownOutlined style={{color: "#62AFAE"}} />}
                    {!visible && <RightOutlined style={{color: "#62AFAE"}} />}
                </Button>
                {visible &&
                    <div>
                        {props.basemaps.map((basemap, i:number) => renderBasemap(basemap, i))}
                        {renderHistoricalBtn()}
                    </div>
                }
            </div>
        </div>
    );
};