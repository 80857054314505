/* Loading animation, used to indicate work in progress */
import { useSelector } from "react-redux";

import { StoreState } from "../../../types";

import "./loading.css";

const Loading = () => {
    const isLoading: boolean = useSelector((state: StoreState) => state.app.loading);

    if(isLoading) {
        return (
            <div className="loading-container">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }

    return null;
};

export default Loading;