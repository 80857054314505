/* app state - import */
import { createSlice } from "@reduxjs/toolkit";
import { ImportState } from "../types";

const initialState: ImportState = {
    dialogVisible: false
}

const importSlice = createSlice({
    name: "import_",
    initialState: initialState,
    reducers: {
        setImportDialogVisible(state, action) {
            state.dialogVisible = action.payload;
        }
    }
});

export default importSlice.reducer;

export const { setImportDialogVisible } = importSlice.actions;
