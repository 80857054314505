/* Utils class for basemap data */
import Basemap from '@arcgis/core/Basemap';
import LocalBasemapsSource from "@arcgis/core/widgets/BasemapGallery/support/LocalBasemapsSource";
import { LayerInfo } from "../types";
import { getLayer } from "./layerFactory";

/**
 * Get LocalBasemapsSource with list of basemaps
 * @param basemaps list of basemaps
 * @returns LocalBasemapsSource
 */
export const getBasemapSource = (basemaps: Array<Basemap>): LocalBasemapsSource => {
    return new LocalBasemapsSource({
        basemaps: basemaps
    });
};

/**
 * Get list of Basemap objects from config objects
 * @param layerInfos list of config objects for basemaps
 * @returns list of Basemap objects
 */
export const getBasemaps = (layerInfos: Array<LayerInfo>): Array<Basemap> => {
    let baselayers:Array<Basemap> = [];

    layerInfos.forEach((layerInfo) => { 
        const bl = getLayer(layerInfo);
        if(bl) {
            if(layerInfo.attachTo) {
                const ablInfo = layerInfos[layerInfos.findIndex((li) => li.id === layerInfo.attachTo)]
                if(ablInfo) {
                    const abl = getLayer(ablInfo);
                    if(abl) {
                        baselayers.push(new Basemap({
                            baseLayers: [abl, bl],
                            title: bl.title,
                            thumbnailUrl: layerInfo.thumbnail
                        })); 
                    }
                }
            }
            else {
                baselayers.push(new Basemap({
                    baseLayers: [bl],
                    title: bl.title,
                    thumbnailUrl: layerInfo.thumbnail
                })); 
            }
        }
    });

    return baselayers;
};