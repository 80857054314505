/* app state - export */
import { createSlice } from "@reduxjs/toolkit";
import { ExportState } from "../types";

const initialState: ExportState = {
    userSearchQueries: [],
    publicSearchQueries: [],
    selectedSearchQuery: null,
    dialogVisible: false
}

const exportSlice = createSlice({
    name: "export_",
    initialState: initialState,
    reducers: {
        setUserSearchQueries(state, action) {
            state.userSearchQueries = action.payload;
        },
        setPublicSearchQueries(state, action) {
            state.publicSearchQueries = action.payload;
        },
        appendToUserSearchQueries(state, action) {
            if(action.payload.isPublic) {
                const existing = state.publicSearchQueries.filter(q => {return q.queryName === action.payload.response.queryName});
                if(existing.length === 0) {
                    state.publicSearchQueries.push(action.payload.response);
                }
                else {
                    const rest = state.publicSearchQueries.filter(q => {return q.queryName !== action.payload.response.queryName});
                    state.publicSearchQueries = [...rest, action.payload.response];
                }
            }
            else {
                const existing = state.userSearchQueries.filter(q => {return q.queryName === action.payload.response.queryName});
                if(existing.length === 0) {
                    state.userSearchQueries.push(action.payload.response);
                }
                else {
                    const rest = state.userSearchQueries.filter(q => {return q.queryName !== action.payload.response.queryName});
                    state.userSearchQueries = [...rest, action.payload.response];
                }
            }
        },
        removeFromUserSearchQueries(state, action) {
            if(action.payload.isPublic) {
                state.publicSearchQueries = state.publicSearchQueries.filter(s => {return s.queryName !== action.payload.queryName});
            }
            else {
                state.userSearchQueries = state.userSearchQueries.filter(s => {return s.queryName !== action.payload.queryName});
            }
        },
        setQuerySharedAs(state, action) {
            state.userSearchQueries.forEach(q => {
                if(q.queryName === action.payload.queryName) {
                    q.sharedAs = action.payload.sharedAs;
                }
            });
        },
        setSelectedSearchQuery(state, action) {
            state.selectedSearchQuery = action.payload;
        },
        setExportDialogVisible(state, action) {
            state.dialogVisible = action.payload;
        }
    }
});

export default exportSlice.reducer;

export const { setUserSearchQueries, setPublicSearchQueries, appendToUserSearchQueries, removeFromUserSearchQueries, 
    setSelectedSearchQuery, setExportDialogVisible, setQuerySharedAs } = exportSlice.actions;
