/* Utils class for structuring and formatting artefact data for search and presentation */
import { capitalize } from ".";
import { museums } from "../config/ArtefactConfig";
import * as conf from "../config/SearchConfig";
import { ArtefactResult, Material, Photo } from "../types";

/**
 * Create query url for artefact search
 * @param q string query
 * @param museum museum filter value, if set
 * @param requirePhoto if yes, only return artefacts with photos
 * @param museumNO museumNP value, if set
 * @param county county filter value, if set
 * @param municipality municipality filter value, if set
 * @param maxHits max number of hits returned
 * @param page index to start returning from if more than maxHits
 * @returns query url with given content
 */
export const getArtefactQueryUrl = (q: string, museum: string | null, requirePhoto: boolean, museumNO: string | null, 
    county: string | null, municipality: string | null, maxHits:number, page: number): string => {

    let firstPart = "?";
    let queryUrl = conf.ArtefactUrl + conf.ArtefactSearchPart + firstPart + "&collectionType=Arkeologi&limit=" + 
        maxHits + "&offset=" + ((page - 1) * maxHits);

    if(q) {
        queryUrl +=  "&freetext=" + q;
    }
    if(museumNO) {
        queryUrl += "&museumNo=" + museumNO.trim();
    }
    if(museum) {
        queryUrl += "&museum=" + museum.trim();
    }
    if(requirePhoto) {
        queryUrl += "&requirePhoto=true";
    }
    if(county) {
        queryUrl += "&county=" + county.trim().toLowerCase();
    }
    if(municipality) {
        queryUrl += "&municipality=" + municipality.trim().toLowerCase();
    }

    return queryUrl;
};

/**
 * Get museum code by name
 * @param name museum name
 * @returns code for given museum
 */
export const getMuseumCode = (name: string) => {
    const museum = museums.filter(m => {return m.name.toLowerCase() === name.toLowerCase();});
    if(museum.length > 0) {
        return museum[0].code;
    }

    return name;
};

/**
 * Get museum name by code
 * @param code museum code
 * @returns name for given museum
 */
export const getMuseumName = (code: string) => {
    const museum = museums.filter(m => {return m.code === code;});
    if(museum.length > 0) {
        return museum[0].name;
    }

    return code;
};

/**
 * Get WKID for projection description
 * @param projection projection string to convert
 * @returns WKID for given projection
 */
export const getWKID = (projection: string): number => {
    switch(projection) {
        case "EU89-UTM; Sone 31":
            return 25831;
        case "EU89-UTM; Sone 32":
            return 25832;
        case "EU89-UTM; Sone 33":
            return 25833;
        case "EU89-UTM; Sone 34":
            return 25834;
        case "EU89-UTM; Sone 35":
            return 25835;
        case "EU89-UTM; Sone 36":
            return 25836;
        default:
            return 25833;
    }
};

/**
 * Order materials according to their given sorting-value
 * @param materials list of materials to sort
 * @returns ordered list of materials
 */
export const orderMaterials = (materials: Array<Material>): string => {
    let orderedMaterials = materials.slice().sort((a, b) => a.sorting - b.sorting);

    return orderedMaterials.map(m => { 
        return capitalize(m.material) + (m.spesMaterial ? " - " + m.spesMaterial : "");
    }).join(", ");
};

/**
 * Filter list of photos to only return unique
 * @param photos list of photos
 * @returns list of unique photos
 */
export const uniquePhotos = (photos: Array<Photo>): Array<Photo> => {
    let uniquePhotos: Array<Photo> = [];

    photos.forEach(p => {
        const alreadyAdded = uniquePhotos.filter(u => {return (u.imageUrl === p.imageUrl)});
        if(alreadyAdded.length === 0) {
            uniquePhotos.push(p);
        }
    });

    return uniquePhotos;
};

/**
 * Get list of artefacts related to a photo
 * @param res one photo result for a given artefact
 * @param thingUid GUID of current artefact
 * @returns list of other artefacts also related to the photo
 */
export const getOtherThings = (res: Photo, thingUid: string): Array<ArtefactResult> => {
    let otherThings: Array<ArtefactResult> = [];

    res.things.forEach(t => {
        if(t.thingUuid !== thingUid) {
            const alreadyAdded = otherThings.filter(u => {return (u.thingUuid === t.thingUuid)});
            if(alreadyAdded.length === 0) {
                otherThings.push(t);
            }
        }
    });

    return otherThings;
};