/* Main App component */
import React from "react";

import ADEDMap from "./map/adedmap";
import Loading from "./app/loading/loading";
import Alert from "./app/alert/alert";
import Info from "./info/info";
import LeftMenu from "./leftmenu/leftmenu";
import HeaderView from "./app/header/headerview";
import Import from "./app/dialogs/import";
import { AuthService } from "../services/authorisationservice";

import "antd/dist/antd.css";
import "./App.css";
import "./ant-d-overrides.css";
import UserAdmin from "./app/dialogs/useradmin";
import Export from "./app/dialogs/export";

interface AppProps {
    authService: AuthService
};

const App: React.FC<AppProps> = (props: AppProps) => {
    return (
        <div className="app">
            <HeaderView authService={props.authService} />
            <LeftMenu />
            <div className="content-container">
            {/* Consider getting the aded_id env var to add this back in for dev and test */}
            {/* <div id="alert-testbase">TESTBASE</div> */}    
                <ADEDMap />
                <Info />
            </div>
            <Loading />
            <Alert />
            <Import />
            <Export />
            <UserAdmin />
        </div>
    );
}

export default App;
