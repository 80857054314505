/* Utils class for generating Map Layer objects from config items */
import Layer from "@arcgis/core/layers/Layer";
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import WMSSublayer from "@arcgis/core/layers/support/WMSSublayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import ActionButton from "@arcgis/core/support/actions/ActionButton";
import Collection from "@arcgis/core/core/Collection";

import { LayerInfo } from "../types";
import * as constants from "../constants";
import { clusterRenderer, projectPointClusterRenderer, resultLineRenderer, resultPointRenderer, resultPolygonRenderer } from "../config/MapConfig";

import flagImg from "../images/flag.png";
// import zoomImg from "../images/zoom.png";
import layersImg from "../images/layers_wobg.png";

interface WMSSublayerGFI extends WMSSublayer {
    featureInfoFormat?: string
};

/**
 * Get popuptemplate
 * @param title title of popup
 * @param content content of popup
 * @returns popuptemplate with given title and content
 */
export const getPopupTemplate = (title: string, content: string): PopupTemplate => {
    return new PopupTemplate({
        title: title,
        content: content
    });
}

/**
 * Get list of Layer objects from config items
 * @param layerInfos list of config items
 * @returns list of Layers
 */
export const getLayers = (layerInfos: Array<LayerInfo>): Array<Layer> => {
    let layers = layerInfos.map((info) => { return getLayer(info); });

    let noNulls = layers.filter((layer) => { return layer !== null; });
    return noNulls as Array<Layer>;
};

/**
 * Get Layer object from config item
 * @param layerInfo config item
 * @returns Layer or null if unknown layer type in config item
 */
export const getLayer = (layerInfo: LayerInfo): Layer | null => {
    switch(layerInfo.type) {
        case constants.LAYER_TYPE.WMS:
            return _getWMSLayer(layerInfo);
        case constants.LAYER_TYPE.FEATURE:
            return _getFeatureLayer(layerInfo);
        case constants.LAYER_TYPE.GEOJSON:
            return _getGeoJSONLayer(layerInfo);
        case constants.LAYER_TYPE.VECTORTILE:
            return _getVectorTileLayer(layerInfo);
        case constants.LAYER_TYPE.GRAPHIC:
            return _getGraphicsLayer(layerInfo);
        case constants.LAYER_TYPE.GROUP:
            return _getGroupLayer(layerInfo);
        case constants.LAYER_TYPE.IMAGE:
            return _getImageLayer(layerInfo);
        case constants.LAYER_TYPE.TILE:
            return _getTileLayer(layerInfo);
        default: 
            return null;
            //throw "not implemented";
    }
};

/**
 * Set properties for GetFeatureInfo-requests for WMSLayer
 * @param wmsLayer layer object to set properties for
 */
export const setWMSGFIProps = (wmsLayer:WMSLayer) => {
    if(wmsLayer.sublayers && wmsLayer.featureInfoFormat) {
        const fif:string = wmsLayer.featureInfoFormat;
        wmsLayer.sublayers.forEach((s: WMSSublayer) => {
            _handleSublayer(s, fif);
        });
    }
};

// private
const _setCommonProperties = (layer: Layer, layerInfo: LayerInfo): Layer => {
    layer.id = layerInfo.id;
    layer.title = layerInfo.caption || layerInfo.id;

    if(layerInfo.hidden === true) {
        layer.visible = false;
    }
    if(layerInfo.opacity) {
        layer.opacity = layerInfo.opacity;
    }

    return layer;
};

const _getFeatureLayer = (layerInfo: LayerInfo): FeatureLayer => {
    const clusterConfig:any = {
        type: "cluster",
        clusterRadius: "100px",
        popupTemplate: {
          content: "Denne klyngen representerer {cluster_count} treff.<br/> Zoom inn for å se detaljer."
        },
        clusterMinSize: "24px",
        clusterMaxSize: "60px",
        // labelingInfo: [{
        //     // turn off deconfliction to ensure all clusters are labeled
        //     deconflictionStrategy: "none",
        //     labelExpressionInfo: {
        //       expression: "Text($feature.cluster_count, '#,###')"
        //     },
        //     symbol: {
        //       type: "text",
        //       haloColor: "#6e706e",
        //       haloSize: "1px",
        //       color: "#ffffff",
        //       font: {
        //         weight: "bold",
        //         family: "Noto Sans",
        //         size: "12px"
        //       }
        //     },
        //     labelPlacement: "center-center",
        // }]
    };

    let layer = new FeatureLayer({
        url: layerInfo.url,
        popupEnabled: true
    });

    if(layerInfo.enableClustering === true) {
        layer.featureReduction = clusterConfig;
        layer.renderer = projectPointClusterRenderer;
    }

    if(layerInfo.useADEDTemplate) {
        let template:PopupTemplate;
        if(layerInfo.id.indexOf("_flater") > -1) {
            template = _getADEDTemplate("", "objekt_hoved_type", "objekt_type", "input_id", "kommune", "fylke", "SHAPE_Area", "Areal (kvm)");
        }
        else if(layerInfo.id.indexOf("_linjer") > -1) {
            template = _getADEDTemplate("", "objekt_hoved_type", "objekt_type", "input_id", "kommune", "fylke", "SHAPE_Length", "Lengde (m)");
        }
        else {
            template = _getADEDTemplate("", "objekt_hoved_type", "objekt_type", "input_id", "kommune", "fylke");
        }

        layer.popupTemplate = template
        layer.outFields = ["*"];
    }
    if(layerInfo.maxScale) {
        layer.maxScale = layerInfo.maxScale
    }
    if(layerInfo.minScale) {
        layer.minScale = layerInfo.minScale
    }

    return _setCommonProperties(layer, layerInfo) as FeatureLayer;
};

const _getWMSLayer = (layerInfo: LayerInfo): WMSLayer => {
    let layer = new WMSLayer({
        url: layerInfo.url,
        legendEnabled: true
    });

    if(layerInfo.url && layerInfo.featureInfoFormat) {
        layer.featureInfoUrl = layerInfo.url;
        layer.featureInfoFormat = layerInfo.featureInfoFormat;
    }

    return _setCommonProperties(layer, layerInfo) as WMSLayer;
};

const _getGeoJSONLayer = (layerInfo: LayerInfo): GeoJSONLayer => {
    const clusterConfig:any = {
        type: "cluster",
        clusterRadius: "100px",
        popupTemplate: {
          // cluster_count is an aggregate field indicating the number of features summarized by the cluster
          content: "Denne klyngen representerer {cluster_count} treff.<br/> Zoom inn for å se detaljer."
        },
        clusterMinSize: "24px",
        clusterMaxSize: "60px",
        // labelingInfo: [{
        //     // turn off deconfliction to ensure all clusters are labeled
        //     deconflictionStrategy: "none",
        //     labelExpressionInfo: {
        //       expression: "Text($feature.cluster_count, '#,###')"
        //     },
        //     symbol: {
        //       type: "text",
        //       haloColor: "#6e706e",
        //       haloSize: "1px",
        //       color: "#ffffff",
        //       font: {
        //         weight: "bold",
        //         family: "Noto Sans",
        //         size: "12px"
        //       }
        //     },
        //     labelPlacement: "center-center",
        // }]
    };

    let layer = new GeoJSONLayer({
        url: layerInfo.url
    });

    let template:PopupTemplate;
    if(layerInfo.enableClustering === true) {
        layer.featureReduction = clusterConfig;
        layer.renderer = clusterRenderer;
        template = _getADEDTemplate("Søketreff: ", "ObjektHovedType", "ObjektType", "InputId", "Kommuner", "Fylker");
    }
    else if(layerInfo.featuretype === "polygon") {
        layer.renderer = resultPolygonRenderer;
        template = _getADEDTemplate("Søketreff: ", "ObjektHovedType", "ObjektType", "InputId", "Kommuner", "Fylker"/*, "Areal", "Areal (kvm)"*/);
    }
    else if(layerInfo.featuretype === "line") {
        layer.renderer = resultLineRenderer;
        template = _getADEDTemplate("Søketreff: ", "ObjektHovedType", "ObjektType", "InputId", "Kommuner", "Fylker"/*, "Lengde", "Lengde (m)"*/);
    }
    else {
        layer.renderer = resultPointRenderer;
        template = _getADEDTemplate("Søketreff: ", "ObjektHovedType", "ObjektType", "InputId", "Kommuner", "Fylker");
    }

    layer.popupTemplate = template;
    layer.outFields = ["*"];

    if(layerInfo.maxScale) {
        layer.maxScale = layerInfo.maxScale
    }
    if(layerInfo.minScale) {
        layer.minScale = layerInfo.minScale
    }

    return _setCommonProperties(layer, layerInfo) as GeoJSONLayer;
};

const _getGraphicsLayer = (layerInfo: LayerInfo): GraphicsLayer => {
    let layer = new GraphicsLayer();
    return _setCommonProperties(layer, layerInfo) as GraphicsLayer;
}

const _getTileLayer = (layerInfo: LayerInfo): TileLayer => {
    let layer = new TileLayer({
        url: layerInfo.url
    });

    return _setCommonProperties(layer, layerInfo) as TileLayer;
};

const _getVectorTileLayer = (layerInfo: LayerInfo): VectorTileLayer => {
    let layer = new VectorTileLayer({
        url: layerInfo.url
    });

    return _setCommonProperties(layer, layerInfo) as VectorTileLayer;
};


const _getImageLayer = (layerInfo: LayerInfo): ImageryLayer => {
    let layer = new ImageryLayer({
        url: layerInfo.url
    });

    if(layerInfo.maxScale) {
        layer.maxScale = layerInfo.maxScale
    }
    if(layerInfo.minScale) {
        layer.minScale = layerInfo.minScale
    }

    return _setCommonProperties(layer, layerInfo) as ImageryLayer;
};

const _getGroupLayer = (layerInfo: LayerInfo): GroupLayer => {
    let layers: Array<Layer> = [];
    if(layerInfo.layerInfos) {
        layerInfo.layerInfos.forEach((li: LayerInfo) => {
            const l = getLayer(li);
            if(l) {
                layers.push(l);
            }
        });
    }

    if(layerInfo.layers) {
        layers = layers.concat(layerInfo.layers);
    }

    let c = new Collection();
    c.addMany(layers);

    let layer = new GroupLayer({
        layers: c
    });

    return _setCommonProperties(layer, layerInfo) as GroupLayer;
};

const _getADEDTemplate = (extraTitleInfo: string, htKey: string, otKey: string, idKey: string, kKey: string, fKey: string, gKey?: string, gLabel?: string): PopupTemplate => {
    let btns:Array<ActionButton> = [
        new ActionButton({
            id: "show-aded",
            image: flagImg,
            title: "Vis mer"
        }),
        // new ActionButton({
        //     id: "zoom-to",
        //     image: zoomImg,
        //     title: "Zoom til"
        // }),
        new ActionButton({
            id: "show-toc",
            image: layersImg,
            title: "Kartlag"
        })
    ];

    let fieldInfos:Array<any> = [
        {
            fieldName: htKey,
            label: "Hovedtype"
        },
        {
            fieldName: "prosjektnavn",
            label: "Prosjekt"
        },
        {
            fieldName: kKey,
            label: "Kommune"
        },
        {
            fieldName: fKey,
            label: "Fylke"
        },
        {
            fieldName: "Hoh",
            label: "Høyde over havet (moh.)"
        }
    ];

    if(gKey && gLabel) {
        fieldInfos.push(
            {
                fieldName: gKey,
                label: gLabel,
                format: {places: 2}
            }
        );
    }

    let template = new PopupTemplate({
        title: extraTitleInfo + "{" + otKey + "} - {" + idKey + "}",
        actions: btns,
        // overwriteActions: true,
        content: [
          {
            type: "fields",
            fieldInfos: fieldInfos
          }
        ]
    });

    return template;
};

const _handleSublayer = (sublayer:WMSSublayerGFI, featureInfoFormat: string) => {
    sublayer.popupEnabled = sublayer.queryable;
    sublayer.featureInfoFormat = featureInfoFormat;

    if(sublayer.sublayers) {
        sublayer.sublayers.forEach((s: WMSSublayer) => {
            _handleSublayer(s, featureInfoFormat);
        });
    }
};