/* Object types for user data */
import { User } from "oidc-client";

export interface ImportJob {
    prosjektnavn: string,
    uploadedDate: string,
    status: string,
    indexerStatus: string,
    errormessage: string | null,
};

export interface ExportJob {
    queueId: number,
    exportName: string,
    date: string,
    status: string,
    errormessage: string | null,
    format: string,
    exportType: string
};

export enum RoleEnum {
    Anonym = "Anonym",
    Bruker = "Bruker",
    Saksbehandler = "Saksbehandler",
    Administrator = "Administrator"
};

export interface UserState {
    user: User | null,
    isLoadingUser: boolean,
    signin: boolean,
    error: any,
    role: RoleEnum
};