/* Dialog for user admin: Admin users can administrate user roles */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { User } from "oidc-client";
import { Button } from "antd";
import { CloseOutlined, TeamOutlined, ReloadOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import Draggable from 'react-draggable';

import { StoreState, UserRole } from "../../../types";
import { setUserAdminDialogVisible } from "../../../store/adminSlice";

import "./dialogs.css";
import EditableUser from "./editableuser";
import { getUsers, putUserRole } from "../../../services/adminservice";
import { showAlert, showLoading } from "../../../store/appSlice";
import { ALERT_TYPE } from "../../../constants";
import { orderItems } from "../../../utils";

const UserAdmin = () => {
    const dispatch = useDispatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [users, setUsers] = useState<Array<UserRole>>([]);
    const [orderBy, setOrderBy] = useState<string>("fullName");
    const [orderAttrType, setAttrType] = useState<string>("string");
    const [orderReverse, setOrderReverse] = useState<boolean>(false);

    const visible: boolean = useSelector((state: StoreState) => state.admin.dialogVisible);
    const user: User | null = useSelector((state: StoreState) => state.user.user);

    const [usersMsg, setUsersMsg] = useState<string>("Ingen brukere registrert med rolle");

    const orderUsers = (us: Array<UserRole>, by: string, attrType: string, reverse: boolean) => {
        const orderedUsers = orderItems(us, by, attrType, reverse);

        setOrderBy(by);
        setAttrType(attrType);
        setOrderReverse(reverse);

        setUsers(orderedUsers);
    };

    const getAndShowUsers = () => {
        setUsersMsg("Henter brukere...");
        dispatch(showLoading(true));

        if(user) {
            getUsers(user).then(response => {
                        
                setUsersMsg("Ingen brukere registrert med rolle");
                dispatch(showLoading(false));

                if (response.status === 200) {
                    orderUsers(response.data, orderBy, orderAttrType, orderReverse);
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente brukere", type: ALERT_TYPE.ERROR, manualClose: false}));
                }
            });
        }
    };

    useEffect(
        () => {
            if (visible && user) {
                setUsersMsg("Henter brukere...");
                dispatch(showLoading(true));

                getUsers(user).then(response => {
                    
                    setUsersMsg("Ingen brukere registrert med rolle");
                    dispatch(showLoading(false));

                    if (response.status === 200) {
                        setUsers(response.data);
                    }
                    else {
                        dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente brukere", type: ALERT_TYPE.ERROR, manualClose: false}));
                    }
                });
            }
    }, [visible]);

    const userEdited = (edited: UserRole) => {
        if(user) {
            putUserRole(user, edited.userName, edited.groupName).then(response => {
                if (response.status === 201) {
                    let existingUser = users.filter(u => { return u.userName === edited.userName });
                    if(existingUser.length > 0) {
                        existingUser[0].groupName = edited.groupName;
                        setUsers([...users.filter(u => u.userName !== edited.userName), existingUser[0]]);
                    }

                    orderUsers(users, orderBy, orderAttrType, orderReverse);
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å lagre ny rolle for bruker", type: ALERT_TYPE.ERROR, manualClose: false}));
                }
            });
        }
    };

    if(!visible) {
        return null;
    }

    if(!initialized) {
        setInitialized(true);
        getAndShowUsers();
    }

    const renderUsers = () => {
        if(users.length === 0) {
            return (
                <tr>
                    <td colSpan={4} className="empty-result">{usersMsg}</td>
                </tr>
            );
        }

        return (
            users.map((u: UserRole, i:number) => {
                return <EditableUser  key={`user_${i}`} user={u} callback={userEdited} />
            })
        );
    };

    return (
        <Draggable>
            <div className="dialog">
                <div className="dialog-header">
                    <div>
                        <TeamOutlined className="margin-right-small" />
                        Brukeradministrasjon
                    </div>
                    <Button type="link" onClick={() => dispatch(setUserAdminDialogVisible(!visible))} >
                        <CloseOutlined />
                    </Button>
                </div>
                <div className="dialog-body">
                    <div>
                        Her ser du en oversikt over alle registrerte brukere, og du kan gi en bruker andre rettigheter. <br />
                        Ser du ikke brukeren i lista, må du be vedkommende logge inn i applikasjonen èn gang først.
                    </div>
                    <div className="margin-top margin-left">
                        <Button type="link" title="Oppdater oversikten" onClick={() => getAndShowUsers()} style={{padding: 0}} >
                            <ReloadOutlined />
                            <span className="margin-left-small">Oppfrisk</span>
                        </Button>
                    </div>
                    <table className="dialog-table">
                        <thead>
                            <tr>
                                <th>
                                    BRUKERNAVN
                                    <Button className="margin-left" type="link" title="Sorter synkende på brukernavn" onClick={() => orderUsers(users, "userName", "string", false)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "userName" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på brukernavn" onClick={() => orderUsers(users, "userName", "string", true)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "userName" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th>
                                    NAVN
                                    <Button className="margin-left" type="link" title="Sorter synkende på navn" onClick={() => orderUsers(users, "fullName", "string", false)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "fullName" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på navn" onClick={() => orderUsers(users, "fullName", "string", true)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "fullName" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th>
                                    ROLLE
                                    <Button className="margin-left" type="link" title="Sorter synkende på rolle" onClick={() => orderUsers(users, "groupName", "string", false)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "groupName" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på rolle" onClick={() => orderUsers(users, "groupName", "string", true)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "groupName" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderUsers()}
                        </tbody>
                    </table>
                </div>
            </div>
        </Draggable>
    );
};

export default UserAdmin;