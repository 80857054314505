/* UI for saving and retrieving ADED data queries */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "oidc-client";
import { Button, Checkbox, Input, Popconfirm } from "antd";
import { PlusCircleFilled, ShareAltOutlined, DeleteOutlined } from "@ant-design/icons";

import { Filter, RoleEnum, StoreState, UserSearchQuery } from "../../../types";
import { getSearchResult, applySavedSearch, setUrlToShare, setShareDialogVisible, setGeoBBox } from "../../../store/searchSlice";
import { extractGeoBBoxFromSearchQueryObject, extractSearchFiltersFromSearchQueryObject, extractSearchPhraseFromSearchQueryObject, hasActiveFilters } from "../../../utils/searchHelper";
import { showAlert, showLoading } from "../../../store/appSlice";
import { ADED, ALERT_TYPE, PUBLIC_USER } from "../../../constants";
import { deleteUserQuery, getSharedSearchQuery, postUserQuery, shareSearchQuery } from "../../../services/userservice";
import { appendToUserSearchQueries, removeFromUserSearchQueries, setQuerySharedAs, setSelectedSearchQuery } from "../../../store/exportSlice";
import { HasAdministratorPrivileges } from "../../../utils/userHelper";
import { getActiveFilterValues } from "../../../utils/adedHelper";
import Share from "../../app/dialogs/share";
import { getUrlWithoutRoute } from "../../../utils";

import starImg from "../../../images/star.png";
import heartImg from "../../../images/heart.png";
import "./savesearch.css";
import "../../app/dialogs/dialogs.css";

const SaveADEDSearch: React.FC = () => {
    const dispatch = useDispatch();

    const sharedUrlKey = "shared";
    
    const [showSavedSearch, setShowSavedSearch] = useState<boolean>(false);
    const [selectedSavedSearchType, setSelectedSavedSearchType] = useState<string>("");
    const [visibleSaveSearchModal, setVisibilitySaveSearchModal] = useState<boolean>(false);
    const [initialSavedSearch, setInitialSavedSearch] = useState<UserSearchQuery | null>(null);

    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [popupType, setPopupType] = useState<string>("");
    const [popupTitle, setPopupTitle] = useState<string>("");
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [selectedSearch, setSelectedSearch] = useState<UserSearchQuery | null>(null);

    const [customName, setCustomName] = useState<string>(""); // for use when saving search
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);

    const user: User | null = useSelector((state: StoreState) => state.user.user);
    const role: RoleEnum = useSelector((state: StoreState) => state.user.role);
    const mapReady: boolean = useSelector((state: StoreState) => state.map.mapReady);
    const leftMenuTopVisible: boolean = useSelector((state: StoreState) => state.app.leftMenuTopVisible);
    const userSearchQueries: Array<UserSearchQuery> = useSelector((state: StoreState) => state.export.userSearchQueries);
    const publicSearchQueries: Array<UserSearchQuery> = useSelector((state: StoreState) => state.export.publicSearchQueries);
    const resultFilters: Array<Filter> = useSelector((state: StoreState) => state.search.resultFilters);
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryADED);
    const selectedSavedSearch: UserSearchQuery | null = useSelector((state: StoreState) => state.export.selectedSearchQuery);
    const geoBBox: Array<number> | null = useSelector((state: StoreState) => state.search.geoBBox);

    useEffect(() => {
        if(window.location.search) {
            let parts: Array<string> = window.location.search.replace("?", "").split("&");
            let keyvals: Array<any> = parts.map(p => {return p.split("=")});
            keyvals.forEach(kv => {
                if(kv[0] === sharedUrlKey && kv.length === 2) {
                    getSharedSearchQuery(kv[1]).then((response: any) => {
                        if (response.status === 200) {
                            if(resultFilters.length > 0) { // Check filters loaded
                                handleOpenSavedSearchQuery(response.data);
                            }
                            else { // add saved search to state if filters not ready
                                setInitialSavedSearch(response.data);
                            }
                        }
                        else {
                            dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente søket", type: ALERT_TYPE.ERROR, manualClose: false}));
                        }
                    });
                }
            });
        }
    }, []); // empty dependency array = execute only once
    
    useEffect(
        () => {
            if(resultFilters.length > 0 && initialSavedSearch && mapReady) {
                handleOpenSavedSearchQuery(initialSavedSearch);
                setInitialSavedSearch(null);
            } 

            if (searchQuery) {
                setCanSave(true);
            }
            else {
                const activeValues = getActiveFilterValues(resultFilters);
                if(activeValues.length > 0) {
                    setCanSave(true);
                }
                else {
                    setCanSave(false);
                }
            }
        }, [resultFilters, searchQuery]);

    useEffect(
        () => {
            if(resultFilters.length > 0 && initialSavedSearch && mapReady) {
                handleOpenSavedSearchQuery(initialSavedSearch);
                setInitialSavedSearch(null);
            } 
        }, [mapReady]);

    const toggleShow = (typeToShow: string) => {
        if(selectedSavedSearchType === typeToShow) {
            setShowSavedSearch(!showSavedSearch);
        }
        else {
            setSelectedSavedSearchType(typeToShow);
            setShowSavedSearch(true);
        }
    };

    // Open saved search
    const handleOpenSavedSearchQuery = (s: UserSearchQuery) => {
        const queryObject = JSON.parse(s.query);

        const searchPhrase = extractSearchPhraseFromSearchQueryObject(queryObject);
        if (searchPhrase) {
            dispatch(showLoading(true));
            dispatch(getSearchResult({type: ADED, phrase: searchPhrase}));
        }
        
        const filters = extractSearchFiltersFromSearchQueryObject(queryObject);

        const geobbox = extractGeoBBoxFromSearchQueryObject(queryObject);
        if(geobbox) {
            setGeoBBox(geobbox);
        }

        dispatch(setSelectedSearchQuery(s));
        dispatch(applySavedSearch({filters, searchPhrase, geobbox}));
    };

    const canOverwriteSelectedSearch = ():boolean => {
        if(!selectedSavedSearch) {
            return false;
        }
        if(selectedSavedSearch.user === PUBLIC_USER && !HasAdministratorPrivileges(role)) {
            return false;
        }

        return true;
    };

    // Save new search
    const saveHandler = () => {
        if(selectedSavedSearch) {
            setCustomName(selectedSavedSearch.queryName);
            if(selectedSavedSearch.user === PUBLIC_USER && HasAdministratorPrivileges(role)) {
                setIsPublic(true);
            }
            else {
                setIsPublic(false);
            }
        }

        setVisibilitySaveSearchModal(true);
        setTimeout(() => {
            const focusElement = document.getElementById("saveSearchName");
            if(focusElement) {
                (focusElement as HTMLElement).focus();
            }
        }, 300);
    };

    const handleSaveOk = (): void => {
        postUserQuery(user as User, resultFilters, searchQuery, customName, isPublic, geoBBox).then((response: any) => {
            if (response.status === 201) {
                dispatch(appendToUserSearchQueries({isPublic: isPublic, response: response.data}));
            }
            else {
                dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å lagre søket", type: ALERT_TYPE.ERROR, manualClose: false}));
            }
        });

        setVisibilitySaveSearchModal(false);
    }

    const handleSaveCancel = (): void => {
        setVisibilitySaveSearchModal(false);
    }

    const handlePopupOk = () => {
        if(popupType === "delete") {
            handleDeleteOk();
        }
        else {
            handleOpenOk();
        }
    };

    const handlePopupCancel = () => {
        setPopupVisible(false);
    };

    const setVisibleOpenPopup = (s: UserSearchQuery): void => {
        if (hasActiveFilters(resultFilters)) {
            setSelectedSearch(s);
            setPopupType("open");
            setPopupTitle("For å se et lagret søk må søkekriteriene nullstilles");
            setPopupVisible(true);
        }
        else {
            setPopupVisible(false);
            handleOpenSavedSearchQuery(s);
        }
    };
 
    const handleOpenOk = () => {
        setConfirmLoading(true);
        if(selectedSearch) {
            handleOpenSavedSearchQuery(selectedSearch);
        }
        setTimeout(() => {
            setPopupVisible(false);
            setConfirmLoading(false);
            setSelectedSearch(null);
        }, 2000);
    };

    // Delete saved search
    const setVisibleDeletePopup = (s: UserSearchQuery): void => {
        setSelectedSearch(s);
        setPopupType("delete");
        setPopupTitle("Vil du slette dette søket?");
        setPopupVisible(true);
    };

    const handleDeleteOk = (): void => {
        if(selectedSearch) {
            deleteUserQuery(user as User, selectedSearch.queryName, selectedSavedSearchType === PUBLIC_USER).then((response: any) => {
                if (response.status === 204) {
                    dispatch(removeFromUserSearchQueries({queryName: selectedSearch.queryName, isPublic: (selectedSavedSearchType === PUBLIC_USER)}));
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å slette søket", type: ALERT_TYPE.ERROR, manualClose: false}));
                }
            });
        }

        setPopupVisible(false);
    };

    const showShareUrl = (searchUID: string) => {
        const url = getUrlWithoutRoute();

        const searchUrl = url + "?" + sharedUrlKey + "=" + searchUID;
        dispatch(setUrlToShare(searchUrl));
        dispatch(setShareDialogVisible(true));
    };

    const share = (s: UserSearchQuery): void => {
        if(s.sharedAs) {
            showShareUrl(s.sharedAs);
        }
        else {
            shareSearchQuery(user as User, s).then((response: any) => {
                if (response.status === 200) {
                    showShareUrl(response.data.sharedAs);
                    dispatch(setQuerySharedAs(response.data));
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å dele søket", type: ALERT_TYPE.ERROR, manualClose: false}));
                }
            });
        }
    };

    const renderSavedSearch = ((x: UserSearchQuery) => {
        return (
            <div key={x.queryName} className="search-row" >
                <Button type="link" size="small" onClick={() => setVisibleOpenPopup(x)} title={`Utfør søk '${x.queryName}'`}>
                    <div>{ x.queryName }</div>
                </Button>
                <div className="search-row-btns">
                    <Button type="link" size="small" onClick={() => setVisibleDeletePopup(x)} title={`Slett '${x.queryName}'`}
                            className={(selectedSavedSearchType !== PUBLIC_USER || HasAdministratorPrivileges(role)) ? "" : "remove-element"} >
                        <DeleteOutlined />
                    </Button>
                    <Button type="link" size="small" onClick={() => share(x)} title={`Del '${x.queryName}'`} 
                            className={selectedSavedSearchType === PUBLIC_USER ? "remove-element" : ""}>
                        <ShareAltOutlined />
                    </Button>
                </div>
            </div>
        );
    });

    const renderSavedSearches = () => {
        if(selectedSavedSearchType === PUBLIC_USER) {
            return publicSearchQueries.map((x: UserSearchQuery) => {
                return renderSavedSearch(x);
            });
        }
        else {
            return userSearchQueries.map((x: UserSearchQuery) => {
                return renderSavedSearch(x);
            });
        }
    }

    return (
        <div className="save-search-container">
            <div className={leftMenuTopVisible ? "" : "remove-element"}>
                {user && <div>
                    <div className="save-search-button">
                        <Button type="link" onClick={() => saveHandler()} title="Lagre søk" disabled={!canSave} >
                            <PlusCircleFilled />
                            <span style={{marginLeft: "11px"}}>Lagre dette søket</span>
                        </Button>
                    </div>

                    <div className="dialog" style={{ visibility: visibleSaveSearchModal ? 'visible' : 'hidden' }}>
                        <div className="dialog-header">
                            Lagre søk
                        </div>
                        <div className="dialog-body">
                            <Input id="saveSearchName" onChange={(e) => setCustomName(e.target.value)} value={customName} placeholder="Navn på lagret søk" 
                                   required autoFocus maxLength={100} />
                            {canOverwriteSelectedSearch() && 
                                <div className="margin-top margin-left">
                                    Overskrive lagret søk? Hvis ikke - gi nytt navn.
                                </div>
                            }
                            <div className="dialog-btn-container">
                                {HasAdministratorPrivileges(role) &&
                                    <Checkbox onChange={(e) => setIsPublic(e.target.checked)} checked={isPublic}>
                                        <span>Tilgjengelig for alle</span>
                                    </Checkbox>
                                }
                                <Button className="margin-right" onClick={() => handleSaveCancel()}>Avbryt</Button>
                                <Button onClick={() => handleSaveOk()} disabled={customName === ""}>Ok</Button>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className={`saved-search ${user ? "" : "margin-bottom"}`}>
                    <div className="saved-search-header">
                        <Button className={(showSavedSearch && selectedSavedSearchType === PUBLIC_USER) ? "selected" : ""}
                                onClick={() => toggleShow(PUBLIC_USER)} >
                            <img src={starImg} alt="Stjerne" title="Se forhåndsdefinerte søk" />
                            <span className="margin-left-small">Populære søk</span>
                        </Button>
                        {user &&
                            <Button className={(showSavedSearch && selectedSavedSearchType !== PUBLIC_USER) ? "selected" : ""}
                                    onClick={() => toggleShow("mine")} >
                                <img src={heartImg} alt="Hjerte" title="Se mine lagrede søk" />
                                <span className="margin-left-small">Mine søk</span>
                            </Button>
                        }
                    </div>
                    <div className={`saved-search-content ${showSavedSearch ? "" : "remove-element"}`}>
                        <Popconfirm
                            title={popupTitle}
                            open={popupVisible}
                            onConfirm={handlePopupOk}
                            okButtonProps={{ loading: confirmLoading }}
                            onCancel={handlePopupCancel}
                            cancelText="Avbryt"
                            placement="right"
                        ></Popconfirm>
                        {renderSavedSearches()}
                    </div>
                </div>
            </div>
            <Share />
        </div>
    );
}

export default SaveADEDSearch;
