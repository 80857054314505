/* Utils class calling services for search */
import axios from "axios";
import { SearchResult, Filter, aggsObject, queryObject, Bucket, SortField } from "../types";
import { ADED, Artefact, MapHit, BUCKET_SIZE } from "../constants";
import { ADEDBuckets } from "../config/ADEDConfig";
import { getMainQueryPart } from "../utils/adedHelper";

export default class SearchService {
    
    /**
     * Search for artefacts or locations
     * @param queryUrl url to query
     * @param type type of query - Artefact or location
     * @returns result of given type or error
     */
    static get(queryUrl: string, type: Artefact | MapHit): Promise<SearchResult> {
        return new Promise((resolve, reject) => {
            axios.get(queryUrl)
            .then(function (response) {
                resolve({type: type, result: response.data, error: null});
            })
            .catch(function (error) {
                resolve({type: type, result: [], error: type + " search failed"});
            })
            .then(function () {
                // always executed
            });
        });
    };

    /**
     * Get contents for all configured filter categories from ADED search engine
     * @param queryUrl url to query
     * @param un username for search auth
     * @param pwd password for search auth
     * @returns current content for filter categories
     */
    static postBucketsOnly(queryUrl: string, un: string, pwd: string): Promise<SearchResult> {
        const aggs = getAggsObjekt();

        let postQueryObject: queryObject = {
            "size": 0,
            "from": 0,
            "track_total_hits": true,
            "sort": {},
            "query": { "match_all": {} },
            "aggs": aggs
        };

        let headers:any = {};
        if(un && pwd) {
            headers = { auth: {
                username: un,
                password: pwd
            }};
        }

        return new Promise((resolve, reject) => {
            axios.post(queryUrl, postQueryObject, headers)
            .then(function (response) {
                resolve({type: ADED, result: response.data, error: null});
            })
            .catch(function (error) {
                resolve({type: ADED, result: [], error: error});
            })
            .then(function () {
                // always executed
            });
        });
    };

    /**
     * Search for ADED objects using string, filters and/or geographic bounds
     * @param queryUrl url to query
     * @param resultFilters current active filters
     * @param queryValue string to search for
     * @param maxHits max number of hits returned
     * @param currentPage index to start returning from if more than maxHits
     * @param sortField json indicating which attribute to sort by
     * @param un username for search auth
     * @param pwd password for search auth
     * @param geobbox boundingbox for geographic limit
     * @returns ADED objects matching query elements
     */
    static post(queryUrl: string, resultFilters: Array<Filter>, queryValue: string, maxHits: number, currentPage: number, sortField: SortField, un: string, pwd: string, geobbox: Array<number> | null): Promise<SearchResult> {
        const mainQuery = getMainQueryPart(resultFilters, queryValue);

        if(geobbox && geobbox.length === 4) {
            const bb = {
                "geo_bounding_box": {
                    "CenterPoint": {
                        "top_left": {
                            "lat": geobbox[3].toString(),
                            "lon": geobbox[0].toString()
                        },
                        "bottom_right": {
                            "lat": geobbox[1].toString(),
                            "lon": geobbox[2].toString()
                        }
                    }
                }
            };
        
            mainQuery.bool.filter.push(bb);
        }

        const aggs = getAggsObjekt();

        let headers:any = {};
        if(un && pwd) {
            headers = { auth: {
                username: un,
                password: pwd
            }};
        }

        // let sorttypestring = (sorttype ? { "Prosjektnavn.keyword" : {"order": "asc"}} : {});
        //Prosjektnavn OK, ObjektHovedType OK
        var sortquery = {};
        if (sortField.field !== ""){
            (sortquery as any)[sortField.field + ".keyword"] = {order: sortField.order}; //order er alltid "asc" eller "desc"
        }

        //console.log(sortquery)
        // alert(JSON.stringify(sortquery))

        let postQueryObject: queryObject = { // ignore query if it is empty
            "size": maxHits,
            "from": ((currentPage - 1) * maxHits),
            "track_total_hits": true,
            "sort": sortquery,
            "query": mainQuery,
            "aggs": aggs
        };

        return new Promise((resolve, reject) => {
            axios.post(queryUrl, postQueryObject, headers)
            .then(function (response) {
                //console.log(response);
                resolve({type: ADED, result: response.data, error: null});
            })
            .catch(function (error) {
                console.log(error);
                
                resolve({type: ADED, result: [], error: error});
            })
            .then(function () {
                console.log("always executed");
                // always executed
            });
        });
    };
};

// private
const getAggsObjekt = () => {
    let aggs: aggsObject = {};

    ADEDBuckets.forEach((bucket: Bucket) => {
        if(!bucket.range) {
            let bDef:any = {
                "terms": {
                    "field": bucket.field + (bucket.keyword ? ".keyword" : ""),
                    "size": BUCKET_SIZE
                }
            };

            if(bucket.buckets) {
                let a2: aggsObject = {};
                bucket.buckets.forEach((b2: Bucket) => {
                    a2[b2.field + "_bucket"] = {
                        "terms": {
                            "field": b2.field + (b2.keyword ? ".keyword" : ""),
                            "size": BUCKET_SIZE
                        }
                    }
                });
                bDef.aggs = a2;
            }

            aggs[bucket.field + "_bucket"] = bDef;
        }
    });

    return aggs;
}