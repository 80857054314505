/* Using Kulturminnesøk (https://kulturminnesok.no) to search for and display kulturminner related to the objects */
import { useEffect, useState } from "react";
import { Button, Collapse } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import * as conf from '../../../../config/SearchConfig';
import LookupData from "../../../../services/lookupservice";
import { showAlert } from "../../../../store/appSlice";
import { ALERT_TYPE } from "../../../../constants";
import { isCanceled, useCancellablePromise } from "../../../../utils/useCancelablePromise";

import kulturminneImg from "../../../../images/kulturminne.png";
import "./kulturminner.css";

interface myProps {
    lokaliteter: Array<string>;
}

const Kulturminner = (props: myProps) => {
    const dispatch = useDispatch();
    const { cancellablePromise } = useCancellablePromise();
    const { Panel } = Collapse;
    const idAttrKey = "lokalId";

    const [expanded, setExpanded] = useState<boolean>(true);
    const [lokalitetIDer, setLokalitetIDer] = useState<Array<string>>([]);
    const [kulturminner, setKulturminner] = useState<Array<any>>([]);
    const [unmatchedLokalitetIDer, setUnmatchedLokalitetIDer] = useState<Array<string>>([]);

    const performQueries = () => {
        const queries = props.lokaliteter.map(l => {
            const q = conf.KulturminneUrl + conf.Lokaliteter + "/query?where=lokalId=" + l;
            return cancellablePromise(LookupData.getKulturminne(q));
        });

        const load = async () => {
            try {
                const response = await Promise.all(queries);

                const res2 = response.map(r => { return r.result as Array<any>}).flat(1);
                const res3 = res2.map(r => { return r.features }).flat(1);
                if(res3) {

                    // Check whether response belongs to current list of lokaliteter
                    const unmatching = res3.filter(r => {
                        return props.lokaliteter.indexOf(r.attributes[idAttrKey].toString()) === -1;
                    });
                    if(unmatching.length === 0) {
                        setKulturminner(res3);
                    }

                    // Check whether all lokaliteter was found
                    const missing = props.lokaliteter.filter(l => {
                        return res3.filter(r => {
                            return r.attributes[idAttrKey].toString() === l;
                        }).length === 0;
                    });
                    setUnmatchedLokalitetIDer(missing);
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente kulturminner.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            } catch (error) {
                if (!isCanceled(error)) {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente kulturminner.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            }
        };
        load();
    };

    useEffect(
        () => {
            if(props.lokaliteter) {
                // Check whether array of lokaliteter has changed since previous render cycle
                let equal = true;
                props.lokaliteter.forEach(l => {
                    if(lokalitetIDer.indexOf(l) === -1) {
                        equal = false;
                    }
                });
        
                if(!equal) {
                    setKulturminner([]);
                    setUnmatchedLokalitetIDer([]);
                    setLokalitetIDer(props.lokaliteter);
                    performQueries();
                }
            }
    }, []);

    const renderKulturminne = (km: any) => {
        return (
            <div className="margin-top">
                <div>
                    <b>Kategori:</b> {km.attributes["kulturminneKategori"] || "-"}
                </div>
                <div>
                    <b>Art:</b> {km.attributes["kulturminneLokalitetArt"] || "-"}
                </div>
                <div>
                    <b>Navn:</b> {km.attributes["navn"] || "-"}
                </div>
                <div className="margin-top">
                    {km.attributes["informasjon"]}
                </div>
                <div>
                    <Button type="link" title="Ekstern lenke - se lokaliteten i kulturminnesøk" target="_blank" rel="noopener noreferrer" style={{paddingLeft: "0"}}
                            href={"https://kulturminnesok.no/minne/?queryString=https://data.kulturminne.no/askeladden/lokalitet/" + km.attributes[idAttrKey]} >
                        Se mer i Kulturminnesøk
                    </Button>
                </div>
            </div>
        );
    }

    const renderPanelHeader = (lokalId: string) => {
        return (
            <span>
                <img src={kulturminneImg} alt="Kulturminne" />
                <span className="margin-left-small">{ lokalId }</span>
            </span>
        )
    }

    const renderKulturminner = () => {
        if(kulturminner.length === 0) {
            return <div></div>;
        }

        if(kulturminner.length === 1) {
            return (
                <div>
                    <div className="kulturminne-header">
                        {renderPanelHeader(kulturminner[0].attributes[idAttrKey])}
                    </div>
                    {renderKulturminne(kulturminner[0])}
                </div>
            );
        }

        return (
            <Collapse expandIconPosition="end">
                {kulturminner.map((km: any, i: number) => {
                    return (
                        <Panel header={renderPanelHeader(km.attributes[idAttrKey])} key={"kmPanel_" + i}>
                            <div className="margin-left margin-bottom">
                                { renderKulturminne(km) }
                            </div>
                        </Panel>
                    );
                })}
            </Collapse>
        )
    };

    const renderUnmatchedLokaliteter = () => {
        if(unmatchedLokalitetIDer.length === 0) {
            return <div></div>;
        }

        return (
            <div className="empty-result">
                <div>Finner ingen informasjon om disse:</div>
                {unmatchedLokalitetIDer.map((l: string, i: number) => {
                    return (
                        <Button key={`missingLokID${i}`} type="link" title="Ekstern lenke - se lokaliteten i kulturminnesøk" target="_blank" rel="noopener noreferrer" style={{paddingLeft: "0"}}
                                href={"https://kulturminnesok.no/minne/?queryString=https://data.kulturminne.no/askeladden/lokalitet/" + l} >
                            {l}
                        </Button>
                    );
                })}
            </div>
        )
    };

    return (
        <div className="details-table-wrapper">
            <div className="details-table-container w60p">
                <div className={`details-table-container-header ${expanded ? "" : "white"}`}>
                    Kulturminner
                    <Button type="link" onClick={() => setExpanded(!expanded)} >
                        {expanded && <MinusCircleFilled /> }
                        {!expanded && <PlusCircleFilled /> }
                    </Button>
                </div>
                { expanded && 
                    <div className="kulturminner-container">
                        { renderKulturminner() }
                        { renderUnmatchedLokaliteter() }
                    </div>
                }
            </div>
        </div>
    );
};

export default Kulturminner;
