/* List of ADED objects results from a search */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { CloseCircleFilled, DownOutlined, UpOutlined } from "@ant-design/icons";

import { ADEDResult, Filter, SortField, StoreState } from "../../../types";
import { getActiveFilters, replaceInType } from "../../../utils/adedHelper";
import { setLocation } from "../../../store/mapSlice";
import { Paging } from "./paging";
import { setFirstTime, showDisplayType } from "../../../store/appSlice";
import { ADED, DISPLAY_TYPE, RANGE_FILTER } from "../../../constants";
import { getSearchResult, resetADED, setAdedPanelsOpen, setGeoBBox, toggleFilter } from "../../../store/searchSlice";
import { setSelectedSearchQuery } from "../../../store/exportSlice";
import { setSortField } from "../../../store/searchSlice";

import placeMarkerGrayImg from "../../../images/place_marker_gray_dark_big.png";
import helpGreenLargerImg from "../../../images/help_green_larger.png";
import collapseIcon from "../../../images/collapseIcon.svg";


interface myProps {
    results: Array<ADEDResult>;
    searchPhrase: string;
    noHits: number;
    lastPageNotMax: boolean;
}

export const ADEDResultItems = (props: myProps) => {
    const dispatch = useDispatch();

    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryADED);
    const resultFilters: Array<Filter> = useSelector((state: StoreState) => state.search.resultFilters);
    const geoBBox: Array<number> | null = useSelector((state: StoreState) => state.search.geoBBox);
    const sortField: SortField | null = useSelector((state: StoreState) => state.search.sortField);
  
    const clickLocation = (r: ADEDResult) => {
        const location = {
            x: r.CenterPoint && r.CenterPoint[0] ? r.CenterPoint[0] : 0,
            y: r.CenterPoint && r.CenterPoint[1] ? r.CenterPoint[1] : 0
        };
        console.log(location)
        dispatch(setLocation(location));
    };

    const getLetterClass = (type: string):string => {
        if(type === "Arkeologisk objekt") {
            return "arkeologisk-objekt";
        }
        if(type === "Topografisk objekt") {
            return "topografisk-objekt";
        }
        if(type === "Område") {
            return "omrade"
        }
        if(type === "Prøve") {
            return "prove";
        }
        if(type === "Profil") {
            return "profil";
        }
        if(type === "Rute") {
            return "rute";
        }
        if(type === "Graveenhet") {
            return "graveenhet";
        }
        if(type === "Funnenhet") {
            return "funnenhet";
        }
        if(type === "Prosjekt") {
            return "prosjekt";
        }

        return ""
    }

    const renderHeader = () => { 
        return (
            <div key={"ADEDresultItemHeader"} className="headerlistdiv hit-container"> 
                <div className="hit-content">
                    <div className="hit-letter-container">
                        <div className={"hit-letter"}>
                            {/* <span>A</span> */}
                        </div>
                    </div>
                    <div className="hit-info">
                        <div className="width-20">
                            <div className="hit-header">
                                <Link onClick={sortField.field === "ObjektType" && sortField.order === "asc" ? () => dispatch(setSortField({field:"ObjektType",order:"desc"})): () => dispatch(setSortField({field:"ObjektType",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på ObjektType" >
                                    Objekttype {sortField.field === "ObjektType" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                                </Link>
                            </div>
                            <div className="hit-details">
                                <Link onClick={sortField.field === "ObjektHovedType" && sortField.order === "asc" ? () => dispatch(setSortField({field:"ObjektHovedType",order:"desc"})): () => dispatch(setSortField({field:"ObjektHovedType",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på ObjektHovedType" >
                                    Hovedtype {sortField.field === "ObjektHovedType" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                                </Link>
                            </div>
                        </div>
                        <div className="hit-details width-20">
                            <Link onClick={sortField.field === "Undersokelsesmetode" && sortField.order === "asc" ? () => dispatch(setSortField({field:"Undersokelsesmetode",order:"desc"})): () => dispatch(setSortField({field:"Undersokelsesmetode",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på Undersokelsesmetode" >
                                Undersøkelsesmetode {sortField.field === "Undersokelsesmetode" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                            </Link>
                        </div>
                        <div className="hit-details width-20">
                            <Link onClick={sortField.field === "OverordnetKontekst" && sortField.order === "asc" ? () => dispatch(setSortField({field:"OverordnetKontekst",order:"desc"})): () => dispatch(setSortField({field:"OverordnetKontekst",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på OverordnetKontekst" >
                                Overordnet Kontekst {sortField.field === "OverordnetKontekst" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                            </Link>
                        </div>
                        <div className="hit-details width-20">
                            <div className="bold">                            
                                <Link onClick={sortField.field === "Prosjektnavn" && sortField.order === "asc" ? () => dispatch(setSortField({field:"Prosjektnavn",order:"desc"})): () => dispatch(setSortField({field:"Prosjektnavn",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på Prosjektnavn" >
                                    Prosjektnavn {sortField.field === "Prosjektnavn" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                                </Link>
                            </div>
                            <div>
                                <Link onClick={sortField.field === "Kommuner" && sortField.order === "asc" ? () => dispatch(setSortField({field:"Kommuner",order:"desc"})): () => dispatch(setSortField({field:"Kommuner",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på Kommuner" >
                                    Kommune{sortField.field === "Kommuner" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                                </Link>
                                ,
                                <Link onClick={sortField.field === "Fylker" && sortField.order === "asc" ? () => dispatch(setSortField({field:"Fylker",order:"desc"})): () => dispatch(setSortField({field:"Fylker",order:"asc"}))}  to={""} className="hit-link-btn" title="Sorter på Fylker" >
                                    Fylke{sortField.field === "Fylker" ? (sortField.order === "asc" ? <UpOutlined style={{color: "#00594C"}} /> : <DownOutlined style={{color: "#00594C"}} />) : ""}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="hit-buttons">
                    </div>
                </div>
            </div>
        );
    };

    const renderResult = (r: ADEDResult, i: number) => {
        return (
            <div key={"ADEDresultItem" + i} className="hit-container"> 
                <div className="hit-content">
                    <div className="hit-letter-container">
                        <div className={"hit-letter " + getLetterClass(r.ObjektHovedType || "")}>
                            <span>{(replaceInType(r.ObjektHovedType)?.toString())[0]}</span>
                        </div>
                    </div>
                    <div className="hit-info">
                        <div className="width-20">
                            <Link onClick={() => dispatch(setFirstTime(false))} className="hit-link-btn" to={"/ADED/" + r.ObjektId} title="Vis detaljer">
                                <div className="hit-header">
                                    <span>{replaceInType(r.ObjektType ? r.ObjektType : r.ObjektHovedType)} </span>
                                    <span>{(r.ObjektType === "Prosjekt" ? (" " + r.Prosjektnavn) : "")}</span>
                                    <span>{" - " + r.InputId}</span>
                                </div>
                            </Link>
                            <div className="hit-details">
                                {replaceInType(r.ObjektHovedType)}
                            </div>
                        </div>
                        <div className="hit-details width-20">
                            {r.Undersokelsesmetode?.length > 0 && r.Undersokelsesmetode.toString()}
                        </div>
                        <div className="hit-details width-20">
                            {r.OverordnetKontekst?.length > 0 && r.OverordnetKontekst.toString()}
                        </div>
                        <div className="hit-details width-20">
                            <div className="bold">
                                {r.Prosjektnavn}
                            </div>
                            <div>
                                {r.Kommuner ? (r.Kommuner + ", " + r.Fylker) : ""}
                            </div>
                        </div>
                    </div>
                    <div className="hit-buttons">
                        {r.CenterPoint && 
                            <Button onClick={() => clickLocation(r)} type="link" title="Vis i kartet" >
                                <img src={placeMarkerGrayImg} alt="Kartmarkør" className="hit-btn-map-img" />
                            </Button>
                        }
                    </div>
                </div>
                <hr />
            
            </div>
        );
    };

    const renderFilter = (f: Filter, i: number) => {
        let caption = "";
        let typeString = "";

        if(f.type && f.type !== "Utgravningsprosjekter" && (!f.group || f.group !== "Objekter")) {
            typeString = f.type + ":";
        }
        
        if(f.filtertype === RANGE_FILTER) {
            if (f.values && f.values.length > 0) {
                caption = (f.values[0].caption === "gte" ? "> " : "< ") + f.values[0].value;
            }
        }
        else {
            if (f.values && f.values.length > 0) {
                caption = f.values[0].caption;
            }

            if(caption.indexOf("|") !== -1) {
                caption = caption.split("|")[0];
            }
        }

        return(
            <Button key={`activeFilter${i}`} type="link" onClick={() => {dispatch(toggleFilter(f))}} title="Fjern filter">
                {typeString}<b>{caption}</b>
                <CloseCircleFilled />
            </Button>
        );
    };

    const resetAll = (event: React.MouseEvent | null) => {
        event?.stopPropagation();
        dispatch(resetADED());
        dispatch(setSelectedSearchQuery(null));
        dispatch(setGeoBBox(null));
        dispatch(showDisplayType(DISPLAY_TYPE.MAP_VIEW));
        dispatch(setAdedPanelsOpen(false));
    };

    const renderSearchInfo = () => {
        const activeValues = getActiveFilters(resultFilters);

        return (
            <div className="resultlist-search-info">
                <div className="resultlist-search-info-content">
                    {searchQuery && 
                        <Button type="link" onClick={() => {dispatch(getSearchResult({type: ADED, phrase: ""}))}} title="Nullstill søkeord">
                            Søkeord: <b>{searchQuery}</b>
                            <CloseCircleFilled />
                        </Button>
                    }
                    {geoBBox && 
                        <Button type="link" onClick={() => {dispatch(setGeoBBox(null))}} title="Nullstill område fra kart">
                            <b>Område fra kart</b>
                            <CloseCircleFilled />
                        </Button>
                    }

                    {activeValues.map((f: Filter, i: number) => {
                        return renderFilter(f, i)
                    })}

                    <br/>
                    <Button type="link" style={{color: "black"}} onClick={(event) => resetAll(event)} title="Nullstill utvalg" >
                        Fjern alle
                        <CloseCircleFilled />
                    </Button>
                </div>

                <div>
                    <Button type="link" onClick={() => setInfoVisible(!infoVisible)} >
                        <img src={helpGreenLargerImg} alt="Hjelp" title="Hjelp meg!" />
                    </Button>
                </div>
            </div>
        )
    };

    const renderList = () => {
        if(props.results?.length < 1) {
            return (
                <div className="empty-result">Ingen resultater å vise, gjør et nytt søk</div>
            );
        }
        
        return (
            props.results?.map((r: ADEDResult, i:number) => renderResult(r, i))
        );
    };

    return (
        <div className="result-container">
            <div className="result-container-scroll">
                <div className="resultlist-header">
                    <div className={"context-help hitlist-help " + (infoVisible ? "" : "remove-element")}>
                        <p>
                            Lista under inneholder objekter i Utgravninger som inneholder angitt søkeord i et eller flere felter, og/eller matcher 
                            valgte kriterier i filterlista til venstre. Treffene som vises i lista markeres i kartet.
                        </p>
                        <p>
                            <i>Eksempel: </i> Hvis du har skrevet "Ovn" i søkefeltet, vil lista inneholde alle objekter som inneholder ordet "Ovn" i
                            et eller flere av feltene Hovedtype, Objekttype, Prosjektnavn, og generelle egenskaper som beskrivelse, form, undersøkelsesmetode osv.
                            <br />
                            Hvis du heller er ute etter kun objekter med type "Ovn" må du ekspandere <i>Objekter</i>-panelet i venstremenyen,
                            og velge "Ovn" under "Arkeologisk objekt" der.
                        </p>
                        <p>
                            For å sikre at alle treffene tilhører et gitt prosjekt må du ekspandere <i>Utgravningsprosjekter</i>-panelet i venstremenyen,
                            og velge ønsket prosjekt der.
                        </p>
                        <p>
                            Gå inn på et enkelt objekt for å se detaljer. Fra detaljsiden kan du navigere videre til relaterte objekter.
                        </p>
                        <div>
                            <Button type="link" onClick={() => setInfoVisible(false)} style={{float: "right"}}>
                                Lukk
                            </Button>
                        </div>
                    </div>

                    {renderSearchInfo()}
                </div>
                {/* {renderHeader()} */}
                <div className="resultlist-content-aded">
                    {renderHeader()}
                    {renderList()}
                    {props.lastPageNotMax && 
                    <div className="empty-result">Det finnes flere treff... gjør ytterligere filtreringer</div>
                    }
                </div>
            </div>
            <Paging noHits={props.results?.length} totalNoHits={props.noHits} />
        </div>
    );
};
