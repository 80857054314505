/* Dialog for imports: import new data, show status for imports */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { User } from "oidc-client";
import { UploadChangeParam } from "antd/lib/upload";
import { Button, ConfigProvider, DatePicker, Input, Upload, message } from "antd";
import { CloseOutlined, FileZipOutlined, ReloadOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import nb_NO from "antd/lib/locale/nb_NO";
import "moment/locale/nb";
import Draggable from 'react-draggable';

import { ImportJob, StoreState } from "../../../types";
import { setImportDialogVisible } from "../../../store/importSlice";

import "./dialogs.css";
import { getImportJobs } from "../../../services/userservice";
import { showAlert } from "../../../store/appSlice";
import { ALERT_TYPE, FAILED_STATUS } from "../../../constants";
import { orderItems } from "../../../utils";

const Import = () => {
    const dispatch = useDispatch();

    const [showErrorIndicators, setShowErrorIndicators] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>("");
    const [hvor, setHvor] = useState<string>("");
    const [hvem, setHvem] = useState<string>("");
    const [hva, setHva] = useState<string>("");
    const [metode, setMetode] = useState<string>("");
    const [dataSetVersjon, setDataSetVersjon] = useState<string>("");
    const [showLog, setShowLog] = useState<boolean>(false);
    const [importJobsMsg, setImportJobsMsg] = useState<string>("");
    const [importJobs, setImportJobs] = useState<Array<ImportJob>>([]);
    const [orderBy, setOrderBy] = useState<string>("uploadedDate");
    const [orderAttrType, setAttrType] = useState<string>("date");
    const [orderReverse, setOrderReverse] = useState<boolean>(true);
    const [fileList, setFileList] = useState<Array<any>>([]);

    const visible: boolean = useSelector((state: StoreState) => state.import.dialogVisible);
    const user: User | null = useSelector((state: StoreState) => state.user.user);

    const checkInput = ():boolean => {
        setShowErrorIndicators(true);
        if(projectName && hvor && hvem && hva && metode && dataSetVersjon) {
            return true;
        }
        
        return false;
    }

    const fileProps = {
        name: "file",
        action: window.environment.aded_protected_api + "user/project",
        headers: {
            authorization: `${user?.token_type} ${user?.access_token}`,
        },
        onChange(info:UploadChangeParam) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }

            if (info.file.status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }

            if(info.fileList.length) {
                setFileList([info.fileList[info.fileList.length - 1]]);
            }
        }
    };

    const getMetadata = () => {
        const metaContent = {
            projectname: projectName,
            hvor: hvor,
            hvem: hvem,
            hva: hva,
            metode: metode,
            datasetversjon: dataSetVersjon,
        };
        return {
            metadata: JSON.stringify(metaContent)
        };
    };

    const orderJobs = (jobs: Array<ImportJob>, by: string, attrType: string, reverse: boolean) => {
        const orderedJobs = orderItems(jobs, by, attrType, reverse);

        setOrderBy(by);
        setAttrType(attrType);
        setOrderReverse(reverse);

        setImportJobs(orderedJobs);
    };

    const getAndShowLog = () => {
        setImportJobsMsg("Henter importjobber...")
        getImportJobs(user as User).then((response: any) => {
            if (response.status === 200) {
                orderJobs(response.data, orderBy, orderAttrType, orderReverse);
                setImportJobsMsg("* Etter at data er importert må prosjektet gjøres tilgjengelig for søk og for visning i karttjenestene. Dette kan ta noen timer.");
            }
            else {
                setImportJobsMsg("Klarte ikke å hente importjobber");
                dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente importjobber", type: ALERT_TYPE.ERROR, manualClose: false}));
            }
        });

        setShowLog(true);
    };

    const getStatusinfo = (j: ImportJob):string => {
        let s = j.status;

        if(j.status === FAILED_STATUS && j.errormessage) {
            if(j.errormessage === "translation_failed") {
                s += " - Kontakt systemadministrator"
            }
            else {
                s += " - " + j.errormessage;
            }
        }

        return s;
    };

    if(!visible) {
        return null;
    }

    const renderJobs = () => {
        if(importJobs.length === 0) {
            return (
                <tr>
                    <td colSpan={4} className="empty-result">Finner ingen tidligere import-jobber</td>
                </tr>
            );
        }

        return (
            importJobs.map((j: ImportJob, i:number) => {
                return (
                    <tr key={`importJob${i}`} className={j.status === FAILED_STATUS ? "import-failed" : ""}>
                        <td>{j.uploadedDate}</td>
                        <td>{j.prosjektnavn}</td>
                        <td>{getStatusinfo(j)}</td>
                        <td>{j.indexerStatus === "done" ? "Completed" : j.indexerStatus}</td>
                    </tr>
                )
            })
        );
    };

    return (
        <Draggable>
            <div className="dialog">
                <div className="dialog-header">
                    <div>
                        <FileZipOutlined className="margin-right-small" />
                        Importèr prosjekt
                    </div>
                    <Button type="link" onClick={() => dispatch(setImportDialogVisible(!visible))} >
                        <CloseOutlined />
                    </Button>
                </div>
                <div className="dialog-body">
                    <ConfigProvider locale={nb_NO}>
                        <div className="input-caption">
                            Prosjektnavn
                        </div>
                        <div className="input-field-container">
                            <Input className={`input-field ${((showErrorIndicators && !projectName) ? "missing-input" : "")}`} 
                                value={projectName} onChange={(e) => setProjectName(e.target.value)} required autoFocus maxLength={100} />
                            {(showErrorIndicators && !projectName) &&
                                <div className="validation-msg">
                                    <div>!</div>
                                    Vennligst angi navn på prosjekt
                                </div>
                            }
                        </div>
                        <div className="date-container">
                            <div className="date-container-inner">
                                <div className="input-caption">
                                    Hvor
                                </div>
                                <div className="input-field-container">
                                    <Input className={`input-field ${((showErrorIndicators && !hvor) ? "missing-input" : "")}`}
                                        value={hvor} onChange={(e) => setHvor(e.target.value)} required autoFocus maxLength={100} />
                                    {(showErrorIndicators && !hvor) &&
                                        <div className="validation-msg">
                                            <div>!</div>
                                            Vennligst angi hvor
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="date-container-inner">
                                <div className="input-caption">
                                    Hvem
                                </div>
                                <div className="input-field-container">
                                    <Input className={`input-field ${((showErrorIndicators && !hvem) ? "missing-input" : "")}`}
                                        value={hvem} onChange={(e) => setHvem(e.target.value)} required autoFocus maxLength={100} />
                                    {(showErrorIndicators && !hvem) &&
                                        <div className="validation-msg">
                                            <div>!</div>
                                            Vennligst angi hvem
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="date-container-inner">
                                <div className="input-caption">
                                    Hva
                                </div>
                                <div className="input-field-container">
                                    <Input className={`input-field ${((showErrorIndicators && !hva) ? "missing-input" : "")}`} 
                                        value={hva} onChange={(e) => setHva(e.target.value)} required autoFocus maxLength={100} />
                                    {(showErrorIndicators && !hva) &&
                                        <div className="validation-msg">
                                            <div>!</div>
                                            Vennligst angi hva
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="date-container-inner">
                                <div className="input-caption">
                                    Metode
                                </div>
                                <div className="input-field-container">
                                    <Input className={`input-field ${((showErrorIndicators && !metode) ? "missing-input" : "")}`} 
                                        value={metode} onChange={(e) => setMetode(e.target.value)} required autoFocus maxLength={100} />
                                    {(showErrorIndicators && !metode) &&
                                        <div className="validation-msg">
                                            <div>!</div>
                                            Vennligst angi metode
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="date-container-inner">
                                <div className="input-caption">
                                    Datasettets versjon
                                </div>
                                <div className="input-field-container">
                                    <Input className={`input-field ${((showErrorIndicators && !dataSetVersjon) ? "missing-input" : "")}`}
                                        value={dataSetVersjon} onChange={(e) => setDataSetVersjon(e.target.value)} required autoFocus maxLength={100} />
                                    {(showErrorIndicators && !dataSetVersjon) &&
                                        <div className="validation-msg">
                                            <div>!</div>
                                            Vennligst angi datasettets versjon
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    
                        <div className="dialog-btn-container">
                            <Upload {...fileProps} accept=".zip" fileList={fileList} beforeUpload={checkInput} data={getMetadata} >
                                <Button icon={<FileZipOutlined />} disabled={!(projectName && hvor && hvem && hva && metode && dataSetVersjon)}
                                        title={!(projectName && hvor && hvem && hva && metode && dataSetVersjon) ? "Angi prosjektnavn og metadata" : "Importèr prosjekt"} >
                                    Velg zip-fil og kjør import
                                </Button>
                            </Upload>
                        </div>
                    </ConfigProvider>


                    <div style={{marginTop: "1rem"}}>
                        <div className={`admin-btn-container ${showLog ? "remove-element" : ""}`}>
                            <Button title="Se oversikt over dine tidligere importjobber med status" onClick={() => getAndShowLog()}>
                                Se logg
                            </Button>
                        </div>
                        <div className={`margin-top ${showLog ? "" : "remove-element"}`}>
                            <Button type="link" title="Lukk oversikten" onClick={() => setShowLog(false)}>
                                Lukk
                            </Button>
                            <Button className="margin-left" type="link" title="Oppdater oversikten" onClick={() => getAndShowLog()} style={{padding: 0}} >
                                <ReloadOutlined />
                                <span className="margin-left-small">Oppfrisk</span>
                            </Button>
                            <table className="dialog-table">
                                <thead>
                                    <tr>
                                        <th>
                                            DATO FOR OPPLASTING
                                            <Button className="margin-left" type="link" title="Sorter synkende på dato" onClick={() => orderJobs(importJobs, "uploadedDate", "date", true)} style={{padding: 0}} >
                                                <DownOutlined className={(orderBy === "uploadedDate" && orderReverse === true) ? "sorted-by" : ""} />
                                            </Button>
                                            <Button type="link" title="Sorter stigende på dato" onClick={() => orderJobs(importJobs, "uploadedDate", "date", false)} style={{padding: 0}} >
                                                <UpOutlined className={(orderBy === "uploadedDate" && orderReverse === false) ? "sorted-by" : ""} />
                                            </Button>
                                        </th>
                                        <th>
                                            PROSJEKTNAVN
                                            <Button className="margin-left" type="link" title="Sorter synkende på navn" onClick={() => orderJobs(importJobs, "prosjektnavn", "string", false)} style={{padding: 0}} >
                                                <DownOutlined className={(orderBy === "prosjektnavn" && orderReverse === false) ? "sorted-by" : ""} />
                                            </Button>
                                            <Button type="link" title="Sorter stigende på navn" onClick={() => orderJobs(importJobs, "prosjektnavn", "string", true)} style={{padding: 0}} >
                                                <UpOutlined className={(orderBy === "prosjektnavn" && orderReverse === true) ? "sorted-by" : ""} />
                                            </Button>
                                        </th>
                                        <th style={{width: "30%"}}>
                                            IMPORTSTATUS
                                            <Button className="margin-left" type="link" title="Sorter synkende på importstatus" onClick={() => orderJobs(importJobs, "status", "string", false)} style={{padding: 0}} >
                                                <DownOutlined className={(orderBy === "status" && orderReverse === false) ? "sorted-by" : ""} />
                                            </Button>
                                            <Button type="link" title="Sorter stigende på importstatus" onClick={() => orderJobs(importJobs, "status", "string", true)} style={{padding: 0}} >
                                                <UpOutlined className={(orderBy === "status" && orderReverse === true) ? "sorted-by" : ""} />
                                            </Button>
                                        </th>
                                        <th>
                                            INDEKSERINGSSTATUS *
                                            <Button className="margin-left" type="link" title="Sorter synkende på indekseringsstatus" onClick={() => orderJobs(importJobs, "indexerStatus", "string", false)} style={{padding: 0}} >
                                                <DownOutlined className={(orderBy === "indexerStatus" && orderReverse === false) ? "sorted-by" : ""} />
                                            </Button>
                                            <Button type="link" title="Sorter stigende på indekseringsstatus" onClick={() => orderJobs(importJobs, "indexerStatus", "string", true)} style={{padding: 0}} >
                                                <UpOutlined className={(orderBy === "indexerStatus" && orderReverse === true) ? "sorted-by" : ""} />
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderJobs()}
                                </tbody>
                            </table>
                            <div style={{margin: ".5rem"}}>
                                {importJobsMsg}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default Import;