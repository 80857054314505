/* Container for basemaps and map layers */
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { Button } from "antd";

import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Basemap from '@arcgis/core/Basemap';
import Layer from "@arcgis/core/layers/Layer";

import { CustomLegend } from "./customlegend";
import { BasemapList } from "./basemaplist";
import { Layerlist } from "./layerlist";
import { StoreState } from "../../../types";
import { setTOCVisibility } from "../../../store/mapSlice";
import { DISPLAY_TYPE } from "../../../constants";

import helpGreenLargerImg from "../../../images/help_green_larger.png";
import layersImg from "../../../images/layers.png";
import panelRightImg from "../../../images/panel_right.png";

interface tocProps {
    map: Map | null,
    mapView: MapView | null,
    basemaps: Array<Basemap> | null,
    historicalLayer: Layer | null
};

export const TOC = (props: tocProps) => {
    const dispatch = useDispatch();
    
    const focusElement = useRef<any>(null);
    const [infoVisible, setInfoVisible] = useState<boolean>(false);

    const tocVisible: boolean = useSelector((state: StoreState) => state.map.tocVisible);
    const displayType: DISPLAY_TYPE = useSelector((state: StoreState) => state.app.displayType);

    useHotkeys("ctrl+alt+l", () => {
        if(!tocVisible) {
            dispatch(setTOCVisibility(true));
        }

        // Set focus on first tab, small timeout to allow rendering
        setTimeout(() => {
            const rightmenu = document.getElementById("rightmenu");
            if(rightmenu) {
                const focusElements = rightmenu.getElementsByClassName("ant-tabs-tab-btn");
                if(focusElements.length > 0) {
                    (focusElements.item(0) as HTMLElement).focus();
                }
            }
        }, 300);
    });

    return (
        <div id="rightmenu" className="right-menu">
            <div className={`toc-container ${(tocVisible ? "" : " remove-element")}`}>
                <div className="toc-container-header">
                    <div>
                        Kartlag
                        <Button type="link" size="small" className="margin-left" onClick={() => setInfoVisible(!infoVisible)} ref={focusElement} >
                            <img src={helpGreenLargerImg} alt="Hjelp" title="Hjelp meg!" />
                        </Button>
                    </div>
                </div>

                <div className="toc-scrollable-content">
                    <CustomLegend map={props.map} mapView={props.mapView} basemaps={props.basemaps} historicalLayer={props.historicalLayer} />
                    <BasemapList map={props.map} basemaps={props.basemaps} historicalLayer={props.historicalLayer} />
                    <Layerlist map={props.map} mapView={props.mapView} />
                </div>

                <div className={"context-help toc-help " + (infoVisible ? "" : "remove-element")}>
                    <p>
                    Denne listen inneholder en oversikt over tema som kan ses i kartet.
                    </p>
                    <p>
                    Temaene vises eller skjules avhengig av om du har skrudd “øyet” av eller på.
                    </p>
                    <p>
                    Finner du ikke det du leter etter kan det være fordi kartlaget er skrudd av.
                    </p>
                    <div>
                        <Button type="link" onClick={() => setInfoVisible(false)} style={{float: "right"}}>
                            Lukk
                        </Button>
                    </div>
                </div>
            </div>

            <Button className="toc-btn" onClick={() => dispatch(setTOCVisibility(!tocVisible))} 
                    hidden={tocVisible || (displayType === DISPLAY_TYPE.INFO_VIEW)}
                    title="Åpne meny for å velge bakgrunnskart og/eller kartlag" >
                <span>Kartlag</span>
                <img src={layersImg} alt="Kartlag" />
            </Button>

            <Button type="link" className="toc-btn-inline" onClick={() => dispatch(setTOCVisibility(!tocVisible))} hidden={!tocVisible} >
                <img src={panelRightImg} alt="Close" className="btn-icon" />
            </Button>
            
            <div className="shortcut-hint" style={{marginLeft: "-125px", marginTop: "30px"}} hidden >CTRL + ALT + L</div>
        </div>
    );
};