/* UI for custom Legend */
import { useEffect, useState, useRef } from "react";
import { RightOutlined, DownOutlined, InfoCircleFilled, CheckCircleFilled, LoadingOutlined, } from "@ant-design/icons";
import { Button } from "antd";

import Map from '@arcgis/core/Map';
import Basemap from '@arcgis/core/Basemap';
import Layer from "@arcgis/core/layers/Layer";

import legendIcon from "../../../images/legendicon.svg";

import Legend from "@arcgis/core/widgets/Legend.js"; //NY
import MapView from '@arcgis/core/views/MapView';
import * as query from "@arcgis/core/rest/query.js"; //NY

interface basemaplistProps {
    map: Map | null,
    mapView: MapView | null,
    basemaps: Array<Basemap> | null,
    historicalLayer: Layer | null
};

export const CustomLegend = (props: basemaplistProps) => {
    const legendRef = useRef(null);

    const [visible, setVisible] = useState<boolean>(false);
    //const [legendinit, setLegendInit] = useState<boolean>(false);
    const [isredrawinglegend, setIsRedrawingLegend] = useState<boolean>(false);
    const [customlegend, setCustomLegend] = useState<Legend | null>(null);
    // const [currentBasemap, setCurrentBasemap] = useState<string>(props.map?.basemap.id || "");
    // const [historicalVisible, setHistoricalVisible] = useState<boolean>(false);

    //Get layer from the map by the layer's id
    //var featureLayer = mapIns.findLayerById("aded_arkobj");
    useEffect(() => {
        if(props.mapView !== null && props.map !== null) {
            if(customlegend === null) {
                // let legendContainer: any = legendRef.current;
                // console.log(legendContainer)
                let legendContainer: any = legendRef.current;
                if(legendContainer !== null) {
                    console.log("legend init")
                    console.log(customlegend)
                    //var featureLayer = props.map.findLayerById("aded_arkobj");
                    var featureLayer = props.map.findLayerById("aded_group");
                    //const featureLayer = webmap.layers.getItemAt(0);
        
                    const legend = new Legend({
                        // @ts-ignore
                        view: props.mapView,
                        container: legendContainer,
                        layerInfos: [
                            {
                            layer: featureLayer,
                            //title: "Testtest"
                            }
                        ]
                    });
                    
                    //legend.hideLayersNotInCurrentView = true;

                    //legend.visible = false; //Always hide default legend
                    setCustomLegend(legend);
                    console.log(legend)
                }
            }
            if (visible && customlegend !== null){
                //customlegend.visible = true
                redrawLegend(); //Hide original legend and create custom legend
            }
        }
    }, [visible, props.mapView, props.map]);

    const redrawLegend = () => {
        console.log("redraw legend")
        if (props.map && props.mapView && customlegend !== null){


            setIsRedrawingLegend(true);

            var specialLegend = document.getElementById("specialLegend") as HTMLElement; //Dette blir min nye legend
            specialLegend.innerHTML = ""; //Clear element dersom det ligger \noe der fra forrige gang

            //Get all layers in extent, alle må ligge i Legend også
            //Utgravinger, Arkeologisk objekt

            //Samme rekkefølge som i kartlagslista
            var queryUrl1 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/1" //Funnenhet
            var queryUrl2 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/2" //Prøve
            var queryUrl3 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/4" //Profil
            var queryUrl4 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/6" //Rute
            var queryUrl5 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/7" //Graveenhet

            //Topografisk objekt
            var queryUrl6 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/5" //Linjer
            var queryUrl7 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/8" //Flater

            //Arkeologisk objekt
            var queryUrl8 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/0" //Punkter
            var queryUrl9 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/3" //Linjer
            var queryUrl10 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/9" //Flater

            var queryUrl11 = window.environment.aded_mapserver + "/Hovedtyper/MapServer/10" //Område

            //Prosjekt
            var queryUrl12 = window.environment.aded_mapserver + "/Estimerte/MapServer/0" //Punkter
            var queryUrl13 = window.environment.aded_mapserver + "/Estimerte/MapServer/3" //Flater - Estimert geometri

            let promises = [query.executeQueryJSON(queryUrl1, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl2, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl3, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl4, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl5, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl5, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl6, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl7, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl8, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl9, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl10, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl11, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl12, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            }),query.executeQueryJSON(queryUrl13, {  // autocasts as new Query()
                where: "1=1",
                outFields: ["objekt_type"],
                geometry: props.mapView.extent,
                returnDistinctValues: true,
            })];

            console.log(document.getElementsByClassName("esri-legend")) //Dette er den originale legenden
            var addedelem = document.getElementsByClassName("esri-legend")[0].cloneNode(true);
            specialLegend.appendChild(addedelem)
            Promise.all(promises).then(function(results2){
                //NB: promises vil komme i samme rekkefølge som de ble lagt inn i arrayet, så vi kan bruke index for å vite hvilket layer det er fra
                //TODO: Dersom det ikke kommer noe fra et layer, så kan hele layeret fjernes fra legenden
                //console.log(results2);
                var results: string[] = []
                for (var i = 0; i < results2.length; i++) {
                    //console.log(results2[i].features);
                    Array.prototype.push.apply(results, results2[i].features.map(a => a.attributes.objekt_type));
                }
                //console.log(results) //OBS: F.eks Stolpehull finnes både i flater og linjer
                //console.log(specialLegend)
                Array.from(specialLegend.children[0].children).forEach(element => { //Hele legenden
                    Array.from(element.children).forEach(element2 => { //En tom div som Esri har lagt inn
                        Array.from(element2.children).forEach(element3 => { //Group-layer (f.eks. Arkeologisk objekt) //Ikke alle har group-layer
                            if (element3.nodeName === "DIV") { //Må unngå å sjekke h3
                                Array.from(element3.children).forEach(element4 => { //group-layer-child (f.eks punkter)
                                    if (element4.nodeName === "DIV") { //Må unngå å sjekke h3
                                        Array.from(element4.children).forEach(element5 => {//layer
                                            if (element5.nodeName === "DIV") { //Ikke nødvenig, ingen h3 her
                                                Array.from(element5.children).forEach(element6 => {//layer-table (f.eks objekt_type)
                                                    //console.log(element6)
                                                    if (element6.innerHTML === "objekt_type") { //objekt_type ligger i en div, ikke i en h3 av en eller annen grunn. Ønsker ikke å ha med denne.
                                                        element6.remove();
                                                    }
                                                    else { //objekt_type ligger i en div, ikke i en h3 av en eller annen grunn
                                                        Array.from(element6.children).forEach(element7 => {//layer-body (f.eks objekt_type)
                                                            if (element7.innerHTML === "objekt_type"){
                                                                element7.remove();
                                                            }
                                                            else if (element7.className === "esri-legend__layer-row" && !results.includes(element7.children[1].innerHTML) && element7.children[1].innerHTML !== "&lt;all other values&gt;") {
                                                                //console.log(element7)
                                                                element7.remove();
                                                            }
                                                            else {
                                                                Array.from(element7.children).forEach(element8 => {//layer-body (f.eks objekt_type) //element8.children.length > 1 && element8.children[1].innerHTML &&
                                                                    if(element8.className === "esri-legend__layer-row" && !results.includes(element8.children[1].innerHTML) && element8.children[1].innerHTML !== "&lt;all other values&gt;"){ //Fjern alt som ikke kom i resultatet
                                                                        element8.remove();
                                                                    }
                                                                    else{ //TODO: Bruk denne til å vise "ingen resultater" hvis vi aldri kommer inn hit
                                                                        console.log("Found one that was not removed")
                                                                        //console.log(element8)
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });
                });


                specialLegend.children[0].setAttribute("style", "height: ''"); //Default legend har 0 i height legend
                //console.log(specialLegend)
                setIsRedrawingLegend(false);
            });
        }
    };

    return (
        <div className="basemap-container">
            <div>
                <Button className="basemap-container-btn" onClick={() => setVisible(!visible)}>
                    <div>
                        <img src={legendIcon} alt="Tegnforklaring" />
                        Tegnforklaring
                    </div>
                    {visible && <DownOutlined style={{color: "#62AFAE"}} />}
                    {!visible && <RightOutlined style={{color: "#62AFAE"}} />}
                </Button>
                {/* Dette er esri default legend, og er alltid skjult (height=0px). Må ligge i DOM for å kunne bygge opp specialLegend*/}
                <div ref={legendRef} style={{height:"0px"}}></div>

                {/* Denne er custom legend, bygges opp fra elementer i default Legend*/}
                <div>
                    {/* <Button onClick={() => redrawLegend()} style={{float: "right"}}>
                        <div>
                            <img src={legendIcon} alt="Tegnforklaring" />
                            Refresh
                        </div>
                    </Button> */}
                    {isredrawinglegend && visible && <div><LoadingOutlined spin className="empty-result"/> Laster...</div>}
                    <div id="specialLegend" style={visible ? {display:""} : {display:"none"}}></div>
                </div>
            </div>
        </div>
    );
};