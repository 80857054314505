/* Dialog for sharing: user can share saved queries with others using query GUID */
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "antd";
import { CloseOutlined, ShareAltOutlined } from "@ant-design/icons";
import Draggable from 'react-draggable';

import { setShareDialogVisible } from "../../../store/searchSlice";
import { StoreState } from "../../../types";

import "./dialogs.css";

const Share = () => {
    const dispatch = useDispatch();

    const urlRef:any = useRef(null);

    const visible: boolean = useSelector((state: StoreState) => state.search.shareDialogVisible);
    const urlToShare: string | null = useSelector((state: StoreState) => state.search.urlToShare);

    const copyToClipBoard = () => {
        const txt = urlRef.current;

        if(txt !== null) {
            txt.select();
            txt.input.setSelectionRange(0, txt.input.value.length); /* For mobile devices */

            document.execCommand("copy");
        }
    };

    const selectAll = () => {
        setTimeout(() => {
            const txt = urlRef.current;

            if(txt !== null) {
                txt.select();
            }
        }, 500) // Give DOM time to render
    }

    if(!visible) {
        return null;
    }
    
    return (
        <Draggable>
            <div className="dialog">
                <div className="dialog-header">
                    <div>
                        <ShareAltOutlined className="margin-right-small" />
                        Del lagret søk
                    </div>
                    <Button type="link" onClick={() => dispatch(setShareDialogVisible(false))} >
                        <CloseOutlined />
                    </Button>
                </div>
                <div className="dialog-body">
                    <Input ref={urlRef} value={urlToShare || ""} autoFocus onFocus={() => {selectAll();}} />

                    <div className="dialog-btn-container">
                        <Button className="margin-right" onClick={() => dispatch(setShareDialogVisible(false))}>Avbryt</Button>
                        <Button onClick={() => copyToClipBoard()} disabled={!urlToShare}>
                            Kopier
                        </Button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default Share;