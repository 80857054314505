/* Dialog for exports: show status, download results */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { User } from "oidc-client";
import { Button, Popconfirm } from "antd";
import { CloseOutlined, DownloadOutlined, ReloadOutlined, UpOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons";
import Draggable from 'react-draggable';

import { ExportJob, StoreState } from "../../../types";
import { deleteExportJob, getExportJobs, getResultFile } from "../../../services/userservice";
import { showAlert } from "../../../store/appSlice";
import { ALERT_TYPE, EXPORTED_SUCCESS_STATUS, FAILED_STATUS } from "../../../constants";
import { setExportDialogVisible } from "../../../store/exportSlice";

import "./dialogs.css";
import { ExportFormats } from "../../../config/ADEDConfig";
import { orderItems } from "../../../utils";

const Export = () => {
    const dispatch = useDispatch();

    const [exportJobsMsg, setExportJobsMsg] = useState<string>("* Når resultatet er ferdig produsert kan du laste ned fila her.");
    const [initialized, setInitialized] = useState<boolean>(false);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [exportJobToDelete, setExportJobToDelete] = useState<ExportJob | null>(null);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [exportJobs, setExportJobs] = useState<Array<ExportJob>>([]);
    const [orderBy, setOrderBy] = useState<string>("date");
    const [orderAttrType, setAttrType] = useState<string>("date");
    const [orderReverse, setOrderReverse] = useState<boolean>(true);

    const visible: boolean = useSelector((state: StoreState) => state.export.dialogVisible);
    const user: User | null = useSelector((state: StoreState) => state.user.user);

    useEffect(() => { //Oppfrisk listen en gang når dialogen åpnes
        if (visible) {
            getAndShowLog();
        }
    },[visible]);

    const orderJobs = (jobs: Array<ExportJob>, by: string, attrType: string, reverse: boolean) => {
        const orderedJobs = orderItems(jobs, by, attrType, reverse);

        setOrderBy(by);
        setAttrType(attrType);
        setOrderReverse(reverse);

        setExportJobs(orderedJobs);
    };

    const getAndShowLog = () => {
        setExportJobsMsg("Henter eksportjobber...")
        const userIdentifyer = user?.profile.sub || localStorage.getItem("userIdentifyer");
        if (!(userIdentifyer === null || userIdentifyer === ""))  {
            getExportJobs(userIdentifyer).then((response: any) => {
                if (response.status === 200) {
                    orderJobs(response.data, orderBy, orderAttrType, orderReverse);
                    setExportJobsMsg("* Når resultatet er ferdig produsert kan du laste ned fila her.");
                }
                else {
                    setExportJobsMsg("Klarte ikke å hente eksportjobber");
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente eksportjobber", type: ALERT_TYPE.ERROR, manualClose: false}));
                }
            });
        }
        else {
            setExportJobsMsg("Klarte ikke å hente eksportjobber");
            dispatch(showAlert({title: "Feilet!", message: "Noe gikk galt, forsøk å refreshe siden", type: ALERT_TYPE.ERROR, manualClose: false}));
        }
    };

    const getFormatCaption = (format: string): string => {
        const v = ExportFormats.filter(f => { return f.value === format; });
        if(v.length > 0) {
            return v[0].caption;
        }

        return format;
    };

    const getFormatExtension = (format: string): string => {
        return ".zip";
    }

    const downloadResult = (queueId: number, exportName: string, format: string) => {
        getResultFile(queueId).then((response: any) => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", exportName + getFormatExtension(format));
                document.body.appendChild(link);
                link.click();
            }
            else {
                dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å laste ned resultatfila", type: ALERT_TYPE.ERROR, manualClose: false}));
            }
        });
    };

    const getPopupTitle = () => {
        return (
            <div>
                <div>Vil du slette <i>{exportJobToDelete?.exportName || ""} ({getFormatCaption(exportJobToDelete?.format || "")})</i> ?</div>
                <div className="margin-top">
                    Sletter også det produserte resultatet fra serveren, så vær sikker på at du har lastet det ned hvis du vil ta vare på det.
                </div>
            </div>
        );
    };

    const confirmDelete = (j: ExportJob) => {
        setExportJobToDelete(j);
        setPopupVisible(true);
    }

    const handlePopupOk = () => {
        setExportJobToDelete(null);
        setPopupVisible(false);
        setConfirmLoading(true);

        if(exportJobToDelete?.queueId) {
            const userIdentifyer = user?.profile.sub || localStorage.getItem("userIdentifyer");
            if (!(userIdentifyer === null || userIdentifyer === ""))  {
                deleteExportJob(userIdentifyer, exportJobToDelete.queueId).then((response: any) => {
                    setConfirmLoading(false);
                    if (response.status === 204) {
                        getAndShowLog();
                    }
                    else {
                        dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å slette jobben", type: ALERT_TYPE.ERROR, manualClose: false}));
                    }
                });
            }
            else {
                setConfirmLoading(false);
                dispatch(showAlert({title: "Feilet!", message: "Noe gikk galt, forsøk å refreshe siden", type: ALERT_TYPE.ERROR, manualClose: false}));
            }
        }
    };

    const handlePopupCancel = () => {
        setPopupVisible(false);
    };

    if(!visible) {
        return null;
    }

    if(!initialized) {
        setInitialized(true);
        getAndShowLog();
    }

    const renderJobs = () => {
        if(exportJobs.length === 0) {
            return (
                <tr>
                    <td colSpan={5} className="empty-result">Finner ingen eksportjobber</td>
                </tr>
            );
        }

        return (
            exportJobs.map((j: ExportJob, i:number) => {
                return (
                    <tr key={`exportJob${i}`} className={j.status === FAILED_STATUS ? "import-failed" : ""}>
                        <td>{j.date}</td>
                        <td>{j.exportName}</td>
                        <td>{getFormatCaption(j.format)}</td>
                        <td>{j.status}</td>
                        <td>
                            {j.status === EXPORTED_SUCCESS_STATUS &&
                                <Button type="link" onClick={() => downloadResult(j.queueId, j.exportName, j.format)} title="Last ned fil" style={{padding: 0}}>
                                    <DownloadOutlined />
                                    <span className="margin-left-small">Resultat</span>
                                </Button>
                            }
                        </td>
                        <td>
                            <Button type="link" onClick={() => confirmDelete(j)} title="Slett jobben og resultatet" >
                                <DeleteOutlined />
                            </Button>
                        </td>
                    </tr>
                );
            })
        );
    };

    return (
        <Draggable>
            <div className="dialog">
                <div className="dialog-header">
                    <div>
                        <DownloadOutlined className="margin-right-small" />
                        Eksportjobber
                    </div>
                    <Button type="link" onClick={() => dispatch(setExportDialogVisible(!visible))} >
                        <CloseOutlined />
                    </Button>
                </div>
                <div className="dialog-body">
                    <div>
                        Her kan du se en oversikt over dine eksportjobber, og laste ned ferdigproduserte resultater. <br />
                        Hvis du ikke har noen eksportjobber kan du gjøre et søk og bestille en eksport fra trefflista.
                    </div>
                    <div className="margin-top margin-left">
                        <Button type="link" title="Oppdater oversikten" onClick={() => getAndShowLog()} style={{padding: 0}} >
                            <ReloadOutlined />
                            <span className="margin-left-small">Oppfrisk</span>
                        </Button>
                    </div>
                    <table className="dialog-table margin-top">
                        <thead>
                            <tr>
                                <th>
                                    DATO FOR BESTILLING
                                    <Button className="margin-left" type="link" title="Sorter synkende på dato" onClick={() => orderJobs(exportJobs, "date", "date", true)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "date" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på dato" onClick={() => orderJobs(exportJobs, "date", "date", false)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "date" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th>
                                    NAVN PÅ EKSPORT
                                    <Button className="margin-left" type="link" title="Sorter synkende på navn" onClick={() => orderJobs(exportJobs, "exportName", "string", false)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "exportName" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på navn" onClick={() => orderJobs(exportJobs, "exportName", "string", true)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "exportName" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th>
                                    FORMAT
                                    <Button className="margin-left" type="link" title="Sorter synkende på format" onClick={() => orderJobs(exportJobs, "format", "string", false)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "format" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på format" onClick={() => orderJobs(exportJobs, "format", "string", true)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "format" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th>
                                    STATUS
                                    <Button className="margin-left" type="link" title="Sorter synkende på status" onClick={() => orderJobs(exportJobs, "status", "string", false)} style={{padding: 0}} >
                                        <DownOutlined className={(orderBy === "status" && orderReverse === false) ? "sorted-by" : ""} />
                                    </Button>
                                    <Button type="link" title="Sorter stigende på status" onClick={() => orderJobs(exportJobs, "status", "string", true)} style={{padding: 0}} >
                                        <UpOutlined className={(orderBy === "status" && orderReverse === true) ? "sorted-by" : ""} />
                                    </Button>
                                </th>
                                <th>LAST NED FIL *</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderJobs()}
                        </tbody>
                    </table>
                    <Popconfirm
                        title={getPopupTitle()}
                        open={popupVisible}
                        onConfirm={handlePopupOk}
                        okButtonProps={{ loading: confirmLoading }}
                        onCancel={handlePopupCancel}
                        cancelText="Avbryt"
                        placement="top"
                    ></Popconfirm>
                    <div style={{margin: ".5rem"}}>
                        {exportJobsMsg}
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default Export;