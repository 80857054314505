/* UI for log in */
import { useSelector } from "react-redux";
import { Button } from "antd";
import { User } from "oidc-client";

import { StoreState } from "../../../types";
import { AuthService } from "../../../services/authorisationservice";

interface loginProps {
    authService: AuthService
};

const LoginView = (props: loginProps) => {
    const user: User | null = useSelector((state: StoreState) => state.user.user);

    const login = () => {
        props.authService.login();
    }

    const render = () => {
        if(user) {
          return null;
        }
        return (
            <div className="user-container">
                <Button type="link" className="login-btn" onClick={() => login()} title="Logg inn for å lagre egne søk eller eksportere søkeresultater til Excel el.lign." >
                    Logg inn
                </Button>
            </div>
        );
      };

    return render();
};

export default LoginView;