/* Get and show details of one selected ADED object, using Elasticsearch engine to get the details */
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Collapse, Popconfirm, Select } from "antd";
import { PlusCircleFilled, MinusCircleFilled, LeftOutlined, CaretUpOutlined, CaretDownOutlined, ArrowRightOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";

import { ArtefactResult, StoreState, ADEDResult, ADEDAttributt, TypeAttributes, SearchResult, ConsistingOf, ADEDDatedObject, RoleEnum } from "../../../types";
import * as conf from "../../../config/SearchConfig";
import { ADED, ALERT_TYPE, Artefact, DISPLAY_TYPE } from "../../../constants";
import { getFormattedValue, testADEDType, replaceInType, getProjectAttributes, getArtefactNOs, getLabel } from "../../../utils/adedHelper";
import LookupData from "../../../services/lookupservice";
import { TypeBucket } from "./typebucket";
import { Relations } from "./relations";
import { Incidents } from "./incidents";
import { addMapMarkers } from "../../../store/mapSlice";
import { searchForArtefactMuseumNO, setSelectedItem } from "../../../store/searchSlice";
import { showAlert, showDisplayType } from "../../../store/appSlice";
import { navigateApp } from "../../../utils/appUtils";
import { excludedAttributter, hovedtypeAttr, MuseumNOKey } from "../../../config/ADEDConfig";
import Timeline from "./timeline/timeline";
import ADEDPhoto from "./photogallery/ADEDphoto";
import Kulturminner from "./kulturminner/kulturminner";
import { HasAdministratorPrivileges } from "../../../utils/userHelper";
import { deleteProject } from "../../../services/adminservice";
import { User } from "oidc-client";
import { isCanceled, useCancellablePromise } from "../../../utils/useCancelablePromise";

import potImg from "../../../images/pot.png";
import potWhiteImg from "../../../images/pot_white.png";
import listImg from "../../../images/list.png";
import "./details.css";

interface iParams {
    id?: string
}

const ADEDDetails = () => {
    const { Panel } = Collapse;
    const { Option } = Select;

    const params:iParams = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cancellablePromise } = useCancellablePromise();

    const [projectInfo, setProjectInfo] = useState<any>(null);
    const [showAttributes, setShowAttributes] = useState<boolean>(true);
    const [showProjectAttributes, setShowProjectAttributes] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showConsistingOf, setShowConsistingOf] = useState<boolean>(true);
    const [adedResult, setAdedResult] = useState<ADEDResult | null>(null);
    const [showTimeline, setShowTimeline] = useState<boolean>(false);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    const selectedItem: ADEDResult | ArtefactResult | null = useSelector((state: StoreState) => state.search.selectedItem);
    const displayType: DISPLAY_TYPE = useSelector((state: StoreState) => state.app.displayType);
    const adedResults: SearchResult | null = useSelector((state: StoreState) => state.search.adedResults);
    const firstTime: boolean = useSelector((state: StoreState) => state.app.firstTime);
    const user: User | null = useSelector((state: StoreState) => state.user.user);
    const role: RoleEnum = useSelector((state: StoreState) => state.user.role);

    const adedID = useRef<string>("");

    useEffect(
        () => {
            if(selectedItem !== null && selectedItem.type === ADED) {
                setAdedResult(selectedItem as ADEDResult);

                if(selectedItem?.ObjektType === "Prosjekt") {
                    const adedQ = window.environment.aded_searchserver + conf.ADEDProjectUrlPart + conf.ADEDDocPart + "/" + params.id;

                    const load = async () => {
                        try {
                            const response = await cancellablePromise(
                                LookupData.getADED(adedQ, window.environment.aded_un || "", window.environment.aded_pwd || "")
                            );
            
                            setProjectInfo(response.result);
                        } catch (error) {
                            if (!isCanceled(error)) {
                                dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å hente prosjektinfo.", type: ALERT_TYPE.ERROR, manualClose: false }));
                            }
                        }
                    };
                    load();
                }
            }
            else {
                setAdedResult(null);
            }
    }, [selectedItem]);

    if(selectedItem === null || selectedItem.type === Artefact || adedID.current !== params.id) {
        adedID.current = params.id || '';
        
        let adedQ = window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDDocPart + "/" + params.id;

        const load = async () => {
            try {
                const response = await cancellablePromise(
                    LookupData.getADED(adedQ, window.environment.aded_un || "", window.environment.aded_pwd || "")
                );

                const adedResult = response.result as ADEDResult;
                if(adedResult) {
                    setShowMore(false);
                
                    if(adedResult.Shape && adedResult.Shape.type) {
                        markGeometry(adedResult);
                    }
    
                    dispatch(setSelectedItem(response.result));
                    if(displayType === DISPLAY_TYPE.MAP_VIEW) {
                        dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
                    }
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Fant ikke objektet.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            } catch (error) {
                if (!isCanceled(error)) {
                    dispatch(showAlert({title: "Feilet!", message: "Fant ikke objektet.", type: ALERT_TYPE.ERROR, manualClose: false }));
                }
            }
        };
        load();
    }

    const close = () => {
        dispatch(setSelectedItem(null));
        navigateApp(dispatch, navigate, "/");
    };
    
    const markGeometry = (r: ADEDResult) => {
        if (testADEDType(r)) {
            const mapMarker = {
                geometry: r.Shape?.coordinates,
                caption: r.ObjektId,
                type: r.ObjektType || '',
                shape: r.Shape?.type};
            dispatch(addMapMarkers([mapMarker]));
        }
        return true;
    };

    const searchForArtefacts = (m: string) => {
        if(m) {
            dispatch(searchForArtefactMuseumNO(m));
            navigateApp(dispatch, navigate, "/");
        }
        return true;
    };

    const renderKulturminner = (r: ADEDResult) => {
        var askeladdenIDattr = r.Attributter.filter((a: ADEDAttributt) => {
            return (a.Label.toLowerCase().indexOf("askeladden") !== -1);
        });

        if(askeladdenIDattr.length > 0 && askeladdenIDattr[0].Value) {
            const aIDs = askeladdenIDattr[0].Value.split(",") || [];
            return <Kulturminner lokaliteter={aIDs.map(l => { return l.trim() })} />;
        }

        return null;
    };

    const renderUndersokelse = (r: ADEDResult) => {
        const undersokelse = r.Attributter.filter((a: ADEDAttributt) => {
            return (a.Label.toLowerCase() === "undersøkt" && a.Value?.toLowerCase() === "true");
        });

        if(undersokelse.length === 0) {
            return <div></div>;
        }

        const datoBeskrevet = r.Attributter.filter((a: ADEDAttributt) => {
            return (a.Label.toLowerCase() === "dato beskrevet");
        });

        const beskrevetAv = r.Attributter.filter((a: ADEDAttributt) => {
            return (a.Label.toLowerCase() === "beskrevet av");
        });

        return (
            <Collapse expandIconPosition="end" style={{marginTop: "-2px"}}>
                <Panel header="Undersøkelse" key="panelUndersokelse">
                    <div className="margin-left">
                    <table className="margin-top object-link">
                        <tbody>
                            <tr>
                                <td className="details-key">Dato beskrevet</td>
                                <td className="details-value">{datoBeskrevet.length > 0 ? datoBeskrevet[0].Value : ""}</td>
                            </tr>
                            <tr>
                                <td className="details-key">Beskrevet av</td>
                                <td className="details-value">{beskrevetAv.length > 0 ? beskrevetAv[0].Value : ""}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </Panel>
            </Collapse>
        )
    }

    const renderAttrTable = (attr: Array<ADEDAttributt>, secondTable: boolean) => {
        return (
            <table className={`details-table ${secondTable ? "push-details-right": ""}`}>
                <tbody>
                    {attr.map((a: ADEDAttributt, i: number) => {
                        return (
                            <tr key={"attributeRow" + i}>
                                <td className="details-key">{getLabel(a.Label)}</td>
                                <td className="details-value">{getFormattedValue(a.Value)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    const renderAttributes = (r: ADEDResult) => {
        const typeAttr = hovedtypeAttr.filter((ht: TypeAttributes) => {
            return ht.typeKey === r.ObjektHovedType;
        });
        const typeExcl = typeAttr.length > 0 ? typeAttr[0].excludedAttr : [];
        let filteredAttributter = r.Attributter.filter((a: ADEDAttributt) => {
            return excludedAttributter.indexOf(a.Label) === -1 && typeExcl.indexOf(a.Label) === -1;
        });

        if(r.Hoh) {
            filteredAttributter.push({Label: "Høyde over havet", Value: r.Hoh.toFixed(0) + " moh"});
        }

        if(filteredAttributter.length < 1) {
            return (
                <div className="empty-result" >Finner ingen ytterligere informasjon om objektet.</div>
            );
        }

        if(r.ObjektType === "Prosjekt") {
            filteredAttributter = getProjectAttributes(filteredAttributter, showProjectAttributes);
        }
        else {
            filteredAttributter.unshift({Label: replaceInType(r.ObjektHovedType), Value: replaceInType(r.ObjektType)});
        }

        if(filteredAttributter.length > 5) {
            const part1 = filteredAttributter.splice(0, Math.ceil(filteredAttributter.length/2));
            return (
                <div className="details-table-container-content">
                    {renderAttrTable(part1, false)}
                    {renderAttrTable(filteredAttributter, true)}
                </div>
            );
        }

        return (
            <div className="details-table-container-content centered">
                {renderAttrTable(filteredAttributter, false)}
            </div>
        );
    };

    const renderRelations = (r: ADEDResult) => {
        if(r.RelatedObjects.length === 0) {
            return <div></div>;
        }

        return <Relations relations={r.RelatedObjects} />;
    };

    const renderDescription = (r: ADEDResult) => {
        const description = r.Attributter.filter((a: ADEDAttributt) => {
            return (a.Label.toLowerCase() === "beskrivelse" && a.Value);
        });

        if(description.length === 0 || !description[0].Value) {
            return <div></div>;
        }

        if(description[0].Value?.length < 500) {
            return (
                <div className="details-table-wrapper">
                    <div className="details-description">
                        <div className="details-subheader">Beskrivelse</div>
                        <div>{description[0].Value}</div>
                    </div>
                </div>
            )
        }

        return (
            <div className="details-table-wrapper">
                <div className="details-description">
                    <div className="details-subheader">Beskrivelse</div>
                    <div>{showMore ? description[0].Value : (description[0].Value.substring(0, 500) + " ...")}</div>
                    <Button type="link" onClick={() => setShowMore(!showMore)}>
                        {"Vis " + (showMore ? "mindre" : "mer...")}
                    </Button>
                </div>
            </div>
        );
    };

    const getRelation = (a: ADEDResult, dd: ADEDDatedObject) => {
        if(!dd.Relasjon) {
            return "";
        }

        const relations = dd.Relasjon.split(";");
        let relationList = getHeaderText(a);
        relations.forEach((r: string, i: number) => {
            if(i > 0) {
                relationList += " som";
            }
            let rs = r.split(":");
            relationList += " " + rs[0].toLowerCase() + " " + rs[1].toLowerCase();
        });

        return relationList;
    }

    const renderDatingInformation = (r: ADEDResult) => {
        if(r.DatedDescendants.length === 0) {
            return <div></div>;
        }

        return (
            <div className="details-table-wrapper">
                <div className="details-description">
                    <div className="details-subheader">
                        Datering
                    </div>
                    <div>
                        Listen viser prøver som er tatt av dette og relaterte objekter, med tilhørende analyse. <br/>
                        Dette objektet {" (" + getHeaderText(r) + " - " + r.InputId + ") "} har fått median alder {r.MedianAge} beregnet ut fra disse analysene, 
                        og det er den beregnede verdien som brukes til å filtrere objekter basert på Egenskaper/Alder.
                    </div>
                    <div className="datering-container">
                        <table className="datering-table">
                            <thead>
                                <tr>
                                    <th>Prøve tatt av</th>
                                    <th>Prøvetype</th>
                                    <th>Analysetype</th>
                                    <th>C14 Alder</th>
                                </tr>
                            </thead>
                            <tbody>
                                {r.DatedDescendants.map((dd: ADEDDatedObject, i: number) => {
                                    return (
                                        <tr key={`dd_${i}`}>
                                            <td>
                                                <b>{replaceInType(dd.ObjektType)}</b>
                                                {(dd.Relasjon) &&
                                                    <div>
                                                        <i>Relasjon: </i>
                                                        <span className="margin-left-small">{getRelation(r, dd)}</span>
                                                    </div>
                                                }
                                            </td>
                                            <td>{dd.Provetype}</td>
                                            <td>
                                                <Link to={"/ADED/" + dd.ProveanalyseObjektId} title="Gå til analyseresultatet" className="margin-left-small" >
                                                    <b>{dd.Analysetype}</b>
                                                    <ArrowRightOutlined className="margin-left" />
                                                </Link>
                                            </td>
                                            <td>{dd.Alder}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    const renderProject = (r: ADEDResult) => {
        if(r.ProsjektId === r.ObjektId) { // Prosjektet er også et objekt
            return <div></div>;
        }

        return (
            <div className="details-objekt-subheader">
                <Link to={"/ADED/" + r.ProsjektId} title="Gå til prosjekt" className="margin-left-small" >
                    <span className="margin-right-small">Prosjekt: </span>
                    <b>{r.Prosjektnavn}</b>
                </Link>
            </div>
        );
    };

    const renderConsistingOf = () => {
        if(!projectInfo) {
            return <div></div>;
        }

        const p = projectInfo as ADEDResult;

        let consistingOf:Array<ConsistingOf> = [];
        if(p.ConsistingOf && p.ConsistingOf.length > 0) {
            consistingOf = p.ConsistingOf;
        }

        if(consistingOf.length === 0) {
            return <div></div>;
        }

        return (
            <div className="details-related-info-container">
                <div className={`details-table-container-header ${showConsistingOf ? "" : "white"}`}>
                    Består av
                    <Button type="link" onClick={() => setShowConsistingOf(!showConsistingOf)} >
                        {showConsistingOf && <MinusCircleFilled /> }
                        {!showConsistingOf && <PlusCircleFilled /> }
                    </Button>
                </div>
                {showConsistingOf &&
                    <div className="details-related-info-container-content">
                        <TypeBucket types={consistingOf} selectedItem={selectedItem} />
                    </div>
                }
            </div>
        );
    };

    const renderMuseumNOs = () => {
        if(!projectInfo) {
            return <div></div>;
        }

        const p = projectInfo as ADEDResult;

        let fa = p.Attributter.filter((a: ADEDAttributt) => {
            return a.Label === MuseumNOKey;
        });

        const museumNOAttributt:ADEDAttributt | null = fa.length > 0 ? fa[0] : null;
        let museumNOs: Array<string> = [];
        if(museumNOAttributt) {
            museumNOs = getArtefactNOs(museumNOAttributt.Value || "");
        }

        if(museumNOs.length === 0) {
            return <div></div>;
        }

        return (
            <div className="details-related-info-container">
                <div>
                    <div className="details-table-container-header">
                        Gjenstander
                    </div>
                    <div className="details-related-info-container-content">
                        <div className="margin-bottom">
                            Søk etter gjenstander tilknyttet museumsnummer:
                        </div>
                        {museumNOs.length < 10 &&
                            museumNOs.map((m: string, i: number) => {
                                return (
                                    <Button key={`museumNO${i}`} type="link" className="margin-right" title="Søk etter gjenstander"
                                            onClick={() => searchForArtefacts(m)}>
                                        <b>{m}</b>
                                    </Button>
                                );
                            })
                        }
                        {museumNOs.length >= 10 &&
                            <Select defaultValue="Velg museumsnr." onChange={searchForArtefacts}>
                                {museumNOs.map(m => {
                                    return (
                                        <Option key={m} value={m}>
                                            {m}
                                        </Option>
                                    )
                                })}
                            </Select>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const renderKommuneFylke = (r: ADEDResult) => {
        if(r.Kommuner || r.Fylker) {
            return " - " + r.Kommuner + ", " + r.Fylker;
        }

        return "";
    };

    const getHeaderText = (r: ADEDResult) => {
        let header = "";
        if(r.ProsjektId === r.ObjektId) {
            header = r.Prosjektnavn || "";
        }
        else {
            const type = (r.ObjektType && r.ObjektType !== "Ikke angitt") ? r.ObjektType : r.ObjektHovedType || "";
            header = replaceInType(type);
        }

        return header;
    }

    const renderHeader = () => {
        if(adedResult === null) {
            return <div></div>;
        }

        const header = getHeaderText(adedResult);

        return (
            <div className="details-header">
                <b>
                    { header + " - " + adedResult.InputId }
                </b>
            </div>
        );
    };

    const handlePopupOk = () => {
        setPopupVisible(false);
        
        if(adedResult && adedResult.ProsjektId) {
            deleteProject(user as User, adedResult.ProsjektId).then((response: any) => {
                if (response.status === 200) {
                    dispatch(showAlert({title: "Slettet!", message: "Prosjektet er slettet, men det er fortsatt tilgjengelig for søk inntil automatisk re-indeksering er kjørt", 
                        type: ALERT_TYPE.INFO, manualClose: true}));
                    close();
                }
                else {
                    dispatch(showAlert({title: "Feilet!", message: "Klarte ikke å slette prosjektet", type: ALERT_TYPE.ERROR, manualClose: false}));
                }
            });
        }
    };

    const handlePopupCancel = () => {
        setPopupVisible(false);
    };

    const confirmDeleteProject = () => {
        setPopupVisible(true);
    };

    const renderResult = () => {
        if(adedResult === null) {
            return <div></div>;
        }

        return (
            <div className="details-form">
                <div className="details-objekt-header">
                    {replaceInType(adedResult.ObjektHovedType)}
                    {renderKommuneFylke(adedResult)}
                    {adedResult.ObjektType === "Prosjekt" && HasAdministratorPrivileges(role) &&
                        <div className="delete-project-container" >
                            <Button onClick={confirmDeleteProject} >
                                <DeleteOutlined />
                                Slett
                            </Button>
                            <Popconfirm
                                title={`Slett prosjekt ${adedResult.Prosjektnavn}? OBS! Dette fjerner prosjektet fra datagrunnlaget, og er en irreversibel handling!`}
                                open={popupVisible}
                                onConfirm={handlePopupOk}
                                onCancel={handlePopupCancel}
                                cancelText="Avbryt"
                                placement="top"
                            ></Popconfirm>
                        </div>
                    }
                    {renderProject(adedResult)}
                    <div className="timeline-btn-container">
                        <Button className={`timeline-btn ${showTimeline ? "shown" : ""}`} onClick={() => {setShowTimeline(!showTimeline)}} 
                            title={`${showTimeline ? "Skjul" : "Vis"} tidsepoker med lenker til norgeshistorie.no`}>
                            <img src={showTimeline ? potWhiteImg : potImg} alt="Vase" />
                            <span>{`${showTimeline ? "Skjul" : "Vis"} tidsepoker`}</span>
                            {showTimeline && <CaretUpOutlined /> }
                            {!showTimeline && <CaretDownOutlined /> }
                        </Button>
                    </div>
                    {adedResult.ObjektType === "Prosjekt" && 
                    <div className="details-objekt-download">
                        <Button>
                            <a href={"https://s3-oslo.educloud.no/1008-green-aded-prod-repositorium-bucket/" + adedResult.ProsjektId + "/" + adedResult.ProsjektId + ".zip"}>
                                <DownloadOutlined />
                                &ensp; Last ned prosjektdata
                            </a>
                        </Button>
                    </div>
                    }
                </div>

                {showTimeline && <Timeline />}

                <div className="details-table-wrapper">
                    <div className={`details-table-container ${showAttributes ? "" : "w60p"}`}>
                        <div className={`details-table-container-header ${showAttributes ? "" : "white"}`}>
                            Egenskaper
                            <Button type="link" onClick={() => setShowAttributes(!showAttributes)} >
                                {showAttributes && <MinusCircleFilled /> }
                                {!showAttributes && <PlusCircleFilled /> }
                            </Button>
                        </div>
                        { showAttributes && renderAttributes(adedResult) }
                        { showAttributes && adedResult.ObjektType === "Prosjekt" && 
                            <div>
                                <Button type="link" onClick={() => {setShowProjectAttributes(!showProjectAttributes)}} 
                                        title={`Vis ${showProjectAttributes ? "utvalgte": "alle"} egenskaper`}>
                                    {`Vis ${showProjectAttributes ? "mindre": "mer"}`}
                                </Button>
                            </div>
                        }
                        { showAttributes && renderDescription(adedResult) }

                        { showAttributes && renderDatingInformation(adedResult) }

                        {(!adedResult.CenterPoint && !adedResult.Shape) &&
                            <div className="no-geometry" style={{marginLeft: "3rem"}}>
                                Ingen geometri registrert
                            </div>
                        }
                    </div>
                </div>

                {renderConsistingOf()}

                {renderKulturminner(adedResult)}
                
                { adedResult.ObjektType !== "Prosjekt" && 
                    <div className="details-related-info-container">
                        <div className="details-table-container-header">
                            Relasjoner
                        </div>
                        <div className="details-related-info-container-content">
                            {renderRelations(adedResult)}
                            <Incidents objectID={adedResult.ObjektId} incidents={adedResult.Hendelser} />
                            {renderUndersokelse(adedResult)}
                        </div>
                    </div>
                }

                {renderMuseumNOs()}

                { adedResult.ObjektType === "Prosjekt" &&
                    <ADEDPhoto />
                }
            </div>
        );
    };

    const renderBackBtn = () => {
        if(!adedResults) {
            return <div></div>;
        }

        return (
            <Button type="link" className="btn-link-small no-padding resultlist-btn" onClick={() => close()} >
                Til resultatlisten
                <img src={listImg} alt="List" className="info-header-btn-img" />
            </Button>
        );
    };

    const goToPrevious = () => {
        navigate(-1);
    };

    const renderNavigation = () => {
        if(navigate.length < 1) {
            return <div></div>
        }

        return (
            <div className="info-header">
                <div className="info-header-btns">
                    { !firstTime &&
                    <Button type="link" className="btn-link-small no-padding" onClick={goToPrevious} >
                        <LeftOutlined />
                        Forrige
                    </Button>
                    }

                    { renderBackBtn() }
                </div>
                { renderHeader() }
            </div>
        );
    };

    return (
        <div className="details-container"> 
            {renderNavigation()}
            <div className="details-content">
                {renderResult()}
            </div>
        </div>
    );
};

export default ADEDDetails;