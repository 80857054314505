/* UI for performing string search in the ADED data */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Input } from "antd";

import * as conf from "../../../config/SearchConfig";
import SearchData from "../../../services/searchservice";
import { ADED, DISPLAY_TYPE } from "../../../constants";
import { StoreState, Filter, SortField } from "../../../types";
import { ADEDResultIsEmpty } from "../../../utils/searchHelper";
import { clearMapSearchResult } from "../../../store/mapSlice";
import { clearADEDSearchResult, getSearchResult, setSearchResult, setSearchResultFilters, setSelectedItem, updateSearchResultFilters } from "../../../store/searchSlice";
import { showDisplayType, showLoading } from "../../../store/appSlice";
import { getActiveFilterValues, parseCurrentFilters } from "../../../utils/adedHelper";
import { navigateApp } from "../../../utils";

const SearchBarADED: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [searchVal, setSearchVal] = useState<string>("");
    
    const searchQuery: string = useSelector((state: StoreState) => state.search.searchQueryADED);
    const sortField: SortField = useSelector((state: StoreState) => state.search.sortField);
    const resultFilters: Array<Filter> = useSelector((state: StoreState) => state.search.resultFilters);
    const maxHits: number = useSelector((state: StoreState) => state.search.maxHits);
    const geoBBox: Array<number> | null = useSelector((state: StoreState) => state.search.geoBBox);

    const { Search } = Input;

    const performSearch = () => {
        SearchData.post(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart, 
            resultFilters, searchQuery, maxHits, 1, sortField,  window.environment.aded_un || "", window.environment.aded_pwd || "", geoBBox).then(response => {
            if(location.pathname !== "/") {
                navigateApp(dispatch, navigate, "/");  // On new result - always navigate to root
            }
            dispatch(setSelectedItem(null));

            const adedResults = response;
            if(adedResults) {
                dispatch(updateSearchResultFilters(adedResults));
            }

            dispatch(setSearchResult(response));

            if(!ADEDResultIsEmpty(response)) {
                dispatch(showDisplayType(DISPLAY_TYPE.SPLIT_VIEW));
            }
        });

        if(searchQuery !== searchVal) {
            setSearchVal(searchQuery);
        }
    };

    useEffect(
        () => {
        // we don't want to make a "blind" search when searchQuery is empty:
        if (searchQuery) {
            if(resultFilters.length === 0) { // Initialize filter buckets if not already in place before performing search
                SearchData.postBucketsOnly(window.environment.aded_searchserver + conf.ADEDUrlPart + conf.ADEDSearchPart,
                    window.environment.aded_un || "", window.environment.aded_pwd || "").then(response => {
                    if(response.error === null) {
                        const filters = parseCurrentFilters(response);
                        dispatch(setSearchResultFilters(filters));
                    }

                    performSearch();
                });
            }
            else {
                performSearch();
            }
        }
        else {
            if (searchQuery === "") {
                setSearchVal("");
            }
            const activeFilters = getActiveFilterValues(resultFilters);
            if(activeFilters.length === 0) {
                dispatch(clearADEDSearchResult());
                dispatch(clearMapSearchResult());
            }
        }
    }, [searchQuery]);

    useEffect(
        () => {
            if(!geoBBox && searchQuery !== "") { // perform search if geoBBox is removed and no filters active
                const activeFilters = getActiveFilterValues(resultFilters);
                if(activeFilters.length === 0) {
                    performSearch();
                }
            }
    }, [geoBBox]);

    const showSearchResult = (q: string) => {
        if(q !== searchQuery) {
            dispatch(showLoading(true));
            dispatch(getSearchResult({type: ADED, phrase: q}));
        }
    };

    return (
        <div className="searchbar">
            <Search suffix={""} placeholder="Søk på ord og uttrykk" value={searchVal} onChange={(e) => setSearchVal(e.target.value)}
                onSearch={value => showSearchResult(value)} className="searchbar-input" allowClear enterButton maxLength={100} />
        </div>
    );
}

export default SearchBarADED;
