/* String constants for search functionality */
export const VALUE_FILTER = "value";
export type VALUE_FILTER = typeof VALUE_FILTER;

export const RANGE_FILTER = "range";
export type RANGE_FILTER = typeof RANGE_FILTER;

export const ADED = "ADED";
export type ADED = typeof ADED;

export const Artefact = "Artefact";
export type Artefact = typeof Artefact;

export const MapHit = "MapHit";
export type MapHit = typeof MapHit;

export const ProjectKey = "ProjectBucket";
export const ProjectTypeKey = ProjectKey + ".keyword";

/* String constants for ADED Elasticsearch engine */
export const BUCKET_SIZE = 500;
export const SEARCH_FIELDS = ["ObjektType", "ObjektHovedType", "Prosjektnavn", "Attributter.Value", "Kommuner", "InputId^2", "AskeladdenID^3"];

export const PUBLIC_USER = "public";