/* Index component, handling auth, state and routing */
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import App from "./components/App";
import storeSetup from "./storeSetup";
import * as serviceWorker from "./serviceWorker";
import { uuidv4 } from "./utils";

import "./index.css";
import CallbackComponent from "./components/app/login/callbackComponent";
import { AuthService } from "./services/authorisationservice";
import Home from "./components/home/home";

console.log("env: " + (window.environment.aded_public_url || "-") + ", redirect uri: " + window.environment.aded_redirect_uri);

if (localStorage.getItem("userIdentifyer") === null){ //Sett en unik brukerid dersom den ikke finnes fra før
    localStorage.setItem("userIdentifyer", uuidv4())
}

const authService = new AuthService();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={storeSetup.store}>
        <Router basename={ window.environment.aded_public_url || '/' } >
            <Routes>
                <Route path="/callback" element={<CallbackComponent userManager={authService.userManager} />} />
                <Route path="/home" element={<Home />} />
                <Route path="/*" element={<App authService={authService} />} />
            </Routes>
        </Router>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
