/* String constants for App */
export const MAP_VIEW = "map";
export const INFO_VIEW = "info";
export const SPLIT_VIEW = "split";

export enum DISPLAY_TYPE { MAP_VIEW, INFO_VIEW, SPLIT_VIEW };

export const INFO = "info";
export const ERROR = "error";
export const SEARCH = "search";

export enum ALERT_TYPE { INFO, ERROR, SEARCH };

export const FAILED_STATUS = "Failed";
export const IMPORTED_SUCCESS_STATUS = "Imported";
export const EXPORTED_SUCCESS_STATUS = "Exported";