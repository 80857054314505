/* app state - layout and warnings */
import { createSlice } from '@reduxjs/toolkit'
import { AppState } from "../types";
import { DISPLAY_TYPE } from "../constants";

const initialState: AppState = {
    loading: true,
    displayType: DISPLAY_TYPE.MAP_VIEW,
    firstTime: true,
    leftMenuOpen: true,
    leftMenuTopVisible: true
}

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        showDisplayType(state, action) {
            state.displayType = action.payload;
        },
        showLoading(state, action) {
            state.loading = action.payload;
        },
        showAlert(state, action) {
            state.alertContent = action.payload;
        },
        setFirstTime(state, action) {
            state.firstTime = action.payload;
        },
        setLeftMenuVisibility(state, action) {
            state.leftMenuOpen = action.payload;
        },
        setLeftMenuTopVisibility(state, action) {
            state.leftMenuTopVisible = action.payload;
        }
    }
});

export default appSlice.reducer;

export const { showDisplayType, showLoading, showAlert, setFirstTime, setLeftMenuVisibility, setLeftMenuTopVisibility } = appSlice.actions;
